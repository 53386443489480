import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

interface ModeAddEdit {
  [key: string]: string | undefined;
  modeEmployee?: "add" | "edit" | undefined;
}

const initialState: ModeAddEdit = {
  modeEmployee: undefined,
};

// TODO(DBB) : Retire this
const modeAddEdit = createSlice({
  name: "modeAddEdit",
  initialState,
  reducers: {
    setModeEmployeeAddEdit(state, action: PayloadAction<Partial<ModeAddEdit>>) {
      Object.entries(action.payload).forEach(([k, v]) => {
        state[k] = v;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setModeEmployeeAddEdit } = modeAddEdit.actions;

export default modeAddEdit.reducer;
