import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";

import { resetCurrentEmployee } from "../../../features/state/employee/currentEmployeeSlice";
import { resetProvisionedEmployee } from "../../../features/state/employee/provisionedEmployeeSlice";
import { resetProvisionedEmployeePayRate } from "../../../features/state/employeePayRate/employeePayRateSlice";
import { resetProvisionedEmployeeScheme } from "../../../features/state/employeeScheme/employeeSchemeSlice";
import { resetProvisionedPayroll } from "../../../features/state/payroll/payrollSlice";
import { resetCurrentCompany } from "../../../features/state/company/companySlice";
import { resetCurrentPayrollEntry } from "../../../features/state/payrollEntry/payrollEntrySlice";
import { setCurrentCompany } from "../../../features/state/company/companySlice";

import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import { Company } from "../../../types/Company";

interface Props {
  currentUserMemberCompanies: any;
  triggerLazyGetCompany: any;
  handleClose: () => void;
}

const CompanySelectorOptions: FC<Props> = ({
  currentUserMemberCompanies,
  triggerLazyGetCompany,
  handleClose,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentCompany = useAppSelector((state) => state.currentCompany);

  // Handle Company selection
  const handleCompanySelect = (selectedCompanyId: number) => {
    // Why?
    triggerLazyGetCompany(selectedCompanyId).then((response: any) => {
      dispatch(setCurrentCompany(response.data));
    });

    // Purge Redux stores
    dispatch(resetCurrentCompany());
    dispatch(resetCurrentEmployee());
    dispatch(resetProvisionedEmployee());
    dispatch(resetProvisionedEmployeePayRate());
    dispatch(resetProvisionedEmployeeScheme());
    dispatch(resetProvisionedPayroll());
    dispatch(resetCurrentPayrollEntry());

    // NOTE(DBB) : Assures both Redux state and
    //             React useContext are purged
    navigate("/home");
  };

  return (
    <>
      {currentUserMemberCompanies &&
        currentUserMemberCompanies.length > 1 &&
        currentUserMemberCompanies
          .slice(0, 3)
          .filter((item: Company) => item.id !== currentCompany.id)
          // .sort((a: any, b: any) => parseFloat(a.id) - parseFloat(b.id))
          .sort((a: Company, b: Company) =>
            a.name_trading.localeCompare(b.name_trading)
          )
          .map((item: Company) => {
            return (
              <MenuItem
                key={item.id}
                onClick={() => handleCompanySelect(item.id)}
              >
                {item.name_trading}
              </MenuItem>
            );
          })}

      {currentUserMemberCompanies.length > 3 && (
        <>
          <Divider />

          <Box sx={{ padding: "0.33rem 1rem" }}>
            <Button size="medium" variant="outlined" href="/company/select">
              View all Companies
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default CompanySelectorOptions;
