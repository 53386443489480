import { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Footer from "./Footer";

import DataGridPreviewPayroll from "./DataGridPreviewPayroll";
import HeaderSummary from "./HeaderSummary";

interface Props {
  handleStepperPrev: () => void;
  handleStepperNext: () => void;
}

const PreviewPayroll: FC<Props> = ({
  handleStepperPrev,
  handleStepperNext,
}) => {
  return (
    <>
      <Typography
        component="h5"
        variant="h5"
        gutterBottom
        sx={{ marginTop: "2rem" }}
      >
        Preview & Approve
      </Typography>

      <HeaderSummary />

      <Box>
        <DataGridPreviewPayroll />
      </Box>

      <Divider className="block w-full" sx={{ mt: "16px", mb: "24px" }} />

      <Footer
        handleStepperPrev={handleStepperPrev}
        handleStepperNext={handleStepperNext}
      />
    </>
  );
};

export default PreviewPayroll;
