import { Outlet } from "react-router-dom";

function LayoutSignIn() {
  return (
    <>
      <div id="Layout" style={{ background: "#0059C7" }}>
        <div id="content" className="h-screen">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default LayoutSignIn;
