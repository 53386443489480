import { FC } from "react";
import SectionHeader from "./header/SectionHeader";
import SectionRunPayrolls from "./paryoll-run/SectionRunPayrolls";
import SectionEmployeesOverview from "./employees-overview/SectionEmployeesOverview";
import SectionLatestCompletedPayroll from "./payroll-completed/SectionLatestCompletedPayroll";

const DashboardHome: FC = () => {
  return (
    <>
      <SectionHeader />
      <SectionRunPayrolls />
      <SectionLatestCompletedPayroll />
      <SectionEmployeesOverview />
    </>
  );
};

export default DashboardHome;
