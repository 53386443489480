import { FC } from "react";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

import Container from "@mui/material/Container";
import OptionsCompanySelect from "./OptionsCompanySelect";
import { User } from "../../../types/User";

const DashboardCompanyAdd: FC = () => {
  const currentUserMemberCompanies = useAppSelector((state: RootState) =>
    state.currentUser?.members?.map((item: User) => item.company)
  );

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: "#FDFBFF",
        }}
      >
        <OptionsCompanySelect
          currentUserMemberCompanies={currentUserMemberCompanies}
        />
      </Container>
    </>
  );
};

export default DashboardCompanyAdd;
