import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  PasswordResetEmail,
  SchemaPasswordResetEmail,
} from "./ValidatePasswordResetEmail";

import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { useResetPasswordRequestMutation } from "../../features/api/apiPasswordReset";

const FormPasswordResetEmail: FC = () => {
  const [submissionSuccessful, setSubmissionSuccessful] =
    useState<boolean>(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<PasswordResetEmail>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaPasswordResetEmail),
    defaultValues: {
      email_address: "",
    },
  });

  const [resetPasswordRequest] = useResetPasswordRequestMutation();

  const onSubmit = async (data: PasswordResetEmail) => {
    resetPasswordRequest(data)
      .unwrap()
      .then(() => setSubmissionSuccessful(true));
  };

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        className="mx-auto"
        style={{ maxWidth: "696px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack direction="column" gap={4}>
          <Stack direction="column" gap={1}>
            <Typography
              component="h2"
              variant="h4"
              sx={{ marginBottom: "1rem" }}
            >
              Reset Password
            </Typography>
            <Typography>
              Enter your email address below to begin the password reset
              process.
            </Typography>
            <Typography>
              For further support, contact{" "}
              <Link href="mailto:support@cloudpayroll.io">
                <strong>support@cloudpayroll.io</strong>
              </Link>
              .
            </Typography>
          </Stack>

          {!submissionSuccessful ? (
            <Stack direction="column" gap={2}>
              {/* Input : Email Address */}
              <Controller
                control={control}
                name="email_address"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email Address"
                    variant="outlined"
                    error={!!errors.email_address}
                    helperText={errors.email_address?.message}
                    fullWidth={true}
                    disabled={false}
                  />
                )}
              />

              <Button
                variant="contained"
                type="submit"
                size="large"
                disableElevation
              >
                Submit
              </Button>
            </Stack>
          ) : (
            <Alert severity="success">
              <AlertTitle>Submission Successful</AlertTitle>
              <Typography sx={{ marginBottom: "0.75rem" }}>
                You will receive an email from{" "}
                <strong>noreply@cloudpayroll.io</strong>. Check your spam/junk
                folder if nothing arrives in your primary inbox.
              </Typography>
              <Button href="/signin" variant="outlined" color="success">
                Return to Sign In
              </Button>
            </Alert>
          )}
        </Stack>
      </form>
    </>
  );
};

export default FormPasswordResetEmail;
