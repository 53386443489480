import { cloudPayrollApi } from "./cloudPayrollApi";

import { UserSignUp } from "../../types/UserSignUp";
import { UserSignUpPassword } from "../../types/UserSignUpPassword";
import { User } from "../../types/User";

const apiSignUp = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // POST User Sign Up
    submitUserSignUp: builder.mutation<
      Partial<UserSignUp>,
      Partial<UserSignUp>
    >({
      query: (userSignUp) => ({
        url: `/signup`,
        method: "POST",
        body: userSignUp,
      }),
      invalidatesTags: ["UserSignUp"],
    }),

    // POST User Sign Up Password
    submitUserSignUpPassword: builder.mutation<
      Partial<UserSignUpPassword>,
      Partial<UserSignUpPassword>
    >({
      query: (userSignUp) => ({
        url: `/signup/setSignUpPassword`,
        method: "POST",
        body: userSignUp,
      }),
      invalidatesTags: ["UserSignUp"],
    }),

    // POST User Sign Up Password
    verifyUserSignUpPassword: builder.mutation<
      boolean,
      Partial<UserSignUpPassword>
    >({
      query: (userSignUp) => ({
        url: `/signup/verifySignupCode`,
        method: "POST",
        body: userSignUp,
      }),
      invalidatesTags: ["UserSignUp"],
    }),

    // POST Resend Email Verification Code
    resendEmailVerificationCode: builder.mutation<
      Partial<User>,
      Partial<UserSignUp>
    >({
      query: (userEmailAddress) => ({
        url: `/signup/generateNewCode`,
        method: "POST",
        body: userEmailAddress,
      }),
    }),
  }),
});

export const {
  useSubmitUserSignUpMutation,
  useSubmitUserSignUpPasswordMutation,
  useVerifyUserSignUpPasswordMutation,
  useResendEmailVerificationCodeMutation,
} = apiSignUp;
