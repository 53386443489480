import { FC } from 'react'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'
import Button from '@mui/material/Button'

interface NoDataProps {
  title: string;
  page: string;
  description: string;
  icon?: string;
  callback?: (data: any) => any;
}

const customStyles = {
  mb16: {
    marginBottom: "16px"
  }
}

const NoData: FC<NoDataProps> = ({ title, page, description, callback }) => {
  const buttonName = {
    payComponent: "Add a pay component",
    schemes: "Add a scheme",
    paySchedules: "Add first pay schedule",
    costCentres: "Add a cost centre"
  }

  return (
    <>
      <Typography component="h5" variant="h5" gutterBottom sx={customStyles.mb16}>
        {title}
      </Typography>
      <Typography component="p" variant="body1" gutterBottom sx={customStyles.mb16}>
        {description}
      </Typography>
      <Button variant="contained" onClick={callback} endIcon={<Icon>add</Icon>}>
        {buttonName[page as keyof typeof buttonName]}
      </Button>
    </>
  )
}

export default NoData;
