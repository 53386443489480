import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import SubHeader from "../SubHeader";
import SectionRunPayrolls from "./01-run-payrolls/SectionRunPayrolls";
import SectionPayrollConfigurations from "./03-payroll-configurations/SectionPayrollConfigurations";
import SectionLatestCompletedPayroll from "./02-latest-completed-payroll/SectionLatestCompletedPayroll";

const DashboardOverview: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <SubHeader
        title="Overview"
        page="overview"
        icon="home"
        callback={() => {
          navigate("/payrolls/run");
        }}
      />

      <Container maxWidth="xl" sx={{ width: "100%", marginTop: "1rem" }}>
        <Stack direction="column">
          <SectionRunPayrolls />
          <SectionLatestCompletedPayroll />
          <SectionPayrollConfigurations />
        </Stack>
      </Container>
    </>
  );
};

export default DashboardOverview;
