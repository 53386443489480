// Ref: https://stackoverflow.com/questions/69864165/error-privateroute-is-not-a-route-component-all-component-children-of-rou
// Ref: https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5

import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";

import { User } from "../../../types/User";
import IdleTimer from "../idle-timer/IdleTimer";

const ProtectedRoute: FC = () => {
  const currentUser: Partial<User> = useAppSelector(
    (state) => state.currentUser
  );

  const checkUser = {
    id: currentUser.id,
    email_address: currentUser.email_address,
    email_verified: currentUser.email_verified,
    account_type: currentUser.account_type,
    // status: currentUser.status,
  };

  const userExists = Object.values(checkUser).every((x) => !!x);

  const userAccountTypeStandard = checkUser.account_type === "STANDARD";

  const userPasses = userExists && userAccountTypeStandard;

  return userPasses ? (
    <IdleTimer>
      <Outlet />
    </IdleTimer>
  ) : (
    <Navigate to="/signin" />
  );
};

export default ProtectedRoute;
