import { FC, useState, useEffect, useContext } from "react";
import { RootState } from "../../../../app/store";
import { useAppSelector } from "../../../../app/hooks";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { PaySchedule, SchemaPaySchedule } from "./ValidationPaySchedule";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormHelperText from "@mui/material/FormHelperText";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { CurrentPayrollScheduleContext } from "../DashboardPaySchedules";
import { useGetCurrentTaxYearQuery } from "../../../../features/api/apiTaxYears";

import { useGeneratePayrollsByPayrollScheduleIdMutation } from "../../../../features/api/apiPayrolls";
import {
  useCreatePayrollScheduleByCompanyIdMutation,
  useLazyGetPayrollScheduleQuery,
  useUpdatePayrollScheduleMutation,
} from "../../../../features/api/apiPayrollSchedules";
import { useGetAllPayrollFrequenciesQuery } from "../../../../features/api/apiPayrollFrequencies";
import { PayrollFrequency } from "../../../../types/PayrollFrequency";

interface SelectItem {
  id: string | number;
  name: string;
}

interface Props {
  handleModalClose: () => void;
}

const FormPaySchedule: FC<Props> = ({ handleModalClose }) => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<PaySchedule>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaPaySchedule),
    defaultValues: {
      name: "",
      payroll_frequency: "",
      monthly_pay_day: 1,
      weekly_pay_day: 0,
    },
  });

  const { data: dataPayFrequencies } = useGetAllPayrollFrequenciesQuery();

  const filteredPayFrequencies = dataPayFrequencies?.filter(
    (item: PayrollFrequency) => item.id === 3 || item.id === 5
  );

  const watchPayFrequency = watch("payroll_frequency", undefined);

  const { payrollScheduleId, isEdit } = useContext(
    CurrentPayrollScheduleContext
  );

  const [createPayrollScheduleByCompanyId] =
    useCreatePayrollScheduleByCompanyIdMutation();

  const [triggerLazyGetPayrollSchedule] = useLazyGetPayrollScheduleQuery();

  const [updatePayrollSchedule] = useUpdatePayrollScheduleMutation();

  const [generatePayrolls] = useGeneratePayrollsByPayrollScheduleIdMutation();

  const { data: dataGetCurrentTaxYear } = useGetCurrentTaxYearQuery();

  useEffect(() => {
    isEdit &&
      payrollScheduleId &&
      triggerLazyGetPayrollSchedule(payrollScheduleId).then((res) => {
        const { name, payroll_frequency, monthly_pay_day, weekly_pay_day } =
          res.data || {};

        reset({
          name: name,
          payroll_frequency: payroll_frequency.id || "",
          monthly_pay_day: monthly_pay_day || 0,
          weekly_pay_day: weekly_pay_day || 0,
        });
      });
  }, [isEdit, payrollScheduleId, triggerLazyGetPayrollSchedule, reset]);

  const onSubmit = (data: any) => {
    const submissionPaySchedule = {
      company: currentCompanyId,
      status: "ACTIVE",
      ...data,
    };

    // Add
    !isEdit &&
      currentCompanyId &&
      createPayrollScheduleByCompanyId(submissionPaySchedule)
        .unwrap()
        .then((res) => {
          // NOTE(DBB) : Generate Payrolls for current Tax Year
          dataGetCurrentTaxYear &&
            res &&
            generatePayrolls({
              payroll_schedule_id: Number(res.id),
              tax_year_id: Number(dataGetCurrentTaxYear.id),
            });
        })
        .then(() => {
          reset({
            name: "",
            payroll_frequency: "" as unknown as undefined,
            monthly_pay_day: 0,
            weekly_pay_day: 0,
          });
        })
        .then(() => {
          handleModalClose();
        });

    // Edit
    const submissionPayScheduleEdit = {
      id: payrollScheduleId,
      ...submissionPaySchedule,
    };

    isEdit &&
      currentCompanyId &&
      payrollScheduleId &&
      updatePayrollSchedule(submissionPayScheduleEdit).then(() => {
        handleModalClose();
      });
  };

  const onError = (errors: object) => {
    Object.values(errors).forEach((item) =>
      setSnackbar({
        children: `${item.message}`,
        severity: "error",
      })
    );
  };

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Stack direction="column" spacing={3}>
          <Typography component="h6" variant="h6">
            Pay Schedule Name
          </Typography>

          <Typography component="p">
            Choose a distigushable name for your Pay Schedule.
          </Typography>

          {/* Input : Pay Schedule Name  */}
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Pay Schedule Name"
                variant="outlined"
                fullWidth={true}
                error={!!errors.name}
                helperText={errors.name ? errors.name?.message : null}
              />
            )}
          />

          <Divider orientation="horizontal" />

          <Typography component="h6" variant="h6">
            Pay Frequency
          </Typography>

          {/* Input : Pay Frequency */}
          {dataPayFrequencies?.length !== 0 && (
            <FormControl sx={{ marginBottom: "2rem" }}>
              <Controller
                control={control}
                name="payroll_frequency"
                render={({ field: { name, value, onChange, onBlur } }) => (
                  <>
                    <InputLabel id="label-pay-frequency">
                      Pay Frequency
                    </InputLabel>
                    <Select
                      label="Pay Frequency"
                      labelId="label-pay-frequency"
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.payroll_frequency}
                      disabled={false}
                    >
                      {filteredPayFrequencies?.map((item: SelectItem) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                )}
              />
              <FormHelperText error={true}>
                {errors.payroll_frequency?.message}
              </FormHelperText>
            </FormControl>
          )}

          <Divider orientation="horizontal" />

          <Typography component="h6" variant="h6">
            Pay Day
          </Typography>

          {watchPayFrequency === ("" as unknown as number) && (
            <Typography component="p">
              Select a Pay Frequency above to choose a Pay Day.
            </Typography>
          )}

          {/* Input : Pay Date */}
          {watchPayFrequency === 3 && (
            <>
              <Typography component="p">
                Enter Pay Day by index number, betweeen Day 1 and Day 31.
                Selecting Day 29, 30 or 31 will roll backwards when not
                applicable within a given month.
              </Typography>

              <Controller
                control={control}
                name="monthly_pay_day"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Pay Day"
                    type="number"
                    variant="outlined"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: 1,
                      max: 31,
                    }}
                    sx={{ marginBottom: "2rem", width: "7rem" }}
                    error={!!errors.monthly_pay_day}
                    helperText={errors.monthly_pay_day?.message}
                    fullWidth={true}
                    disabled={false}
                  />
                )}
              />
            </>
          )}

          {watchPayFrequency === 5 && (
            <FormControl sx={{ marginBottom: "2rem" }}>
              <Controller
                control={control}
                name="weekly_pay_day"
                render={({ field }) => (
                  <>
                    <InputLabel id="label-pay-day">Pay Day</InputLabel>
                    <Select
                      {...field}
                      label="Pay Day"
                      labelId="label-pay-day"
                      error={!!errors.weekly_pay_day}
                      disabled={false}
                    >
                      {dataPayDayWeekly?.map((item: SelectItem) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                )}
              />
              <FormHelperText error={true}>
                {errors.weekly_pay_day?.message}
              </FormHelperText>
            </FormControl>
          )}

          <Divider orientation="horizontal" />

          <Stack direction="row" spacing={2}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disableElevation={true}
            >
              Submit & Close
            </Button>

            <Button
              type="button"
              onClick={() => {
                reset();
                handleModalClose();
              }}
              variant="outlined"
              size="large"
              color="secondary"
              disableElevation={true}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

const dataPayDayWeekly = [
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
  { id: 0, name: "Sunday" },
];

export default FormPaySchedule;
