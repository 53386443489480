import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { Controller, useForm, SubmitHandler } from "react-hook-form";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { useUserSignInMutation } from "../../features/api/cloudPayrollApi";
import { setCurrentUser } from "../../features/state/user/userSlice";
import { UserSignIn } from "../../types/UserSignIn";
import { User } from "../../types/User";

const FormSignIn: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    // register,
    control,
    handleSubmit,
    // formState: { errors },
  } = useForm<UserSignIn>();

  const [apiErrorStatus, setApiErrorStatus] = useState("");
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [
    userSignIn,
    {
      isError: isErrorUserSignIn,
      // error: errorUserSignIn
    },
  ] = useUserSignInMutation({});

  // Ref: https://stackoverflow.com/a/72572169
  const onSubmit: SubmitHandler<UserSignIn> = async (data) => {
    setLoading(true);

    userSignIn(data)
      .unwrap()
      .then((data) => dispatch(setCurrentUser(data as Partial<User>)))
      .then(() => {
        navigate("/welcome");
      })
      .catch((error: any) => {
        setLoading(false);
        renderError(error);
      });
  };

  // TODO(DBB) : This should be a standalone component
  function renderError(error: any) {
    // Ref: https://stackoverflow.com/a/70025645
    if (error && "data" in error) {
      setApiErrorStatus(error.data.status);
      setApiErrorMessage(error.data.error);
    }
    setApiErrorStatus(error?.data?.statusCode);
    setApiErrorMessage(error?.data?.message);
  }

  return (
    <>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          {/* Component: Title */}
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h4"
              gutterBottom
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              Sign In
            </Typography>
          </Grid>

          {/* Component: Email */}
          <Grid item xs={12}>
            <Controller
              name="email_address"
              control={control}
              defaultValue=""
              rules={{
                required: "Work email is required",
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "Invalid email address",
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email_address"
                  label="Work Email"
                  name="email_address"
                  autoComplete="email"
                  autoFocus
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          {/* Component: Password */}
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: "Password is required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  sx={{ marginBottom: "8px" }}
                  autoComplete="current-password"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />

            <div
              style={{
                color: "rgba(47, 48, 51, 0.72)",
                fontSize: "14px",
                lineHeight: "1.43",
                letterSpacing: "0.15px",
              }}
            >
              Forgot your password?{" "}
              <Link
                href="/password-reset"
                className="font-bold no-underline"
                sx={{ textDecoration: "none" }}
              >
                Reset password
              </Link>
              .
            </div>
          </Grid>

          {/* Component: CTA Sign In */}
          <Grid item xs={12}>
            <LoadingButton
              sx={{ minWidth: "200px" }}
              variant="contained"
              type="submit"
              loading={loading}
              fullWidth
            >
              Sign in
            </LoadingButton>
          </Grid>

          {/* Error: Conditional rendered error notice */}
          {isErrorUserSignIn && (
            <Grid item xs={12}>
              {apiErrorStatus && apiErrorMessage ? (
                <Alert severity="error">
                  <AlertTitle>Error {apiErrorStatus}</AlertTitle>
                  {apiErrorMessage} — contact support@cloudpayroll.io for
                  assistance.
                </Alert>
              ) : (
                <Alert severity="error">
                  <AlertTitle>Something went wrong.</AlertTitle>
                  Contact support@cloudpayroll.io for assistance.
                </Alert>
              )}
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
};

export default FormSignIn;
