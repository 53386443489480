// Ref: https://redux-toolkit.js.org/rtk-query/usage/persistence-and-rehydration#redux-persist
// Ref: https://stackoverflow.com/a/69399219

import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { cloudPayrollApi } from "../features/api/cloudPayrollApi";

// TODO(DBB) : IMPORTANT : `key` should be a .env variable
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [cloudPayrollApi.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    // TODO(DBB) : Set REDUX_DEVTOOLS as env var
    // devTools: process.env.REDUX_DEVTOOLS,
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(cloudPayrollApi.middleware),
});

export let persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
