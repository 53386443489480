import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { UserSignUp } from "../../../types/UserSignUp";
import { useResendEmailVerificationCodeMutation } from "../../../features/api/apiUserSignUp";

const DashboardCodeResend: FC = () => {
  const navigate = useNavigate();

  const { handleSubmit, control } = useForm<UserSignUp>();

  const [submitUserEmailAddress] = useResendEmailVerificationCodeMutation();

  const onSubmit = async (data: Partial<UserSignUp>) => {
    const emailAddress = data.email_address;

    const submission = {
      email_address: emailAddress as string,
    };

    submitUserEmailAddress(submission)
      .unwrap()
      .then(() => {
        navigate("/signup");
      })
      .catch((error: any) =>
        console.error(
          `[${error.data.statusCode}] ${error.data.error}: "${error.data.message}"`
        )
      );
  };

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        className="mx-auto"
        style={{ maxWidth: "696px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          className="p-12"
          sx={(theme) => ({
            [theme.breakpoints.down(992)]: {
              padding: "0 0 50px 0",
            },
          })}
        >
          <Grid item xs={12}>
            <Typography
              component="h4"
              variant="h4"
              gutterBottom
              className="m-4"
            >
              Resend Email Verification Code
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Verify your email address to continue creating a Cloud Payroll
              account.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Enter your account email address and check your inbox for a
              one-time email verification code. Don't forget to check your
              junk/spam folder.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <Link href="mailto:support@cloudpayroll.io" className="font-bold">
                Contact support
              </Link>{" "}
              if you do not get an email from us.
            </Typography>
          </Grid>

          {/* Email */}
          <Grid item xs={12}>
            <Controller
              name="email_address"
              control={control}
              defaultValue=""
              rules={{
                required: "Work email is required",
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "Invalid email address",
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email_address"
                  label="Work Email"
                  name="email_address"
                  autoComplete="email"
                  autoFocus
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              className="mb-9"
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ height: "60px" }}
            >
              Request Email Verification Code
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default DashboardCodeResend;
