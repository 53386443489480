import { FC } from "react";
import Box from "@mui/material/Box";
import StepperCreateAccount from "./StepperCreateAccount";

const DashboardCreateAccount: FC = () => {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <StepperCreateAccount />
        </Box>
      </Box>
    </>
  );
};

export default DashboardCreateAccount;
