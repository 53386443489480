import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CTAGenerateFormT37s from "./CTAGenerateFormT37s";

const Footer: FC = () => {
  const navigate = useNavigate();

  const handleNavigationBackClick = () => {
    navigate("/tax-forms/t37");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "1.5rem",
          paddingBottom: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        <Button variant="outlined" onClick={handleNavigationBackClick}>
          Back
        </Button>

        <CTAGenerateFormT37s />

        {/* <LoadingButton
          loading={loadingGeneratePdfs}
          variant="contained"
          color="secondary"
          onClick={handleGeneratePDFs}
        >
          Generate Form T37
        </LoadingButton> */}
      </Box>
    </>
  );
};

export default Footer;
