import { FC, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

import Container from "@mui/material/Container";
import SubHeader from "../SubHeader";
import DataGridEmployees from "./DataGridEmployees";

import { useGetAllPayrollSchedulesByCompanyIdQuery } from "../../../features/api/apiPayrollSchedules";
import { useGetAllPayComponentsByCompanyIdQuery } from "../../../features/api/apiPayComponents";
import ModalActionEmployeeLeave from "./ModalActionEmployeeLeave";

const DashboardEmployees: FC = () => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const { data: dataPayrollSchedulesByCompanyId } =
    useGetAllPayrollSchedulesByCompanyIdQuery(currentCompanyId);

  const { data: dataPayComponentsByCompanyId } =
    useGetAllPayComponentsByCompanyIdQuery(currentCompanyId);

  const [modalOpen, setModalOpen] = useState(false);
  const [currentEmployeeId, setCurrentEmployeeId] = useState<number>();
  const [currentEmployeeLeaveDate, setCurrentEmployeeLeaveDate] =
    useState<Date>();

  const handleModalOpen = (employeeId: number, employeeLeaveDate: Date) => {
    setCurrentEmployeeId(employeeId);
    setCurrentEmployeeLeaveDate(employeeLeaveDate);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <SubHeader
        title="All Employees"
        icon="people"
        disabled={
          dataPayrollSchedulesByCompanyId?.length === 0 ||
          dataPayComponentsByCompanyId?.length === 0
        }
      />

      <Container maxWidth="xl" sx={{ width: "100%", marginTop: "2rem" }}>
        {currentEmployeeId && (
          <ModalActionEmployeeLeave
            modalOpen={modalOpen}
            handleModalClose={handleModalClose}
            currentEmployeeId={currentEmployeeId}
            currentEmployeeLeaveDate={currentEmployeeLeaveDate}
          />
        )}

        <DataGridEmployees handleModalOpen={handleModalOpen} />
      </Container>
    </>
  );
};

export default DashboardEmployees;
