import { FC } from "react";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import BlockPaySummaryValue from "./BlockPaySummaryValue";

import { useGetPayrollEntryQuery } from "../../../../features/api/apiPayrollEntries";
// import { useGetAllPayrollSchemesByPayrollEntryIdQuery } from "../../../../features/api/apiPayrollSchemes";
import Typography from "@mui/material/Typography";

interface RouterState {
  payrollEntryId?: string;
}

const SummaryScheme: FC = () => {
  const location = useLocation();

  const { payrollEntryId: routerPayrollEntryId } =
    (location.state as RouterState) || {};

  const { data: dataPayrollEntry } = useGetPayrollEntryQuery(
    routerPayrollEntryId!
  );

  // const { data: dataPayrollSchemesByPayrollEntryId } =
  //   useGetAllPayrollSchemesByPayrollEntryIdQuery(routerPayrollEntryId!);

  const {
    calculated_employee_gross_schemes_contribution,
    calculated_employer_schemes_contribution,
  } = dataPayrollEntry || {};

  // TODO(DBB) : Proper call to Payroll Schemes
  // dataPayrollSchemesByPayrollEntryId &&
  //   console.log(dataPayrollSchemesByPayrollEntryId);

  return (
    <>
      <Box component="section">
        <Typography
          variant="h6"
          component="h2"
          sx={{ marginBottom: "0.667rem" }}
        >
          Scheme Summary
        </Typography>
        <Box
          sx={{ display: "grid", gap: 1, gridTemplateColumns: "1fr 4fr 1fr" }}
        >
          <Box
            sx={{
              border: "1px solid #E7E6E9",
              borderRadius: "0.5rem",
              backgroundColor: "white",
              padding: "0.75rem 1.5rem 1.5rem",
            }}
          >
            <BlockPaySummaryValue label="Scheme" value="No Scheme Applied" />
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns:
                "1fr max-content 1fr max-content 1fr max-content",
              gridTemplateRows: "1fr max-content 1fr",
              columnGap: "1rem",
              rowGap: "1rem",
              padding: "1rem",
              border: "1px solid #E7E6E9",
              borderRadius: "0.5rem",
              backgroundColor: "white",
            }}
          >
            <BlockPaySummaryValue label="Employee Min. Ctrb." value={0} />
            <Divider orientation="vertical" />
            <BlockPaySummaryValue
              label="Tax"
              value={calculated_employee_gross_schemes_contribution}
            />
            <Divider orientation="vertical" />
            <BlockPaySummaryValue
              label="Employee N.I."
              value={calculated_employee_gross_schemes_contribution}
            />
            <Divider
              orientation="horizontal"
              sx={{ gridColumn: "1/-1", borderColor: "#E7E6E9" }}
            />
            <BlockPaySummaryValue label="Employer Min. Ctrb." value={0} />
            <Divider orientation="vertical" />
            <BlockPaySummaryValue
              label="Employee Ctrb."
              value={calculated_employer_schemes_contribution}
            />
            <Divider orientation="vertical" />
            <BlockPaySummaryValue
              label="Employer Total"
              value={calculated_employer_schemes_contribution}
            />
          </Box>

          <Box
            sx={{
              backgroundColor: "#F1F0F4",
              borderRadius: "0.5rem",
              padding: "1rem",
              border: "1px solid #E7E6E9",
            }}
          >
            <BlockPaySummaryValue
              label="Total"
              value={Number(
                calculated_employee_gross_schemes_contribution! +
                  calculated_employer_schemes_contribution!
              )}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SummaryScheme;
