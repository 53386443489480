import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconArrowForward from "@mui/icons-material/ArrowForward";

const DashboardCompletion: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Stack direction="column" gap={4}>
        <Typography variant="h4" component="h3">
          Your new company has been created!
        </Typography>

        <Stack direction="row" gap={4}>
          {/* <Box sx={{ width: "100%", backgroundColor: "#ccc" }}>Image here</Box> */}

          <Stack direction="column" gap={2}>
            <Typography>
              To begin running payrolls for this company, it is required to set
              up Payroll Schedules, Pay Components and add your Employees.
            </Typography>

            <Typography>
              The Cloud Payroll interface will guide you through this process.
            </Typography>

            <Button
              color="primary"
              size="large"
              variant="contained"
              disableElevation={true}
              sx={{ maxWidth: "16rem" }}
              onClick={() => navigate("/home")}
              endIcon={<IconArrowForward />}
            >
              Visit Home Dashboard
            </Button>

            <Button
              color="primary"
              size="large"
              variant="outlined"
              disableElevation={true}
              sx={{ maxWidth: "16rem" }}
              onClick={() =>
                navigate("/payrolls/pay-schedules", {
                  state: { routerSetModalOpen: true },
                })
              }
            >
              Add Payroll Schedule
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default DashboardCompletion;
