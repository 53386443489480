import { FC, useState } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconEdit from "@mui/icons-material/Edit";

import DialogContactDetails from "./DialogContactDetails";
import DetailsCompanyAddress from "./DetailsContactDetails";

const SectionContactDetails: FC = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <DialogContactDetails
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
      />

      <Grid container sx={{ padding: "2rem" }}>
        <Grid item xs={3}>
          <Typography component="h6" variant="h6" gutterBottom>
            Contact Details
          </Typography>
        </Grid>

        <Grid item xs={8} sx={{ marginTop: "0.5rem" }}>
          <DetailsCompanyAddress />
        </Grid>

        <Grid item xs={1}>
          <Button
            onClick={handleModalOpen}
            startIcon={<IconEdit fontSize="small" />}
            sx={{ textTransform: "uppercase" }}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionContactDetails;
