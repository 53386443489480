import { z } from "zod";

export type EssentialInformation = z.infer<typeof SchemaEssentialInformation>;

export const SchemaEssentialInformation = z.object({
  id: z.number().optional(),

  title: z.string().min(1, {
    message: "Title is required",
  }),

  forenames: z
    .string()
    .min(2, {
      message: "Forename(s) required, initials are not acceptable",
    })
    .max(56, {
      message: "Forename(s) should not exceed 56 characters",
    }),

  surname: z
    .string()
    .min(1, {
      message: "Surname is required",
    })
    .max(56, {
      message: "Surname should not exceed 56 characters",
    }),

  birth_date: z
    .date({
      invalid_type_error: "Date of Birth is required",
    })
    .or(z.string().transform((str) => new Date(str))),

  country: z
    .string()
    .min(1, {
      message: "Country is required",
    })
    .max(56, {
      message: "Country should not exceed 56 characters",
    }),

  address_1: z
    .string()
    .min(1, {
      message: "Address 1 is required",
    })
    .max(96, {
      message: "Address 1 should not exceed 96 characters",
    }),

  address_2: z
    .string()
    .max(96, {
      message: "Address 2 should not exceed 96 characters",
    })
    .optional(),

  city: z
    .string()
    .min(1, {
      message: "City is required",
    })
    .max(56, {
      message: "City should not exceed 56 characters",
    }),

  region: z.string().max(56, {
    message: "Region should not exceed 56 characters",
  }),

  post_code: z
    .string()
    .regex(
      new RegExp(/^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/),
      "Format should match a UK postcode"
    ),
  // Ref: https://stackoverflow.com/a/51885364/11698046

  tax_reference_number: z
    .string()
    .regex(
      new RegExp(/^H\d{6}-\d{2}$/),
      "Tax Reference Number should consist of a letter followed by six numbers, a hyphen and then two further numbers e.g. H123456-78"
    ),

  // TODO(DBB) : revisit this logic
  // tax_reference_missing: z.boolean(), 
    
  tax_code: z
    .string()
    .min(1, {
      message: "Tax Code is required",
    })
    .max(6, {
      message: "Tax Code should not exceed 5 characters",
    }),


  // TODO(DBB) : Validation not corrent for ENUMs
  // resident_status: z.enum(["Isle of Man Resident", "Non-resident"]),
  resident_status: z.string().min(1, {
    message: "Resident status is required",
  }),

  // TODO(DBB) : Validation not corrent for ENUMs
  // ni_table_code: z.enum(["A"]),
  ni_table_code: z.string().min(1, {
    message: "N.I. table code is required",
  }),

  ni_number: z
    .string()
    .min(9, {
      message:
        "National Insurance Number (NINO) consists of two letters, six numbers and a final letter e.g. MA123456C",
    })
    .max(9, {
      message:
        "National Insurance Number (NINO) consists of two letters, six numbers and a final letter e.g. MA123456C",
    }),
});
