import { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Drawer from "@mui/material/Drawer";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";

const pages = [
  {
    key: "overview",
    name: "Overview",
    to: "/tax-forms/overview",
    icon: "home",
    items: [],
  },
  {
    key: "t14",
    name: "T14",
    to: "/tax-forms/t14",
    icon: "description",
    items: [],
  },
  {
    key: "t21",
    name: "T21",
    to: "/tax-forms/t21",
    icon: "description",
    items: [],
  },
  {
    key: "t35",
    name: "T35",
    to: "/tax-forms/t35",
    icon: "description",
    items: [],
  },
  {
    key: "t37",
    name: "T37",
    to: "/tax-forms/t37",
    icon: "description",
    items: [],
  },
  // {
  //   key: "year-end-documents",
  //   name: "Year End Documents",
  //   to: "/tax-forms/year-end",
  //   icon: "description",
  //   items: [],
  // },
];

function SubNavigation() {
  const navigate = useNavigate();
  const [open, setOpen] = useState<any>({});

  const handleClick = (key: any) => {
    setOpen({ [key]: !open[key] });
  };

  const itemNav = (
    items: any,
    key: string,
    to: string,
    icon: string,
    isOpen: boolean,
    name: string
  ) => (
    <ListItemButton
      onClick={() => (items && items.length ? handleClick(key) : navigate(to))}
    >
      <ListItemIcon sx={{ minWidth: "36px" }}>
        <Icon fontSize="small">{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={name} />
      {items && items.length > 0 && (isOpen ? <ExpandLess /> : <ExpandMore />)}
    </ListItemButton>
  );

  return (
    // TODO(DBB) : Refactor into global component
    <Drawer
      variant="permanent"
      sx={{
        boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.08)",
        width: 288,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 288,
          boxSizing: "border-box",
          paddingTop: "4.5rem",
          paddingBottom: "2rem",
        },
      }}
    >
      <Typography
        component="h6"
        variant="h6"
        gutterBottom
        sx={{ padding: "16px 16px 24px 16px", mb: 0 }}
      >
        Tax Forms
      </Typography>

      <Box sx={{ overflow: "auto" }}>
        <List>
          {pages.map(({ name, to, icon, items, key }) => {
            const isOpen = open[key] || false;
            return (
              <div key={key}>
                <ListItem
                  key={name}
                  disablePadding
                  sx={{ "& .active": { background: "rgba(0, 89, 199, 0.08)" } }}
                >
                  {items && items.length ? (
                    itemNav(items, key, to, icon, isOpen, name)
                  ) : (
                    <NavLink
                      style={{ width: "100%" }}
                      to={to}
                      className={(navData) =>
                        navData.isActive ? "active" : ""
                      }
                    >
                      {itemNav(items, key, to, icon, isOpen, name)}
                    </NavLink>
                  )}
                </ListItem>

                {/* Submenu */}
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {items.map(
                      ({ key: childKey, name: childName, to: ChildTo }) => (
                        <ListItemButton key={childKey}>
                          <ListItemText inset primary={childName} />
                        </ListItemButton>
                      )
                    )}
                  </List>
                </Collapse>
              </div>
            );
          })}
        </List>
      </Box>

      {/* <Box sx={{ marginTop: "auto" }}>
        <List>
          {configurations.map(({ name, to, icon, items, key }) => {
            const isOpen = open[key] || false;
            return (
              <div key={key}>
                <ListItem
                  key={name}
                  disablePadding
                  sx={{ "& .active": { background: "rgba(0, 89, 199, 0.08)" } }}
                >
                  {items && items.length ? (
                    itemNav(items, key, to, icon, isOpen, name)
                  ) : (
                    <NavLink
                      style={{ width: "100%" }}
                      to={to}
                      className={(navData) =>
                        navData.isActive ? "active" : ""
                      }
                    >
                      {itemNav(items, key, to, icon, isOpen, name)}
                    </NavLink>
                  )}
                </ListItem>
              </div>
            );
          })}
        </List>
      </Box> */}
    </Drawer>
  );
}

export default SubNavigation;
