import { FC } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
// import { ZodEmployee } from "../../../../../zod/ZodEmployee";
import { EssentialInformation } from "../ValidateEssentialInformation";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

import HeaderSection from "../../HeaderSection";
import FormDivider from "../../FormDivider";

interface SelectItem {
  id: string | number;
  name: string;
}

interface Props {
  control: Control<EssentialInformation, any>;
  errors: {
    title?: FieldError | undefined;
    forenames?: FieldError | undefined;
    surname?: FieldError | undefined;
  };
}

const SectionNameTitle: FC<Props> = ({ control, errors }) => {
  return (
    <>
      <Box>
        <HeaderSection title="Name & Title" />

        {/* Input : Title */}
        <FormControl sx={{ width: "8rem", marginBottom: "2rem" }}>
          <Controller
            control={control}
            name="title"
            render={({ field: { name, value, onChange, onBlur } }) => (
              <>
                <InputLabel id="label-title">Title</InputLabel>
                <Select
                  label="Title"
                  labelId="label-title"
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.title}
                  disabled={false}
                >
                  {titles?.map((item: SelectItem) => {
                    return (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            )}
          />
          <FormHelperText error={true}>{errors.title?.message}</FormHelperText>
        </FormControl>

        {/* Input : Forenames */}
        <Controller
          control={control}
          name="forenames"
          render={({ field: { name, value, onChange, onBlur } }) => (
            <TextField
              label="Forename(s)"
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              sx={{ marginBottom: "2rem" }}
              variant="outlined"
              error={!!errors.forenames}
              helperText={errors.forenames?.message}
              fullWidth={true}
              disabled={false}
            />
          )}
        />

        {/* Input : Surname */}
        <Controller
          control={control}
          name="surname"
          render={({ field: { name, value, onChange, onBlur } }) => (
            <TextField
              label="Surname"
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              sx={{ marginBottom: "2rem" }}
              variant="outlined"
              error={!!errors.surname}
              helperText={errors.surname?.message}
              fullWidth={true}
              disabled={false}
            />
          )}
        />
      </Box>

      <FormDivider mt={2} mb={4} />
    </>
  );
};

const titles = [
  {
    id: 1,
    name: "Mr",
  },
  {
    id: 2,
    name: "Mrs",
  },
  {
    id: 3,
    name: "Ms",
  },
  {
    id: 4,
    name: "Miss",
  },
];

export default SectionNameTitle;
