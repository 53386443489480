import { Dispatch, FC, SetStateAction } from "react";

import Box from "@mui/material/Box";
import OptionPayrollNext from "./OptionPayrollNext";

import { useGetAllNextPayrollsByCompanyIdQuery } from "../../../../features/api/apiPayrolls";
import { useAppSelector } from "../../../../app/hooks";

interface Props {
  selectedPayroll: string;
  setSelectedPayroll: Dispatch<SetStateAction<string>>;
  setCanProgress: Dispatch<SetStateAction<boolean>>;
}

const OptionsPayrollsNext: FC<Props> = ({
  selectedPayroll,
  setSelectedPayroll,
  setCanProgress,
}) => {
  const currentCompanyId = useAppSelector((state) => state.currentCompany.id);

  const { data: dataGetAllNextPayrollsByCompanyId } =
    useGetAllNextPayrollsByCompanyIdQuery(currentCompanyId);

  return (
    <>
      {dataGetAllNextPayrollsByCompanyId ? (
        <Box>
          {dataGetAllNextPayrollsByCompanyId.map((item: any, index) => {
            return (
              <OptionPayrollNext
                key={index}
                data={item}
                selectedPayroll={selectedPayroll}
                setSelectedPayroll={setSelectedPayroll}
                setCanProgress={setCanProgress}
                disabled={false}
              />
            );
          })}
        </Box>
      ) : (
        <Box>None</Box>
      )}
    </>
  );
};

export default OptionsPayrollsNext;
