import { AlertMessage } from "../../types/AlertMessage";
import { User } from "../../types/User";
import { cloudPayrollApi } from "./cloudPayrollApi";

// TODO(DBB) : Author type
interface MemberSubmission {
  email_address_recipient: string;
  company_id: number;
  email_address_sender: string;
}

interface Member {
  id: string;
  user_id: string;
  company_id: number;
  date_time_invitation_created: Date;
  date_time_invitation_accepted: Date;
  date_time_invitation_expiry: Date;
  status: string;
  user: User;
}

const apiMembers = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // INVITE Member
    inviteMember: builder.mutation<AlertMessage, MemberSubmission>({
      query: (member) => ({
        url: `/members/invite`,
        method: "POST",
        body: member,
      }),
      invalidatesTags: ["Member"],
    }),

    // READ All Members by Company ID
    getAllMembersByCompanyId: builder.query<Member[], number | undefined>({
      query: (companyId) => `/members/company/${companyId}`,
      providesTags: ["Member"],
    }),

    // UPDATE Member
    updateMember: builder.mutation<Member, Partial<Member>>({
      query: ({ id, ...rest }) => ({
        url: `/members/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Member"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useInviteMemberMutation,
  useGetAllMembersByCompanyIdQuery,
  useUpdateMemberMutation,
} = apiMembers;
