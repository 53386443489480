import { z } from "zod";

export type ContactDetails = z.infer<typeof SchemaContactDetails>;

export const SchemaContactDetails = z.object({
  country: z
    .string()
    .min(1, {
      message: "Country is required",
    })
    .max(56, {
      message: "Country should not exceed 56 characters",
    }),

  address_1: z
    .string()
    .min(1, {
      message: "Address 1 is required",
    })
    .max(96, {
      message: "Address 1 should not exceed 96 characters",
    }),

  address_2: z
    .string()
    .max(96, {
      message: "Address 2 should not exceed 96 characters",
    })
    .optional(),

  city: z
    .string()
    .min(1, {
      message: "City is required",
    })
    .max(56, {
      message: "City should not exceed 56 characters",
    }),

  region: z.string().max(56, {
    message: "Region should not exceed 56 characters",
  }),

  post_code: z
    .string()
    .min(1, {
      message: "Post code is required",
    })
    .max(8, {
      message: "Post code should not exceed 96 characters",
    }),
  // TODO(DBB) : Regex

  email_address: z.string().email().optional().or(z.literal("")),

  phone_number: z.string().optional(),

  // website_url: z.string().url().max(254).optional(),
});
