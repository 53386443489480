import { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  logo_url?: string;
  name_trading: string;
}

const LogoCompany: FC<Props> = ({ logo_url, name_trading }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "6rem",
        height: "6rem",
        border: "1px solid #E7E6E9",
        borderRadius: "0.25rem",
      }}
    >
      {logo_url ? (
        <img src={logo_url} alt={`Company logo for ${name_trading}`} />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "4rem",
            height: "4rem",
            backgroundColor: "#0059C7",
          }}
        >
          {name_trading && (
            <Typography
              display="block"
              sx={{
                color: "white",
                fontSize: "3rem",
                fontWeight: "bold",
              }}
            >
              {name_trading.charAt(0)}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default LogoCompany;
