import { FC, useState, useContext, useEffect } from "react";
import { RootState } from "../../../../app/store";
import { useAppSelector } from "../../../../app/hooks";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { PayComponent, SchemaPayComponent } from "./ValidationPayComponent";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Divider from "@mui/material/Divider";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { CurrentPayComponentContext } from "../DashboardPayComponents";

import {
  useCreatePayComponentByCompanyIdMutation,
  useLazyGetPayComponentQuery,
  useUpdatePayComponentMutation,
} from "../../../../features/api/apiPayComponents";
import { useGetAllPayComponentTypesQuery } from "../../../../features/api/apiPayComponentTypes";
import { useGetAllPayrollCountUnitsQuery } from "../../../../features/api/apiPayrollCountUnits";
import { PayrollCountUnit } from "../../../../types/PayrollCountUnit";

interface SelectItem {
  id: string | number;
  name: string;
}

interface Props {
  handleModalClose: () => void;
}

const FormPayComponent: FC<Props> = ({ handleModalClose }) => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<PayComponent>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaPayComponent),
    defaultValues: {
      name: "",
      pay_component_type: "",
      payroll_count_unit: "",
      is_gross_pay: true,
      is_liable_to_tax: true,
      is_liable_to_ni: true,
      is_pensionable: true,
      additional_description: "",
    },
  });

  const { data: dataPayComponentTypes } = useGetAllPayComponentTypesQuery();

  const { data: dataPayrollCountUnits } = useGetAllPayrollCountUnitsQuery();

  const filteredPayrollCountUnits = dataPayrollCountUnits?.filter(
    (item: PayrollCountUnit) => item.id === 3 || item.id === 5 || item.id === 8
  );

  const { payComponentId, isEdit } = useContext(CurrentPayComponentContext);

  const [createPayComponentByCompanyId] =
    useCreatePayComponentByCompanyIdMutation();

  const [triggerLazyGetPayComponent] = useLazyGetPayComponentQuery();

  const [updatePayComponent] = useUpdatePayComponentMutation();

  useEffect(() => {
    isEdit &&
      payComponentId &&
      triggerLazyGetPayComponent(payComponentId).then((res) => {
        const {
          name,
          pay_component_type,
          payroll_count_unit,
          is_gross_pay,
          is_liable_to_tax,
          is_liable_to_ni,
          is_pensionable,
          additional_description,
        } = res.data || {};

        reset({
          name: name || "",
          pay_component_type: pay_component_type.id || "",
          payroll_count_unit: payroll_count_unit.id || "",
          is_gross_pay: is_gross_pay,
          is_liable_to_tax: is_liable_to_tax,
          is_liable_to_ni: is_liable_to_ni,
          is_pensionable: is_pensionable,
          additional_description: additional_description || "",
        });
      });
  }, [isEdit, payComponentId, triggerLazyGetPayComponent, reset]);

  const onSubmit = (data: any) => {
    const submissionPayComponent = {
      company: currentCompanyId,
      status: "ACTIVE",
      ...data,
    };

    // Add
    !isEdit &&
      currentCompanyId &&
      createPayComponentByCompanyId(submissionPayComponent)
        .unwrap()
        .then(() => {
          reset();
        })
        .then(() => {
          handleModalClose();
        });

    // Edit
    const submissionPayComponentEdit = {
      id: payComponentId,
      ...submissionPayComponent,
    };

    isEdit &&
      currentCompanyId &&
      updatePayComponent(submissionPayComponentEdit).then(() => {
        handleModalClose();
      });
  };

  const onError = (errors: object) => {
    Object.values(errors).forEach((item) =>
      setSnackbar({
        children: `${item.message}`,
        severity: "error",
      })
    );
  };

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Stack direction="column" spacing={3}>
          <Typography component="h6" variant="h6">
            Pay Component Name
          </Typography>

          <Typography component="p">
            Choose a distinguishable name for your pay component. Example: Basic
            Hour, Commission, Bonus, Overtime, etc.
          </Typography>

          {/* Input : Pay Component Name  */}
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Pay Component Name"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name ? errors.name?.message : null}
              />
            )}
          />

          <Divider />

          <Typography component="h6" variant="h6">
            Pay Component Type
          </Typography>

          <Typography component="p">
            Select the type of pay component that can contribute to an
            employee's compensation. Choose between Pay, Statutory Pay, Addition
            or Subtraction.
          </Typography>

          {/* Input : Pay Component Type  */}
          {dataPayComponentTypes?.length !== 0 && (
            <FormControl sx={{ marginBottom: "2rem" }}>
              <Controller
                control={control}
                name="pay_component_type"
                render={({ field: { name, value, onChange, onBlur } }) => (
                  <>
                    <InputLabel id="label-pay-component-type">
                      Pay Component Type
                    </InputLabel>
                    <Select
                      label="Pay Component Type"
                      labelId="label-pay-component-type"
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.pay_component_type}
                      disabled={false}
                    >
                      {dataPayComponentTypes?.map((item: SelectItem) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                )}
              />
              <FormHelperText error={true}>
                {errors.pay_component_type?.message}
              </FormHelperText>
            </FormControl>
          )}

          <Divider />

          <Typography component="h6" variant="h6">
            Pay Component Count Unit
          </Typography>

          <Typography component="p">
            The time unit used to track a measure of compensation.
          </Typography>

          {/* Input : Payroll Count Units */}
          {dataPayrollCountUnits?.length !== 0 && (
            <FormControl sx={{ marginBottom: "2rem" }}>
              <Controller
                control={control}
                name="payroll_count_unit"
                render={({ field: { name, value, onChange, onBlur } }) => (
                  <>
                    <InputLabel id="label-payroll-count-units">
                      Payroll Count Unit
                    </InputLabel>
                    <Select
                      label="Payroll Count Unit"
                      labelId="label-payroll-count-units"
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.payroll_count_unit}
                      disabled={false}
                    >
                      {filteredPayrollCountUnits?.map((item: SelectItem) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                )}
              />
              <FormHelperText error={true}>
                {errors.pay_component_type?.message}
              </FormHelperText>
            </FormControl>
          )}

          <Divider />

          <Typography component="h6" variant="h6">
            Pay Component Configuration
          </Typography>

          <FormGroup>
            <Typography
              component="h5"
              variant="caption"
              sx={{ fontWeight: 700, textTransform: "uppercase" }}
            >
              Gross Pay
            </Typography>

            <Controller
              control={control}
              name="is_gross_pay"
              render={({ field }) => (
                <FormControl>
                  <FormLabel id="is_gross_pay">
                    Is this component part of gross pay?
                  </FormLabel>
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby="is_gross_pay_group"
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value={true}
                      label="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={false}
                      label="No"
                    />
                  </RadioGroup>
                  <FormHelperText error={true}>
                    {errors.is_gross_pay?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Typography
              component="h5"
              variant="caption"
              sx={{ fontWeight: 700, textTransform: "uppercase" }}
            >
              Taxability
            </Typography>

            <Controller
              control={control}
              name="is_liable_to_tax"
              render={({ field }) => (
                <FormControl>
                  <FormLabel id="is_liable_to_tax_group">
                    Is this component liable to tax?
                  </FormLabel>
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby="is_liable_to_tax_group"
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value={true}
                      label="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={false}
                      label="No"
                    />
                  </RadioGroup>
                  <FormHelperText error={true}>
                    {errors.is_liable_to_tax?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Typography
              component="h5"
              variant="caption"
              sx={{ fontWeight: 700, textTransform: "uppercase" }}
            >
              N.I.ability
            </Typography>

            <Controller
              control={control}
              name="is_liable_to_ni"
              render={({ field }) => (
                <FormControl>
                  <FormLabel id="is_liable_to_ni_group">
                    Is this component liable to N.I.?
                  </FormLabel>
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby="is_liable_to_ni_group"
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value={true}
                      label="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={false}
                      label="No"
                    />
                  </RadioGroup>
                  <FormHelperText error={true}>
                    {errors.is_liable_to_ni?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Typography
              component="h5"
              variant="caption"
              sx={{ fontWeight: 700, textTransform: "uppercase" }}
            >
              Pensionability
            </Typography>

            <Controller
              control={control}
              name="is_pensionable"
              render={({ field }) => (
                <FormControl>
                  <FormLabel id="is_pensionable_group">
                    Is this component pensionable?
                  </FormLabel>
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby="is_pensionable_group"
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value={true}
                      label="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={false}
                      label="No"
                    />
                  </RadioGroup>
                  <FormHelperText error={true}>
                    {errors.is_pensionable?.message && field.value}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </FormGroup>

          <Divider />

          <Typography component="h6" variant="h6">
            Additional Description
          </Typography>

          <Controller
            control={control}
            name="additional_description"
            defaultValue=""
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Additional Description"
                multiline
                rows={2}
                variant="outlined"
                error={!!errors.additional_description}
                helperText={
                  errors.additional_description
                    ? errors.additional_description?.message
                    : null
                }
              />
            )}
          />

          <Divider />

          <Stack direction="row" gap={2}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disableElevation={true}
            >
              Submit & Close
            </Button>

            <Button
              type="button"
              onClick={() => {
                reset();
                handleModalClose();
              }}
              variant="outlined"
              size="large"
              color="secondary"
              disableElevation={true}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

export default FormPayComponent;
