import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import FormSignIn from "./FormSignIn";
import Footer from "./Footer";

import { resetCurrentEmployee } from "../../features/state/employee/currentEmployeeSlice";
import { resetProvisionedEmployee } from "../../features/state/employee/provisionedEmployeeSlice";
import { resetProvisionedEmployeePayRate } from "../../features/state/employeePayRate/employeePayRateSlice";
import { resetProvisionedEmployeeScheme } from "../../features/state/employeeScheme/employeeSchemeSlice";
import { resetProvisionedPayroll } from "../../features/state/payroll/payrollSlice";
import { resetCurrentCompany } from "../../features/state/company/companySlice";
import { resetCurrentPayrollEntry } from "../../features/state/payrollEntry/payrollEntrySlice";

const DashboardSignIn: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetCurrentCompany());
    dispatch(resetCurrentEmployee());
    dispatch(resetProvisionedEmployee());
    dispatch(resetProvisionedEmployeePayRate());
    dispatch(resetProvisionedEmployeeScheme());
    dispatch(resetProvisionedPayroll());
    dispatch(resetCurrentPayrollEntry());
  }, [dispatch]);

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "2rem",
          paddingBottom: "4rem",
        }}
      >
        <Card
          sx={{
            maxWidth: 424,
            boxShadow: "16px 16px 0px rgba(0, 0, 0, 0.12)",
            borderRadius: "1rem",
            padding: "1rem 1.5rem 1.5rem",
            marginTop: "6rem",
          }}
        >
          <CardContent sx={{ padding: 0, paddingBottom: "0 !important" }}>
            <FormSignIn />
          </CardContent>
        </Card>
      </Container>

      <Footer />
    </>
  );
};

export default DashboardSignIn;
