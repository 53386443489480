import { cloudPayrollApi } from "./cloudPayrollApi";

import { PayrollPaymentMethod } from "../../types/PayrollPaymentMethod";

const apiPayrollPaymentMethod = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // READ All PaymentMethods
    getAllPayrollPaymentMethods: builder.query<PayrollPaymentMethod[], void>({
      query: () => `/payroll-payment-methods`,
      providesTags: ["PayrollPaymentMethod"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllPayrollPaymentMethodsQuery } = apiPayrollPaymentMethod;
