import { FC, useState, useEffect, useContext } from "react";
import { RootState } from "../../../../app/store";
import { useAppSelector } from "../../../../app/hooks";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CurrentSchemeContext } from "../DashboardSchemes";
import { Scheme, SchemaScheme } from "./ValidationScheme";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Divider from "@mui/material/Divider";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import {
  useCreateSchemeByCompanyIdMutation,
  useLazyGetSchemeQuery,
  useUpdateSchemeMutation,
} from "../../../../features/api/apiSchemes";
import { useGetAllSchemeTypesQuery } from "../../../../features/api/apiSchemeTypes";
import InputMaskPercentageTwoDecimal from "../../../../utils/InputMaskPercentageTwoDecimal";
import InputMaskCurrencyGBP from "../../../../utils/InputMaskCurrencyGBP";

interface SelectItem {
  id: string | number;
  name: string;
}

interface Props {
  handleModalClose: () => void;
}

const FormScheme: FC<Props> = ({ handleModalClose }) => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useForm<Scheme>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaScheme),
    defaultValues: {
      name: "",
      scheme_type: "",
      employee_contribution_rate_type: "",
      employee_contribution_count: 0,
      employee_contribution_deducted_before_tax: false,
      employee_contribution_deducted_before_ni: false,
      employer_contribution_rate_type: "",
      employer_contribution_count: 0,
    },
  });

  const { data: dataGetAllSchemeTypes } = useGetAllSchemeTypesQuery();

  const { schemeId, isEdit } = useContext(CurrentSchemeContext);

  const [triggerLazyGetScheme] = useLazyGetSchemeQuery();

  useEffect(() => {
    isEdit &&
      schemeId &&
      triggerLazyGetScheme(schemeId).then(({ data }) => {
        const {
          name,
          scheme_type,
          employee_contribution_rate_type,
          employee_contribution_count,
          employee_contribution_deducted_before_tax,
          employee_contribution_deducted_before_ni,
          employer_contribution_rate_type,
          employer_contribution_count,
        } = data || {};

        reset({
          name: name || "",
          scheme_type: scheme_type.id,
          employee_contribution_rate_type:
            employee_contribution_rate_type || "",
          employee_contribution_count: Number(
            (employee_contribution_count &&
              employee_contribution_count / 100) ||
              0
          ),
          employee_contribution_deducted_before_tax:
            employee_contribution_deducted_before_tax || false,
          employee_contribution_deducted_before_ni:
            employee_contribution_deducted_before_ni || false,
          employer_contribution_rate_type:
            employer_contribution_rate_type || "",
          employer_contribution_count: Number(
            (employer_contribution_count &&
              employer_contribution_count / 100) ||
              0
          ),
        });
      });
  }, [isEdit, schemeId, triggerLazyGetScheme, reset]);

  const [createSchemeByCompanyId] = useCreateSchemeByCompanyIdMutation();
  const [updateScheme] = useUpdateSchemeMutation();

  const onSubmit = (data: any) => {
    const submissionScheme = {
      company: currentCompanyId,
      ...data,
      employee_contribution_deducted_before_tax: true,
      employee_contribution_deducted_before_ni: false,
      employee_contribution_count: Number(
        data.employee_contribution_count * 100
      ),
      employer_contribution_count: Number(
        data.employer_contribution_count * 100
      ),
      status: "ACTIVE",
    };

    // Add
    !isEdit &&
      currentCompanyId &&
      createSchemeByCompanyId(submissionScheme)
        .unwrap()
        .then(() => {
          setSnackbar({
            children: `Scheme record created`,
            severity: "success",
          });
        })
        .then(() => {
          handleModalClose();
        })
        .then(() => {
          reset({
            name: "",
            scheme_type: "",
            employee_contribution_rate_type: "",
            employee_contribution_count: 0,
            employee_contribution_deducted_before_tax: false,
            employee_contribution_deducted_before_ni: false,
            employer_contribution_rate_type: "",
            employer_contribution_count: 0,
          });
        });

    // Edit
    const submissionSchemeEdit = {
      id: schemeId,
      ...submissionScheme,
    };

    isEdit &&
      currentCompanyId &&
      schemeId &&
      updateScheme(submissionSchemeEdit).then(() => {
        handleModalClose();
      });
  };

  const onError = (errors: object) => {
    Object.values(errors).forEach((item) =>
      setSnackbar({
        children: `${item.message}`,
        severity: "error",
      })
    );
  };

  const watchEmployeeRateType = watch("employee_contribution_rate_type");
  const watchEmployerRateType = watch("employer_contribution_rate_type");

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Stack direction="column" spacing={3}>
          <Typography component="h6" variant="h6">
            Scheme Type
          </Typography>

          <Typography component="p">
            Select the Scheme type you wish to create.
          </Typography>

          {/* Input : Scheme Type */}
          {dataGetAllSchemeTypes?.length !== 0 && (
            <FormControl sx={{ marginBottom: "2rem" }}>
              <Controller
                control={control}
                name="scheme_type"
                render={({ field: { name, value, onChange, onBlur } }) => (
                  <>
                    <InputLabel id="label-pay-frequency">
                      Scheme Type
                    </InputLabel>
                    <Select
                      label="Scheme Type"
                      labelId="label-pay-frequency"
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.scheme_type}
                      disabled={false}
                    >
                      {dataGetAllSchemeTypes?.map((item: SelectItem) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                )}
              />
              <FormHelperText error={true}>
                {errors.scheme_type?.message}
              </FormHelperText>
            </FormControl>
          )}

          <Divider />

          <Typography component="h6" variant="h6">
            Scheme Name
          </Typography>

          <Typography component="p">
            Choose a distigushable name for your Scheme.
          </Typography>

          {/* Input : Pay Schedule Name  */}
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Scheme Name"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name ? errors.name?.message : null}
              />
            )}
          />

          <Divider />

          <Typography component="h6" variant="h6">
            Employee's Contribution
          </Typography>

          <Typography component="p">
            Select Rate Type to set your amount as a percentage or a fixed
            value.
          </Typography>

          <Box>
            <Stack
              direction="row"
              spacing={4}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {/* Input : Employee Contribution Rate Type  */}
              <FormControl>
                <Controller
                  control={control}
                  name="employee_contribution_rate_type"
                  render={({ field: { name, value, onChange, onBlur } }) => (
                    <>
                      <InputLabel id="label-employee-rate-type">
                        Employee Rate Type
                      </InputLabel>
                      <Select
                        label="Employee Rate Type"
                        labelId="label-employee-rate-type"
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.employee_contribution_rate_type}
                        disabled={false}
                        sx={{ width: "12rem" }}
                      >
                        <MenuItem key="percentage" value="PERCENTAGE">
                          Percentage
                        </MenuItem>
                        <MenuItem key="fixed" value="FIXED">
                          Fixed
                        </MenuItem>
                      </Select>
                    </>
                  )}
                />
                <FormHelperText error={true}>
                  {errors.employee_contribution_rate_type?.message}
                </FormHelperText>
              </FormControl>

              {watchEmployeeRateType === "PERCENTAGE" && (
                <Controller
                  control={control}
                  name="employee_contribution_count"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Amount"
                      variant="outlined"
                      sx={{ width: "8rem" }}
                      error={!!errors.employee_contribution_count}
                      helperText={errors.employee_contribution_count?.message}
                      fullWidth={true}
                      disabled={false}
                      InputProps={{
                        inputComponent: InputMaskPercentageTwoDecimal as any,
                      }}
                      inputProps={{
                        min: 0,
                        max: 100,
                      }}
                    />
                  )}
                />
              )}

              {watchEmployeeRateType === "FIXED" && (
                <Controller
                  control={control}
                  name="employee_contribution_count"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Amount"
                      variant="outlined"
                      sx={{ width: "8rem" }}
                      error={!!errors.employee_contribution_count}
                      helperText={errors.employee_contribution_count?.message}
                      fullWidth={true}
                      disabled={false}
                      InputProps={{
                        inputComponent: InputMaskCurrencyGBP as any,
                      }}
                      inputProps={{
                        min: 0,
                      }}
                    />
                  )}
                />
              )}
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              sx={{ paddingLeft: "0.5rem", marginTop: "0.25rem" }}
            >
              {/* Input : Deduct before Tax */}
              <FormGroup>
                <Controller
                  control={control}
                  name="employee_contribution_deducted_before_tax"
                  render={({ field }) => (
                    <FormControlLabel
                      label="Deduct before Tax"
                      control={
                        <Checkbox
                          // NOTE(DBB & HG) : In accordance with IOM rules, tax is calculated AFTER Employee Scheme
                          // contributions are deducted
                          // Ref : https://www.gov.im/categories/tax-vat-and-your-money/income-tax-and-national-insurance/employers/payments-other-than-ordinary-salary/pension-scheme-contributions-and-the-taxation-of-pensions
                          // checked={field.value}
                          // onChange={(e) => field.onChange(e.target.checked)}
                          checked={true}
                          disabled={true}
                        />
                      }
                    />
                  )}
                />
              </FormGroup>

              {/* Input : Deduct before N.I. */}
              <FormGroup>
                <Controller
                  control={control}
                  name="employee_contribution_deducted_before_ni"
                  render={({ field }) => (
                    <FormControlLabel
                      label="Deduct before N.I."
                      control={
                        <Checkbox
                          // NOTE(DBB & HG) : In accordance with IOM rules, NI is calculated BEFORE Employee Scheme
                          // contributions are deducted
                          // Ref : https://www.gov.im/categories/tax-vat-and-your-money/income-tax-and-national-insurance/employers/payments-other-than-ordinary-salary/pension-scheme-contributions-and-the-taxation-of-pensions
                          // checked={field.value}
                          // onChange={(e) => field.onChange(e.target.checked)}
                          checked={false}
                          disabled={true}
                        />
                      }
                    />
                  )}
                />
              </FormGroup>
            </Stack>
          </Box>

          <Typography>
            In accordance with Isle of Man Government rules, NI is calculated
            before Employee Scheme contributions are deducted, after which ITIP
            is deducted.
          </Typography>
          <Typography>
            More information can be found on the relevant{" "}
            <Link
              href="https://www.gov.im/categories/tax-vat-and-your-money/income-tax-and-national-insurance/employers/payments-other-than-ordinary-salary/pension-scheme-contributions-and-the-taxation-of-pensions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Income Tax and National Insurance page
            </Link>
            .
          </Typography>

          <Divider />

          <Typography component="h6" variant="h6">
            Employer's Contribution
          </Typography>

          <Typography component="p">
            Select Rate Type to set your amount as a percentage or a fixed
            value.
          </Typography>

          <Stack
            direction="row"
            spacing={4}
            divider={<Divider orientation="vertical" flexItem />}
          >
            {/* Input : Employer Contribution Rate Type  */}
            <FormControl>
              <Controller
                control={control}
                name="employer_contribution_rate_type"
                render={({ field: { name, value, onChange, onBlur } }) => (
                  <>
                    <InputLabel id="label-employer-rate-type">
                      Employer Rate Type
                    </InputLabel>
                    <Select
                      label="Employer Rate Type"
                      labelId="label-employer-rate-type"
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.employer_contribution_rate_type}
                      disabled={false}
                      sx={{ width: "12rem" }}
                    >
                      <MenuItem key="percentage" value="PERCENTAGE">
                        Percentage
                      </MenuItem>
                      <MenuItem key="fixed" value="FIXED">
                        Fixed
                      </MenuItem>
                    </Select>
                  </>
                )}
              />
              <FormHelperText error={true}>
                {errors.employer_contribution_rate_type?.message}
              </FormHelperText>
            </FormControl>

            {/* Input : Employer Contribution Amount */}
            {/* TODO(DBB) Does this require input masking for GBP? */}
            {/* <Controller
              control={control}
              name="employer_contribution_count"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Amount"
                  type="number"
                  variant="outlined"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    min: 0,
                  }}
                  sx={{ width: "8rem" }}
                  error={!!errors.employer_contribution_count}
                  helperText={errors.employer_contribution_count?.message}
                  fullWidth={true}
                  disabled={false}
                />
              )}
            /> */}

            {watchEmployerRateType === "PERCENTAGE" && (
              <Controller
                control={control}
                name="employer_contribution_count"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Amount"
                    variant="outlined"
                    sx={{ width: "8rem" }}
                    error={!!errors.employer_contribution_count}
                    helperText={errors.employer_contribution_count?.message}
                    fullWidth={true}
                    disabled={false}
                    InputProps={{
                      inputComponent: InputMaskPercentageTwoDecimal as any,
                    }}
                    inputProps={{
                      min: 0,
                      max: 100,
                    }}
                  />
                )}
              />
            )}

            {watchEmployerRateType === "FIXED" && (
              <Controller
                control={control}
                name="employer_contribution_count"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Amount"
                    variant="outlined"
                    sx={{ width: "8rem" }}
                    error={!!errors.employer_contribution_count}
                    helperText={errors.employer_contribution_count?.message}
                    fullWidth={true}
                    disabled={false}
                    InputProps={{
                      inputComponent: InputMaskCurrencyGBP as any,
                    }}
                    inputProps={{
                      min: 0,
                    }}
                  />
                )}
              />
            )}
          </Stack>

          <Divider />

          <Stack direction="row" gap={2}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disableElevation={true}
            >
              Submit & Close
            </Button>

            <Button
              type="button"
              onClick={() => {
                reset();
                handleModalClose();
              }}
              variant="outlined"
              size="large"
              color="secondary"
              disableElevation={true}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

export default FormScheme;
