import { FC } from "react";
import { RootState } from "../../../app/store";
import { useAppSelector } from "../../../app/hooks";

import Container from "@mui/material/Container/Container";
import Box from "@mui/material/Box";

import ModuleNextPayroll from "./ModuleNextPayroll";
import TitleSection from "../TitleSection";
import ModuleRunNewPayroll from "./ModuleRunNewPayroll";

const ModuleRunPayrolls: FC = () => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          width: "100%",
          marginTop: ".889rem",
          paddingTop: "1.333rem",
          paddingBottom: "2.667rem",
        }}
      >
        <TitleSection title="Run Payrolls" />

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "3fr 1fr",
            gap: ".889rem",
            maxWidth: "57rem",
          }}
        >
          {currentCompanyId && (
            <ModuleNextPayroll currentCompanyId={currentCompanyId!} />
          )}

          <ModuleRunNewPayroll />
        </Box>
      </Container>
    </>
  );
};

export default ModuleRunPayrolls;
