import { FC } from "react";

import Box from "@mui/material/Box";

import TitleSection from "../TitleSection";
import ModuleLatestCompletedPayroll from "./ModuleLatestCompletedPayroll";

const ModuleRunPayrolls: FC = () => {

  return (
    <>
      <Box
        maxWidth="xl"
        sx={{
          width: "100%",
          paddingBottom: "2.444rem",
        }}
      >
        <TitleSection title="Latest Completed Payroll" />

        <Box
          sx={{
            display: "grid",
            gap: ".889rem",
            maxWidth: "56rem"
          }}>
          <ModuleLatestCompletedPayroll />
        </Box>

      </Box>
    </>
  );
};

export default ModuleRunPayrolls;
