import { Outlet } from "react-router-dom";
import HeaderSignUp from "../components/common/header/HeaderSignUp";

function LayoutSignUp() {
  return (
    <>
      <HeaderSignUp />

      <Outlet />
    </>
  );
}

export default LayoutSignUp;
