import { z } from "zod";

export type PaySchedule = z.infer<typeof SchemaPaySchedule>;

export const SchemaPaySchedule = z.object({
  name: z
    .string()
    .min(2, {
      message: "Pay Schedule Name is required.",
    })
    .max(64, {
      message: "Pay Schedule Name should not exceed 64 characters",
    }),

  payroll_frequency: z.number().or(z.literal("")),

  monthly_pay_day: z.coerce
    .number()
    .nonnegative()
    .min(0, { message: "Not below 1" })
    .max(31, { message: "Not above 31" })
    .optional(),

  weekly_pay_day: z.coerce
    .number()
    .nonnegative()
    .min(0, { message: "Not below 0" })
    .max(6, { message: "Not above 6" })
    .optional(),
});
