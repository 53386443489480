import { FC } from "react";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import BlockPaySummaryValue from "./BlockPaySummaryValue";

import { useGetPayrollEntryQuery } from "../../../../features/api/apiPayrollEntries";
import { Typography } from "@mui/material";

interface RouterState {
  payrollEntryId?: string;
}

const SummaryPay: FC = () => {
  const location = useLocation();

  const { payrollEntryId: routerPayrollEntryId } =
    (location.state as RouterState) || {};

  const { data: dataPayrollEntry } = useGetPayrollEntryQuery(
    routerPayrollEntryId!
  );

  const {
    calculated_employee_gross_normal_pay,
    calculated_employee_tax_free_pay,
    calculated_employee_tax_payable,
    calculated_employee_ni_contribution,
    calculated_employer_ni_contribution,
    calculated_employee_gross_other,
    calculated_employee_gross_schemes_contribution,
    calculated_employer_schemes_contribution,
    calculated_employee_net_pay,
    calculated_total_cost,
  } = dataPayrollEntry || {};

  return (
    <>
      <Box component="section">
        <Typography variant="h6" component="h2" sx={{ marginBottom: "0.667rem" }}>Pay Summary</Typography>
        <Box
          sx={{ display: "grid", gap: 1, gridTemplateColumns: "1fr 4fr 1fr" }}
        >
          <Box
            sx={{
              border: "1px solid #E7E6E9",
              borderRadius: "0.5rem",
              backgroundColor: "white",
              padding: "1rem",
            }}
          >
            <BlockPaySummaryValue
              label="Gross"
              value={calculated_employee_gross_normal_pay}
            />
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns:
                "1fr max-content 1fr max-content 1fr max-content 1fr max-content ",
              gridTemplateRows: "1fr max-content 1fr",
              columnGap: "1rem",
              rowGap: "1rem",
              padding: "1rem",
              border: "1px solid #E7E6E9",
              borderRadius: "0.5rem",
              backgroundColor: "white",
            }}
          >
            <BlockPaySummaryValue
              label="Free Pay"
              value={calculated_employee_tax_free_pay}
            />
            <Divider orientation="vertical" />
            <BlockPaySummaryValue
              label="Tax"
              value={calculated_employee_tax_payable}
            />
            <Divider orientation="vertical" />
            <BlockPaySummaryValue
              label="Employee N.I."
              value={calculated_employee_ni_contribution}
            />
            <Divider orientation="vertical" />
            <BlockPaySummaryValue
              label="Employer N.I."
              value={calculated_employer_ni_contribution}
            />
            <Divider
              orientation="horizontal"
              sx={{ gridColumn: "1/-1", borderColor: "#E7E6E9" }}
            />
            <BlockPaySummaryValue
              label="Other"
              value={calculated_employee_gross_other}
            />
            <Divider orientation="vertical" />
            <BlockPaySummaryValue
              label="Employee Scheme"
              value={calculated_employee_gross_schemes_contribution}
            />
            <Divider orientation="vertical" />
            <BlockPaySummaryValue
              label="Employer Scheme"
              value={calculated_employer_schemes_contribution}
            />
            <Divider orientation="vertical" />
            <BlockPaySummaryValue
              label="Net"
              value={calculated_employee_net_pay}
            />
          </Box>

          <Box sx={{ backgroundColor: "#F1F0F4", borderRadius: "0.5rem", padding: "1rem", border: "1px solid #E7E6E9" }}>
            <BlockPaySummaryValue
              label="Total Cost"
              value={calculated_total_cost}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SummaryPay;
