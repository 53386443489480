import { FC } from "react";
import { useLocation } from "react-router-dom";

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

import SubHeader from "../../run-t14/form-t14-view/SubHeader";

import { useGetT21ResponseQuery } from "../../../../features/api/apiTaxFormT21";

interface RouterState {
  employeeId?: string;
}

interface TableRowT21Props {
  label: string;
  value: string | boolean;
}

const TableRowT21 = ({ label, value }: TableRowT21Props) => {
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        {label}
      </TableCell>

      {typeof value === "string" && (
        <TableCell align="right">{value}</TableCell>
      )}

      {typeof value === "boolean" && (
        <TableCell align="right">
          <Checkbox checked={value} />
        </TableCell>
      )}

      {typeof value !== "boolean" && !value && (
        <TableCell align="right">
          <Typography sx={{ color: "#dbb" }}>Not supplied</Typography>
        </TableCell>
      )}
    </TableRow>
  );
};

const DashboardFormT21View: FC = () => {
  const location = useLocation();

  const { employeeId: routerEmployeeId } =
    (location.state as RouterState) || {};

  const { data: dataGetT21ResponseByEmployeeId } = useGetT21ResponseQuery(
    Number(routerEmployeeId)
  );
  const { employeeDetails } = dataGetT21ResponseByEmployeeId || {};
  const {
    employeeTaxRefNo,
    employeeNiNo,
    employeeTitle,
    employeeSurname,
    employeeForenames,
    employeeAddress,
    employeePostCode,
    employeeSpouseForenames,
    employeeMaidenName,
    employeeMarriageDate,
    employeeCessationDate,
    employeeCessationCode,
    employeeNonResident,
    employerName,
    employerTaxRefNo,
    totalEmployeeGrossPay,
    totalEmployeeSuperannuation,
    totalEmployeeTaxDeductions,
  } = employeeDetails || {};

  return (
    <>
      <SubHeader title="View Form T21" />

      <Box sx={{ maxWidth: 560, margin: "2rem" }}>
        {employeeDetails && (
          <TableContainer>
            <Table sx={{ minWidth: 240 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Label</TableCell>
                  <TableCell align="right">Value</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRowT21
                  label="Tax Reference Number"
                  value={employeeTaxRefNo!}
                />
                <TableRowT21
                  label="National Insurance Number"
                  value={employeeNiNo!}
                />
                <TableRowT21 label="Title" value={employeeTitle!} />
                <TableRowT21 label="Surname" value={employeeSurname!} />
                <TableRowT21 label="Forenames" value={employeeForenames!} />
                <TableRowT21 label="Address" value={employeeAddress!} />
                <TableRowT21 label="Post Code" value={employeePostCode!} />
                <TableRowT21
                  label="Spouse Forenames"
                  value={employeeSpouseForenames!}
                />
                <TableRowT21 label="Maiden Name" value={employeeMaidenName!} />
                <TableRowT21
                  label="Marriage Date"
                  value={employeeMarriageDate!}
                />
                <TableRowT21
                  label="Cessation Date"
                  value={employeeCessationDate!}
                />
                <TableRowT21
                  label="Cessation Code"
                  value={employeeCessationCode!}
                />
                <TableRowT21
                  label="Non-Resident"
                  value={employeeNonResident!}
                />
                <TableRowT21 label="Employer Name" value={employerName!} />
                <TableRowT21
                  label="Employer Tax Reference Number"
                  value={employerTaxRefNo!}
                />
                <TableRowT21
                  label="Total Employee Gross Pay"
                  value={totalEmployeeGrossPay!}
                />
                <TableRowT21
                  label="Total Employee Superannuation"
                  value={totalEmployeeSuperannuation!}
                />
                <TableRowT21
                  label="Total Employee Tax Deductions"
                  value={totalEmployeeTaxDeductions!}
                />
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </>
  );
};

export default DashboardFormT21View;
