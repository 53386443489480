import { FC } from "react";

import { DataGridPro, GridAlignment, GridColDef } from "@mui/x-data-grid-pro";
import Container from "@mui/material/Container";

import { useGetAllTaxYearsQuery } from "../../../features/api/apiTaxYears";
import { useGetAllTaxRatesQuery } from "../../../features/api/apiTaxRates";
import { displayColumnCurrencyGBP } from "../../../utils/datagridFormatters";

const TableTaxRates: FC = () => {
  const { data: dataAllTaxYears, isLoading: isLoadingAllTaxYears } =
    useGetAllTaxYearsQuery();
  const { data: dataAllTaxRates, isLoading: isLoadingAllTaxRates } =
    useGetAllTaxRatesQuery();

  if (isLoadingAllTaxYears) return <div>Loading...</div>;
  if (!dataAllTaxYears) return <div>No tax years data to display...</div>;
  if (isLoadingAllTaxRates) return <div>Loading...</div>;
  if (!dataAllTaxRates) return <div>No tax rates data to display...</div>;

  const yearsHeaders = dataAllTaxYears
    .map((item) => {
      return {
        id: item.id,
        field: `year_${item.id}`,
        headerName: item.name,
        type: "number",
        width: 120,
        editable: false,
        hide: false,
        align: "right" as GridAlignment,
        valueFormatter: ({ value }: any) => {
          if (value) {
            return displayColumnCurrencyGBP(Number(value));
          } else {
            return null;
          }
        },
      };
    })
    .sort((a: any, b: any) => b.id - a.id);

  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "tax_rate_type",
      headerName: "Tax Rate Type",
      width: 240,
    },
    {
      field: "tax_rate_type_code",
      headerName: "Code",
      width: 80,
    },
    ...yearsHeaders,
  ];

  const rows = dataAllTaxRates.map((item) => {
    const taxRowHeaders = {
      id: item.id,
      tax_rate_type: item.name,
      tax_rate_type_code: item.code,
    };

    const rowValues = item.tax_rate.map((rowValue: any) => {
      return {
        [`year_${rowValue.tax_year.id}`]: rowValue.tax_rate,
      };
    });

    const joinedTaxRow = [taxRowHeaders, ...rowValues];
    const taxRow = Object.assign({}, ...joinedTaxRow);

    return taxRow;
  });

  return (
    <>
      <Container maxWidth="xl" className="mt-4">
        <div style={{ height: 1200, width: "100%" }}>
          <DataGridPro
            rows={rows}
            columns={columns}
            autoHeight={true}
            sx={{ backgroundColor: "#ffffff" }}
            initialState={{ pinnedColumns: { left: ["tax_rate_type"] } }}
          />
        </div>
      </Container>
    </>
  );
};

export default TableTaxRates;
