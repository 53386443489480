import { FC, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import IconDownload from "@mui/icons-material/Download";

import { getPdfFormPaymentInstruction } from "../../../../utils/getPdfForm";
import { getPdfFormPayslipsZip } from "../../../../utils/getPdfFormZip";

interface RouterState {
  payrollId?: string;
}

const HeaderSummary: FC = () => {
  const location = useLocation();

  const [
    loadingDownloadPaymentInstruction,
    setLoadingDownloadPaymentInstruction,
  ] = useState<boolean>(false);
  const [loadingDownloadAllPayslips, setLoadingDownloadAllPayslips] =
    useState<boolean>(false);

  const { payrollId: routerPayrollId } = (location.state as RouterState) || {
    payrollId: 0,
  };

  const handleDownloadPaymentInstructionClick = useCallback(() => {
    setLoadingDownloadPaymentInstruction(true);

    getPdfFormPaymentInstruction(routerPayrollId!)
      .then((data) => {
        if (data) {
          const filename = `PaymentInstruction-${routerPayrollId}`;
          const safeFilename = filename.replace(/[^a-z0-9]/gi, "-");

          const link = document.createElement("a");
          link.href = data;
          link.setAttribute("download", `${safeFilename}.pdf`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .then(() => setLoadingDownloadPaymentInstruction(false));
  }, [routerPayrollId]);

  const handleDownloadAllPayslipsClick = useCallback(() => {
    setLoadingDownloadAllPayslips(true);

    getPdfFormPayslipsZip(routerPayrollId!)
      .then((data) => {
        if (data) {
          const filename = `Payslips-${routerPayrollId}`;
          const safeFilename = filename.replace(/[^a-z0-9]/gi, "-");

          const link = document.createElement("a");
          link.href = data;
          link.setAttribute("download", `${safeFilename}.zip`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .then(() => setLoadingDownloadAllPayslips(false));
  }, [routerPayrollId]);

  return (
    <>
      <Grid
        container
        component="header"
        sx={{
          border: "1px solid #E7E6E9",
          borderRadius: "0.5rem",
          backgroundColor: "white",
          padding: "0.75rem 1.5rem 1.5rem",
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="overline"
            display="block"
            sx={{ fontSize: "1rem", fontWeight: 600 }}
          >
            PDF Documents
          </Typography>
        </Grid>

        <Stack direction="row" spacing={2}>
          <LoadingButton
            variant="outlined"
            size="large"
            endIcon={<IconDownload />}
            onClick={handleDownloadPaymentInstructionClick}
            loading={loadingDownloadPaymentInstruction}
            disabled={false}
          >
            Download Payment Instruction
          </LoadingButton>

          <LoadingButton
            variant="outlined"
            size="large"
            endIcon={<IconDownload />}
            onClick={handleDownloadAllPayslipsClick}
            loading={loadingDownloadAllPayslips}
            disabled={false}
          >
            Download all Payslips
          </LoadingButton>
        </Stack>
      </Grid>
    </>
  );
};

export default HeaderSummary;
