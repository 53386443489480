import { cloudPayrollApi } from "./cloudPayrollApi";
import { PayrollScheme } from "../../types/PayrollScheme";

const apiPayrollSchemes = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // CREATE Payroll Scheme
    createPayrollScheme: builder.mutation<void, PayrollScheme>({
      query: (payrollScheme) => ({
        url: `/payroll-schemes`,
        method: "POST",
        body: payrollScheme,
      }),
      invalidatesTags: ["PayrollScheme"],
    }),

    // READ All Payroll Schemes
    getAllPayrollSchemes: builder.query<PayrollScheme[], number | void>({
      query: (limit = 10) => `/payroll-schemes?limit=${limit}`,
      providesTags: ["PayrollScheme"],
    }),

    // READ All Payroll Schemes by Payroll ID
    getAllPayrollSchemesByPayrollEntryId: builder.query<
      PayrollScheme[],
      string | void | undefined
    >({
      query: (payrollEntryId) =>
        `/payroll-schemes/payroll-entry/${payrollEntryId}`,
      providesTags: ["PayrollScheme"],
    }),

    // READ Single Payroll Scheme
    getPayrollScheme: builder.query<PayrollScheme, number>({
      query: (id) => `/payroll-schemes/${id}`,
      providesTags: ["PayrollScheme"],
    }),

    // UPDATE Payroll Scheme
    updatePayrollScheme: builder.mutation<void, PayrollScheme>({
      query: ({ id, ...rest }) => ({
        url: `/payroll-schemes/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["PayrollScheme"],
    }),

    // DELETE Payroll Scheme
    deletePayrollScheme: builder.mutation<void, string>({
      query: (id) => ({
        url: `/payroll-schemes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PayrollScheme"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreatePayrollSchemeMutation,

  useGetAllPayrollSchemesQuery,
  useGetAllPayrollSchemesByPayrollEntryIdQuery,
  useGetPayrollSchemeQuery,
  useUpdatePayrollSchemeMutation,
  useDeletePayrollSchemeMutation,
} = apiPayrollSchemes;
