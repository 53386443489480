import { FC } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

interface Props {
  mt?: number;
  mb?: number;
}

const FormDivider: FC<Props> = ({ mt, mb }) => {
  return (
    <Box sx={{ mt: mt, mb: mb }}>
      <Divider />
    </Box>
  );
};

export default FormDivider;
