import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { AdditionalInformation } from "../ValidationAdditionalInformation";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

import HeaderSection from "../../HeaderSection";
import FormDivider from "../../FormDivider";

interface Props {
  control: Control<AdditionalInformation, any>;
  // TODO(DBB) : Type me
  errors: any;
}

interface SelectItem {
  id: string | number;
  name: string;
}

const SectionMaritalStatus: FC<Props> = ({ control, errors }) => {
  return (
    <>
      <Box>
        <HeaderSection title="Marital Status" />

        {/* Input : Marital Status */}
        <FormControl sx={{ marginBottom: "2rem" }} fullWidth={true}>
          <Controller
            control={control}
            name="marital_status"
            defaultValue=""
            render={({ field }) => (
              <>
                <InputLabel id="label-marital_status">
                  Marital Status
                </InputLabel>
                <Select
                  {...field}
                  label="Marital Status"
                  labelId="label-marital_status"
                  disabled={false}
                >
                  {maritalStatuses?.map((item: SelectItem) => {
                    return (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            )}
          />
          <FormHelperText error={true}>
            {errors.marital_status?.message}
          </FormHelperText>
        </FormControl>
      </Box>

      <FormDivider mt={2} mb={4} />
    </>
  );
};

const maritalStatuses = [
  {
    id: 1,
    name: "Single",
  },
  {
    id: 2,
    name: "Married",
  },
  {
    id: 3,
    name: "Divorced",
  },
  {
    id: 4,
    name: "Widowed",
  },
];

export default SectionMaritalStatus;
