import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertProps } from "@mui/material/Alert";

import DialogCancel from "./DialogCancel";
import Box from "@mui/material/Box";

interface Props {
  handleStepperNext: () => void;
}

const Footer: FC<Props> = ({ handleStepperNext }) => {
  const [loadingSave, setLoadingSave] = useState(false);

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const navigate = useNavigate();

  const handleSave = async () => {
    setLoadingSave(true);

    const timeout = (ms: number) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    const snackbarSaveProgress = setSnackbar({
      children: "Saving draft payroll...",
      severity: "info",
    });

    let falseLoad = await Promise.all([snackbarSaveProgress, timeout(2500)]);

    const snackbarSaveComplete = setSnackbar({
      children: "Payroll saved!",
      severity: "success",
    });

    let falseLoadComplete = await Promise.all([
      falseLoad,
      snackbarSaveComplete,
      timeout(1250),
    ]);

    falseLoadComplete && navigate("/payrolls/overview");
  };

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Box>
          <DialogCancel />
        </Box>

        <Box>
          <LoadingButton
            loading={loadingSave}
            variant="outlined"
            color="secondary"
            onClick={handleSave}
          >
            Save as draft
          </LoadingButton>

          <Button
            sx={{ minWidth: "200px", marginLeft: "1rem" }}
            variant="contained"
            onClick={handleStepperNext}
          >
            Preview Payroll
          </Button>
        </Box>
      </Box>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

export default Footer;
