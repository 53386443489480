import { z } from "zod";

export type EmploymentInformation = z.infer<typeof SchemaEmploymentInformation>;

export const SchemaEmploymentInformation = z.object({
  pension_only: z.boolean(),

  employment_type: z
    .string({
      invalid_type_error: "Employment Type type is required",
    })
    .min(1, {
      message: "Employment Type is required",
    }),

  job_date_started: z
    .date({
      invalid_type_error: "Job Date Started is required",
    })
    .or(z.string().transform((str) => new Date(str))),

  job_date_finished: z
    .date({
      invalid_type_error: "Job Date Started is required",
    })
    .or(z.string().transform((str) => new Date(str)))
    .optional()
    .nullable(),

  payroll_schedule: z
    .number({
      invalid_type_error: "Pay Schedule type is required",
    })
    .min(1, {
      message: "Payroll Schedule is required",
    }),

  employee_number: z.string().optional(),

  job_title: z.string().optional(),

  work_permit_required: z.boolean(),

  date_work_permit_issue: z
    .date({
      invalid_type_error: "Work Permit Date Issued is required",
    })
    .or(z.string().transform((str) => new Date(str)))
    .optional()
    .nullable(),

  date_work_permit_expiry: z
    .date({
      invalid_type_error: "Work Permit Date Expiry is required",
    })
    .or(z.string().transform((str) => new Date(str)))
    .optional()
    .nullable(),

  work_permit_number: z.string().optional(),
});
