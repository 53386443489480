import { FC } from "react";
import { useAppSelector } from "../../../../app/hooks";

import BlockText from "../../../company/edit/BlockText";

import { User } from "../../../../types/User";

const DetailsAccountProfile: FC = () => {
  const currentUser: Partial<User> = useAppSelector(
    (state) => state.currentUser
  );

  return (
    <>
      <BlockText title="Forename(s)" data={currentUser?.forenames || ""} />

      <BlockText title="Surname" data={currentUser?.surname || ""} />

      <BlockText
        title="Email Address"
        data={currentUser?.email_address || ""}
      />
    </>
  );
};

export default DetailsAccountProfile;
