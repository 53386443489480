import { FC, ReactNode } from "react";
import { useMatch } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BannerActivate from "./BannerActivate";

interface Props {
  children?: ReactNode;
  title: string;
}

const HeaderForm: FC<Props> = ({ children, title }) => {
  const isEdit = useMatch("/employees/edit");

  return (
    <>
      <Box
        sx={{
          borderBottom: "1px solid #E7E6E9",
          marginBottom: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Typography component="h3" variant="h5" sx={{ marginTop: "0.5rem" }}>
            {title}
          </Typography>

          <Box>{children}</Box>
        </Box>
      </Box>

      {isEdit && <BannerActivate />}
    </>
  );
};

export default HeaderForm;
