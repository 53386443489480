import { FC } from "react";
import { useAppSelector } from "../../../app/hooks";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const LogoCompany: FC = () => {
  const currentCompany = useAppSelector((state) => state.currentCompany);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "6rem",
        height: "6rem",
        border: "1px solid #E7E6E9",
        borderRadius: "0.25rem",
      }}
    >
      {currentCompany.logo_url ? (
        <img
          src={currentCompany.logo_url}
          alt={`Company logo for ${currentCompany.name_trading}`}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "4rem",
            height: "4rem",
            backgroundColor: "#0059C7",
          }}
        >
          {currentCompany.name_trading && (
            <Typography
              display="block"
              sx={{
                color: "white",
                fontSize: "3rem",
                fontWeight: "bold",
              }}
            >
              {currentCompany.name_trading.charAt(0)}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default LogoCompany;
