import { cloudPayrollApi } from "./cloudPayrollApi";

import { User } from "../../types/User";

const apiTaxYears = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // TODO(DBB) : Prefer to use findOneByIdTakesCurrentUser(), passing currentUser.id
    // READ Current User Self (refetch)
    getCurrentUserSelf: builder.query<User, void>({
      query: () => `/users/self`,
      providesTags: ["User"],
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetCurrentUserSelfQuery } = apiTaxYears;
