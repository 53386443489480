import { FC, createContext, useState, useMemo } from "react";

import Container from "@mui/material/Container";
import StepperCompanyAdd from "./StepperCompanyAdd";

interface NewCompanyContextType {
  newCompanyId: number | undefined;
  setNewCompanyId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const NewCompanyContext = createContext<NewCompanyContextType>({
  newCompanyId: undefined,
  setNewCompanyId: () => {},
});

const DashboardCompanyAdd: FC = () => {
  const [newCompanyId, setNewCompanyId] = useState<number | undefined>(
    undefined
  );

  const providerNewCompanyId = useMemo(
    () => ({ newCompanyId, setNewCompanyId }),
    [newCompanyId, setNewCompanyId]
  );

  return (
    <>
      <NewCompanyContext.Provider value={providerNewCompanyId}>
        <Container
          maxWidth={false}
          sx={{
            backgroundColor: "#FDFBFF",
          }}
        >
          <StepperCompanyAdd />
        </Container>
      </NewCompanyContext.Provider>
    </>
  );
};

export default DashboardCompanyAdd;
