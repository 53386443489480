import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { FC } from "react";

interface Props {
  title: string;
  optional?: boolean;
}

const HeaderSection: FC<Props> = ({ title, optional }) => {
  return (
    <>
      <header style={{ display: "flex", alignItems: "center" }}>
        <Typography component="h3" variant="h5" sx={{ mt: 2, mb: 2 }}>
          {title}
        </Typography>
        {optional && <Chip label="Optional" sx={{ ml: 1 }} />}
      </header>
    </>
  );
};

export default HeaderSection;
