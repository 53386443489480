import { FC } from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { useGetAppVersionQuery } from "../../features/api/cloudPayrollApi";

const Footer: FC = () => {
  // NOTE : Ping API service to anticipate cold start
  const { data: dataGetAppVersion } = useGetAppVersionQuery();

  return (
    <>
      <Container
        component="footer"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "2rem",
          paddingBottom: "4rem",
        }}
      >
        <Link href="/">
          <img
            src={require("../../assets/svg/logo-horizontal-dark.svg").default}
            srcSet={
              require("../../assets/svg/logo-horizontal-dark.svg").default
            }
            alt="Cloud Payroll"
            loading="lazy"
            className="inline-block"
          />
        </Link>

        <Typography
          sx={{ color: "#FFFFFF", marginTop: "0.5rem", marginBottom: "2rem" }}
          variant="caption"
          display="block"
          gutterBottom
        >
          {dataGetAppVersion ? dataGetAppVersion.version : " "}
        </Typography>

        <Typography sx={{ color: "#FFFFFF" }}>
          Haven't signed up yet?
        </Typography>

        <Link href="/signup" className="font-bold">
          <strong style={{ color: "#FFFFFF" }}>
            Register for a Cloud Payroll account.
          </strong>
        </Link>
      </Container>
    </>
  );
};

export default Footer;
