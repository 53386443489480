import { FC } from "react";
import { Outlet, useLocation } from "react-router-dom";

import SubNavigation from "./SubNavigation";
import Box from "@mui/material/Box";

const DashboardEmployees: FC = () => {
  const location = useLocation();

  const noSubNavigationPaths = ["/employees/add", "/employees/edit"];

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {!noSubNavigationPaths.includes(location.pathname) && (
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <SubNavigation />
          </Box>
        )}
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default DashboardEmployees;
