import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { MemberAdd, SchemaMemberAdd } from "../ValidateMemberAdd";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

interface Props {
  addMemberProspective: (email_address: string) => void;
}

const FormMemberAdd: FC<Props> = ({ addMemberProspective }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<MemberAdd>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaMemberAdd),
    defaultValues: {
      email_address: "",
    },
  });

  const onSubmit = (data: MemberAdd) => {
    const { email_address } = data;

    addMemberProspective(email_address);
    reset();
  };

  return (
    <>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" gap={2}>
          <Typography>
            Enter new member’s email address below to add them to invitation
            list:
          </Typography>

          {/* Input : Email Address */}
          <Controller
            control={control}
            name="email_address"
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Email Address"
                variant="outlined"
                error={!!errors.email_address}
                helperText={errors.email_address?.message || " "}
                fullWidth={true}
                disabled={false}
                InputProps={{
                  endAdornment: (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="medium"
                      disabled={false}
                      disableElevation={true}
                    >
                      Add
                    </Button>
                  ),
                }}
              />
            )}
          />

          <Divider sx={{ marginBottom: "2rem" }} />
        </Stack>
      </form>
    </>
  );
};

export default FormMemberAdd;
