import { useState } from "react";
import { NavLink, Link } from "react-router-dom";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";

import Logo from "./Logo";
import CompanySelector from "../selector-company/CompanySelector";
import MenuAccount from "./MenuAccount";

const pages = [
  {
    title: "Home",
    href: "/home",
    icon: "home",
  },
  {
    title: "Payrolls",
    href: "/payrolls/overview",
    icon: "receipt_long",
  },
  {
    title: "Employees",
    href: "/employees/overview",
    icon: "recent_actors",
  },
  {
    title: "Tax Forms",
    href: "/tax-forms/overview",
    icon: "article",
  },
  // {
  //   title: "Reports",
  //   href: "/reports",
  //   icon: "pie_chart",
  // },
];

const Navigation = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // TODO(DBB) : Extract to own component
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const iconWrap = (name: any) => (
    <Icon className="mr-2" sx={{ color: "rgba(0, 0, 0, 0.26)" }}>
      {name}
    </Icon>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: "0 0 0 #E0E0E0",
      }}
    >
      <Toolbar disableGutters>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "1.5rem",
          }}
        >
          <Logo />

          <CompanySelector />
        </Box>

        <Box
          sx={(theme) => ({
            flexGrow: 1,
            display: "none",
            justifyContent: "center",
            [theme.breakpoints.down(992)]: {
              display: "flex",
              justifyContent: "start",
            },
          })}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <Icon>menu</Icon>
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={(theme) => ({
              display: "none",
              [theme.breakpoints.down(992)]: {
                display: "flex",
              },
            })}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                boxShadow:
                  "0px 8px 10px 1px rgba(47, 48, 51, 0.14), 0px 3px 14px 2px rgba(47, 48, 51, 0.12)",
                mt: "40px",
                left: "8px !important",
              },
            }}
          >
            {pages.map(({ title, href, icon }) => (
              <Link key={title} to={href}>
                <MenuItem
                  sx={{ mb: "12px" }}
                  key={title}
                  onClick={handleCloseNavMenu}
                >
                  {iconWrap(icon)}
                  <Typography textAlign="center">{title}</Typography>
                </MenuItem>
              </Link>
            ))}
          </Menu>
        </Box>

        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={(theme) => ({
            flexGrow: 1,
            display: "none",
            [theme.breakpoints.down(992)]: {
              display: "flex",
            },
          })}
        >
          Cloud Payroll
        </Typography>

        {/* Navbar */}
        <Box
          sx={(theme) => ({
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.down(992)]: {
              display: "none",
            },
          })}
        >
          {pages.map(({ title, href, icon }) => (
            <NavLink
              style={(navData) =>
                navData.isActive
                  ? {
                      borderBottom: "3px solid #FFFFFF",
                      textDecoration: "none",
                    }
                  : {
                      borderBottom: "3px solid transparent",
                      textDecoration: "none",
                    }
              }
              key={title}
              to={href}
            >
              <Button
                onClick={handleCloseNavMenu}
                sx={(theme) => ({
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Supreme, sans-serif",
                  fontWeight: 400,
                  color: "white",
                  textDecoration: "none",
                  padding: "18px 16px",
                  borderBottom: "none",
                  [theme.breakpoints.down(1200)]: {
                    padding: "18px 8px",
                  },
                })}
              >
                <Icon
                  className="mr-3"
                  sx={(theme) => ({
                    marginRight: "0.75rem",
                    [theme.breakpoints.down(1200)]: {
                      marginRight: "0.5rem",
                    },
                  })}
                >
                  {icon}
                </Icon>
                {title}
              </Button>
            </NavLink>
          ))}
        </Box>

        {/* User Account  */}
        <Box sx={{ flexGrow: 0 }}>
          <IconButton
            onClick={handleOpenUserMenu}
            sx={{ mr: "16px", color: "#FFFFFF" }}
          >
            <Avatar alt="User's Name" className="mr-1" />
            <Icon fontSize="medium">keyboard_arrow_down</Icon>
          </IconButton>

          <MenuAccount
            anchorElUser={anchorElUser}
            handleCloseUserMenu={handleCloseUserMenu}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
