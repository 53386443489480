import Box from "@mui/material/Box";
import { Dispatch, FC, SetStateAction } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { useGetAllOverduePayrollsByCompanyIdQuery } from "../../../../features/api/apiPayrolls";
import OptionPayrollNext from "./OptionPayrollNext";

interface Props {
  selectedPayroll: string;
  setSelectedPayroll: Dispatch<SetStateAction<string>>;
  setCanProgress: Dispatch<SetStateAction<boolean>>;
}

const OptionsPayrollsOverdue: FC<Props> = ({
  selectedPayroll,
  setSelectedPayroll,
  setCanProgress,
}) => {
  const currentCompanyId = useAppSelector((state) => state.currentCompany.id);

  const { data: dataGetOverduePayrollsByCompanyId } =
    useGetAllOverduePayrollsByCompanyIdQuery(currentCompanyId);

  const reducedPayrolls = dataGetOverduePayrollsByCompanyId
    ?.slice()
    .reduce((accumulator: any, item) => {
      const { payroll_schedule_id } = item;

      if (!accumulator[payroll_schedule_id]) {
        accumulator[payroll_schedule_id] = [];
      }

      accumulator[payroll_schedule_id].push(item);

      return accumulator;
    }, {});

  const firstInstancesIds: string[] = [];
  reducedPayrolls &&
    Object.keys(reducedPayrolls).forEach((key: any) => {
      const firstPayroll = reducedPayrolls[key][0];
      firstInstancesIds.push(firstPayroll.payroll_id);
    });

  // Ref : https://stackoverflow.com/a/60688789/11698046
  // Ref : https://github.com/microsoft/TypeScript/issues/5710
  // Ref : https://stackoverflow.com/a/53420326/11698046
  const sortedChronologicalPayrolls = dataGetOverduePayrollsByCompanyId
    ?.slice()
    .sort((a, b) => {
      const dateA = +new Date(a.pay_date_scheduled!);
      const dateB = +new Date(b.pay_date_scheduled!);
      return dateA - dateB;
    });

  return (
    <>
      {dataGetOverduePayrollsByCompanyId ? (
        <Box>
          {firstInstancesIds &&
            sortedChronologicalPayrolls?.map((item: any) => {
              return (
                <OptionPayrollNext
                  key={item.key}
                  data={item}
                  selectedPayroll={selectedPayroll}
                  setSelectedPayroll={setSelectedPayroll}
                  setCanProgress={setCanProgress}
                  disabled={
                    firstInstancesIds.includes(item.payroll_id) ? false : true
                  }
                />
              );
            })}
        </Box>
      ) : (
        <Box>None</Box>
      )}
    </>
  );
};

export default OptionsPayrollsOverdue;
