import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconPersonAdd from "@mui/icons-material/PersonAddAltOutlined";

const ModuleViewEmployees: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          maxWidth: "13rem",
          border: "1px dashed #E7E6E9",
          borderRadius: "0.25rem",
          padding: "2rem 1rem 1rem",
          gap: "4rem",
          backgroundColor: "white",
        }}
      >
        <IconPersonAdd
          sx={{
            transform: "scale(4)",
            opacity: "0.66",
            marginTop: "2rem",
            alignSelf: "center",
            color: "#999FAA",
          }}
        />
        <Button
          variant="outlined"
          size="medium"
          disableElevation={true}
          onClick={() => {
            navigate("/employees/add");
          }}
        >
          Add Employee
        </Button>
      </Box>
    </>
  );
};

export default ModuleViewEmployees;
