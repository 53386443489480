import { FC } from "react";
import { RootState } from "../../../../app/store";
import { useAppSelector } from "../../../../app/hooks";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useGetPayrollQuery } from "../../../../features/api/apiPayrolls";
import { dateFormatter } from "../../../../utils/dateFormatter";

const HeaderSummary: FC = () => {
  const provisionedPayrollId: string = useAppSelector(
    (state: RootState) => state.provisionedPayroll.id
  );
  const currentPayrollEntry = useAppSelector(
    (state: RootState) => state.currentPayrollEntry
  );

  const { data: dataPayroll } = useGetPayrollQuery(provisionedPayrollId);

  const { forenames, surname, job_title, tax_code, ni_table_code } =
    currentPayrollEntry;

  const displayPayDate = dateFormatter(dataPayroll?.pay_date_scheduled);

  return (
    <>
      {currentPayrollEntry && (
        <Box sx={customStyles.overviewContainer}>
          <Box sx={customStyles.overviewEmployee}>
            <strong>{`${forenames} ${surname}`}</strong>
            <br />
            {`${job_title}`}
          </Box>

          <Box sx={customStyles.overviewBox}>
            <Box sx={customStyles.overviewGroup}>
              <Typography variant="caption" sx={customStyles.title}>
                <strong>Pay Frequency</strong>
              </Typography>
              <Typography>
                {dataPayroll?.payroll_schedule.payroll_frequency.name}
              </Typography>
            </Box>

            <Box sx={customStyles.overviewGroup}>
              <Typography variant="caption" sx={customStyles.title}>
                <strong>Pay Period</strong>
              </Typography>
              <Typography>{String(dataPayroll?.pay_period)}</Typography>
            </Box>

            <Box sx={customStyles.overviewGroup}>
              <Typography variant="caption" sx={customStyles.title}>
                <strong>Scheduled Pay Date</strong>
              </Typography>
              <Typography>{displayPayDate}</Typography>
            </Box>

            <Box sx={customStyles.overviewGroup}>
              <Typography variant="caption" sx={customStyles.title}>
                <>
                  <strong>Tax & Insurance</strong>
                </>
              </Typography>
              <Typography>
                <>
                  Tax Code: {tax_code} <br />
                  NI Code: {ni_table_code}
                </>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

const customStyles = {
  overviewContainer: {
    padding: "1.5rem",
    display: "flex",
  },

  overviewBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    fontSize: "0.88rem",
    border: "1px solid #E7E6E9",
    borderRadius: "0.5rem",
    padding: "0 1rem",
  },

  overviewGroup: {
    flex: "1 0 auto",
    textAlign: "right",
    borderRight: "1px solid #E7E6E9",
    padding: "1rem 2rem 1rem 1rem",
    margin: "0.5rem 0",
    "&:last-child": {
      paddingRight: 0,
      borderRight: "none",
    },
    "& p": {
      marginBottom: "8px",
    },
  },

  overviewEmployee: {
    paddingRight: "2rem",
    fontSize: "0.88rem",
    strong: {
      display: "inline-block",
      fontSize: "1rem",
      marginBottom: "0.33rem",
    },
  },

  title: {
    textTransform: "uppercase",
    color: "rgba(47, 48, 51, 0.72)",
    marginBottom: "12px",
    display: "block",
  },
};

export default HeaderSummary;
