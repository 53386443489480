import { FC, useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { CurrentEmployeeContext } from "./DashboardEmployee";

import {
  useLazyGetEmployeeQuery,
  useUpdateEmployeeMutation,
} from "../../../features/api/apiEmployees";
import { useLazyGetAllEmployeePayRatesByEmployeeIdQuery } from "../../../features/api/apiEmployeePayRates";

const BannerActivate: FC = () => {
  const { employeeId } = useContext(CurrentEmployeeContext);

  const [allRequiredFieldsPresent, setAllRequiredFieldsPresent] =
    useState<boolean>(false);

  const [triggerLazyGetEmployee, resultLazyGetEmployee] =
    useLazyGetEmployeeQuery();

  const [
    triggerLazyGetEmployeePayRatesByEmployeeId,
    resultLazyGetEmployeePayRatesByEmployeeId,
  ] = useLazyGetAllEmployeePayRatesByEmployeeIdQuery();

  useEffect(() => {
    employeeId && triggerLazyGetEmployee(employeeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);

  useEffect(() => {
    employeeId && triggerLazyGetEmployeePayRatesByEmployeeId(employeeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);

  const {
    title,
    forenames,
    surname,
    birth_date,
    country,
    address_1,
    city,
    post_code,
    tax_reference_number,
    tax_code,
    resident_status,
    ni_table_code,
    ni_number,
    employment_type,
    job_date_started,
    payroll_schedule,
    payroll_payment_method,
    status,
  } = resultLazyGetEmployee.data || {};

  useEffect(() => {
    title &&
      forenames &&
      surname &&
      birth_date &&
      country &&
      address_1 &&
      city &&
      post_code &&
      tax_reference_number &&
      tax_code &&
      resident_status &&
      ni_table_code &&
      ni_number &&
      employment_type &&
      job_date_started &&
      payroll_schedule &&
      payroll_payment_method &&
      setAllRequiredFieldsPresent(true);
  }, [
    title,
    forenames,
    surname,
    birth_date,
    country,
    address_1,
    city,
    post_code,
    tax_reference_number,
    tax_code,
    resident_status,
    ni_table_code,
    ni_number,
    employment_type,
    job_date_started,
    payroll_schedule,
    payroll_payment_method,
  ]);

  const [updateEmployee] = useUpdateEmployeeMutation();

  return (
    <>
      {resultLazyGetEmployee.data &&
        resultLazyGetEmployeePayRatesByEmployeeId?.data &&
        status !== "ACTIVE" && (
          <Box sx={{ marginBottom: "1rem" }}>
            {allRequiredFieldsPresent ? (
              <>
                <Alert severity="info">
                  <Box>
                    <Typography>
                      All required employee information has been provided
                    </Typography>
                    <Button
                      onClick={() =>
                        updateEmployee({ id: employeeId, status: "ACTIVE" })
                      }
                      color="info"
                      variant="outlined"
                      disabled={false}
                      sx={{ marginTop: "0.5rem" }}
                    >
                      Activate Employee
                    </Button>
                  </Box>
                </Alert>
              </>
            ) : (
              <>
                <Alert severity="warning">
                  <AlertTitle>
                    To activate the employee, please submit the following
                    required information:
                  </AlertTitle>
                  <List dense={true}>
                    {!title && (
                      <ListItem sx={{ display: "list-item" }}>Title</ListItem>
                    )}

                    {!forenames && (
                      <ListItem sx={{ display: "list-item" }}>
                        Forenames
                      </ListItem>
                    )}

                    {!surname && (
                      <ListItem sx={{ display: "list-item" }}>Surname</ListItem>
                    )}

                    {!birth_date && (
                      <ListItem sx={{ display: "list-item" }}>
                        Birth Date
                      </ListItem>
                    )}

                    {!country && (
                      <ListItem sx={{ display: "list-item" }}>Country</ListItem>
                    )}

                    {!address_1 && (
                      <ListItem sx={{ display: "list-item" }}>
                        Address 1
                      </ListItem>
                    )}

                    {!city && (
                      <ListItem sx={{ display: "list-item" }}>City</ListItem>
                    )}

                    {!post_code && (
                      <ListItem sx={{ display: "list-item" }}>
                        Post Code
                      </ListItem>
                    )}

                    {!tax_reference_number && (
                      <ListItem sx={{ display: "list-item" }}>
                        Tax Reference Number
                      </ListItem>
                    )}

                    {!tax_code && (
                      <ListItem sx={{ display: "list-item" }}>
                        Tax Code
                      </ListItem>
                    )}

                    {!resident_status && (
                      <ListItem sx={{ display: "list-item" }}>
                        Resident Status
                      </ListItem>
                    )}

                    {!ni_table_code && (
                      <ListItem sx={{ display: "list-item" }}>
                        NI Table Code
                      </ListItem>
                    )}

                    {!ni_number && (
                      <ListItem sx={{ display: "list-item" }}>
                        NI Number
                      </ListItem>
                    )}
                    {!employment_type && (
                      <ListItem sx={{ display: "list-item" }}>
                        Employment Type
                      </ListItem>
                    )}

                    {!job_date_started && (
                      <ListItem sx={{ display: "list-item" }}>
                        Job Date Started
                      </ListItem>
                    )}

                    {!payroll_schedule && (
                      <ListItem sx={{ display: "list-item" }}>
                        Payroll Schedule
                      </ListItem>
                    )}

                    {resultLazyGetEmployeePayRatesByEmployeeId?.data?.length ===
                      0 && (
                      <ListItem sx={{ display: "list-item" }}>
                        Employee Rates of Pay
                      </ListItem>
                    )}

                    {!payroll_payment_method && (
                      <ListItem sx={{ display: "list-item" }}>
                        Payroll Payment Method
                      </ListItem>
                    )}
                  </List>

                  <Button
                    color="warning"
                    variant="outlined"
                    disabled={true}
                    sx={{ marginTop: "0.5rem" }}
                  >
                    Activate Employee
                  </Button>
                </Alert>
              </>
            )}
          </Box>
        )}
    </>
  );
};

export default BannerActivate;
