import { FC, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";

import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridRowParams,
  GridRowsProp,
  GridValidRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";

import Box from "@mui/material/Box";
import IconEdit from "@mui/icons-material/Edit";
import IconReset from "@mui/icons-material/RestartAlt";

import {
  useGetAllPayrollEntriesByPayrollIdQuery,
  useResetPayrollEntryMutation,
} from "../../../../features/api/apiPayrollEntries";
import { setCurrentPayrollEntry } from "../../../../features/state/payrollEntry/payrollEntrySlice";
import { PayrollEntry } from "../../../../types/PayrollEntry";
import Typography from "@mui/material/Typography/Typography";

interface RouterState {
  provisionedPayrollId?: string;
}

const formatValuesCurrency = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

const DataGridPayrollEntries: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState<number>(10);

  const reduxProvisionedPayrollId: string = useAppSelector(
    (state: RootState) => state.provisionedPayroll.id
  );

  const { provisionedPayrollId: routerProvisionedPayrollId } =
    (location.state as RouterState) || {};

  let provisionedPayrollId = "";
  routerProvisionedPayrollId
    ? (provisionedPayrollId = routerProvisionedPayrollId)
    : (provisionedPayrollId = reduxProvisionedPayrollId);

  const {
    data: dataPayrollEntries,
    isLoading: isLoadingPayrollEntries,
    isFetching: isFetchingPayrollEntries,
    isError: isErrorPayrollEntries,
  } = useGetAllPayrollEntriesByPayrollIdQuery(provisionedPayrollId);

  const [resetPayrollEntry] = useResetPayrollEntryMutation();

  const columns: GridColDef[] = useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: "full_name",
        headerName: "Name",
        width: 160,
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.surname || ""}, ${params.row.forenames || ""}`,
      },
      {
        field: "job_title",
        headerName: "Job Title",
        type: "string",
        width: 120,
        editable: false,
        hide: false,
      },
      {
        field: "payment_method",
        headerName: "Payment Method",
        type: "string",
        width: 140,
        editable: false,
        hide: false,
      },
      {
        field: "tax_code",
        headerName: "Tax Code",
        type: "string",
        width: 80,
        editable: false,
        hide: false,
      },
      {
        field: "ni_table_code",
        headerName: "NI Table",
        type: "string",
        width: 40,
        editable: false,
        hide: false,
      },
      {
        field: "total_cost",
        headerName: "Total Cost",
        valueFormatter: ({ value }) =>
          formatValuesCurrency.format(Number(value / 100)),
        align: "right",
        cellClassName: "datagrid-payroll-entries-total-cost",
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 80,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<IconEdit />}
            label="Edit"
            onClick={() => {
              dispatch(setCurrentPayrollEntry(params.row));
              navigate("/payrolls/run/makeup");
            }}
            showInMenu={true}
            disabled={false}
          />,
          <GridActionsCellItem
            icon={<IconReset />}
            label="Reset"
            onClick={() => resetPayrollEntry(params.row.id)}
            showInMenu={true}
            disabled={false}
          />,
        ],
      },
    ],
    [dispatch, navigate, resetPayrollEntry]
  );

  if (isLoadingPayrollEntries) return <div>Loading Payroll Entries...</div>;
  if (isFetchingPayrollEntries) return <div>Fetching Payroll Entries...</div>;
  if (isErrorPayrollEntries) return <div>Error Payroll Entries!</div>;
  if (!dataPayrollEntries)
    return <div>No Payroll Entries data to display...</div>;

  const rows: GridRowsProp = dataPayrollEntries.map((item: PayrollEntry) => {
    const statusModified = item.is_default_state ? "Default" : "Modified";

    return {
      id: item.id,
      forenames: item.employee_forenames,
      surname: item.employee_surname,
      job_title: item.employee_job_title,
      payment_method: item.payroll_payment_method.name,
      tax_code: item.employee_tax_code,
      ni_table_code: item.employee_ni_table_code,
      pay_makeup: statusModified,
      total_cost: item.calculated_total_cost,
    };
  });

  return (
    <>
      <Box sx={{ marginTop: "1.5rem", marginBottom: "1rem" }}>
        <Typography variant="h5" component="h3" gutterBottom>
          Payroll Entries
        </Typography>
      </Box>
      <Box
        sx={{
          "& .datagrid-payroll-entries-total-cost": {
            fontWeight: "600",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
      >
        <DataGridPro
          rows={rows || []}
          columns={columns}
          autoHeight={true}
          pagination={true}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 15, 30]}
          initialState={{
            pinnedColumns: {
              left: ["full_name"],
              right: ["total_cost", "actions"],
            },
          }}
          sx={{ backgroundColor: "white" }}
        />
      </Box>
    </>
  );
};

export default DataGridPayrollEntries;
