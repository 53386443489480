import { FC, createContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import Container from "@mui/material/Container";
import StepperEmployee from "./StepperEmployee";

interface EmployeeContextType {
  employeeId: number | undefined;
  setEmployeeId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const CurrentEmployeeContext = createContext<EmployeeContextType>({
  employeeId: undefined,
  setEmployeeId: () => {},
});

const DashboardEmployee: FC = () => {
  const [employeeId, setEmployeeId] = useState<number | undefined>(undefined);
  const { state: routerState }: any = useLocation();
  const { employeeId: routerEmployeeId } = routerState || {};

  const providerEmployeeId = useMemo(
    () => ({ employeeId, setEmployeeId }),
    [employeeId, setEmployeeId]
  );

  useEffect(() => {
    routerEmployeeId && setEmployeeId(routerEmployeeId);
  }, [routerEmployeeId, employeeId]);

  return (
    <>
      <CurrentEmployeeContext.Provider value={providerEmployeeId}>
        <Container
          maxWidth={false}
          sx={{
            backgroundColor: "#FDFBFF",
          }}
        >
          <StepperEmployee />
        </Container>
      </CurrentEmployeeContext.Provider>
    </>
  );
};

export default DashboardEmployee;
