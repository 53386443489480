import { cloudPayrollApi } from "./cloudPayrollApi";

import { NicRateRow } from "../../types/NicRateRow";

const apiNicRates = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // READ All Tax Rates
    getAllNicRates: builder.query<NicRateRow[], void>({
      query: () => `/im/nic-rates`,
      providesTags: ["NicRate"],
    }),

    // READ Single NIC Rate by Year Index
    getNicRateByYear: builder.query<NicRateRow[], number | void>({
      query: (yearIndex) => `/im/nic-rates/year/${yearIndex}`,
      providesTags: ["NicRate"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllNicRatesQuery, useLazyGetNicRateByYearQuery } =
  apiNicRates;
