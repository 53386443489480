import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import IconArrowForward from "@mui/icons-material/ArrowForward";

import {
  useGetAllNextPayrollsByCompanyIdQuery,
  useGetAllPayrollsByCompanyIdQuery,
  useLazyGetPayrollQuery,
  useProvisionPayrollMutation,
} from "../../../../features/api/apiPayrolls";
import { updateProvisionedPayroll } from "../../../../features/state/payroll/payrollSlice";
import { Payroll } from "../../../../types/Payroll";

const ModuleNextPayroll: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentCompanyId = useAppSelector((state) => state.currentCompany.id);

  const [provisionPayroll] = useProvisionPayrollMutation();

  const [triggerGetPayroll] = useLazyGetPayrollQuery();

  const [nextPayroll, setNextPayroll] = useState<Partial<Payroll>>();

  const [loading, setLoading] = useState<boolean>(false);

  const { data: dataGetAllPayrollsByCompanyId } =
    useGetAllPayrollsByCompanyIdQuery(currentCompanyId);

  const { data: dataGetAllNextPayrollsByCompanyId } =
    useGetAllNextPayrollsByCompanyIdQuery(currentCompanyId);

  useEffect(() => {
    const comparedDate = new Date();
    comparedDate.setMonth(comparedDate.getMonth() - 1);

    if (dataGetAllPayrollsByCompanyId && dataGetAllNextPayrollsByCompanyId) {
      const nextDraftPayrolls = dataGetAllPayrollsByCompanyId
        ?.filter((item) => item.status === "DRAFT")
        .filter((item) => new Date(item.pay_date_scheduled!) > comparedDate)
        .filter((item) => item.payroll_entries.length > 0);

      const allPayrolls = [
        ...nextDraftPayrolls,
        ...dataGetAllNextPayrollsByCompanyId,
      ];
      const nextPayroll = allPayrolls.sort((a, b) => {
        const date1 = new Date(a.pay_date_scheduled!);
        const date2 = new Date(b.pay_date_scheduled!);

        return date1.getTime() - date2.getTime();
      })[0];

      setNextPayroll(nextPayroll);
    }
  }, [dataGetAllPayrollsByCompanyId, dataGetAllNextPayrollsByCompanyId]);

  const handleDraftPayrollContinue = useCallback(
    (id: string) => {
      navigate("/payrolls/run", {
        state: { runPayrollActiveStep: 1, provisionedPayrollId: id },
      });
    },
    [navigate]
  );

  const handleGeneratedPayrollContinue = async (payroll_id: string) => {
    setLoading(true);

    provisionPayroll(payroll_id)
      .unwrap()
      .then(() => {
        const provisionedPayroll = triggerGetPayroll(payroll_id);

        return provisionedPayroll;
      })
      .then((response) => {
        const dataProvisionedPayroll = response.data;

        dataProvisionedPayroll &&
          dispatch(updateProvisionedPayroll(dataProvisionedPayroll));
      })
      .then(() => {
        setLoading(false);

        navigate("/payrolls/run", {
          state: {
            provisionedPayrollId: payroll_id,
            runPayrollActiveStep: 1,
          },
        });
      })
      .catch((error: any) => console.error("rejected", error));
  };

  return (
    <>
      {nextPayroll && (
        <Grid
          sx={{
            backgroundColor: "white",
            padding: "1rem",
            border: "1px solid #E7E6E9",
            borderRadius: "0.5rem",
            display: "grid",
            gridTemplateRows: "repeat(3, max-content) 1fr",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            {nextPayroll?.pay_date_scheduled && (
              <Typography variant="h5" component="h3">
                {new Intl.DateTimeFormat("en-GB", { dateStyle: "full" }).format(
                  new Date(nextPayroll?.pay_date_scheduled)
                )}
              </Typography>
            )}

            <Typography
              sx={{
                color: "rgba(0, 89, 199, 1)",
                backgroundColor: "rgba(0, 89, 199, 0.12)",
                padding: ".5rem .556rem .389rem",
                marginBottom: "auto",
                borderRadius: "5.556rem",
                fontSize: ".722rem",
                lineHeight: "1.2",
              }}
            >
              {nextPayroll?.status ? "Draft" : "New"}
            </Typography>
          </Box>

          {/* Column: Pay Period */}
          <Grid
            container
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "1rem",
            }}
          >
            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={{
                color: "#2F3033",
                fontWeight: "700",
                mb: "0.5rem",
              }}
            >
              Pay Period
            </Typography>

            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={{ color: "#2F3033" }}
            >
              Pay period {nextPayroll?.pay_period} of tax year{" "}
              {nextPayroll?.tax_year.name}
            </Typography>
          </Grid>

          {/* Column: Pay Schedule */}
          <Grid
            container
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "1rem",
            }}
          >
            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={{
                color: "#2F3033",
                fontWeight: "700",
                mb: "0.5rem",
              }}
            >
              Pay Schedule
            </Typography>

            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={{ color: "#2F3033" }}
            >
              {nextPayroll?.payroll_schedule?.name
                ? nextPayroll?.payroll_schedule?.name
                : nextPayroll?.payroll_schedule_name}
            </Typography>
          </Grid>

          <Grid container sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              variant="outlined"
              size="medium"
              onClick={() => {
                nextPayroll?.status === "DRAFT"
                  ? handleDraftPayrollContinue(nextPayroll.id!)
                  : handleGeneratedPayrollContinue(nextPayroll!.payroll_id!);
              }}
              loading={loading}
              sx={{ marginTop: "auto" }}
              endIcon={<IconArrowForward />}
              disabled={false}
            >
              Continue
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ModuleNextPayroll;
