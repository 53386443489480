import { FC } from "react";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import SubHeader from "./SubHeader";
import SectionAccountProfile from "./01-profile/SectionAccountProfile";
import SectionAccountDetails from "./02-account-details/SectionAccountDetails";

const DashboardAccountOverview: FC = () => {
  return (
    <>
      <SubHeader title="Account Overview" icon="person" />

      <Stack direction="column" gap={4} sx={{ margin: "2rem" }}>
        <Box component="section">
          <SectionAccountProfile />

          <SectionAccountDetails />
        </Box>
      </Stack>
    </>
  );
};

export default DashboardAccountOverview;
