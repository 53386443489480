import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { Employee } from "../../../types/Employee";

const initialState: Partial<Employee> = {
  id: "",
  title: "",
  forenames: "",
  surname: "",
  birth_date: undefined,
  address_1: "",
  address_2: "",
  city: "",
  region: "",
  post_code: "",
  country: "",

  pension_only: false,
  employment_type: "",

  job_date_started: null,
  job_date_finished: null,

  employee_number: "",
  // payroll_schedule: undefined,
  payroll_schedule: "",

  // job_title: '',
  // job_department: '',
  tax_code: "",
  tax_reference_number: "",
  tax_reference_missing: false,
  resident_status: "",

  ni_table_code: "",
  ni_number: "",

  work_permit_required: false,
  work_permit_number: "",
  date_work_permit_issue: undefined,
  date_work_permit_expiry: undefined,

  marital_status: "",
  // TODO(DBB) : Typo
  // marraige_date: "",
  // maiden_name: "",
  // birth_date: undefined,
  email_address: "",
  phone_number: "",
};

const currentEmployeeSlice = createSlice({
  name: "currentEmployee",
  initialState,
  reducers: {
    updateCurrentEmployee: (
      state,
      action: PayloadAction<Partial<Employee>>
    ) => {
      Object.entries(action.payload ?? {}).forEach(([k, v]) => {
        state[k] = v;
      });
    },
    resetCurrentEmployee: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { updateCurrentEmployee, resetCurrentEmployee } =
  currentEmployeeSlice.actions;

export default currentEmployeeSlice.reducer;
