import { FC } from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const CompanyBilling: FC = () => {
  return (
    <>
      <Container maxWidth="xl" sx={{ width: "100%" }}>
        <Typography
          component="h1"
          variant="h4"
          sx={{ marginTop: "2rem", marginBottom: "1rem" }}
        >
          Company Billing
        </Typography>

        <Alert severity="info">
          <AlertTitle>
            Contact Cloud Payroll support for billing support
          </AlertTitle>
          While we're still building out features, please contact{" "}
          <Link href="mailto:support@cloudpayroll.io">
            <strong>support@cloudpayroll.io</strong>
          </Link>{" "}
          for billing enquiries and support.
        </Alert>
      </Container>
    </>
  );
};

export default CompanyBilling;
