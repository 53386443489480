import { FC } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

interface BlockAddressData {
  title: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  region?: string;
  post_code?: string;
  country?: string;
}

const BlockAddress: FC<BlockAddressData> = ({
  title,
  address_1,
  address_2,
  city,
  region,
  post_code,
  country,
}) => {
  return (
    <>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: "1rem" }}
      >
        <Grid item xs={4}>
          <Typography
            component="h6"
            variant="subtitle1"
            gutterBottom
            sx={{ fontWeight: 700 }}
          >
            {title}
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography component="h6" variant="body1" gutterBottom>
            {address_1 && (
              <>
                {address_1}
                <br />
              </>
            )}

            {address_2 && (
              <>
                {address_2}
                <br />
              </>
            )}

            {city && (
              <>
                {city}
                <br />
              </>
            )}

            {region && (
              <>
                {region}
                <br />
              </>
            )}

            {post_code && (
              <>
                {post_code}
                <br />
              </>
            )}

            {country && (
              <>
                {country}
                <br />
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default BlockAddress;
