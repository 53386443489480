import { FC, useContext, useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  EssentialInformation,
  SchemaEssentialInformation,
} from "./ValidateEssentialInformation";
import { CurrentEmployeeContext } from "../DashboardEmployee";

import Grid from "@mui/material/Grid";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import HeaderForm from "../HeaderForm";
import SectionNameTitle from "./sections/SectionNameTitle";
import SectionDateOfBirth from "./sections/SectionDateOfBirth";
import SectionHomeAddress from "./sections/SectionHomeAddress";
import SectionTaxInformation from "./sections/SectionTaxInformation";
import SectionNationalInsurance from "./sections/SectionNationalInsurance";
import { CtaCreateAndContinue, CtaSubmitAndContinue } from "../CtaButtons";
import FooterForm from "../FooterForm";

import {
  useLazyGetEmployeeQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
} from "../../../../features/api/apiEmployees";

interface Props {
  steps: string[];
  activeStep: number;
  handleStepperNext: () => void;
}

const FormEssentialInformation: FC<Props> = ({
  steps,
  activeStep,
  handleStepperNext,
}) => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<EssentialInformation>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaEssentialInformation),
    defaultValues: {
      title: "",
      forenames: "",
      surname: "",
      birth_date: null as unknown as Date,
      country: "",
      address_1: "",
      address_2: "",
      city: "",
      region: "",
      post_code: "",
      tax_reference_number: "",
      // tax_reference_missing: false,
      tax_code: "",
      resident_status: "",
      ni_table_code: "",
      ni_number: "",
    },
  });

  const isEdit = useMatch("/employees/edit");
  const { employeeId, setEmployeeId } = useContext(CurrentEmployeeContext);
  const [triggerLazyGetEmployee] = useLazyGetEmployeeQuery();

  useEffect(() => {
    isEdit &&
      employeeId &&
      triggerLazyGetEmployee(employeeId).then((res) => {
        const {
          title,
          forenames,
          surname,
          birth_date,
          country,
          address_1,
          address_2,
          city,
          region,
          post_code,
          tax_reference_number,
          // tax_reference_missing,
          tax_code,
          resident_status,
          ni_table_code,
          ni_number,
        } = res.data || {};

        reset({
          title: title,
          forenames: forenames,
          surname: surname,
          birth_date: birth_date as Date,
          country: country,
          address_1: address_1,
          address_2: address_2,
          city: city,
          region: region,
          post_code: post_code,
          tax_reference_number: tax_reference_number,
          // tax_reference_missing: tax_reference_missing,
          tax_code: tax_code,
          resident_status: resident_status,
          ni_table_code: ni_table_code,
          ni_number: ni_number,
        });
      });
  }, [isEdit, employeeId, triggerLazyGetEmployee, reset]);

  const [createEmployee] = useCreateEmployeeMutation();
  const [updateEmployee] = useUpdateEmployeeMutation();

  const onSubmit = (data: EssentialInformation) => {
    // Submission Employee Add
    const submissionEmployee = {
      company: { id: currentCompanyId },
      status: "DRAFT",
      ...data,
    };

    !isEdit &&
      !employeeId &&
      createEmployee(submissionEmployee)
        .unwrap()
        .then((res) => {
          setEmployeeId(Number(res.id));
        })
        .then(() => {
          setSnackbar({
            children: `Employee record created`,
            severity: "success",
          });
        })
        .then(() => {
          handleStepperNext();
        })
        .catch((error) => {
          setSnackbar({
            children: `${error}`,
            severity: "error",
          });
          console.error(
            `Could not create Employee record: ${JSON.stringify(error.data)}`
          );
        });

    // Submission Employee Edit
    isEdit &&
      employeeId &&
      updateEmployee({ id: employeeId, ...data })
        .unwrap()
        .then(() => {
          setSnackbar({
            children: `Employee record updated`,
            severity: "success",
          });
        })
        .then(() => {
          handleStepperNext();
        })
        .catch((error) => {
          setSnackbar({
            children: `${error}`,
            severity: "error",
          });
          console.error(
            `Could not update Employee record: ${JSON.stringify(error.data)}`
          );
        });
  };

  const onError = (errors: object) => {
    Object.values(errors).forEach((item) =>
      setSnackbar({
        children: `${item.message}`,
        severity: "error",
      })
    );
  };

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <HeaderForm title={steps[activeStep]}>
          {isEdit ? <CtaSubmitAndContinue /> : <CtaCreateAndContinue />}
        </HeaderForm>

        <Grid item xs={8} sx={{ marginBottom: "2rem" }}>
          <SectionNameTitle control={control} errors={errors} />

          <SectionDateOfBirth control={control} errors={errors} />

          <SectionHomeAddress control={control} errors={errors} />

          <SectionTaxInformation control={control} errors={errors} />

          <SectionNationalInsurance control={control} errors={errors} />
        </Grid>

        <FooterForm>
          {isEdit ? <CtaSubmitAndContinue /> : <CtaCreateAndContinue />}
        </FooterForm>
      </form>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      {isEdit && !employeeId && (
        <Alert>Error: No Employee ID found for Employee edit</Alert>
      )}
    </>
  );
};

export default FormEssentialInformation;
