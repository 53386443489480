import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { EmployeePayRate } from "../../../types/EmployeePayRate";

const initialState: Partial<EmployeePayRate> = {
  id: 0,
  pay_rate: 0,
  pay_count: 0,
  // TODO(DBB) : Populate initialState properly
  effective_date_start: "",
  // effective_date_end: null,
};

const employeePayRateSlice = createSlice({
  name: "provisionedEmployeePayRate",
  initialState,
  reducers: {
    updateProvisionedEmployeePayRate: (
      state,
      action: PayloadAction<Partial<EmployeePayRate>>
    ) => {
      Object.entries(action.payload ?? {}).forEach(([k, v]) => {
        state[k] = v;
      });
    },
    resetProvisionedEmployeePayRate: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const {
  updateProvisionedEmployeePayRate,
  resetProvisionedEmployeePayRate,
} = employeePayRateSlice.actions;

export default employeePayRateSlice.reducer;
