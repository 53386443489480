import Grid from "@mui/material/Grid";
import { FC, useMemo, useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import SubNavigation from "./SubNavigation";
import Box from "@mui/material/Box";
import FormCompanyDetails from "./01-company-details/FormCompanyDetails";
import FormContactDetails from "./02-contact-details/FormCompanyAddress";
import DashboardCompletion from "./03-completion/DashboardCompletion";

const StepperCompanyAdd: FC = () => {
  const isFirst = useMatch("/setup/company");

  const steps = useMemo(() => {
    if (isFirst) {
      return ["Todo..."];
    } else {
      // return ["Company Details", "Company Address", "Activate"];
      return ["Company Details", "Company Address"];
    }
  }, [isFirst]);

  const [activeStep, setActiveStep] = useState<number>(0);

  const [completed] = useState<{
    [k: number]: boolean;
  }>({});

  const handleStepperNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <>
      <Grid container spacing={2} sx={{ pt: "2rem", paddingBottom: "4rem" }}>
        <Grid item xs={4} sx={{ pr: "2rem" }}>
          <SubNavigation
            activeStep={activeStep}
            steps={steps}
            completed={completed}
          />
        </Grid>

        <Grid item xs={8}>
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid #E7E6E9",
              borderRadius: "1rem",
              padding: "1.5rem",
            }}
          >
            {activeStep === 0 && (
              <FormCompanyDetails handleStepperNext={handleStepperNext} />
            )}

            {activeStep === 1 && (
              <FormContactDetails handleStepperNext={handleStepperNext} />
            )}

            {activeStep === 2 && <DashboardCompletion />}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default StepperCompanyAdd;
