import { FC } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import { EmploymentInformation } from "../ValidationEmploymentInformation";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import HeaderSection from "../../HeaderSection";
import FormDivider from "../../FormDivider";

interface Props {
  control: Control<EmploymentInformation, any>;
  errors: {
    employee_number?: FieldError | undefined;
  };
}

const SectionEmployeeNumber: FC<Props> = ({ control, errors }) => {
  return (
    <>
      <Box>
        <HeaderSection title="Employee Number" optional />

        {/* Input : Employee Number */}
        <Controller
          control={control}
          name="employee_number"
          render={({ field }) => (
            <TextField
              {...field}
              label="Employee Number"
              sx={{ marginBottom: "2rem" }}
              variant="outlined"
              error={!!errors.employee_number}
              helperText={errors.employee_number?.message}
              fullWidth={true}
              disabled={false}
            />
          )}
        />
      </Box>

      <FormDivider mt={2} mb={4} />
    </>
  );
};

export default SectionEmployeeNumber;
