import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { useAppSelector } from "../../../../app/hooks";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";

import SubHeader from "../../SubHeader";
import HeaderSummary from "./HeaderSummary";
import Calculations from "./Calculations";
import DataGridPayComponents from "./DataGridPayComponents";
import DataGridPayrollSchemes from "./DataGridPayrollSchemes";
import { PayrollEntry } from "../../../../types/PayrollEntry";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const PayrollMakeup: FC = () => {
  const navigate = useNavigate();

  const payrollEntry: Partial<PayrollEntry> = useAppSelector(
    (state: RootState) => state.currentPayrollEntry
  );

  !payrollEntry && navigate("/payrolls/run");

  // const [tabValue, setTabValue] = useState(0);
  const [tabValue, setTabValue] = useState(0); //TODO(DBB) : Pinned for dev

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <SubHeader
        title="Edit Pay Makeup"
        page="makeup"
        icon="receipt_long"
        callback={() =>
          navigate("/payrolls/run", { state: { runPayrollActiveStep: 1 } })
        }
      />

      <HeaderSummary />

      <Calculations />

      <Box sx={{ width: "100%", padding: "1.5rem" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Rates of Pay/Schemes"
          >
            <Tab label="Rates of Pay" {...a11yProps(0)} />
            <Tab label="Schemes" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <DataGridPayComponents />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <DataGridPayrollSchemes />
        </TabPanel>
      </Box>
    </>
  );
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export default PayrollMakeup;
