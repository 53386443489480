import { forwardRef } from "react";

import { NumericFormat, InputAttributes } from "react-number-format";

interface Props {
  onChange: (event: { target: { name: string; value: number } }) => void;
  name: string;
}

const InputMaskCurrencyGBP = forwardRef<InputAttributes, Props>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.floatValue!,
            },
          });
        }}
        valueIsNumericString={true}
        allowNegative={false}
        thousandSeparator=","
        decimalScale={2}
        fixedDecimalScale={true}
        prefix="£"
        defaultValue="0"
      />
    );
  }
);

export default InputMaskCurrencyGBP;
