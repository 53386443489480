import { FC } from "react";
import { Outlet } from "react-router-dom";

import Container from "@mui/material/Container";

const DashboardSignUp: FC = () => {
  return (
    <Container maxWidth="xl" sx={{ marginTop: "4rem" }}>
      <Outlet />
    </Container>
  );
};

export default DashboardSignUp;
