import { FC } from "react";
import { dinero } from "dinero.js";
import { GBP } from "@dinero.js/currencies";
import { intlFormat } from "../../../../../utils/currencyFormatter";

interface Props {
  title: string;
  value: number | undefined;
}

const PaySubtotal: FC<Props> = ({ title, value }) => {
  const displayPaySubtotalAmount =
    value && intlFormat(dinero({ amount: value, currency: GBP }), "en-GB");

  return (
    <div>
      <strong>{title}</strong>: {displayPaySubtotalAmount}
    </div>
  );
};

export default PaySubtotal;
