import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { UserInterface } from "../../../types/UserInterface";

const initialState: UserInterface = {
  firstName: "",
  age: "",
  city: "",
  admin: false,
  premium: false,
  gender: "",
  date: "",
  time: "",
};

const userInterfaceSlice = createSlice({
  name: "userInterfaceSlice",
  initialState,
  reducers: {
    // Possibly Partial is required?
    updateUserInterface: (state, action: PayloadAction<UserInterface>) => {
      Object.entries(action.payload ?? {}).forEach(([k, v]) => {
        state[k] = v;
      });
    },
    resetUserInterface: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { updateUserInterface, resetUserInterface } =
  userInterfaceSlice.actions;

export default userInterfaceSlice.reducer;
