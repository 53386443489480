import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  PasswordResetPassword,
  SchemaPasswordResetPassword,
} from "./ValidatePasswordResetPassword";

import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { useResetPasswordSubmitMutation } from "../../features/api/apiPasswordReset";

interface Props {
  resetToken: string;
}

const FormPasswordResetPassword: FC<Props> = ({ resetToken }) => {
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [submissionSuccessful, setSubmissionSuccessful] =
    useState<boolean>(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<PasswordResetPassword>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaPasswordResetPassword),
    defaultValues: {
      reset_token: resetToken || "",
      password_new: "",
      password_confirm: "",
    },
  });

  const [resetPasswordSubmit] = useResetPasswordSubmitMutation();
  const [serverErrors, setServerErrors] = useState([]);

  const onSubmit = async (data: PasswordResetPassword) => {
    setLoadingSubmit(true);
    setServerErrors([]);

    const submission = {
      reset_token: resetToken,
      password_new: data.password_confirm,
      password_confirm: data.password_confirm,
    };

    resetPasswordSubmit(submission)
      .unwrap()
      .then(() => setLoadingSubmit(false))
      .then(() => setSubmissionSuccessful(true))
      .catch((error: any) => {
        setLoadingSubmit(false);
        setServerErrors(error.data.message);
      });
  };

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        className="mx-auto"
        style={{ maxWidth: "696px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack direction="column" gap={4}>
          <Stack direction="column" gap={1}>
            <Typography
              component="h2"
              variant="h4"
              sx={{ marginBottom: "1rem" }}
            >
              Set New Password
            </Typography>
            <Typography>
              Enter your email address below to begin the password reset
              process.
            </Typography>
            <Typography>
              For further support, contact{" "}
              <Link href="mailto:support@cloudpayroll.io">
                <strong>support@cloudpayroll.io</strong>
              </Link>
              .
            </Typography>
          </Stack>

          {!submissionSuccessful ? (
            <Stack direction="column" gap={2}>
              {/* Input : Password New */}
              <Controller
                control={control}
                name="password_new"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="New Password"
                    type="password"
                    variant="outlined"
                    error={!!errors.password_new}
                    helperText={errors.password_new?.message}
                    fullWidth={true}
                    disabled={false}
                    autoFocus={true}
                  />
                )}
              />

              {/* Input : Password Confirm */}
              <Controller
                control={control}
                name="password_confirm"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Confirm New Password"
                    type="password"
                    variant="outlined"
                    error={!!errors.password_confirm}
                    helperText={errors.password_confirm?.message}
                    fullWidth={true}
                    disabled={false}
                  />
                )}
              />

              {serverErrors &&
                serverErrors?.map((item, index) => {
                  return (
                    <Alert key={index} severity="error">
                      {item}
                    </Alert>
                  );
                })}

              <LoadingButton
                variant="contained"
                type="submit"
                size="large"
                disableElevation
                loading={loadingSubmit}
                color="primary"
              >
                Submit
              </LoadingButton>
            </Stack>
          ) : (
            <Alert severity="success">
              <AlertTitle>Submission Successful</AlertTitle>
              <Typography sx={{ marginBottom: "0.75rem" }}>
                You can now login with your new password.
              </Typography>
              <Button href="/signin" variant="outlined" color="success">
                Return to Sign In
              </Button>
            </Alert>
          )}
        </Stack>
      </form>
    </>
  );
};

export default FormPasswordResetPassword;
