import { ChangeEvent, FC } from "react";
import { RootState } from "../../../../../app/store";
import { useAppSelector } from "../../../../../app/hooks";
import { Control, Controller, FieldError } from "react-hook-form";
import { EmploymentInformation } from "../ValidationEmploymentInformation";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

import HeaderSection from "../../HeaderSection";
import FormDivider from "../../FormDivider";
import AlertDataMissing from "../../../AlertDataMissing";

import { useGetAllPayrollSchedulesByCompanyIdQuery } from "../../../../../features/api/apiPayrollSchedules";

interface SelectItem {
  id: string | number;
  name: string;
}

interface Props {
  control: Control<EmploymentInformation, any>;
  isEdit: boolean;
  errors: {
    pension_only?: FieldError | undefined;
    employment_type?: FieldError | undefined;
    job_date_started?: FieldError | undefined;
    job_date_finished?: FieldError | undefined;
    payroll_schedule?: FieldError | undefined;
  };
}

const SectionEmploymentTerms: FC<Props> = ({ control, isEdit, errors }) => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const { data: dataPayrollSchedulesByCompanyId } =
    useGetAllPayrollSchedulesByCompanyIdQuery(currentCompanyId);

  return (
    <>
      <Box>
        <HeaderSection title="Employment Terms" />

        {/*  Input : Pension Only */}
        <Controller
          control={control}
          name="pension_only"
          render={({ field }) => (
            <>
              <FormControl sx={{ marginBottom: "0.5rem" }} fullWidth={true}>
                <FormControlLabel
                  label="Pension only"
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.checked;
                        field.onChange(value);
                      }}
                      size="medium"
                      disabled={false}
                    />
                  }
                />
                {!!errors.pension_only && (
                  <FormHelperText>
                    {errors.pension_only?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </>
          )}
        />
        <Typography variant="subtitle2" gutterBottom>
          Employee is not employed and receiving pension only.
        </Typography>
      </Box>
      <FormDivider mt={3} mb={5} />

      {/* Input : Employment Type */}
      <FormControl sx={{ marginBottom: "2rem" }} fullWidth={true}>
        <Controller
          control={control}
          name="employment_type"
          render={({ field }) => (
            <>
              <InputLabel id="label-employment-type">
                Employment Type
              </InputLabel>
              <Select
                {...field}
                label="Emplpyment Type"
                labelId="label-employment-type"
                error={!!errors.employment_type}
                disabled={false}
              >
                {employmentTypes?.map((item: SelectItem) => {
                  return (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </>
          )}
        />
        <FormHelperText error={true}>
          {errors.employment_type?.message}
        </FormHelperText>
      </FormControl>

      {/*  Input : Starting Date */}
      <FormControl sx={{ marginBottom: "2rem" }} fullWidth={true}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            name="job_date_started"
            control={control}
            render={({ field: { name, ref, onBlur, ...rest } }) => (
              <DatePicker
                {...rest}
                inputRef={ref}
                label="Job Date Started"
                renderInput={(inputProps) => (
                  <TextField
                    {...inputProps}
                    name={name}
                    onBlur={onBlur}
                    error={!!errors.job_date_started}
                    fullWidth={true}
                  />
                )}
                inputFormat="dd-MM-yyyy"
                minDate={new Date("01-01-2000")}
                maxDate={new Date()}
                disabled={false}
              />
            )}
          />
        </LocalizationProvider>
        <FormHelperText error={true}>
          {errors.job_date_started?.message}
        </FormHelperText>
      </FormControl>

      {/* TODO(DBB) : Integrate job_date_finished logic */}

      {/*  Input : Ending Date (conditionally rendered) */}
      {isEdit && (
        <FormControl sx={{ marginBottom: "2rem" }} fullWidth={true}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="job_date_finished"
              control={control}
              render={({ field: { name, ref, onBlur, ...rest } }) => (
                <DatePicker
                  {...rest}
                  inputRef={ref}
                  label="Job Date Finished"
                  renderInput={(inputProps) => (
                    <TextField
                      {...inputProps}
                      name={name}
                      onBlur={onBlur}
                      error={!!errors.job_date_finished}
                      fullWidth={true}
                    />
                  )}
                  inputFormat="dd-MM-yyyy"
                  minDate={new Date("01-01-2000")}
                  maxDate={new Date()}
                  disabled={false}
                />
              )}
            />
          </LocalizationProvider>
          <FormHelperText error={true}>
            {errors.job_date_finished?.message}
          </FormHelperText>
        </FormControl>
      )}

      {dataPayrollSchedulesByCompanyId?.length === 0 && (
        <AlertDataMissing
          entityName="Payroll Schedule"
          linkAdd="/payrolls/pay-schedules"
          sx={{ marginBottom: "2rem" }}
        />
      )}

      {dataPayrollSchedulesByCompanyId && (
        <FormControl sx={{ marginBottom: "2rem" }} fullWidth={true}>
          <Controller
            control={control}
            name="payroll_schedule"
            render={({ field }) => (
              <>
                <InputLabel id="label-payroll_schedule">
                  Pay Schedule
                </InputLabel>

                <Select
                  {...field}
                  value={field.value || ""}
                  label="Pay Schedule"
                  labelId="label-payroll_schedule"
                  error={!!errors.payroll_schedule}
                  disabled={false}
                >
                  {dataPayrollSchedulesByCompanyId.map((item: SelectItem) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            )}
          />
          <FormHelperText error={true}>
            {errors.payroll_schedule?.message}
          </FormHelperText>
        </FormControl>
      )}

      <FormDivider mt={0} mb={4} />
    </>
  );
};

const employmentTypes = [
  {
    id: 1,
    name: "Full Time",
  },
  {
    id: 2,
    name: "Part Time",
  },
  {
    id: 3,
    name: "Contract",
  },
  {
    id: 4,
    name: "Temporary",
  },
];

export default SectionEmploymentTerms;
