import { FC, useContext, useState } from "react";
import { useMatch } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CurrentEmployeeContext } from "../DashboardEmployee";
import {
  EmployeeRatesOfPay,
  SchemaEmployeeRatesOfPay,
} from "./ValidationRatesOfPay";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import HeaderForm from "../HeaderForm";
import FormDivider from "../FormDivider";
import SectionRateOfPay from "./sections/SectionRateOfPay";

import { useCreateEmployeePayRateMutation } from "../../../../features/api/apiEmployeePayRates";

interface Props {
  steps: string[];
  activeStep: number;
  handleStepperNext: () => void;
  canProgress: boolean;
}

const FormRatesOfPay: FC<Props> = ({
  steps,
  activeStep,
  handleStepperNext,
  canProgress,
}) => {
  const isEdit = useMatch("/employees/edit");
  const { employeeId } = useContext(CurrentEmployeeContext);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<EmployeeRatesOfPay>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaEmployeeRatesOfPay),
    defaultValues: {
      employee: employeeId || 0,
      pay_component: 0,
      pay_rate: 0,
      pay_count: 0,
      effective_date_start: null as unknown as Date,
      status: "ACTIVE",
    },
  });

  const [createEmployeePayRate] = useCreateEmployeePayRateMutation();

  const onSubmit = (data: EmployeeRatesOfPay) => {
    const submittedData = {
      ...data,
      pay_count: data.pay_count * 100,
    };

    employeeId &&
      createEmployeePayRate(submittedData)
        .unwrap()
        .then(() => {
          setSnackbar({
            children: `New employee rate of pay added`,
            severity: "success",
          });
        })
        .then(() => {
          reset();
        });
  };

  const onError = (errors: object) => {
    Object.values(errors).forEach((item) =>
      setSnackbar({
        children: `${item.message}`,
        severity: "error",
      })
    );
  };

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <HeaderForm title={steps[activeStep]}>
          <Button
            id="employee-add-edit-submit"
            variant="contained"
            disableElevation
            onClick={() => handleStepperNext()}
            type="button"
            size="large"
            disabled={!canProgress}
          >
            Continue
          </Button>
        </HeaderForm>

        <Grid item xs={8} sx={{ marginBottom: "2rem" }}>
          <SectionRateOfPay control={control} errors={errors} />

          <Box sx={{ paddingBottom: "2rem" }}>
            <Button type="submit" variant="contained">
              Add Rate of Pay
            </Button>
          </Box>

          <FormDivider mt={0} mb={4} />
        </Grid>
      </form>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      {isEdit && !employeeId && (
        <Alert>Error: No Employee ID found for Employee edit</Alert>
      )}
    </>
  );
};

export default FormRatesOfPay;
