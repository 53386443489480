import { FC } from "react";
import Chip from "@mui/material/Chip";

interface Props {
  status: "DRAFT" | "INVITED" | "ACTIVE" | "DEACTIVATED" | "ARCHIVED";
}

const RenderCellConditionalStatus: FC<Props> = ({ status }) => {
  return (
    <>
      {status === "DRAFT" && (
        <Chip
          label="Draft"
          sx={{
            color: "#B8651B",
            backgroundColor: "rgba(230, 126, 34, 0.12);",
          }}
        />
      )}

      {status === "INVITED" && (
        <Chip
          label="Invited"
          sx={{
            color: "#B8651B",
            backgroundColor: "rgba(230, 126, 34, 0.12);",
          }}
        />
      )}

      {status === "ACTIVE" && (
        <Chip
          label="Active"
          sx={{ color: "#0059C7", backgroundColor: "rgba(0, 89, 199, 0.08)" }}
        />
      )}

      {status === "DEACTIVATED" && <Chip label="Deactivated" color="default" />}

      {status === "ARCHIVED" && <Chip label="Archived" color="default" />}
    </>
  );
};

export default RenderCellConditionalStatus;
