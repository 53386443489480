import { FC } from "react";
import { RootState } from "../../../../app/store";
import { useAppSelector } from "../../../../app/hooks";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import PaySubtotal from "./calculations/PaySubtotal";
import PayTotal from "./calculations/PayTotal";

import { useGetPayrollEntryQuery } from "../../../../features/api/apiPayrollEntries";

const Calculations: FC = () => {
  // TODO(DBB) : Write some proper unit tests
  // Works, round up
  // const tempSubtotalGrossPay = dinero({ amount: 70100, currency: GBP });
  // Works, round up
  // const tempSubtotalGrossPay = dinero({ amount: 87600, currency: GBP });
  // Works, round up
  // const tempSubtotalGrossPay = dinero({ amount: 99700, currency: GBP });
  // Works, round down (banker's round)
  // const tempSubtotalGrossPay = dinero({ amount: 232207, currency: GBP });
  // Works, round down
  // const tempSubtotalGrossPay = dinero({ amount: 1550000, currency: GBP });

  const payrollEntryId: string = useAppSelector(
    (state: RootState) => state.currentPayrollEntry.id || ""
  );

  const { data: dataPayrollEntry } = useGetPayrollEntryQuery(payrollEntryId);

  const {
    calculated_employee_gross_normal_pay,
    calculated_employee_gross_pensionable_pay,
    calculated_employee_gross_other,
    calculated_employee_gross_schemes_contribution,
    calculated_employee_ni_subtotal,
    calculated_employee_ni_rebate,
    calculated_employee_tax_free_pay,
    calculated_employee_tax_payable,
    calculated_employer_ni_contribution,
    calculated_employer_schemes_contribution,
    calculated_employer_rebate,
    calculated_employer_subtotal,
    calculated_employee_net_pay,
  } = dataPayrollEntry || {};

  return (
    <Box sx={customStyles.calculatorContainer}>
      <Box sx={customStyles.calculatorBox}>
        <Box sx={customStyles.calculatorGroup}>
          <Typography variant="caption" sx={customStyles.title}>
            <strong>Gross Pay</strong>
          </Typography>

          <PaySubtotal
            title="Normal Pay"
            value={calculated_employee_gross_normal_pay}
          />

          <PaySubtotal
            title="Pensionable"
            value={calculated_employee_gross_pensionable_pay}
          />

          <PaySubtotal
            title="Schemes"
            value={calculated_employee_gross_schemes_contribution}
          />

          <PaySubtotal title="Other" value={calculated_employee_gross_other} />
        </Box>

        <Box sx={customStyles.calculatorGroup}>
          <Typography variant="caption" sx={customStyles.title}>
            <strong>National Insurance</strong>
          </Typography>

          <PaySubtotal
            title="Contribution"
            value={calculated_employee_ni_subtotal}
          />

          <PaySubtotal title="Rebate" value={calculated_employee_ni_rebate} />

          <PaySubtotal
            title="Subtotal"
            value={calculated_employee_ni_subtotal}
          />
        </Box>

        <Box sx={customStyles.calculatorGroup}>
          <Typography variant="caption" sx={customStyles.title}>
            <strong>Income Tax</strong>
          </Typography>

          <PaySubtotal
            title="Tax Free Pay"
            value={calculated_employee_tax_free_pay}
          />

          <PaySubtotal
            title="Tax Payable"
            value={calculated_employee_tax_payable}
          />
        </Box>

        <Box sx={customStyles.calculatorGroup}>
          <Typography variant="caption" sx={customStyles.title}>
            <strong>Employer</strong>
          </Typography>

          <PaySubtotal
            title="NI Contribution"
            value={calculated_employer_ni_contribution}
          />

          <PaySubtotal
            title="Schemes Contribution"
            value={calculated_employer_schemes_contribution}
          />

          <PaySubtotal title="Rebate" value={calculated_employer_rebate} />

          <PaySubtotal title="Subtotal" value={calculated_employer_subtotal} />
        </Box>

        <Box sx={customStyles.calculatorGroup}>
          <PayTotal title="Net Pay" value={calculated_employee_net_pay} />
        </Box>
      </Box>
    </Box>
  );
};

const customStyles = {
  calculatorContainer: {
    padding: "1.5rem",
  },

  calculatorBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    fontSize: "0.88rem",
    border: "1px solid #E7E6E9",
    borderRadius: "0.5rem",
    padding: "0 1rem",
  },

  calculatorGroup: {
    flex: "1 0 auto",
    textAlign: "right",
    borderRight: "1px solid #E7E6E9",
    padding: "1rem 2rem 1rem 1rem",
    margin: "0.5rem 0",
    "&:last-child": {
      paddingRight: 0,
      borderRight: "none",
    },
    "> div": {
      padding: "0.2rem 0",
    },
  },

  title: {
    textTransform: "uppercase",
    color: "rgba(47, 48, 51, 0.72)",
    marginBottom: "12px",
    display: "block",
  },
};

export default Calculations;
