import { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconClose from "@mui/icons-material/Close";

interface Props {
  handleModalClose: () => void;
  title?: string;
}

const HeaderDialog: FC<Props> = ({ handleModalClose, title }) => {
  return (
    <>
      <Box
        component="header"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#FDFBFF",
          padding: "1rem 1.5rem 1rem",
          borderBottom: "1px solid #E7E6E9",
        }}
      >
        {title && (
          <Typography
            component="h6"
            variant="h6"
            sx={{ position: "relative", top: "0.15rem" }}
          >
            {title}
          </Typography>
        )}

        <Button
          onClick={handleModalClose}
          sx={{
            minWidth: 0,
            color: "rgba(0, 0, 0, 0.54)",
          }}
        >
          <IconClose />
        </Button>
      </Box>
    </>
  );
};

export default HeaderDialog;
