import { FC, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  DataGridPro,
  GridActionsCellItem,
  GridRowParams,
  GridRowsProp,
} from "@mui/x-data-grid-pro";

import Box from "@mui/material/Box";
import IconTableRows from "@mui/icons-material/TableRows";
import IconArticle from "@mui/icons-material/Article";
import IconDownload from "@mui/icons-material/Download";
import IconLockOpen from "@mui/icons-material/LockOpen";

import { useGetAllPayrollEntriesByPayrollIdQuery } from "../../../../features/api/apiPayrollEntries";
import { getPdfFormPayslip } from "../../../../utils/getPdfForm";
import { PayrollEntry } from "../../../../types/PayrollEntry";

interface RouterState {
  payrollId?: string;
}

const formatValuesCurrency = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

const DataGridPayrollEntries: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { payrollId: routerPayrollId } = (location.state as RouterState) || {};

  const handleDownloadPayslipClick = useCallback(
    (payrollEntry: PayrollEntry) => {
      const { id, forenames, surname } = payrollEntry;

      getPdfFormPayslip(id).then((data) => {
        if (data) {
          const filename = `Payslip-${forenames}-${surname}`;
          const safeFilename = filename.replace(/[^a-z0-9]/gi, "-");

          const link = document.createElement("a");
          link.href = data;
          link.setAttribute("download", `${safeFilename}.pdf`);
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    []
  );

  const {
    data: dataPayrollEntries,
    isLoading: isLoadingPayrollEntries,
    isFetching: isFetchingPayrollEntries,
    isError: isErrorPayrollEntries,
  } = useGetAllPayrollEntriesByPayrollIdQuery(routerPayrollId!);

  const columns = useMemo(
    () => [
      {
        field: "surname",
        headerName: "Surname",
        type: "string",
        width: 160,
        editable: false,
        hide: false,
      },
      {
        field: "forenames",
        headerName: "Forename(s)",
        type: "string",
        width: 160,
        editable: false,
        hide: false,
      },
      {
        field: "job_title",
        headerName: "Job Title",
        type: "string",
        width: 160,
        editable: false,
        hide: false,
      },
      {
        field: "payment_method",
        headerName: "Payment Method",
        type: "string",
        width: 160,
        editable: false,
        hide: false,
      },
      {
        field: "total_cost",
        headerName: "Total Cost",
        valueFormatter: ({ value }: any) =>
          formatValuesCurrency.format(Number(value / 100)),
        cellClassName: "datagrid-payroll-entries-total-cost",
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 80,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<IconTableRows />}
            label="View Details"
            onClick={() =>
              navigate("/payrolls/history/pay-summary", {
                state: {
                  payrollEntryId: params.id,
                  totalCost: params.row.total_cost,
                },
              })
            }
            showInMenu
            disabled={false}
          />,
          <GridActionsCellItem
            icon={<IconArticle />}
            label="View Payslip"
            onClick={() =>
              navigate("/payrolls/payslip", {
                state: { payrollEntryId: params.id },
              })
            }
            showInMenu
            disabled={false}
          />,
          <GridActionsCellItem
            icon={<IconDownload />}
            label="Download Payslip"
            onClick={() => handleDownloadPayslipClick(params.row)}
            showInMenu
            disabled={false}
          />,
          <GridActionsCellItem
            icon={<IconLockOpen />}
            label="Unlock to Edit"
            onClick={() => {
              console.log("Unlock");
            }}
            showInMenu
            disabled={true}
          />,
        ],
      },
    ],
    [navigate, handleDownloadPayslipClick]
  );

  if (isLoadingPayrollEntries) return <div>Loading Payroll Entries...</div>;
  if (isFetchingPayrollEntries) return <div>Fetching Payroll Entries...</div>;
  if (isErrorPayrollEntries) return <div>Error Payroll Entries!</div>;
  if (!dataPayrollEntries)
    return <div>No Payroll Entries data to display...</div>;

  const rows: GridRowsProp = dataPayrollEntries.map((item: PayrollEntry) => {
    return {
      id: item.id,
      forenames: item.employee_forenames,
      surname: item.employee_surname,
      job_title: item.employee_job_title,
      payment_method: item.payroll_payment_method.name,
      total_cost: item.calculated_total_cost,
    };
  });

  return (
    <>
      <Box
        sx={{
          "& .datagrid-payroll-entries-total-cost": {
            fontWeight: "600",
          },
        }}
      >
        <div style={{ height: 400 }} className="w-100">
          <DataGridPro
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[10]}
            autoHeight={true}
            initialState={{
              pinnedColumns: {
                right: ["actions"],
              },
            }}
            sx={{ backgroundColor: "white" }}
          />
        </div>
      </Box>
    </>
  );
};

export default DataGridPayrollEntries;
