import { FC, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import FormEmailAddress from "./01-email-submit/FormEmailAddress";
import FormOneTimePass from "./02-one-time-pass-submit/FormOneTimePass";
import FormPasswordSet from "./03-password-set/FormPasswordSet";

const steps = ["Submit Email", "Verify Email", "Set Password", "Confirmation"];

const StepperCreateAccount: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const { state }: any = useLocation();
  const { createAccountActiveStep } = state || {};

  useEffect(() => {
    createAccountActiveStep && setActiveStep(createAccountActiveStep);
  }, [createAccountActiveStep]);

  const isStepOptional = (step: number) => {
    return step === null;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleStepperNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ width: "100%" }}>
        <Box sx={{ width: "100%", marginTop: "1rem" }}>
          <Stepper activeStep={activeStep} sx={{ marginBottom: "4rem" }}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === steps.length ? (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </>
          ) : (
            <>
              {activeStep === 0 && (
                <FormEmailAddress
                  handleStepperNext={handleStepperNext}
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                />
              )}

              {activeStep === 1 && (
                <FormOneTimePass handleStepperNext={handleStepperNext} />
              )}

              {activeStep === 2 && <FormPasswordSet />}
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default StepperCreateAccount;
