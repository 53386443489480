import { z } from "zod";

export type EmployeeScheme = z.infer<typeof SchemaEmployeeScheme>;

export const SchemaEmployeeScheme = z.object({
  id: z.number().optional(),

  employee: z.number(),

  scheme: z.number(),

  effective_date_start: z
    .date({
      invalid_type_error: "Effective Start Date is required",
    })
    .or(z.string().transform((str) => new Date(str))),

  effective_date_end: z
    .date({
      invalid_type_error: "Effective End Date is required",
    })
    .or(z.string().transform((str) => new Date(str)))
    .optional()
    .nullable(),
});
