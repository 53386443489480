import { FC } from "react";
import Button from "@mui/material/Button";

interface CtaBaseProps {
  text: string;
}
const CtaBaseButton: FC<CtaBaseProps> = ({ text }) => {
  return (
    <>
      <Button
        id="employee-add-edit-submit"
        variant="contained"
        type="submit"
        size="large"
        disableElevation
      >
        {text}
      </Button>
    </>
  );
};

const CtaSubmitAndContinue: FC = () => {
  return <CtaBaseButton text="Submit & Continue" />;
};

const CtaCreateAndContinue: FC = () => {
  return <CtaBaseButton text="Create & Continue" />;
};

const CtaSubmit: FC = () => {
  return <CtaBaseButton text="Submit" />;
};

export { CtaSubmitAndContinue, CtaCreateAndContinue, CtaSubmit };
