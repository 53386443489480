import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMatch } from "react-router-dom";
import { CurrentEmployeeContext } from "../DashboardEmployee";

import {
  DataGridPro,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridColumns,
  GridRowParams,
  GridValidRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";

import Button from "@mui/material/Button";
import IconEdit from "@mui/icons-material/Edit";
import IconDelete from "@mui/icons-material/Delete";

import FooterForm from "../FooterForm";

import {
  useGetAllEmployeePayRatesByEmployeeIdQuery,
  useUpdateEmployeePayRateMutation,
} from "../../../../features/api/apiEmployeePayRates";
// TODO(DBB) : Zod type instead of base type
import { EmployeePayRate } from "../../../../types/EmployeePayRate";

const formatValuesCurrency = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

interface Props {
  handleStepperNext: () => void;
  canProgress: boolean;
  setCanProgress: Dispatch<SetStateAction<boolean>>;
}

const DataGridRatesOfPay: FC<Props> = ({
  handleStepperNext,
  canProgress,
  setCanProgress,
}) => {
  const isEdit = useMatch("/employees/edit");
  const { employeeId } = useContext(CurrentEmployeeContext);

  const [rows, setRows] = useState<Partial<EmployeePayRate>[] | undefined>();

  const { data: dataGetAllEmployeePayRatesByEmployeeId } =
    useGetAllEmployeePayRatesByEmployeeIdQuery(Number(employeeId));

  const [updateEmployeePayRate] = useUpdateEmployeePayRateMutation();

  const displayColumnDate = (value: any | Date) => {
    const date = new Date(value);

    const dateFormatted = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(date);

    return dateFormatted;
  };

  const handleEmployeePayRateArchiveClick = useCallback(
    (employeePayRate: GridRowParams) => {
      const updatedEmployeePayRate = {
        id: Number(employeePayRate.id),
        status: "ARCHIVED",
      };

      updateEmployeePayRate(updatedEmployeePayRate);
    },
    [updateEmployeePayRate]
  );

  const columns: GridColDef[] = useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: "payroll_component",
        headerName: "Item",
        type: "string",
        width: 160,
        editable: false,
        hide: false,
        valueGetter: (params: GridCellParams) => {
          return `${params.row.pay_component.name}`;
        },
      },
      {
        field: "effective_date_start",
        headerName: "Eff. Date",
        type: "date",
        align: "right",
        width: 120,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          displayColumnDate(params.row.effective_date_start),
      },
      {
        field: "pay_rate",
        headerName: "Rate",
        align: "right",
        width: 100,
        editable: false,
        hide: false,
        valueFormatter: ({ value }) =>
          formatValuesCurrency.format(Number(value / 100)),
      },
      {
        field: "pay_count",
        headerName: "Count",
        align: "right",
        width: 100,
        editable: false,
        hide: false,
        valueGetter: ({ value }) => {
          const modifiedValue = value / 100;
          return modifiedValue;
        },
        // valueSetter: (params: GridValueSetterParams) => {
        //   const modifiedValue = Math.round(params.value * 100);
        //   return { ...params.row, pay_count: modifiedValue };
        // },
      },
      {
        field: "pay_amount",
        headerName: "Amount",
        align: "right",
        width: 100,
        editable: false,
        hide: false,
        valueFormatter: ({ value }) =>
          formatValuesCurrency.format(Number(value / 100)),
      },
      {
        field: "status",
        headerName: "Status",
        width: 100,
        editable: false,
        hide: true,
      },
      {
        field: "id",
        headerName: "ID",
        type: "string",
        width: 80,
        editable: false,
        hide: true,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 80,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<IconEdit />}
            label="Edit"
            onClick={() => {
              console.log("Edit");
            }}
            showInMenu
            disabled={true}
          />,
          <GridActionsCellItem
            icon={<IconDelete />}
            label="Archive"
            onClick={() => {
              handleEmployeePayRateArchiveClick(params.row);
            }}
            showInMenu
            disabled={false}
          />,
        ],
      },
    ],
    [handleEmployeePayRateArchiveClick]
  );

  useEffect(() => {
    const employeePayRates = dataGetAllEmployeePayRatesByEmployeeId
      ?.filter((item) => item.status === "ACTIVE")
      .map((item: Partial<EmployeePayRate>) => {
        return {
          id: item.id,
          pay_component: item.pay_component,
          pay_rate: item.pay_rate,
          pay_count: item.pay_count,
          pay_amount: item.pay_amount,
          effective_date_start: item.effective_date_start,
          effective_date_end: item.effective_date_end,
          status: item.status,
        };
      });

    setRows(employeePayRates);

    employeePayRates && employeePayRates?.length > 0
      ? setCanProgress(true)
      : setCanProgress(false);
  }, [isEdit, dataGetAllEmployeePayRatesByEmployeeId, setCanProgress]);

  return (
    <>
      <div style={{ height: 300, width: "100%", marginBottom: "2rem" }}>
        <DataGridPro
          rows={rows || []}
          columns={columns}
          initialState={{
            pinnedColumns: {
              right: ["actions"],
            },
          }}
        />
      </div>

      <FooterForm>
        <Button
          id="employee-add-edit-submit"
          variant="contained"
          disableElevation
          onClick={() => handleStepperNext()}
          type="button"
          size="large"
          disabled={!canProgress}
        >
          Continue
        </Button>
      </FooterForm>
    </>
  );
};

export default DataGridRatesOfPay;
