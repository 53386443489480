import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { UserSignIn } from "../../types/UserSignIn";
import { MemberInvitation } from "../../types/MemberInvitation";

import type { User } from "../../types/User";

const cloudPayrollApiUrl = process.env.REACT_APP_CLOUDPAYROLL_API_URL;

export const cloudPayrollApi = createApi({
  reducerPath: "api",

  baseQuery: fetchBaseQuery({
    baseUrl: cloudPayrollApiUrl,
    // prepareHeaders: (headers, { getState }) => {
    //   headers.set("x-api-key", API_KEY);
    //   headers.set("Access-Control-Allow-Origin", "*");
    //   return headers;
    // },
    // Returns cookies
    credentials: "include",
  }),

  tagTypes: [
    "Company",
    "CompanyMembers",
    "Department",
    "Employee",
    "EmployeePayRate",
    "EmployeeScheme",
    "Member",
    "Payroll",
    "PayrollSchedule",
    "PayrollFrequency",
    "Scheme",
    "SchemeType",
    "CostCentre",
    "PasswordReset",
    "PayComponent",
    "PayComponentType",
    "PayPeriod",
    "PayrollCountUnit",
    "PayrollEntry",
    "PayrollPaymentMethod",
    "PayrollScheme",
    "PayrollPayRate",
    "TaxYear",
    "TaxRate",
    "NicRate",
    "User",
    "UserSignUp",
  ],

  endpoints: (builder) => ({
    // GET Application Info
    getAppVersion: builder.query<{ version: string }, void>({
      query: () => `/app-version`,
    }),

    // POST Company Member
    memberInvitation: builder.mutation<void, MemberInvitation>({
      query: (userSignUp) => ({
        url: `member-invitation`,
        method: "POST",
        body: userSignUp,
      }),
      invalidatesTags: [],
    }),

    // POST User Sign In
    userSignIn: builder.mutation<UserSignIn, UserSignIn>({
      query: (userSignIn) => ({
        url: `/auth/signin`,
        method: "POST",
        body: userSignIn,
      }),
      invalidatesTags: [],
    }),

    // POST User Sign Out
    userSignOut: builder.query<void, void>({
      query: () => ({
        url: `/auth/signout`,
        method: "GET",
      }),
    }),

    // ADMIN :: GET All Users
    getAllUsers: builder.query<User[], number | null>({
      query: (limit = 10) => {
        return `/users?limit=${limit}`;
      },
    }),
  }),
});

export const {
  useGetAppVersionQuery,
  useUserSignInMutation,
  useLazyUserSignOutQuery,
  useMemberInvitationMutation,
  useGetAllUsersQuery,
} = cloudPayrollApi;
