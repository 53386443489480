import { FC, useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { NewCompanyContext } from "../DashboardCompanyAdd";
import {
  CompanyProfile,
  SchemaCompanyProfile,
} from "./ValidationCompanyProfile";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { useCreateCompanyMutation } from "../../../../features/api/apiCompanies";

interface Props {
  handleStepperNext: () => void;
}

const FormCompanyProfile: FC<Props> = ({ handleStepperNext }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<CompanyProfile>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaCompanyProfile),
    defaultValues: {
      name_legal: "",
      name_trading: "",
      company_incorporation_number: "",
      company_tax_reference_number: "",
    },
  });

  const { setNewCompanyId } = useContext(NewCompanyContext);
  const [createCompany] = useCreateCompanyMutation();

  const onSubmit = (data: CompanyProfile) => {
    createCompany(data)
      .unwrap()
      .then((res) => {
        const companyId = Number(res.id);

        setNewCompanyId(Number(companyId));

        return companyId;
      })
      .then(() => {
        handleStepperNext();
      })
      .catch(() => {
        setSnackbar({
          children: `Could not create Company record, contact Cloud Payroll support.`,
          severity: "error",
        });
      });
  };

  const onError = (errors: object) => {
    Object.values(errors).forEach((item) =>
      setSnackbar({
        children: `${item.message}`,
        severity: "error",
      })
    );
  };

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Stack direction="column" spacing={4}>
          <Typography component="h2" variant="h4">
            Update Company Profile
          </Typography>

          <Typography
            component="h6"
            variant="h6"
            sx={{ marginBottom: "10rem" }}
          >
            Company Name
          </Typography>

          {/* Input : Legal Company Name  */}
          <Controller
            name="name_legal"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Legal Company Name"
                variant="outlined"
                error={!!errors.name_legal}
                helperText={
                  errors.name_legal ? errors.name_legal?.message : null
                }
              />
            )}
          />

          {/* Input : Trading Company Name */}
          <Controller
            name="name_trading"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Trading Company Name"
                variant="outlined"
                error={!!errors.name_trading}
                helperText={
                  errors.name_trading ? errors.name_trading?.message : null
                }
              />
            )}
          />

          <Divider orientation="horizontal" />

          <Typography component="h6" variant="h6" gutterBottom>
            Company Numbers
          </Typography>

          {/* Input : Company Incorporation Number */}
          <Controller
            name="company_incorporation_number"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Company Incorporation Number"
                variant="outlined"
                error={!!errors.company_incorporation_number}
                helperText={
                  errors.company_incorporation_number
                    ? errors.company_incorporation_number?.message
                    : null
                }
                sx={{ marginBottom: "1rem" }}
              />
            )}
          />

          {/* Input : Company Tax Reference Number */}
          <Controller
            name="company_tax_reference_number"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Company Tax Reference Number "
                variant="outlined"
                error={!!errors.company_tax_reference_number}
                helperText={
                  errors.company_tax_reference_number
                    ? errors.company_tax_reference_number?.message
                    : null
                }
                sx={{ marginBottom: "1rem" }}
              />
            )}
          />

          <Divider orientation="horizontal" />

          <Stack direction="row" spacing={2}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disableElevation
            >
              Save & Continue
            </Button>

            {/* TODO(DBB) : Cancel in case of !isFirst */}
            {/* <Button
              type="button"
              // onClick={handleModalClose}
              variant="outlined"
              size="large"
              disableElevation
            >
              Cancel
            </Button> */}
          </Stack>
        </Stack>
      </form>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

export default FormCompanyProfile;
