import { FC, useState } from "react";
import { RootState } from "../../../../../app/store";
import { useAppSelector } from "../../../../../app/hooks";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CompanyProfile,
  SchemaCompanyProfile,
} from "./ValidationCompanyProfile";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import {
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from "../../../../../features/api/apiCompanies";

interface Props {
  handleModalClose: () => void;
}

const FormCompanyProfile: FC<Props> = ({ handleModalClose }) => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const { data: dataGetCompany } = useGetCompanyQuery(currentCompanyId);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<CompanyProfile>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaCompanyProfile),
    defaultValues: {
      name_legal: dataGetCompany?.name_legal || "",
      name_trading: dataGetCompany?.name_trading || "",
      company_incorporation_number:
        dataGetCompany?.company_incorporation_number || "",
      company_tax_reference_number:
        dataGetCompany?.company_tax_reference_number || "",
    },
  });

  const [updateCompany] = useUpdateCompanyMutation();

  const onSubmit = (data: CompanyProfile) => {
    currentCompanyId &&
      updateCompany({ id: currentCompanyId, ...data })
        .unwrap()
        .then(() => {
          handleModalClose();
        });
  };

  const onError = (errors: object) => {
    Object.values(errors).forEach((item) =>
      setSnackbar({
        children: `${item.message}`,
        severity: "error",
      })
    );
  };

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Stack direction="column" spacing={3}>
          <Typography component="h6" variant="h6">
            Company Name
          </Typography>

          {/* Input : Legal Company Name  */}
          <Controller
            name="name_legal"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Legal Company Name"
                variant="outlined"
                fullWidth={true}
                error={!!errors.name_legal}
                helperText={
                  errors.name_legal ? errors.name_legal?.message : null
                }
              />
            )}
          />

          {/* Input : Trading Company Name */}
          <Controller
            name="name_trading"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Trading Company Name"
                variant="outlined"
                fullWidth={true}
                error={!!errors.name_trading}
                helperText={
                  errors.name_trading ? errors.name_trading?.message : null
                }
              />
            )}
          />

          <Divider orientation="horizontal" />

          <Typography component="h6" variant="h6">
            Company Numbers
          </Typography>

          {/* Input : Company Incorporation Number */}
          <Controller
            name="company_incorporation_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Company Incorporation Number"
                variant="outlined"
                fullWidth={true}
                error={!!errors.company_incorporation_number}
                helperText={
                  errors.company_incorporation_number
                    ? errors.company_incorporation_number?.message
                    : null
                }
                sx={{ marginBottom: "1rem" }}
              />
            )}
          />

          {/* Input : Company Tax Reference Number */}
          <Controller
            name="company_tax_reference_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Company Tax Reference Number "
                variant="outlined"
                fullWidth={true}
                error={!!errors.company_tax_reference_number}
                helperText={
                  errors.company_tax_reference_number
                    ? errors.company_tax_reference_number?.message
                    : null
                }
                sx={{ marginBottom: "1rem" }}
              />
            )}
          />

          <Divider orientation="horizontal" />

          <Stack direction="row" spacing={2}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disableElevation={true}
            >
              Update & Close
            </Button>

            <Button
              type="button"
              onClick={handleModalClose}
              variant="outlined"
              size="large"
              color="secondary"
              disableElevation={true}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

export default FormCompanyProfile;
