import { z } from "zod";

export type AdditionalInformation = z.infer<typeof SchemaAdditionalInformation>;

export const SchemaAdditionalInformation = z.object({
  marital_status: z.string().optional(),
  email_address: z.string().email().optional().or(z.literal("")),
  phone_number: z
    .string()
    .max(16, {
      message: "Phone number should not exceed 16 characters",
    })
    .optional(),
});
