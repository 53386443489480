import { FC } from "react";

import { DataGridPro, GridColDef, GridAlignment } from "@mui/x-data-grid-pro";
import Container from "@mui/material/Container";

import { useGetAllTaxYearsQuery } from "../../../features/api/apiTaxYears";
import { useGetAllNicRatesQuery } from "../../../features/api/apiNicRates";
import { displayColumnCurrencyGBP } from "../../../utils/datagridFormatters";

const TableNicRates: FC = () => {
  const { data: dataAllTaxYears, isLoading: isLoadingAllTaxYears } =
    useGetAllTaxYearsQuery();
  const { data: dataAllNicRates, isLoading: isLoadingAllNicRates } =
    useGetAllNicRatesQuery();

  if (isLoadingAllTaxYears) return <div>Loading...</div>;
  if (!dataAllTaxYears) return <div>No tax years data to display...</div>;
  if (isLoadingAllNicRates) return <div>Loading...</div>;
  if (!dataAllNicRates) return <div>No NIC rates data to display...</div>;

  const yearsHeaders = dataAllTaxYears
    .map((item) => {
      return {
        id: item.id,
        field: `year_${item.id}`,
        headerName: item.name,
        type: "number",
        width: 120,
        editable: false,
        hide: false,
        align: "right" as GridAlignment,
        valueFormatter: ({ value }: any) => {
          if (value) {
            return displayColumnCurrencyGBP(Number(value));
          } else {
            return null;
          }
        },
      };
    })
    .sort((a: any, b: any) => b.id - a.id);

  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "nic_rate_type",
      headerName: "NIC Rate Type",
      width: 240,
    },
    {
      field: "nic_rate_type_code",
      headerName: "Code",
      width: 80,
    },
    ...yearsHeaders,
  ];

  const rows = dataAllNicRates.map((item) => {
    const nicRowHeaders = {
      id: item.id,
      nic_rate_type: item.name,
      nic_rate_type_code: item.code,
    };

    const rowValues = item.nic_rate.map((rowValue: any) => {
      return {
        [`year_${rowValue.tax_year.id}`]: rowValue.nic_rate,
      };
    });

    const joinedNicRow = [nicRowHeaders, ...rowValues];
    const nicRow = Object.assign({}, ...joinedNicRow);

    return nicRow;
  });

  return (
    <>
      <Container maxWidth="xl" className="mt-4">
        <div style={{ height: 960, width: "100%" }}>
          <DataGridPro
            rows={rows}
            columns={columns}
            autoHeight={true}
            sx={{ backgroundColor: "#ffffff" }}
            initialState={{ pinnedColumns: { left: ["nic_rate_type"] } }}
          />
        </div>
      </Container>
    </>
  );
};

export default TableNicRates;
