import { createContext, FC, useMemo, useState } from "react";

import Container from "@mui/material/Container";

import SubHeader from "../SubHeader";
import DialogPaySchedule from "./addEdit/DialogPaySchedule";
import DataGridPaySchedules from "./DataGridPaySchedules";

interface PayrollScheduleContextType {
  payrollScheduleId: number | undefined;
  setPayrollScheduleId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CurrentPayrollScheduleContext =
  createContext<PayrollScheduleContextType>({
    payrollScheduleId: undefined,
    setPayrollScheduleId: () => {},
    isEdit: false,
    setIsEdit: () => {},
  });

const DashboardPaySchedules: FC = () => {
  const [payrollScheduleId, setPayrollScheduleId] = useState<
    number | undefined
  >(undefined);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const providerPayrollSchedule = useMemo(
    () => ({ payrollScheduleId, setPayrollScheduleId, isEdit, setIsEdit }),
    [payrollScheduleId, setPayrollScheduleId, isEdit, setIsEdit]
  );

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = ({ isEdit }: { isEdit: boolean }) => {
    setIsEdit(isEdit);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <CurrentPayrollScheduleContext.Provider value={providerPayrollSchedule}>
        <DialogPaySchedule
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
        />

        <SubHeader
          title="Pay Schedules"
          page="pay-schedules"
          icon="event"
          callback={() => handleModalOpen({ isEdit: false })}
        />

        <Container maxWidth="xl" sx={{ width: "100%", marginTop: "2rem" }}>
          <DataGridPaySchedules
            handleModalOpenEdit={() => handleModalOpen({ isEdit: true })}
            handleModalOpenAdd={() => handleModalOpen({ isEdit: false })}
          />
        </Container>
      </CurrentPayrollScheduleContext.Provider>
    </>
  );
};

export default DashboardPaySchedules;
