import { createContext, FC, useMemo, useState } from "react";

import Container from "@mui/material/Container";

import SubHeader from "../SubHeader";
import DialogSchemeAddEdit from "./addEdit/DialogSchemeAddEdit";
import DataGridSchemes from "./DataGridSchemes";
import DialogSchemeArchive from "./addEdit/DialogSchemeArchive";

interface SchemeContextType {
  schemeId: number | undefined;
  setSchemeId: React.Dispatch<React.SetStateAction<number | undefined>>;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CurrentSchemeContext = createContext<SchemeContextType>({
  schemeId: undefined,
  setSchemeId: () => {},
  isEdit: false,
  setIsEdit: () => {},
});

const DashboardSchemes: FC = () => {
  const [schemeId, setSchemeId] = useState<number | undefined>(undefined);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const providerScheme = useMemo(
    () => ({ schemeId, setSchemeId, isEdit, setIsEdit }),
    [schemeId, setSchemeId, isEdit, setIsEdit]
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [modalArchiveOpen, setModalArchiveOpen] = useState(false);

  const handleModalAddEditOpen = ({ isEdit }: { isEdit: boolean }) => {
    setIsEdit(isEdit);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalArchiveOpen = () => {
    setModalArchiveOpen(true);
  };

  const handleModalArchiveClose = () => {
    setModalArchiveOpen(false);
  };

  return (
    <>
      <CurrentSchemeContext.Provider value={providerScheme}>
        <DialogSchemeAddEdit
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
        />

        <DialogSchemeArchive
          modalArchiveOpen={modalArchiveOpen}
          handleModalArchiveClose={handleModalArchiveClose}
        />

        <SubHeader
          title="Schemes"
          page="schemes"
          icon="savings"
          callback={() => handleModalAddEditOpen({ isEdit: false })}
        />

        <Container maxWidth="xl" sx={{ width: "100%", marginTop: "2rem" }}>
          <DataGridSchemes
            handleModalOpenAdd={() => handleModalAddEditOpen({ isEdit: false })}
            handleModalOpenEdit={() => handleModalAddEditOpen({ isEdit: true })}
            handleModalOpenArchive={() => handleModalArchiveOpen()}
          />
        </Container>
      </CurrentSchemeContext.Provider>
    </>
  );
};

export default DashboardSchemes;
