import { cloudPayrollApi } from "./cloudPayrollApi";
import { Payroll } from "../../types/Payroll";

interface GeneratePayrollsRequest {
  payroll_schedule_id: number;
  tax_year_id: number;
}

const apiPayrolls = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // CREATE Payroll
    createPayroll: builder.mutation<void, Payroll>({
      query: (payroll) => ({
        url: `/payrolls`,
        method: "POST",
        body: payroll,
      }),
      invalidatesTags: ["Payroll"],
    }),

    // GENERATE Payrolls by Payroll Schedule ID
    generatePayrollsByPayrollScheduleId: builder.mutation<
      Payroll[],
      GeneratePayrollsRequest
    >({
      query: (generatePayrollsRequest) => ({
        url: `/payrolls/generate`,
        method: "POST",
        body: generatePayrollsRequest,
      }),

      invalidatesTags: ["Payroll"],
    }),

    // PROVISION Payroll
    provisionPayroll: builder.mutation<Payroll, string>({
      query: (provisionedPayrollId) => ({
        url: `/payrolls/provision/${provisionedPayrollId}`,
        method: "POST",
      }),

      invalidatesTags: ["Payroll"],
    }),

    // READ All Payrolls
    getAllPayrolls: builder.query<Payroll[], number | void>({
      query: (limit = 10) => `/payrolls?limit=${limit}`,
      providesTags: ["Payroll"],
    }),

    // READ All Payrolls by Company ID
    getAllPayrollsByCompanyId: builder.query<Payroll[], number | undefined>({
      query: (companyId, limit = 10) =>
        `/payrolls/company/${companyId}?limit=${limit}`,
      providesTags: ["Payroll"],
    }),

    // READ All Next Payrolls by Company ID
    getAllNextPayrollsByCompanyId: builder.query<Payroll[], number | undefined>(
      {
        query: (companyId) => `/payrolls/nextpayrolls/${companyId}`,
        providesTags: ["Payroll"],
      }
    ),

    // READ All Overdue Payrolls by Company ID
    getAllOverduePayrollsByCompanyId: builder.query<
      Payroll[],
      number | undefined
    >({
      query: (companyId) => `/payrolls/overduepayrolls/${companyId}`,
      providesTags: ["Payroll"],
    }),

    // READ Single Payroll
    getPayroll: builder.query<Payroll, string>({
      query: (id) => `/payrolls/${id}`,
      providesTags: ["Payroll"],
    }),

    // UPDATE Payroll
    updatePayroll: builder.mutation<void, Partial<Payroll>>({
      query: ({ id, ...rest }) => ({
        url: `/payrolls/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Payroll"],
    }),

    // DISCARD/UNDO Payroll
    discardPayroll: builder.mutation<void, string>({
      query: (id) => ({
        url: `/payrolls/undo/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Payroll"],
    }),

    // DELETE Payroll
    deletePayroll: builder.mutation<void, string>({
      query: (id) => ({
        url: `/payrolls/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Payroll"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreatePayrollMutation,
  useProvisionPayrollMutation,
  useGeneratePayrollsByPayrollScheduleIdMutation,
  useGetAllPayrollsQuery,
  useGetAllPayrollsByCompanyIdQuery,
  useLazyGetAllPayrollsByCompanyIdQuery,
  useGetAllNextPayrollsByCompanyIdQuery,
  useGetAllOverduePayrollsByCompanyIdQuery,
  useGetPayrollQuery,
  useLazyGetPayrollQuery,
  useUpdatePayrollMutation,
  useDiscardPayrollMutation,
  useDeletePayrollMutation,
} = apiPayrolls;
