import { FC } from "react";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

interface SubHeaderProps {
  title: string;
  page: string;
  icon?: string;
  callback?: (data: any) => any;
}

const customStyles = {
  appBar: {
    boxShadow: "inset 0px -1px 0px rgba(47, 48, 51, 0.12)",
  },
  toolBar: {
    padding: "16px 32px !important",
    minHeight: "68px !important",
  },
  icon: {
    color: "rgba(47, 48, 51, 0.54)",
    fontSize: "20px",
    marginRight: "10px",
  },
  title: {
    flexGrow: 1,
    fontWeight: 700,
    alignItems: "center",
  },
};

const SubHeader: FC<SubHeaderProps> = ({ title, page, icon, callback }) => {
  return (
    <AppBar position="relative" color="transparent" sx={customStyles.appBar}>
      <Toolbar sx={customStyles.toolBar}>
        <Typography
          variant="body1"
          component="div"
          sx={customStyles.title}
          className="flex"
        >
          {icon && <Icon sx={customStyles.icon}>{icon}</Icon>}

          {page === "makeup" && `Run Payroll > `}

          {title}
        </Typography>

        {page === "overview" && (
          <Button
            variant="contained"
            size="medium"
            onClick={callback}
            startIcon={<Icon>receipt_long</Icon>}
          >
            Run new Payroll
          </Button>
        )}

        {page === "pay-components" && (
          <Button
            variant="contained"
            size="medium"
            onClick={callback}
            startIcon={<Icon>add_circle</Icon>}
          >
            Add new Pay Component
          </Button>
        )}

        {page === "schemes" && (
          <Button
            variant="contained"
            size="medium"
            onClick={callback}
            startIcon={<Icon>add_circle</Icon>}
          >
            Add new Scheme
          </Button>
        )}

        {page === "pay-schedules" && (
          <Button
            variant="contained"
            size="medium"
            onClick={callback}
            startIcon={<Icon>add_circle</Icon>}
          >
            Add new Pay Schedule
          </Button>
        )}

        {page === "cost-centres" && (
          <Button
            variant="contained"
            size="medium"
            onClick={callback}
            startIcon={<Icon>add_circle</Icon>}
          >
            Add new Cost Centre
          </Button>
        )}

        {page === "makeup" && (
          <Button
            variant="outlined"
            size="medium"
            onClick={callback}
            startIcon={<Icon>chevron_left</Icon>}
          >
            Back to Run Payroll
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default SubHeader;
