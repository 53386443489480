import { FC, useState } from "react";

import FormRatesOfPay from "./FormRatesOfPay";
import DataGridRatesOfPay from "./DataGridRatesOfPay";

interface Props {
  steps: string[];
  activeStep: number;
  handleStepperNext: () => void;
}

const DashboardRatesOfPay: FC<Props> = ({
  steps,
  activeStep,
  handleStepperNext,
}) => {
  const [canProgress, setCanProgress] = useState<boolean>(false);

  return (
    <>
      <FormRatesOfPay
        steps={steps}
        activeStep={activeStep}
        handleStepperNext={handleStepperNext}
        canProgress={canProgress}
      />

      <DataGridRatesOfPay
        handleStepperNext={handleStepperNext}
        canProgress={canProgress}
        setCanProgress={setCanProgress}
      />
    </>
  );
};

export default DashboardRatesOfPay;
