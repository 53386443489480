import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";

import Divider from "@mui/material/Divider";

import HeaderSummary from "./HeaderSummary";
import PayrollEntries from "./DataGridPayrollEntries";
import Footer from "./Footer";

import { useGetPayrollQuery } from "../../../../features/api/apiPayrolls";
import { updateProvisionedPayroll } from "../../../../features/state/payroll/payrollSlice";

interface Props {
  handleStepperNext: () => void;
}

interface RouterState {
  provisionedPayrollId?: string;
}

const DashboardEnterPayroll: FC<Props> = ({ handleStepperNext }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const reduxProvisionedPayrollId: string = useAppSelector(
    (state: RootState) => state.provisionedPayroll.id
  );

  const { provisionedPayrollId: routerProvisionedPayrollId } =
    (location.state as RouterState) || {};

  let provisionedPayrollId = "";
  routerProvisionedPayrollId
    ? (provisionedPayrollId = routerProvisionedPayrollId)
    : (provisionedPayrollId = reduxProvisionedPayrollId);

  const { data: dataPayroll } = useGetPayrollQuery(provisionedPayrollId);

  useEffect(() => {
    dataPayroll && dispatch(updateProvisionedPayroll(dataPayroll));
  }, [dispatch, dataPayroll]);

  return (
    <>
      <HeaderSummary data={dataPayroll} />

      <PayrollEntries />

      <Divider className="block w-full" sx={{ mt: "16px", mb: "24px" }} />
      <Footer handleStepperNext={handleStepperNext} />
    </>
  );
};

export default DashboardEnterPayroll;
