import { createTheme } from '@mui/material/styles';
import { ThemeOptions as ThemeOptionsOld } from '@mui/material/styles/createTheme';

// Custom theme: Colors Other
const themeColorsOther = {
  color: {
    divider: "rgba(47, 48, 51, 0.16)",
    outlineBorder: "#FFFFFF",
    standardInputLine: "#FFFFFF",
    backdropOverlay: "rgba(47, 48, 51, 0.5)",
    ratingActive: "#FFB400",
    snackbarBg: "#43474E",
  },
} as const;
  
// Override style Mui
const themeOptions: ThemeOptionsOld = {
  ...themeColorsOther,
  palette: {
    primary: {
      main: "#0059C7",
      light: "#7FAAFF",
      dark: "#002D6D",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#555F71",
      light: "#8691A4",
      dark: "#273141",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#BA1B1B",
      light: "#FF5449",
      dark: "#680003",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#008491",
      light: "#4BD8E9",
      dark: "#006974",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FF6726",
      light: "#FF7940",
      dark: "#B33F0D",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#2DB355",
      light: "#69BF83",
      dark: "#207F3D",
      contrastText: "#FFFFFF",
    },
    text: {
      primary: "#2F3033",
      secondary: "rgba(47, 48, 51, 0.72)",
      disabled: "rgba(47, 48, 51, 0.4)",
    },
    divider: "rgba(47, 48, 51, 0.16)",
    action: {
      active: "rgba(47, 48, 51, 0.54)",
      hover: "rgba(47, 48, 51, 0.04)",
      selected: "rgba(47, 48, 51, 0.08)",
      disabled: "rgba(47, 48, 51, 0.24)",
      disabledBackground: "rgba(47, 48, 51, 0.12)",
      focus: "rgba(47, 48, 51, 0.12)",
    }
  },
  typography: {
    h1: {
      fontFamily: "General Sans, sans-serif",
      fontWeight: 500,
      fontSize: "96px",
      lineHeight: "112px",
      letterSpacing: "-1.5px",
    },
    h2: {
      fontFamily: "General Sans, sans-serif",
      fontWeight: 600,
      fontSize: "60px",
      lineHeight: 1.20,
      letterSpacing: "-0.5px",
    },
    h3: {
      fontFamily: "General Sans, sans-serif",
      fontWeight: 600,
      fontSize: "48px",
      lineHeight: 1.167,
      // letterSpacing: "",
    },
    h4: {
      fontFamily: "General Sans, sans-serif",
      fontWeight: 600,
      fontSize: "34px",
      lineHeight: 1.235,
      letterSpacing: "0.25px",
    },
    h5: {
      fontFamily: "General Sans, sans-serif",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: 1.334,
      // letterSpacing: "",
    },
    h6: {
      fontFamily: "General Sans, sans-serif",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: 1.60,
      letterSpacing: "0.15px",
    },
    subtitle1: {
      fontFamily: "Supreme, sans-serif",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontFamily: "Supreme, sans-serif",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "21.98px",
      letterSpacing: "0.1px",
    },
    body1: {
      fontFamily: "Supreme, sans-serif",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: 1.5,
      letterSpacing: "0.15px",
    },
    body2: {
      fontFamily: "Supreme, sans-serif",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 1.43,
      letterSpacing: "0.15px",
    },
    button: {
      fontFamily: "General Sans, sans-serif",
      fontWeight: 500,
      letterSpacing: "0.3px",
      textTransform: "inherit",
    },
    caption: {
      fontFamily: "Supreme, sans-serif",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: 1.66,
      letterSpacing: "0.4px",
    },
    overline: {
      fontFamily: "Supreme, sans-serif",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: 2.66,
      letterSpacing: "1px",
    }
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {},
      },
    },
  },
};

// Update for Typescript
type CustomTheme = {
    [Key in keyof typeof themeColorsOther]: typeof themeColorsOther[Key];
};

declare module '@mui/material/styles/createTheme' {
    interface Theme extends CustomTheme {}
    interface ThemeOptions extends CustomTheme {}
}

// Create theme
export const theme = createTheme({ ...themeColorsOther, ...themeOptions });