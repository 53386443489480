import { FC, useState } from "react";

import FormSchemes from "./FormSchemes";
import DataGridSchemes from "./DataGridSchemes";

interface Props {
  steps: string[];
  activeStep: number;
  handleStepperNext: () => void;
}

const DashboardSchemes: FC<Props> = ({
  steps,
  activeStep,
  handleStepperNext,
}) => {
  const [canProgress] = useState<boolean>(true);

  return (
    <>
      <FormSchemes
        steps={steps}
        activeStep={activeStep}
        handleStepperNext={handleStepperNext}
        canProgress={canProgress}
      />

      <DataGridSchemes
        handleStepperNext={handleStepperNext}
        canProgress={canProgress}
      />
    </>
  );
};

export default DashboardSchemes;
