import { FC } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import HeaderDialog from "../../../../../components/common/dialog/HeaderDialog";
import FormCompanyProfile from "./FormCompanyProfile";

interface Props {
  modalOpen: boolean;
  handleModalClose: () => void;
}

const ModalCompanyProfile: FC<Props> = ({ modalOpen, handleModalClose }) => {
  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        maxWidth="sm"
        fullWidth={true}
      >
        <HeaderDialog
          handleModalClose={handleModalClose}
          title="Update Company Profile"
        />

        <DialogContent>
          <FormCompanyProfile handleModalClose={handleModalClose} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalCompanyProfile;
