import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "@mui/material/Icon";
import IconDownload from "@mui/icons-material/Download";

import HeaderSummary from "./HeaderSummary";

import { getPdfFormPaymentInstruction } from "../../../../utils/getPdfForm";
import { getPdfFormPayslipsZip } from "../../../../utils/getPdfFormZip";

const PayrollSummary: FC = () => {
  const navigate = useNavigate();

  const [
    loadingDownloadPaymentInstruction,
    setLoadingDownloadPaymentInstruction,
  ] = useState<boolean>(false);
  const [loadingDownloadAllPayslips, setLoadingDownloadAllPayslips] =
    useState<boolean>(false);

  const provisionedPayrollId: string = useAppSelector(
    (state: RootState) => state.provisionedPayroll.id
  );

  const handleDownloadPaymentInstructionClick = useCallback(() => {
    setLoadingDownloadPaymentInstruction(true);

    getPdfFormPaymentInstruction(provisionedPayrollId)
      .then((data) => {
        if (data) {
          const filename = `PaymentInstruction-${provisionedPayrollId}`;
          const safeFilename = filename.replace(/[^a-z0-9]/gi, "-");

          const link = document.createElement("a");
          link.href = data;
          link.setAttribute("download", `${safeFilename}.pdf`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .then(() => setLoadingDownloadPaymentInstruction(false));
  }, [provisionedPayrollId]);

  const handleDownloadAllPayslipsClick = useCallback(() => {
    setLoadingDownloadAllPayslips(true);

    getPdfFormPayslipsZip(provisionedPayrollId!)
      .then((data) => {
        if (data) {
          const filename = `Payslips-${provisionedPayrollId}`;
          const safeFilename = filename.replace(/[^a-z0-9]/gi, "-");

          const link = document.createElement("a");
          link.href = data;
          link.setAttribute("download", `${safeFilename}.zip`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .then(() => setLoadingDownloadAllPayslips(false));
  }, [provisionedPayrollId]);

  const handleViewPayrollClick = () => {
    navigate("/payrolls/history/payroll", {
      state: { payrollId: provisionedPayrollId },
    });
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ width: "100%", p: "24px 0 !important" }}>
        <Typography component="h5" variant="h5" gutterBottom className="mb-2">
          Payroll recorded
        </Typography>

        <Typography
          component="p"
          variant="body1"
          gutterBottom
          sx={{ mb: "1.778rem" }}
        >
          The payroll has been processed and saved to payroll history.
        </Typography>

        <HeaderSummary />

        <Button
          variant="outlined"
          startIcon={<Icon>table_rows</Icon>}
          onClick={handleViewPayrollClick}
          disabled={true}
        >
          View Payroll Summary
        </Button>

        <Typography
          component="h5"
          variant="h6"
          gutterBottom
          sx={{ mt: "2.667rem", mb: "1rem" }}
        >
          What would you like to do next?
        </Typography>

        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: "white",
              padding: "1rem 1rem 1.5rem",
              border: "1px solid #E7E6E9",
              borderRadius: "0.5rem",
            }}
          >
            <Typography component="h6" variant="h6" sx={{ mb: ".889rem" }}>
              Pay Employees
            </Typography>

            <Stack direction="row" spacing={2}>
              <LoadingButton
                variant="outlined"
                size="large"
                endIcon={<IconDownload />}
                onClick={handleDownloadPaymentInstructionClick}
                loading={loadingDownloadPaymentInstruction}
                disabled={false}
              >
                Download Payment Instruction
              </LoadingButton>

              <LoadingButton
                variant="outlined"
                size="large"
                endIcon={<IconDownload />}
                onClick={handleDownloadAllPayslipsClick}
                loading={loadingDownloadAllPayslips}
                disabled={false}
              >
                Download all Payslips
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "2.667rem" }}>
          <Button
            sx={{ mr: "1rem" }}
            variant="contained"
            onClick={() => navigate("/payrolls/overview")}
          >
            Go to Payrolls dashboard
          </Button>

          <Button variant="text" disabled>
            Need to cancel payroll?
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default PayrollSummary;
