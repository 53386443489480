import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";

import Navigation from "../components/common/header/Navigation";

const LayoutApp = () => {
  return (
    <>
      <Box sx={{ paddingTop: "4rem" }}>
        <Navigation />

        <Outlet />
      </Box>
    </>
  );
};

export default LayoutApp;
