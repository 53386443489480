import { Dispatch, FC, SetStateAction } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";

interface Props {
  // TODO(DBB) : Types are broken with new exception object types
  data: any;
  selectedPayroll: string;
  setSelectedPayroll: Dispatch<SetStateAction<string>>;
  setCanProgress: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
}

const OptionPayrollNext: FC<Props> = ({
  data: {
    payroll_id,
    pay_date_scheduled,
    pay_period,
    payroll_frequency_name,
    payroll_schedule_name,
    tax_year: { name: tax_year_name },
  },
  selectedPayroll,
  setSelectedPayroll,
  setCanProgress,
  disabled,
}) => {
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSelectedPayroll(value);

    setCanProgress(true);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          padding: "1rem",
          border: "1px solid #E7E6E9",
          borderRadius: "0.5rem",
          marginBottom: "1rem",
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <Typography variant="h6" component="h5" gutterBottom>
            {payroll_schedule_name}
          </Typography>

          <Radio
            name="radio-option-payroll-next"
            value={payroll_id}
            checked={selectedPayroll === payroll_id}
            onChange={handleRadioChange}
            inputProps={{ "aria-label": payroll_schedule_name.toString() }}
            disabled={disabled}
          />
        </Box>

        <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {/* Column: Pay Period */}
          <Grid item xs>
            <Typography
              component="p"
              variant="body2"
              gutterBottom
              sx={customStyles.subTitle}
            >
              Pay Period
            </Typography>

            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={customStyles.fontBold}
            >
              {`Pay period ${pay_period} of tax
                  year ${tax_year_name}`}
            </Typography>
          </Grid>

          {/* Column: Pay Frequency */}
          <Grid item xs sx={{ borderLeft: "1px solid rgba(47, 48, 51, 0.16)" }}>
            <Typography
              component="p"
              variant="body2"
              gutterBottom
              sx={customStyles.subTitle}
            >
              Pay Frequency
            </Typography>

            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={customStyles.fontBold}
            >
              {payroll_frequency_name}
            </Typography>
          </Grid>

          {/* Column: Pay Date */}
          <Grid item xs sx={{ borderLeft: "1px solid rgba(47, 48, 51, 0.16)" }}>
            <Typography
              component="p"
              variant="body2"
              gutterBottom
              sx={customStyles.subTitle}
            >
              Pay Date
            </Typography>

            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={customStyles.fontBold}
            >
              {new Intl.DateTimeFormat("en-GB", { dateStyle: "full" }).format(
                new Date(pay_date_scheduled)
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const customStyles = {
  fontBold: {
    fontWeight: "bold",
  },
  subTitle: {
    color: "rgba(47, 48, 51, 0.72)",
    textTransform: "uppercase",
    mb: "0.5rem",
  },
};

export default OptionPayrollNext;
