import { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useGetAppVersionQuery } from "../../../features/api/cloudPayrollApi";

const StatusPlatform: FC = () => {
  const { data: dataGetAppVersion } = useGetAppVersionQuery();

  return (
    <>
      <Box
        sx={{ display: "flex", alignItems: "center", marginLeft: "0.75rem" }}
      >
        <Box
          sx={{
            width: "1rem",
            height: "1rem",
            borderRadius: "50%",
            // backgroundColor: "#B40000", // red
            // backgroundColor: "#D3952E", // amber
            backgroundColor: "#00B526", // green
            marginRight: "0.8rem",
          }}
        ></Box>
        <Box>
          <Typography variant="overline">
            Cloud Payroll {dataGetAppVersion ? dataGetAppVersion.version : " "}{" "}
            | System OK
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default StatusPlatform;
