import { FC } from "react";

import Container from "@mui/material/Container";
import SubHeader from "../SubHeader";

const PaymentMethods: FC = () => {
  return (
    <>
      <SubHeader title="Payment Methods" page="payment-methods" icon="payments"/>
      
      <Container maxWidth="xl" sx={{ width: "100%" }}>

      </Container>
    </>
  );
};

export default PaymentMethods;
