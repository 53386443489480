import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import type { User } from "../../../types/User";

const initialState: Partial<User> = {
  id: "",
  email_address: "",
  email_verified: false,
  forenames: "",
  surname: "",
  default_company: 0,
  account_type: "",
  last_login: undefined,
  total_logins: undefined,
  status: "",
  date_time_created: undefined,
  date_time_updated: undefined,
  date_time_archived: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser(state, action: PayloadAction<Partial<User>>) {
      Object.entries(action.payload).forEach(([k, v]) => {
        state[k] = v;
        //TODO(DBB) : check if return is required
        return state;
      });
    },
    resetCurrentUser: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentUser, resetCurrentUser } = userSlice.actions;

export default userSlice.reducer;
