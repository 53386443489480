import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { getPdfFormPayslip } from "../../../../../utils/getPdfForm";

import Box from "@mui/material/Box";
import SubHeader from "./SubHeader";

interface RouterState {
  payrollEntryId?: string;
}

const DashboardPayslip: FC = () => {
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string>("");

  const location = useLocation();

  const { payrollEntryId: routerPayrollEntryId } =
    (location.state as RouterState) || {};

  useEffect(() => {
    getPdfFormPayslip(routerPayrollEntryId).then((data) => {
      data && setPdfBlobUrl(data);
    });
  }, [routerPayrollEntryId]);

  return (
    <>
      <SubHeader
        title="View Payslip"
        routerPayrollEntryId={routerPayrollEntryId!}
        pdfBlobUrl={pdfBlobUrl}
      />

      <Box
        sx={{
          width: "calc(600px + 2px)",
          margin: "2rem",
          border: "1px solid #E7E6E9",
          borderRadius: "4px",
          overflow: "hidden",
        }}
      >
        <Document
          file={pdfBlobUrl}
          loading="Loading payslip PDF..."
          error="Error rendering payslip PDF"
          // onLoadSuccess={() => console.log("Success!")}
        >
          <Page
            pageNumber={1}
            width={600}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </Box>
    </>
  );
};

export default DashboardPayslip;
