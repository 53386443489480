import { FC } from "react";

import SubHeader from "./SubHeader";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import HeaderEmployeeData from "./HeaderEmployeeData";
import SummaryPay from "./SummaryPay";
import SummaryScheme from "./SummaryScheme";

const DashboardPaySummary: FC = () => {
  return (
    <>
      <SubHeader />

      <Container maxWidth="xl" sx={{ width: "100%", padding: "1.25rem" }}>
        <Stack direction="column" spacing={4}>
          <HeaderEmployeeData />

          <SummaryPay />

          <SummaryScheme />
        </Stack>
      </Container>
    </>
  );
};

export default DashboardPaySummary;
