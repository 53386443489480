import { z } from "zod";

export type EmploymentInformation = z.infer<typeof SchemaEmploymentInformation>;

export const SchemaEmploymentInformation = z.object({
  job_date_finished: z
    .date({
      invalid_type_error: "Job Date Finished is required",
    })
    .or(z.string().transform((str) => new Date(str)))
    .nullable(),
});
