import { FC, Fragment } from "react";
import { useAppSelector } from "../../../app/hooks";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import IconWork from "@mui/icons-material/Work";

import SubHeader from "./SubHeader";
import { User } from "../../../types/User";
import { Company } from "../../../types/Company";

const DashboardAccountCompany: FC = () => {
  const currentUser: Partial<User> = useAppSelector(
    (state) => state.currentUser
  );

  const currentUserCompanies = currentUser?.members?.map(
    (item: Partial<Company>) => item.company
  );

  return (
    <>
      <SubHeader title="Manage Companies" icon="business" />

      <Stack direction="column" gap={4} sx={{ margin: "2rem" }}>
        <Box component="section">
          <Typography variant="h5" component="h2" marginBottom="1rem">
            Companies
          </Typography>

          {currentUserCompanies && (
            <List
              sx={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "0.5rem",
              }}
            >
              {currentUserCompanies.map((item: any, index: number) => {
                return (
                  <Fragment key={`item-${index}`}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <IconWork />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.name_trading}
                        secondary={item.name_legal}
                      />

                      <Button variant="outlined" color="error" disabled={true}>
                        Archive
                      </Button>
                    </ListItem>

                    {index !== currentUserCompanies.length - 1 && (
                      <Divider key={`divider-${index}`} />
                    )}
                  </Fragment>
                );
              })}
            </List>
          )}
        </Box>
      </Stack>
    </>
  );
};

export default DashboardAccountCompany;
