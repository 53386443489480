import { FC, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";

import Box from "@mui/material/Box";

import SubNavigation from "./SubNavigation";

import { useGetCurrentTaxYearQuery } from "../../features/api/apiTaxYears";
import { setCurrentTaxYear } from "../../features/state/taxYear/taxYearSlice";

const DashboardTaxForms: FC = () => {
  const dispatch = useAppDispatch();

  const { data: dataCurrentTaxYear } = useGetCurrentTaxYearQuery();

  useEffect(() => {
    dataCurrentTaxYear && dispatch(setCurrentTaxYear(dataCurrentTaxYear));
  }, [dispatch, dataCurrentTaxYear]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <SubNavigation />
        </Box>

        <Box component="main" sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default DashboardTaxForms;
