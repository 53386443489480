import { z } from "zod";

export type CompanyProfile = z.infer<typeof SchemaCompanyProfile>;

export const SchemaCompanyProfile = z.object({
  name_legal: z
    .string()
    .min(2, {
      message: "Company Legal Name is required.",
    })
    .max(100, {
      message: "Company Legal Name should not exceed 100 characters",
    }),

  name_trading: z
    .string()
    .min(2, {
      message:
        "Company Trading Name is required. Repeat legal name if the matching.",
    })
    .max(100, {
      message: "Company Trading Name should not exceed 100 characters",
    })
    .optional(),

  company_incorporation_number: z
    .string()
    .regex(
      new RegExp(/[0-9]{6}[BCFLMPV]/),
      "Format should be 6 digits followed by 1 letter (either B, C, F, L, M, P or V), e.g. '123456C'"
    )
    .length(
      7,
      "Format should be 6 digits followed by 1 letter (either B, C, F, L, M, P or V), e.g. '123456C'"
    )
    .optional(),

  company_tax_reference_number: z
    .string()
    .regex(
      new RegExp(/[A-Z]\d{6}[A-Z]\d{2}-\d{2}/),
      "Format should be 1 uppercase letter followed by 6 numbers, 1 uppercase letter, 2 numbers, 1 hypen and 2 numbers, e.g. 'H012345E01-28'"
    )
    .length(
      13,
      "Format should be 1 uppercase letter followed by 6 numbers, 1 uppercase letter, 2 numbers, 1 hypen and 2 numbers, e.g. 'H012345E01-28'"
    ),
});
