function dateFormatter(date: any) {
  const objectDate = new Date(date);

  if (date) {
    return new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(objectDate);
  } else {
    return "No date to display";
  }
}

export { dateFormatter };
