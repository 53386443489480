import { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

interface Props {
  title: string;
  blurb?: string;
  ctaPrimaryText?: string;
  ctaPrimaryAction?: () => void;
  ctaSecondaryText?: string;
  ctaSecondaryIcon?: string;
  ctaSecondaryAction?: () => void;
  disabled?: boolean;
}

const ModulePayrollConfiguration: FC<Props> = ({
  title,
  blurb,
  ctaPrimaryText,
  ctaPrimaryAction,
  ctaSecondaryText,
  ctaSecondaryIcon,
  ctaSecondaryAction,
  disabled,
}) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          border: "1px solid #E7E6E9",
          padding: "1rem",
          borderRadius: "0.5rem",
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <Typography
          variant="h6"
          component="h3"
          sx={{ marginBottom: "0.25rem" }}
        >
          {title}
        </Typography>

        <Typography sx={{ fontSize: "0.8rem" }}>{blurb}</Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <Button
            variant="text"
            size="small"
            color="secondary"
            onClick={ctaPrimaryAction}
            disabled={disabled}
          >
            {ctaPrimaryText}
          </Button>

          {ctaSecondaryAction && ctaSecondaryText && (
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={ctaSecondaryAction}
              disabled={disabled}
              endIcon={ctaSecondaryIcon && <Icon>{ctaSecondaryIcon}</Icon>}
            >
              {ctaSecondaryText}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ModulePayrollConfiguration;
