import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const ErrorMessage: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ padding: "2rem" }}>
        <Alert severity="error">
          <AlertTitle>Routing Error</AlertTitle>

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
          >
            <Typography>No Payroll ID was provided to the router.</Typography>

            <Button
              variant="outlined"
              color="warning"
              size="small"
              onClick={() => navigate("/payrolls/overview")}
            >
              Return to Payrolls Overview
            </Button>
          </Stack>
        </Alert>
      </Box>
    </>
  );
};

export default ErrorMessage;
