import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Controller, useForm } from "react-hook-form";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Grow from "@mui/material/Grow";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import CheckCircleSharp from "@mui/icons-material/CheckCircleSharp";

import { setCurrentUserSignUp } from "../../../../features/state/signup/userSignUpSlice";

import { UserSignUp } from "../../../../types/UserSignUp";
import { useVerifyUserSignUpPasswordMutation } from "../../../../features/api/apiUserSignUp";
import { RootState } from "../../../../app/store";
import FooterDisclaimer from "../FooterDisclaimer";
import Alert from "@mui/material/Alert";

interface Props {
  handleStepperNext: () => void;
}

const FormOneTimePass: FC<Props> = ({ handleStepperNext }) => {
  const dispatch = useAppDispatch();

  const { email_address } = useAppSelector(
    (state: RootState) => state.currentUserSignUp
  );

  const { handleSubmit, control } = useForm<UserSignUp>();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showButtonPassword, setShowButtonPassword] = useState<boolean>(false);

  const [showRetry, setShowRetry] = useState<boolean>(false);

  const handleFocusPassword = () => {
    setShowButtonPassword(true);
  };

  const handleBlurPassword = () => {
    setShowButtonPassword(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const passwordCorrect = (
    <CheckCircleSharp className="mr-2" sx={{ color: "#0059C7" }} />
  );
  const passwordIncorrect = (
    <CheckCircleOutline
      className="mr-2"
      sx={{ color: "rgba(47, 48, 51, 0.24)" }}
    />
  );

  const iconWrap = (name: any) => (
    <Typography variant="subtitle2" noWrap component="span">
      {name}
    </Typography>
  );

  const [verifyUserSignUpPassword] = useVerifyUserSignUpPasswordMutation();

  const onSubmit = async (data: UserSignUp) => {
    const submission = {
      ...data,
      email_address: email_address as string,
    };

    // TODO(DBB) : Inactivity countdown => signOut()

    verifyUserSignUpPassword(submission)
      .unwrap()
      .then((payload) => {
        if (payload === false) {
          // generateNewCode
          setShowRetry(true);
        }

        if (payload === true) {
          dispatch(setCurrentUserSignUp({ password_otp: data.password }));
          handleStepperNext();
        }

        // if return false
        // -- wrong code
        // -- expired code
        // SHOULD
        // -- try again || generate new code
      });

    // new Promise((resolve, reject) => {
    //   resolve(dispatch(setCurrentUserSignUp({ password_otp: data.password })));
    // })
    //   .then(() => {
    //     handleStepperNext();
    //   })
    //   .catch((error: any) =>
    //     console.error(
    //       `[${error.data.statusCode}] ${error.data.error}: "${error.data.message}"`
    //     )
    //   );
  };

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        className="mx-auto"
        style={{ maxWidth: "696px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          className="p-12"
          sx={(theme) => ({
            [theme.breakpoints.down(992)]: {
              padding: "0 0 50px 0",
            },
          })}
        >
          <Grid item xs={12}>
            <Typography
              component="h4"
              variant="h4"
              gutterBottom
              className="m-4"
            >
              Verify Email
            </Typography>
            <div className="mb-3">
              Please insert the one time pass code sent to your email address to
              continue.
            </div>
            <Typography variant="subtitle1" gutterBottom component="div">
              Thank your for signing up. Please check your email for a
              verification link (the email may lands in your spam folder).
              Verify your email to continue with Cloud Payroll trial.
            </Typography>

            <Typography variant="subtitle1" gutterBottom component="div">
              <Link href="/signup/resend" className="font-bold">
                Click here
              </Link>{" "}
              if you do not get an email from us.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: "Six digit verification is required",
                pattern: {
                  value: /^(?=.*\d).{6,}$/,
                  message:
                    "At least 6 characters, comprised of letters and numbers should be submitted",
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="password"
                    label="Six Character Verification Code"
                    id="password"
                    value={value}
                    error={!!error}
                    helperText={error ? error.message : null}
                    onChange={onChange}
                    onFocus={handleFocusPassword}
                    onBlur={handleBlurPassword}
                  />

                  {!!showRetry && (
                    <Alert severity="warning" sx={{ marginTop: "1rem" }}>
                      Invalid value submitted. Try again, or contact{" "}
                      <strong>support@cloudpayroll.io</strong>.
                    </Alert>
                  )}

                  {value && (
                    <Grow
                      in={!!value}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(!!value ? { timeout: 1000 } : {})}
                    >
                      <Grid
                        container
                        className="p-6 pt-2"
                        rowSpacing={2}
                        sx={{
                          backgroundColor: "rgba(0, 89, 199, 0.08)",
                          marginTop: "8px",
                        }}
                      >
                        <Grid item xs={12} sm={6}>
                          {/^(?=.*\d).{6,}$/.test(value as string)
                            ? passwordCorrect
                            : passwordIncorrect}
                          {iconWrap("At least 6 characters")}
                        </Grid>
                      </Grid>
                    </Grow>
                  )}
                </>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              className="mb-9"
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ height: "60px" }}
            >
              Submit
            </Button>
          </Grid>

          <FooterDisclaimer />
        </Grid>
      </form>
    </>
  );
};

export default FormOneTimePass;
