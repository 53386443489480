import { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const pages = [
  {
    key: "company_information",
    name: "Company Information",
    to: "/company/information",
    icon: "domain",
    items: [],
  },

  {
    key: "members",
    name: "Members",
    to: "/company/members",
    icon: "people",
    items: [],
  },

  {
    key: "plan_billing",
    name: "Plan & Billing",
    to: "/company/billing",
    icon: "receipt_long",
    items: [
      {
        key: "manage_subscription",
        name: "Manage Subscription",
        to: "/company/billing",
        disabled: false,
      },
      {
        key: "payment_preferences",
        name: "Payment Preferences",
        to: "",
        disabled: true,
      },
      {
        key: "invoice_history",
        name: "Invoice History",
        to: "",
        disabled: true,
      },
    ],
  },
];

function SubNavigation() {
  const navigate = useNavigate();
  const [open, setOpen] = useState<any>({});

  const handleClick = (key: any) => {
    setOpen({ [key]: !open[key] });
  };

  const itemNav = (
    items: any,
    key: string,
    to: string,
    icon: string,
    isOpen: boolean,
    name: string
  ) => (
    <ListItemButton
      onClick={() => (items && items.length ? handleClick(key) : navigate(to))}
    >
      <ListItemIcon sx={{ minWidth: "36px" }}>
        <Icon fontSize="small">{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={name} />
      {items && items.length > 0 && (isOpen ? <ExpandLess /> : <ExpandMore />)}
    </ListItemButton>
  );

  return (
    // TODO(DBB) : Refactor into global component
    <Drawer
      variant="permanent"
      sx={{
        boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.08)",
        width: 288,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 288,
          boxSizing: "border-box",
          marginTop: "2rem",
          paddingTop: "64px",
        },
      }}
    >
      <Typography
        component="h6"
        variant="h6"
        gutterBottom
        sx={{ padding: "0 16px 24px 16px", mb: 0 }}
      >
        Company Settings
      </Typography>

      <Box sx={{ overflow: "auto" }}>
        <List>
          {pages.map(({ name, to, icon, items, key }) => {
            const isOpen = open[key] || false;
            return (
              <div key={key}>
                <ListItem
                  key={name}
                  disablePadding
                  sx={{ "& .active": { background: "rgba(0, 89, 199, 0.08)" } }}
                >
                  {items && items.length ? (
                    itemNav(items, key, to, icon, isOpen, name)
                  ) : (
                    <NavLink
                      style={{ width: "100%" }}
                      to={to}
                      className={(navData) =>
                        navData.isActive ? "active" : ""
                      }
                    >
                      {itemNav(items, key, to, icon, isOpen, name)}
                    </NavLink>
                  )}
                </ListItem>

                {/* Submenu  */}
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {items.map(
                      ({
                        key: childKey,
                        name: childName,
                        to: childTo,
                        disabled,
                      }) => (
                        <NavLink
                          key={childKey}
                          style={{ width: "100%" }}
                          to={childTo}
                          className={(navData) =>
                            navData.isActive ? "active" : ""
                          }
                        >
                          <ListItemButton key={childKey} disabled={disabled}>
                            <ListItemText
                              primary={childName}
                              sx={{ paddingLeft: "2.25rem" }}
                            />
                          </ListItemButton>
                        </NavLink>
                      )
                    )}
                  </List>
                </Collapse>
              </div>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
}

export default SubNavigation;
