import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { TaxYear } from "../../../types/TaxYear";

const initialState: Partial<TaxYear> = {
  id: "",
  name: "",
  date_from: undefined,
  date_to: undefined,
};

export const taxYearSlice = createSlice({
  name: "currentTaxYear",
  initialState,
  reducers: {
    setCurrentTaxYear: (state, action: PayloadAction<Partial<TaxYear>>) => {
      Object.entries(action.payload ?? {}).forEach(([k, v]) => {
        state[k] = v;
      });
    },
    resetCurrentTaxYear: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setCurrentTaxYear, resetCurrentTaxYear } = taxYearSlice.actions;

export default taxYearSlice.reducer;
