import { FC } from "react";
import { useAppSelector } from "../../../../app/hooks";

import BlockText from "../../../company/edit/BlockText";

import { User } from "../../../../types/User";
import { dateFormatter } from "../../../../utils/dateFormatter";

const DetailsAccountDetails: FC = () => {
  const currentUser: Partial<User> = useAppSelector(
    (state) => state.currentUser
  );

  return (
    <>
      <BlockText title="Account Status" data={currentUser?.status || ""} />

      <BlockText title="Account Type" data={currentUser?.account_type || ""} />

      <BlockText
        title="Account Opened"
        data={dateFormatter(currentUser?.date_time_created) || ""}
      />
    </>
  );
};

export default DetailsAccountDetails;
