import { FC, FocusEventHandler, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { Controller, useForm } from "react-hook-form";
import { RootState } from "../../../../app/store";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Grow from "@mui/material/Grow";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import CheckCircleSharp from "@mui/icons-material/CheckCircleSharp";

import FooterDisclaimer from "../FooterDisclaimer";

import { useSubmitUserSignUpPasswordMutation } from "../../../../features/api/apiUserSignUp";
import { UserSignUp } from "../../../../types/UserSignUp";
import { useNavigate } from "react-router-dom";

const FormPasswordSet: FC = () => {
  const navigate = useNavigate();

  const { email_address, password_otp } = useAppSelector(
    (state: RootState) => state.currentUserSignUp
  );

  const [storePassword, setStorePassword] = useState<string>("");
  const [storePasswordConfirm, setStorePasswordConfirm] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showButtonPassword, setShowButtonPassword] = useState<boolean>(false);

  const { handleSubmit, control } = useForm<UserSignUp>();

  const [sumbitUserSignUpPassword] = useSubmitUserSignUpPasswordMutation();

  const handleFocusPassword = () => {
    setShowButtonPassword(true);
  };

  const handleBlurPassword: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const value = event.target.value;
    setStorePassword(value);
  };

  const handleBlurPasswordConfirm: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const value = event.target.value;
    setStorePasswordConfirm(value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const passwordCorrect = (
    <CheckCircleSharp className="mr-2" sx={{ color: "#0059C7" }} />
  );

  const passwordIncorrect = (
    <CheckCircleOutline
      className="mr-2"
      sx={{ color: "rgba(47, 48, 51, 0.24)" }}
    />
  );

  const iconWrap = (name: any) => (
    <Typography variant="subtitle2" noWrap component="span">
      {name}
    </Typography>
  );

  const onSubmit = async (data: UserSignUp) => {
    const newPassword = data.password;

    const submission = {
      email_address: email_address as string,
      new_password: newPassword as string,
      old_password: password_otp as string,
      is_signup: true,
    };

    if (storePassword === storePasswordConfirm) {
      sumbitUserSignUpPassword(submission)
        .unwrap()
        .then(() => {
          // handleStepperNext();
          navigate("/welcome");
        })
        .catch((error: any) =>
          console.error(
            `[${error.data.statusCode}] ${error.data.error}: "${error.data.message}"`
          )
        );
    } else {
      // TODO(DBB) : Handle error properly
      console.error("Failed to match passwords");
    }
  };

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        className="mx-auto"
        style={{ maxWidth: "696px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          className="p-12"
          sx={(theme) => ({
            [theme.breakpoints.down(992)]: {
              padding: "0 0 50px 0",
            },
          })}
        >
          {/* Page Title */}
          <Grid item xs={12}>
            <Typography
              component="h4"
              variant="h4"
              gutterBottom
              className="m-4"
            >
              Set New Password
            </Typography>
            <div className="mb-3">
              Password should contain of at least 8 characters, with at least
              one lowercase letter, one uppercase letter, one number and one
              special character
            </div>
          </Grid>

          {/* Password */}
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: "Password is required",
                pattern: {
                  value:
                    /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                  message:
                    "At least 8 characters, at least one lowercase letter, one uppercase letter, one number and one special character",
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="password_new"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    value={value}
                    error={!!error}
                    helperText={error ? error.message : null}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showButtonPassword && (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={onChange}
                    onFocus={handleFocusPassword}
                    onBlur={handleBlurPassword}
                  />

                  {value && (
                    <Grow
                      in={!!value}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(!!value ? { timeout: 1000 } : {})}
                    >
                      <Grid
                        container
                        className="p-6 pt-2"
                        rowSpacing={2}
                        sx={{
                          backgroundColor: "rgba(0, 89, 199, 0.08)",
                          marginTop: "8px",
                        }}
                      >
                        <Grid item xs={12} sm={6}>
                          {/^(?=.*\d).{8,}$/.test(value as string)
                            ? passwordCorrect
                            : passwordIncorrect}
                          {iconWrap("At least 8 characters")}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          {/\d/.test(value as string)
                            ? passwordCorrect
                            : passwordIncorrect}
                          {iconWrap("One number")}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          {/^.*[A-Z].*$/.test(value as string) &&
                          /^.*[a-z].*$/.test(value as string)
                            ? passwordCorrect
                            : passwordIncorrect}
                          {iconWrap("Lowercase and uppercase letters")}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          {/^.*[@$!%*?&].*$/.test(value as string)
                            ? passwordCorrect
                            : passwordIncorrect}
                          {iconWrap("One special charater")}
                        </Grid>
                      </Grid>
                    </Grow>
                  )}
                </>
              )}
            />
          </Grid>

          {/* Confirm Password */}
          <Grid item xs={12}>
            <Controller
              name="ConfirmPassword"
              control={control}
              defaultValue=""
              rules={{
                required: "Password confirmation is required",
                validate: (value) => {
                  return value === storePassword || "Passwords do not match";
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="password_confirm"
                    label="Confirm Password"
                    type={showPassword ? "text" : "password"}
                    id="password_confirm"
                    autoComplete="new-password"
                    value={value}
                    error={!!error}
                    helperText={error ? error.message : null}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showButtonPassword && (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={onChange}
                    onFocus={handleFocusPassword}
                    onBlur={handleBlurPasswordConfirm}
                  />
                </>
              )}
            />
          </Grid>

          {/* CTA: Create Account */}
          <Grid item xs={12}>
            <Button
              className="mb-9"
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ height: "60px" }}
            >
              Create Account
            </Button>
          </Grid>

          <FooterDisclaimer />
        </Grid>
      </form>
    </>
  );
};

export default FormPasswordSet;
