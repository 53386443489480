import { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

interface Props {
  emailAddress?: string;
  removeMemberProspective?: (email_address: string) => void;
}

const ListItemMemberProspective: FC<Props> = ({
  emailAddress,
  removeMemberProspective,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.75rem 1rem",
        }}
      >
        {emailAddress ? (
          emailAddress
        ) : (
          <Typography sx={{ color: "rgba(47, 48, 51, 0.40)" }}>
            No entries...
          </Typography>
        )}

        {emailAddress && removeMemberProspective && (
          <Button
            type="button"
            onClick={() => removeMemberProspective(emailAddress)}
            variant="outlined"
            color="error"
            size="small"
            disabled={false}
            disableElevation={true}
          >
            Remove
          </Button>
        )}
      </Box>
    </>
  );
};

export default ListItemMemberProspective;
