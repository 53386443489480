import { FC } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

interface BlockTextData {
  title: string;
  data: string;
}

const BlockText: FC<BlockTextData> = ({ title, data }) => {
  return (
    <>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: "1rem" }}
      >
        <Grid item xs={4}>
          <Typography
            component="h6"
            variant="subtitle1"
            gutterBottom
            sx={{ fontWeight: 700 }}
          >
            {title}
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography component="h6" variant="body1" gutterBottom>
            {data ? (
              data
            ) : (
              <span style={{ color: "#cccccc" }}>Not available</span>
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default BlockText;
