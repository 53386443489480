import { FC, useCallback, useState, useEffect, useMemo } from "react";
import { RootState } from "../../../../app/store";
import { useAppSelector } from "../../../../app/hooks";

import {
  DataGridPro,
  GridRowModel,
  GridColumns,
  GridRowModesModel,
  useGridApiRef,
  GridValidRowModel,
  GridActionsCellItem,
  GridRowId,
  GridCellParams,
  GridRowParams,
  GridValueSetterParams,
  GridEditInputCell,
} from "@mui/x-data-grid-pro";

import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertProps } from "@mui/material/Alert";
import IconDelete from "@mui/icons-material/DeleteOutlined";

import AddPayComponent from "./AddPayComponent";

import {
  useGetAllPayrollPayRatesByPayrollEntryIdQuery,
  useUpdatePayrollPayRateMutation,
  useDeletePayrollPayRateMutation,
} from "../../../../features/api/apiPayrollPayRates";
import { PayrollPayRate } from "../../../../types/PayrollPayRate";

const usePayrollPayRateMutation = () => {
  return useCallback(
    (payrollPayRate: Partial<PayrollPayRate>) =>
      new Promise<Partial<PayrollPayRate>>((resolve, reject) =>
        setTimeout(() => {
          resolve({
            ...payrollPayRate,
          });
        }, 0)
      ),
    []
  );
};

const formatValuesCurrency = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

// TODO(DBB) : Ref: https://mui.com/x/react-data-grid/editing/#create-your-own-edit-component

const DataGridPayComponents: FC = () => {
  const currentPayrollEntryId = useAppSelector(
    (state: RootState) => state.currentPayrollEntry.id
  );

  const mutateRow = usePayrollPayRateMutation();

  const apiRef = useGridApiRef();

  const [rows, setRows] = useState<Partial<PayrollPayRate>[] | undefined>();

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [pageSize, setPageSize] = useState<number>(5);

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const { data: dataGetAllPayrollPayRatesByCompanyId } =
    useGetAllPayrollPayRatesByPayrollEntryIdQuery(currentPayrollEntryId);

  const [updatePayrollPayRate] = useUpdatePayrollPayRateMutation();

  const [deletePayrollPayRate] = useDeletePayrollPayRateMutation();

  useEffect(() => {
    const payrollPayRates = dataGetAllPayrollPayRatesByCompanyId?.map(
      (item: Partial<PayrollPayRate>) => {
        return {
          id: item.id,
          payroll_entry: item.payroll_entry,
          pay_component: item.pay_component,
          // payroll_count_unit: item.pay_component?.payroll_count_unit,
          pay_rate: item.pay_rate,
          pay_count: item.pay_count,
          pay_amount: item.pay_amount,
          is_gross_pay: item.is_gross_pay,
          is_liable_to_tax: item.is_liable_to_tax,
          is_liable_to_ni: item.is_liable_to_ni,
          is_pensionable: item.is_pensionable,
          status: item.status,
          date_time_created: item.date_time_created,
        };
      }
    );
    setRows(payrollPayRates);
  }, [dataGetAllPayrollPayRatesByCompanyId]);

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel) => {
      try {
        const response = await mutateRow(newRow);

        updatePayrollPayRate(response as Partial<PayrollPayRate>).then(() => {
          setSnackbar({
            children: "Record successfully saved",
            severity: "success",
          });
        });

        return response;
      } catch (error) {
        setSnackbar({
          children: `Error writing to store: ${error}`,
          severity: "error",
        });
      }
    },
    [mutateRow, updatePayrollPayRate]
  );

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  const handleDeleteClick = useCallback(
    (id: GridRowId) => {
      deletePayrollPayRate(String(id)).then(
        (resolve) => {
          setSnackbar({
            children: "Record deleted",
            severity: "success",
          });
        },
        (reject) => {
          setSnackbar({
            children: "Unable to delete record",
            severity: "error",
          });
        }
      );
      deletePayrollPayRate(String(id));
    },
    [deletePayrollPayRate]
  );

  const columns = useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: "id",
        headerName: "ID",
        type: "number",
        width: 300,
        editable: false,
        hide: true,
      },
      {
        field: "pay_component",
        headerName: "Pay Component",
        type: "singleSelect",
        width: 160,
        editable: false,
        valueGetter: (params: GridCellParams) => {
          return params.row.pay_component ? params.row.pay_component.name : "";
        },
      },
      {
        field: "payroll_count_unit",
        headerName: "Time Unit",
        type: "number",
        width: 120,
        editable: false,
        valueGetter: (params: GridCellParams) => {
          return params.row.pay_component.payroll_count_unit
            ? params.row.pay_component.payroll_count_unit.name
            : "";
        },
        hide: false,
      },
      {
        field: "pay_count",
        headerName: "Pay Count",
        type: "number",
        width: 100,
        editable: true,
        valueGetter: ({ value }) => {
          const modifiedValue = value / 100;
          return modifiedValue;
        },
        valueSetter: (params: GridValueSetterParams) => {
          const modifiedValue = Math.round(params.value * 100);
          return { ...params.row, pay_count: modifiedValue };
        },
        renderEditCell: (params) => (
          <GridEditInputCell
            {...params}
            inputProps={{
              min: 0,
            }}
          />
        ),
      },
      {
        field: "pay_rate",
        headerName: "Pay Rate",
        type: "number",
        width: 100,
        editable: true,
        valueFormatter: ({ value }) =>
          formatValuesCurrency.format(Number(value / 100)),
        renderEditCell: (params) => (
          <GridEditInputCell
            {...params}
            inputProps={{
              min: 0,
            }}
          />
        ),
      },
      {
        field: "pay_amount",
        headerName: "Pay Amount",
        type: "number",
        width: 120,
        editable: false,
        valueFormatter: ({ value }) =>
          formatValuesCurrency.format(Number(value / 100)),
        cellClassName: "datagrid-pay-components-pay-rate",
      },
      {
        field: "date_time_created",
        headerName: "Created",
        type: "dateTime",
        width: 180,
        editable: false,
        hide: true,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 80,
        cellClassName: "actions",
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              icon={<IconDelete />}
              label="Delete"
              onClick={() => handleDeleteClick(params.id)}
              color="inherit"
              disabled
            />,
          ];
        },
      },
    ],
    [handleDeleteClick]
  );

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <Box
        sx={{
          height: 300,
          width: "100%",
          "& .datagrid-pay-components-pay-rate": {
            fontWeight: "600",
          },
        }}
      >
        <div style={{ height: 400, width: "100%" }}>
          <DataGridPro
            apiRef={apiRef}
            rows={rows || []}
            columns={columns}
            autoHeight={true}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            pagination={true}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            experimentalFeatures={{ newEditingApi: true }}
            disableSelectionOnClick={false}
            initialState={{
              sorting: {
                sortModel: [{ field: "date_time_created", sort: "asc" }],
              },
              pinnedColumns: {
                left: ["pay_component"],
                right: ["pay_amount", "actions"],
              },
            }}
            sx={{ backgroundColor: "white", marginBottom: "0.5rem" }}
          />

          <AddPayComponent />

          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
          )}
        </div>
      </Box>
    </>
  );
};

export default DataGridPayComponents;
