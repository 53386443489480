import { FC } from "react";

import Container from "@mui/material/Container/Container";
import Box from "@mui/material/Box/Box";

import TitleSection from "../TitleSection";
import ModuleAddEmployee from "./ModuleAddEmployee";
import ModuleEmployeesInformation from "./ModuleEmployeesInformation";

const SectionEmployeesOverview: FC = () => {

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          width: "100%",
          paddingTop: "1.333rem",
          paddingBottom: "2.667rem",
        }}
      >
        <TitleSection title="Employees Overview" />

        <Box sx={{
          display: "grid",
          gridTemplateColumns: "3fr 1fr",
          gap: ".889rem",
          maxWidth: "57rem"
        }}>

          <ModuleEmployeesInformation />

          <ModuleAddEmployee />

        </Box>
        
      </Container>
    </>
  );
};

export default SectionEmployeesOverview;
  