import { FC, useContext, useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  AdditionalInformation,
  SchemaAdditionalInformation,
} from "./ValidationAdditionalInformation";
import { CurrentEmployeeContext } from "../DashboardEmployee";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import HeaderForm from "../HeaderForm";
import SectionMaritalStatus from "./sections/SectionMaritalStatus";
import SectionContactInformation from "./sections/SectionContactInformation";
import { CtaSubmitAndContinue, CtaSubmit } from "../CtaButtons";
import {
  useLazyGetEmployeeQuery,
  useUpdateEmployeeMutation,
} from "../../../../features/api/apiEmployees";
import FooterForm from "../FooterForm";

interface Props {
  steps: string[];
  activeStep: number;
  handleStepperNext: () => void;
}

const FormAdditionalInformation: FC<Props> = ({
  steps,
  activeStep,
  handleStepperNext,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<AdditionalInformation>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaAdditionalInformation),
    defaultValues: {
      marital_status: "",
      email_address: "",
      phone_number: "",
    },
  });

  const isEdit = useMatch("/employees/edit");
  const { employeeId } = useContext(CurrentEmployeeContext);
  const [triggerLazyGetEmployee] = useLazyGetEmployeeQuery();

  useEffect(() => {
    isEdit &&
      employeeId &&
      triggerLazyGetEmployee(employeeId).then((res) => {
        const { marital_status, email_address, phone_number } = res.data || {};

        reset({
          marital_status: marital_status || "",
          email_address: email_address || "",
          phone_number: phone_number || "",
        });
      });
  }, [isEdit, employeeId, triggerLazyGetEmployee, reset]);

  const [updateEmployee] = useUpdateEmployeeMutation();

  const onSubmit = (data: AdditionalInformation) => {
    updateEmployee({ id: employeeId, ...data })
      .unwrap()
      .then(() => {
        setSnackbar({
          children: `Employee record updated`,
          severity: "success",
        });
      })
      .then(() => {
        !isEdit && handleStepperNext();
      })
      .catch((error) => {
        setSnackbar({
          children: `${error.data.message}`,
          severity: "error",
        });
        console.error(
          `Could not update Employee record: ${JSON.stringify(error.data)}`
        );
      });
  };

  const onError = (errors: object) => {
    Object.values(errors).forEach((item) =>
      setSnackbar({
        children: `${item.message}`,
        severity: "error",
      })
    );
  };

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <HeaderForm title={steps[activeStep]}>
          {isEdit ? <CtaSubmit /> : <CtaSubmitAndContinue />}
        </HeaderForm>

        <Grid item xs={8} sx={{ marginBottom: "2rem" }}>
          <Box>
            <SectionMaritalStatus control={control} errors={errors} />

            <SectionContactInformation control={control} errors={errors} />
          </Box>
        </Grid>

        <FooterForm>
          {isEdit ? <CtaSubmit /> : <CtaSubmitAndContinue />}
        </FooterForm>
      </form>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

export default FormAdditionalInformation;
