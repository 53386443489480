import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { useAppSelector } from "../../../../app/hooks";

import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
  GridRowParams,
  GridValidRowModel,
  GridValueGetterParams,
  // GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconActivate from "@mui/icons-material/PowerSettingsNew";

import CtaEmployeeAdd from "../../../employees/dashboard/CtaEmployeeAdd";
// import AlertDataMissing from "../AlertDataMissing";
import RenderCellConditionalStatus from "../../../../utils/RenderCellConditionalStatus";

import {
  useGetAllEmployeeIdsByCompanyIdByTaxYearWithPayrollCountQuery,
  useGetAllEmployeesByCompanyIdQuery,
  useUpdateEmployeeMutation,
} from "../../../../features/api/apiEmployees";
import { Employee } from "../../../../types/Employee";
import { PayrollSchedule } from "../../../../types/PayrollSchedule";

import { useGetAllPayrollSchedulesByCompanyIdQuery } from "../../../../features/api/apiPayrollSchedules";
import { useGetAllPayComponentsByCompanyIdQuery } from "../../../../features/api/apiPayComponents";

const DataGridEmployees: FC = () => {
  const navigate = useNavigate();

  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const currentTaxYearId = useAppSelector(
    (state: RootState) => state.currentTaxYear.id
  );

  const { data: dataPayrollSchedulesByCompanyId } =
    useGetAllPayrollSchedulesByCompanyIdQuery(currentCompanyId);
  const { data: dataPayComponentsByCompanyId } =
    useGetAllPayComponentsByCompanyIdQuery(currentCompanyId);

  const [rows, setRows] = useState<Partial<Employee>[] | undefined>();

  const [pageSize, setPageSize] = useState<number>(20);

  const { data: dataGetAllEmployeesByCompanyId } =
    useGetAllEmployeesByCompanyIdQuery(currentCompanyId);

  const { data: dataGetAllEmployeesByCompanyIdPayrollsCount } =
    useGetAllEmployeeIdsByCompanyIdByTaxYearWithPayrollCountQuery({
      companyId: currentCompanyId!,
      taxYearId: Number(currentTaxYearId!),
    });

  const columns = useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: "id",
        headerName: "ID",
        type: "string",
        width: 80,
        editable: false,
        hide: true,
      },
      {
        field: "employee_number",
        headerName: "No.",
        type: "string",
        width: 80,
        editable: false,
        hide: false,
      },
      {
        field: "forenames",
        headerName: "Forenames",
        type: "string",
        width: 130,
        editable: false,
        hide: false,
      },
      {
        field: "surname",
        headerName: "Surname",
        type: "string",
        width: 130,
        editable: false,
        hide: false,
      },
      {
        field: "ni_number",
        headerName: "NI No.",
        type: "string",
        width: 130,
        editable: false,
        hide: false,
      },
      {
        field: "tax_reference_number",
        headerName: "Tax Ref. No.",
        type: "string",
        width: 130,
        editable: false,
        hide: false,
      },
      {
        field: "tax_code",
        headerName: "Tax Code",
        type: "string",
        width: 80,
        editable: false,
        hide: false,
      },
      {
        field: "employment_type",
        headerName: "Employment",
        type: "string",
        width: 130,
        editable: false,
        hide: false,
      },
      {
        field: "payroll_schedule",
        headerName: "Pay Schedule",
        type: "string",
        width: 130,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.payroll_schedule && params.row.payroll_schedule?.name,
      },
      {
        field: "job_title",
        headerName: "Job Title",
        type: "string",
        width: 130,
        editable: false,
        hide: false,
      },
      {
        field: "total_payrolls",
        headerName: "Payrolls",
        type: "number",
        width: 80,
        editable: false,
        hide: false,
        align: "center",
      },
      {
        field: "status",
        headerName: "Status",
        type: "string",
        width: 90,
        editable: false,
        hide: false,
        renderCell: (params: GridRenderCellParams<string>) => (
          <RenderCellConditionalStatus status={params.row.status} />
        ),
      },
    ],
    []
  );

  useEffect(() => {
    const extendedEmployees =
      dataGetAllEmployeesByCompanyId &&
      dataGetAllEmployeesByCompanyIdPayrollsCount &&
      dataGetAllEmployeesByCompanyId.map((employee) => {
        const payroll_count = dataGetAllEmployeesByCompanyIdPayrollsCount.find(
          (payrollCount) => employee.id === payrollCount.id
        );
        const payrolls_total_count =
          payroll_count?.payroll_schedule?.payrolls_total_count;
        return { ...employee, payrolls_total_count };
      });

    const employees =
      extendedEmployees &&
      extendedEmployees
        // ?.filter(
        //   (item) =>
        //     item.status === "ACTIVE" ||
        //     item.status === "DRAFT" ||
        //     item.status === "PROVISIONED"
        // )
        .map((item: Partial<Employee>) => {
          return {
            id: item.id || "",
            employee_number: item.employee_number || "",
            forenames: item.forenames || "",
            surname: item.surname || "",
            ni_number: item.ni_number || "",
            tax_reference_number: item.tax_reference_number || "",
            tax_code: item.tax_code || "",
            employment_type: item.employment_type,
            payroll_schedule:
              item.payroll_schedule || ("" as unknown as PayrollSchedule),
            job_title: item.job_title || "",
            status: item.status || "",
            total_payrolls: item.payrolls_total_count,
          };
        });
    setRows(employees);
  }, [
    dataGetAllEmployeesByCompanyId,
    dataGetAllEmployeesByCompanyIdPayrollsCount,
  ]);

  // TODO(DBB) : Consistant dynamic rendering
  // if (isLoadingGetAllEmployeesByCompanyId)
  //   return <div>Loading Employees...</div>;
  // if (isFetchingGetAllEmployeesByCompanyId)
  //   return <div>Fetching Employees...</div>;
  // if (isErrorGetAllEmployeesByCompanyId) return <div>Error Employees!</div>;
  // if (!dataGetAllEmployeesByCompanyId)
  //   return <div>No Employees data to display...</div>;

  return (
    <>
      {dataGetAllEmployeesByCompanyId?.length === 0 ? (
        <>
          <Typography component="h3" variant="h4" gutterBottom>
            You have no Employees.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
            Create your first employee in order to manage payroll for them.
          </Typography>
          <CtaEmployeeAdd
            disabled={
              dataPayrollSchedulesByCompanyId?.length === 0 ||
              dataPayComponentsByCompanyId?.length === 0
            }
          />
        </>
      ) : (
        <div className="w-100">
          <DataGridPro
            rows={rows || []}
            columns={columns}
            autoHeight={true}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50]}
            pagination
            initialState={{
              pinnedColumns: {
                // left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
                right: ["total_payrolls", "status"],
              },
              sorting: {
                sortModel: [{ field: "status", sort: "asc" }],
              },
            }}
            sx={{ backgroundColor: "white" }}
          />
        </div>
      )}
    </>
  );
};

export default DataGridEmployees;
