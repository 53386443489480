import { FC } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { RootState } from "../../../../../app/store";
import { Control, Controller, FieldError } from "react-hook-form";
import { EmployeeRatesOfPay } from "../ValidationRatesOfPay";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import FormDivider from "../../FormDivider";

import HeaderSection from "../../HeaderSection";

import AlertDataMissing from "../../../AlertDataMissing";
import InputMaskCurrencyGBP from "../../../../../utils/InputMaskCurrencyGBP";

import { useGetAllPayComponentsByCompanyIdQuery } from "../../../../../features/api/apiPayComponents";

interface SelectItem {
  id: string | number;
  name: string;
}

interface Props {
  control: Control<EmployeeRatesOfPay, any>;
  errors: {
    pay_component?: FieldError | undefined;
    pay_rate?: FieldError | undefined;
    pay_count?: FieldError | undefined;
    effective_date_start?: FieldError | undefined;
  };
}

const SectionRateOfPay: FC<Props> = ({ control, errors }) => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const { data: dataPayComponentsByCompanyId } =
    useGetAllPayComponentsByCompanyIdQuery(currentCompanyId);

  return (
    <>
      <Box>
        <HeaderSection title="Employee Rates of Pay" />

        {/* Text : Instruction */}
        <Typography>
          Complete all fields and select “Add Pay Rate” to add to the table
          below.
        </Typography>

        <FormDivider mt={4} mb={4} />

        {/* Input : Pay Components  */}
        {dataPayComponentsByCompanyId?.length === 0 ? (
          <AlertDataMissing
            entityName="Pay Component"
            linkAdd="/payrolls/pay-components"
            sx={{ marginBottom: "2rem" }}
          />
        ) : (
          dataPayComponentsByCompanyId && (
            <FormControl sx={{ marginBottom: "2rem" }} fullWidth={true}>
              <Controller
                control={control}
                name="pay_component"
                render={({ field }) => (
                  <>
                    <InputLabel id="label-pay-component">
                      Pay Component
                    </InputLabel>

                    <Select
                      {...field}
                      value={field.value || ""}
                      label="Pay Component"
                      labelId="label-pay-component"
                      error={!!errors.pay_component}
                      disabled={false}
                    >
                      {dataPayComponentsByCompanyId?.map((item: SelectItem) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                )}
              />
              <FormHelperText error={true}>
                {errors.pay_component?.message}
              </FormHelperText>
            </FormControl>
          )
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              flex: "0 1 8rem",
              paddingRight: "1rem",
              marginRight: "1rem",
              borderRight: "1px solid rgba(47, 48, 51, 0.16)",
            }}
          >
            {/* Input : Pay Rate */}
            <Typography sx={{ paddingBottom: "0.5rem" }}>
              Enter pay rate:
            </Typography>

            <Controller
              control={control}
              name="pay_rate"
              render={({ field: { name, value, onChange, onBlur } }) => (
                <TextField
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label="Pay Rate"
                  sx={{ marginBottom: "2rem", width: "10rem" }}
                  variant="outlined"
                  error={!!errors.pay_rate}
                  helperText={errors.pay_rate?.message}
                  fullWidth={true}
                  disabled={false}
                  InputProps={{
                    inputComponent: InputMaskCurrencyGBP as any,
                  }}
                  inputProps={{
                    min: 0,
                  }}
                />
              )}
            />
          </Box>

          <Box
            sx={{
              flex: "1 1 auto",
            }}
          >
            {/*  Input : Pay Count */}
            <Typography sx={{ paddingBottom: "0.5rem" }}>
              Enter pay count:
            </Typography>

            <Controller
              control={control}
              name="pay_count"
              render={({ field: { value, ...rest } }) => (
                <TextField
                  {...rest}
                  value={value}
                  label="Pay Count"
                  type="number"
                  variant="outlined"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    min: 0,
                  }}
                  sx={{ marginBottom: "2rem", width: "6rem" }}
                  error={!!errors.pay_count}
                  helperText={errors.pay_count?.message}
                  fullWidth={true}
                  disabled={false}
                />
              )}
            />
          </Box>
        </Box>

        {/*  Input : Effective Date Start */}
        <Box>
          <FormControl sx={{ marginBottom: "2rem" }} fullWidth={true}>
            <Typography sx={{ paddingBottom: "0.5rem" }}>
              Effective from date:
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                name="effective_date_start"
                control={control}
                render={({ field: { name, ref, onBlur, ...rest } }) => (
                  <DatePicker
                    {...rest}
                    inputRef={ref}
                    label="Effective Start Date"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={name}
                        error={!!errors.effective_date_start}
                        fullWidth={false}
                        sx={{ maxWidth: "18rem" }}
                      />
                    )}
                    inputFormat="dd-MM-yyyy"
                    minDate={new Date("01-01-1950")}
                    // maxDate={new Date()}
                    disabled={false}
                  />
                )}
              />
              <FormHelperText error={true}>
                {errors.effective_date_start?.message}
              </FormHelperText>
            </LocalizationProvider>
          </FormControl>
        </Box>

        {/*  TODO(DBB) : Input : Effective Date End */}
      </Box>
    </>
  );
};

export default SectionRateOfPay;
