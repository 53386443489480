import { cloudPayrollApi } from "./cloudPayrollApi";

// TODO(DBB) : IMPORTANT : API and frontend need refactoring

interface TaxFormT21Response {
  employeeDetails: {
    employeeTaxRefNo: string;
    employeeNiNo: string;
    employeeTitle: string;
    employeeSurname: string;
    employeeForenames: string;
    employeeAddress: string;
    employeePostCode: string;
    employeeSpouseForenames: string;
    employeeMaidenName: string;
    employeeMarriageDate: string;
    employeeCessationDate: string;
    employeeCessationCode: string;
    employeeNonResident: false;
    employerName: string;
    employerTaxRefNo: string;
    totalEmployeeGrossPay: string;
    totalEmployeeSuperannuation: string;
    totalEmployeeTaxDeductions: string;
  };
}

const apiTaxFormT21 = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    getT21Response: builder.query<TaxFormT21Response, number>({
      query: (employeeId) => `/pdf/generateT21data/${employeeId}`,
      // TODO(DBB) : Can't cache this data for reuse...
      // providesTags: ["TaxYear"],
    }),

    // READ Single Template
    // getTemplate: builder.query<Template, number>({
    //   query: (id) => `/templates/${id}`,
    //   providesTags: ["TaxYear"],
    // }),
  }),
  overrideExisting: false,
});

export const { useGetT21ResponseQuery } = apiTaxFormT21;
