import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";

import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import IconPeople from "@mui/icons-material/PeopleAlt";
import Button from "@mui/material/Button/Button";

import { useLazyGetAllEmployeesByCompanyIdQuery } from "../../../features/api/apiEmployees";

const ModuleEmployeesInformation: FC = () => {
  const navigate = useNavigate();

  const currentCompanyId = useAppSelector((state) => state.currentCompany.id);

  const [totalActiveEmployees, setTotalActiveEmployees] = useState<
    number | undefined
  >();
  const [totalDraftEmployees, setTotalDraftEmployees] = useState<
    number | undefined
  >();
  const [totalPensionOnlyEmployees, setTotalPensionOnlyEmployees] = useState<
    number | undefined
  >();

  const [
    triggerLazyGetAllEmployeesByCompanyId,
    resultLazyGetAllEmployeesByCompanyId,
  ] = useLazyGetAllEmployeesByCompanyIdQuery();

  useEffect(() => {
    currentCompanyId &&
      triggerLazyGetAllEmployeesByCompanyId(currentCompanyId).unwrap();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyId]);

  useEffect(() => {
    const activeEmployees = resultLazyGetAllEmployeesByCompanyId?.data?.filter(
      (item) => item.status === "ACTIVE"
    );
    const draftEmployees = resultLazyGetAllEmployeesByCompanyId?.data?.filter(
      (item) => item.status === "DRAFT"
    );
    const pensionOnlyEmployees = activeEmployees?.filter(
      (item) => item.pension_only
    );

    activeEmployees && setTotalActiveEmployees(activeEmployees.length);
    draftEmployees && setTotalDraftEmployees(draftEmployees.length);
    pensionOnlyEmployees &&
      setTotalPensionOnlyEmployees(pensionOnlyEmployees.length);
  }, [resultLazyGetAllEmployeesByCompanyId]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          padding: "1.111rem",
          border: "1px solid #E7E6E9",
          borderRadius: ".444rem",
          display: "grid",
          gap: "1.778rem",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr max-content 1fr max-content 1fr",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "max-content 1fr",
              gap: "1rem",
              fontSize: "1.667rem",
            }}
          >
            <IconPeople
              sx={{ color: "rgba(47, 48, 51, 0.54)", marginBlock: ".333rem" }}
              fontSize="inherit"
            />
            <Typography component="p">
              <Typography
                component="strong"
                variant="h4"
                sx={{ paddingBottom: ".222rem", display: "block" }}
              >
                {totalActiveEmployees}
              </Typography>
              <Typography component="span" variant="body1">
                active {totalActiveEmployees === 1 ? "employee" : "employees"}
              </Typography>
            </Typography>
          </Box>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#E7E6E9" }}
          />

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "max-content 1fr",
              gap: "1rem",
              fontSize: "1.667rem",
            }}
          >
            <IconPeople
              sx={{ color: "rgba(47, 48, 51, 0.54)", marginBlock: ".333rem" }}
              fontSize="inherit"
            />

            <Typography component="p">
              <Typography
                component="strong"
                variant="h4"
                sx={{ paddingBottom: ".222rem", display: "block" }}
              >
                {totalDraftEmployees}
              </Typography>
              <Typography component="span" variant="body1">
                draft {totalDraftEmployees === 1 ? "employee" : "employees"}
              </Typography>
            </Typography>
          </Box>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#E7E6E9" }}
          />

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "max-content 1fr",
              gap: "1rem",
              fontSize: "1.667rem",
            }}
          >
            <IconPeople
              sx={{ color: "rgba(47, 48, 51, 0.54)", marginBlock: ".333rem" }}
              fontSize="inherit"
            />

            <Typography component="p">
              <Typography
                component="strong"
                variant="h4"
                sx={{ paddingBottom: ".222rem", display: "block" }}
              >
                {totalPensionOnlyEmployees}
              </Typography>
              <Typography component="span" variant="body1">
                pension only{" "}
                {totalPensionOnlyEmployees === 1 ? "employee" : "employees"}
              </Typography>
            </Typography>
          </Box>
        </Box>

        <Button
          variant="outlined"
          color="secondary"
          sx={{ marginRight: "auto", marginTop: "auto" }}
          onClick={() => {
            navigate("/employees/all");
          }}
        >
          View and manage
        </Button>
      </Box>
    </>
  );
};

export default ModuleEmployeesInformation;
