import { FC } from "react";
import { useMatch } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stack from "@mui/material/Stack";

interface Props {
  activeStep: number;
  steps: any;
  completed: { [k: number]: boolean };
  handleStepperArrowLeftClick?: () => void;
  handleStepperArrowRightClick?: () => void;
}

const SubNavigation: FC<Props> = ({
  activeStep,
  steps,
  completed,
  handleStepperArrowLeftClick,
  handleStepperArrowRightClick,
}) => {
  const isEdit = useMatch("/employees/edit");

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="subtitle2" sx={{ textTransform: "uppercase" }}>
            Employee
          </Typography>
          <Typography variant="h6">
            {!isEdit && "Add an employee"}
            {isEdit && "Edit an employee"}
          </Typography>
        </Box>

        {isEdit && (
          <Stack direction="row" spacing={1}>
            <IconButton
              aria-label="previous"
              size="medium"
              onClick={handleStepperArrowLeftClick}
              sx={{
                border: "1px solid rgba(47, 48, 51, 0.16)",
                borderRadius: "50%",
              }}
            >
              <ArrowBackIcon />
            </IconButton>

            <IconButton
              aria-label="next"
              size="medium"
              onClick={handleStepperArrowRightClick}
              sx={{
                border: "1px solid rgba(47, 48, 51, 0.16)",
                borderRadius: "50%",
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Stack>
        )}

        <Stepper nonLinear orientation="vertical" activeStep={activeStep}>
          {steps.map((label: string, index: number) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" disabled>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </>
  );
};

export default SubNavigation;
