import { z } from "zod";

export type Scheme = z.infer<typeof SchemaScheme>;

export const SchemaScheme = z.object({
  name: z
    .string()
    .min(2, {
      message: "Scheme Name is required.",
    })
    .max(64, {
      message: "Scheme Name should not exceed 64 characters",
    }),

  scheme_type: z.number().or(z.literal("")),

  employee_contribution_rate_type: z.string(),

  employee_contribution_count: z.coerce.number().nonnegative(),

  employee_contribution_deducted_before_tax: z.boolean(),

  employee_contribution_deducted_before_ni: z.boolean(),

  employer_contribution_rate_type: z.string(),

  employer_contribution_count: z.coerce.number().nonnegative(),
});
