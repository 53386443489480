import { FC } from "react";

import Box from "@mui/material/Box";

import ModuleNextPayroll from "./ModuleNextPayroll";
import TitleSection from "../TitleSection";
import ModuleRunNewPayroll from "./ModuleRunNewPayroll";

const ModuleRunPayrolls: FC = () => {

  return (
    <>
      <Box
        maxWidth="xl"
        sx={{
          width: "100%",
          paddingBottom: "2.444rem",
        }}
      >
        <TitleSection title="Run Payrolls" />

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "3fr 1fr",
            gap: ".889rem",
            maxWidth: "57rem"
          }}>
          <ModuleNextPayroll />
          <ModuleRunNewPayroll />
        </Box>

      </Box>
    </>
  );
};

export default ModuleRunPayrolls;
