import { ChangeEvent, FC, useState } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
// import { ZodEmployee } from "../../../../../zod/ZodEmployee";
import { EssentialInformation } from "../ValidateEssentialInformation";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

import HeaderSection from "../../HeaderSection";
import FormDivider from "../../FormDivider";

interface RadioItem {
  id: string | number;
  label: string;
  value: string | number | boolean;
}

interface Props {
  control: Control<EssentialInformation, any>;
  errors: {
    tax_reference_number?: FieldError | undefined;
    tax_code?: FieldError | undefined;
    resident_status?: FieldError | undefined;
  };
}

const SectionTaxInformation: FC<Props> = ({ control, errors }) => {

  return (
    <>
      <Box>
        <HeaderSection title="Tax Information" />

        {/* Input : Tax Reference Number */}
        <Controller
          control={control}
          name="tax_reference_number"
          render={({ field }) => (
            <TextField
              {...field}
              label="Tax Reference Number"
              sx={{ marginBottom: "0.25rem", width: "16rem" }}
              variant="outlined"
              error={!!errors.tax_reference_number}
              helperText={errors.tax_reference_number?.message}
              fullWidth={true}
              disabled={false}
            />
          )}
        />

        <FormDivider mt={4} mb={4} />

        <Typography component="h3" variant="h6" gutterBottom>
          Tax Code
        </Typography>

        {/* Input : Tax Code */}
        {/* TODO(DBB) : Revisit tax_reference_missing logic */}
        {/* <Controller
          control={control}
          name="tax_code"
          render={({ field }) => (
            <TextField
              {...field}
              // value={taxRefMissing ? "" : field.value}
              // value={String(field.value)}
              label="Tax Code"
              sx={{ marginBottom: "1rem", width: "12em" }}
              variant="outlined"
              error={!!errors.tax_code}
              helperText={errors.tax_code?.message}
              fullWidth={false}
              // disabled={taxRefMissing}
              disabled={false}
            />
          )}
        /> */}

        <Controller
          control={control}
          name="tax_code"
          render={({ field }) => (
            <TextField
              {...field}
              label="Tax Code"
              sx={{ marginBottom: "2rem", width: "12rem" }}
              variant="outlined"
              error={!!errors.tax_code}
              helperText={errors.tax_code?.message}
              fullWidth={true}
              disabled={false}
            />
          )}
        />

        <FormDivider mt={4} mb={4} />

        <Typography component="h3" variant="h6" gutterBottom>
          Resident Status
        </Typography>

        {/*  Input : Resident Status */}
        <Controller
          control={control}
          name="resident_status"
          render={({ field }) => (
            <FormControl>
              <RadioGroup aria-label="Resident Status" {...field}>
                {residentStatuses?.map((item: RadioItem) => {
                  return (
                    <FormControlLabel
                      control={<Radio />}
                      key={item.id}
                      value={item.value}
                      label={item.label}
                      disabled={false}
                    />
                  );
                })}
              </RadioGroup>
              <FormHelperText error={true}>
                {errors.resident_status?.message}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Box>

      <FormDivider mt={4} mb={4} />
    </>
  );
};

const residentStatuses = [
  {
    id: 1,
    label: "Isle of Man Resident",
    value: "Isle of Man Resident",
  },
  {
    id: 2,
    label: "Non-resident",
    value: "Non-resident",
  },
];

export default SectionTaxInformation;
