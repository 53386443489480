import { cloudPayrollApi } from "./cloudPayrollApi";
import { PasswordResetEmail } from "../../pages/reset/ValidatePasswordResetEmail";
import { PasswordResetPassword } from "../../pages/reset/ValidatePasswordResetPassword";

const apiPasswordReset = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // SUBMIT Reset Email Address
    resetPasswordRequest: builder.mutation<boolean | void, PasswordResetEmail>({
      query: (body) => ({
        url: `/auth/password-reset-request`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["PasswordReset"],
    }),

    // SUBMIT Reset Password New Password
    resetPasswordSubmit: builder.mutation<
      boolean | void,
      PasswordResetPassword
    >({
      query: ({ reset_token, password_new }) => ({
        url: `/auth/password-reset-submit`,
        method: "POST",
        body: {
          password_reset_code: reset_token,
          new_password: password_new,
        },
      }),
      invalidatesTags: ["PasswordReset"],
    }),
  }),
});

export const {
  useResetPasswordRequestMutation,
  useResetPasswordSubmitMutation,
} = apiPasswordReset;
