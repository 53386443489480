import { FC, useContext, useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CurrentEmployeeContext } from "../DashboardEmployee";

import Grid from "@mui/material/Grid";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import HeaderForm from "../HeaderForm";
import SectionEmploymentTerms from "./sections/SectionEmploymentTerms";
import SectionEmployeeNumber from "./sections/SectionEmployeeNumber";
import SectionJobDetails from "./sections/SectionJobDetails";
import SectionWorkPermit from "./sections/SectionWorkPermit";
import { CtaSubmitAndContinue } from "../CtaButtons";
import FooterForm from "../FooterForm";

import {
  useLazyGetEmployeeQuery,
  useUpdateEmployeeMutation,
} from "../../../../features/api/apiEmployees";
import {
  EmploymentInformation,
  SchemaEmploymentInformation,
} from "./ValidationEmploymentInformation";

interface Props {
  steps: string[];
  activeStep: number;
  handleStepperNext: () => void;
}

const FormEmploymentInformation: FC<Props> = ({
  steps,
  activeStep,
  handleStepperNext,
}) => {
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<EmploymentInformation>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaEmploymentInformation),
    defaultValues: {
      pension_only: false,
      employment_type: "",
      job_date_started: null as unknown as Date,
      job_date_finished: null as unknown as Date,
      payroll_schedule: 0,
      employee_number: "",
      job_title: "",
      work_permit_required: false,
      date_work_permit_issue: null as unknown as Date,
      date_work_permit_expiry: null as unknown as Date,
      work_permit_number: "",
    },
  });

  const isEdit = useMatch("/employees/edit");
  const { employeeId } = useContext(CurrentEmployeeContext);
  const [triggerLazyGetEmployee] = useLazyGetEmployeeQuery();

  useEffect(() => {
    isEdit &&
      employeeId &&
      triggerLazyGetEmployee(employeeId).then((res) => {
        const {
          pension_only,
          employment_type,
          job_date_started,
          job_date_finished,
          payroll_schedule,
          employee_number,
          job_title,
          work_permit_required,
          date_work_permit_issue,
          date_work_permit_expiry,
          work_permit_number,
        } = res.data || {};

        reset({
          pension_only: pension_only || false,
          employment_type: employment_type || "",
          job_date_started: job_date_started as Date,
          job_date_finished: job_date_finished as Date,
          payroll_schedule: payroll_schedule?.id,
          employee_number: employee_number || "",
          job_title: job_title || "",
          work_permit_required: work_permit_required || false,
          date_work_permit_issue: date_work_permit_issue as Date,
          date_work_permit_expiry: date_work_permit_expiry as Date,
          work_permit_number: work_permit_number || "",
        });
      });
  }, [isEdit, employeeId, triggerLazyGetEmployee, reset]);

  const [updateEmployee] = useUpdateEmployeeMutation();

  const onSubmit = (data: EmploymentInformation) => {
    employeeId &&
      updateEmployee({ id: employeeId, ...data })
        .unwrap()
        .then(() => {
          setSnackbar({
            children: `Employee record updated`,
            severity: "success",
          });
        })
        .then(() => {
          handleStepperNext();
        })
        .catch((error) => {
          setSnackbar({
            children: `${error.data.message}`,
            severity: "error",
          });
          console.error(
            `Could not update Employee record: ${JSON.stringify(error.data)}`
          );
        });
  };

  const onError = (errors: object) => {
    console.error(errors);
    Object.values(errors).forEach((item) =>
      setSnackbar({
        children: `${item.message}`,
        severity: "error",
      })
    );
  };

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <HeaderForm title={steps[activeStep]}>
          <CtaSubmitAndContinue />
        </HeaderForm>

        <Grid item xs={8} sx={{ marginBottom: "2rem" }}>
          <SectionEmploymentTerms
            control={control}
            errors={errors}
            isEdit={Boolean(isEdit)}
          />

          <SectionEmployeeNumber control={control} errors={errors} />

          <SectionJobDetails control={control} errors={errors} />

          <SectionWorkPermit control={control} errors={errors} />
        </Grid>

        <FooterForm>
          <CtaSubmitAndContinue />
        </FooterForm>
      </form>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

export default FormEmploymentInformation;
