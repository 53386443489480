import { FC, useContext } from "react";
import { CurrentSchemeContext } from "../DashboardSchemes";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button/Button";
import Divider from "@mui/material/Divider";

import HeaderDialog from "../../../../components/common/dialog/HeaderDialog";
import { useUpdateSchemeMutation } from "../../../../features/api/apiSchemes";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";

interface Props {
  modalArchiveOpen: boolean;
  handleModalArchiveClose: () => void;
}

const DialogSchemeArchive: FC<Props> = ({
  modalArchiveOpen,
  handleModalArchiveClose,
}) => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const { schemeId } = useContext(CurrentSchemeContext);
  const [updateScheme] = useUpdateSchemeMutation();

  const handleArchive = (data: any) => {
    const submission = {
      id: schemeId,
      status: "ARCHIVED",
    };

    currentCompanyId &&
      schemeId &&
      updateScheme(submission)
        .then((res) => {
          // console.log(res);
        })
        .then(() => {
          handleModalArchiveClose();
        });
  };

  return (
    <>
      <Dialog
        open={modalArchiveOpen}
        onClose={handleModalArchiveClose}
        maxWidth="sm"
        fullWidth={true}
      >
        <HeaderDialog
          handleModalClose={handleModalArchiveClose}
          title="Archive Scheme"
        />

        <DialogContent>
          <Stack direction="column" gap={2}>
            <Typography>
              Are you sure you want to archive this Scheme?
            </Typography>

            <Divider />

            <Stack direction="row" gap={2}>
              <Button
                onClick={handleArchive}
                variant="contained"
                color="error"
                size="medium"
                disabled={false}
                disableElevation={true}
              >
                Archive
              </Button>

              <Button
                onClick={handleModalArchiveClose}
                variant="outlined"
                color="secondary"
                size="medium"
                disabled={false}
                disableElevation={true}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogSchemeArchive;
