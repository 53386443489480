import { cloudPayrollApi } from "./cloudPayrollApi";

import { EmployeePayRate } from "../../types/EmployeePayRate";

// TODO(DBB) : Confirm if we're replacing all base types with Zod schemas
import { EmployeeRatesOfPay } from "../../pages/employees/addEdit/03-rates-of-pay/ValidationRatesOfPay";

const apiEmployeePayRates = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // CREATE Employee Pay Rate
    // createEmployeePayRate: builder.mutation<void, Partial<EmployeePayRate>>({
    //   query: (employeePayRate) => ({
    //     url: `/employee-pay-rates`,
    //     method: "POST",
    //     body: employeePayRate,
    //   }),
    //   invalidatesTags: ["EmployeePayRate"],
    // }),
    createEmployeePayRate: builder.mutation<void, Partial<EmployeeRatesOfPay>>({
      query: (employeePayRate) => ({
        url: `/employee-pay-rates`,
        method: "POST",
        body: employeePayRate,
      }),
      invalidatesTags: ["EmployeePayRate"],
    }),

    // READ All Employee Pay Rates
    getAllEmployeePayRates: builder.query<EmployeePayRate[], number | void>({
      query: (limit = 10) => `/employee-pay-rates?limit=${limit}`,
      providesTags: ["EmployeePayRate"],
    }),

    // READ All Employee Pay Rates by Employee ID
    getAllEmployeePayRatesByEmployeeId: builder.query<
      EmployeePayRate[],
      number | undefined
    >({
      query: (employeeId) => `/employee-pay-rates/employee/${employeeId}`,
      providesTags: ["EmployeePayRate"],
    }),

    // READ Single Employee Pay Rate
    getEmployeePayRate: builder.query<EmployeePayRate, number>({
      query: (id) => `/employee-pay-rates/${id}`,
      providesTags: ["EmployeePayRate"],
    }),

    // UPDATE Employee Pay Rate
    updateEmployeePayRate: builder.mutation<void, Partial<EmployeePayRate>>({
      query: ({ id, ...rest }) => ({
        url: `/employee-pay-rates/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["EmployeePayRate"],
    }),

    // DELETE Employee Pay Rate
    deleteEmployeePayRate: builder.mutation<void, number>({
      query: (id) => ({
        url: `/employee-pay-rates/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EmployeePayRate"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateEmployeePayRateMutation,
  useGetAllEmployeePayRatesQuery,
  useGetAllEmployeePayRatesByEmployeeIdQuery,
  useLazyGetAllEmployeePayRatesByEmployeeIdQuery,
  useGetEmployeePayRateQuery,
  useUpdateEmployeePayRateMutation,
  useDeleteEmployeePayRateMutation,
} = apiEmployeePayRates;
