import { FC } from "react";
import { ReactComponent as LogoCloudPayroll } from "../../../assets/svg/logo-cloudpayroll-glyph.svg";

const Logo: FC = () => {
  return (
    <>
      <LogoCloudPayroll />
    </>
  );
};

export default Logo;
