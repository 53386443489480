import { FC } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import { useGetAllTaxYearsQuery } from "../../../features/api/apiTaxYears";
import { TaxYear } from "../../../types/TaxYear";
import { setCurrentTaxYear } from "../../../features/state/taxYear/taxYearSlice";

interface SelectItem {
  id: string | number;
  name: string;
}

const SelectTaxYear: FC = () => {
  const dispatch = useAppDispatch();

  const currentTaxYearId = useAppSelector((state) => state.currentTaxYear.id);

  const { data: dataGetAllTaxYears } = useGetAllTaxYearsQuery();

  const reverseDataGetAllTaxYears = dataGetAllTaxYears
    ?.map((item: TaxYear) => item)
    .reverse();

  const handleSelectChange = (event: SelectChangeEvent) => {
    const value = Number(event.target.value);

    dataGetAllTaxYears &&
      dispatch(setCurrentTaxYear(dataGetAllTaxYears[value - 1]));
  };

  return (
    <>
      {dataGetAllTaxYears && (
        <FormControl fullWidth={true}>
          <InputLabel id="label-tax-year">Tax Year</InputLabel>
          <Select
            label="Tax Year"
            labelId="label-tax-year"
            name="tax_year"
            value={currentTaxYearId}
            onChange={handleSelectChange}
            disabled={false}
          >
            {reverseDataGetAllTaxYears?.map((item: SelectItem) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default SelectTaxYear;
