import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";

import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
  GridRowParams,
  GridValidRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import TableRowsIcon from "@mui/icons-material/TableRows";

import ModalActionPayrollUnlock from "./ModalActionPayrollUnlock";
import IconLockOpen from "@mui/icons-material/LockOpen";

import { useGetAllPayrollsByCompanyIdQuery } from "../../../features/api/apiPayrolls";
import { Payroll } from "../../../types/Payroll";
import { PayrollEntry } from "../../../types/PayrollEntry";
import {
  displayColumnCurrencyGBP,
  displayColumnDate,
} from "../../../utils/datagridFormatters";

const DataGridPayrollsComplete: FC = () => {
  const navigate = useNavigate();

  const currentCompanyId = useAppSelector((state) => state.currentCompany.id);

  const [rows, setRows] = useState<Partial<Payroll>[] | undefined>();

  const [pageSize, setPageSize] = useState<number>(10);

  const [selectedPayrollId, setSeletedPayrollId] = useState<string>("");

  const [modalUnlockOpen, setModalUnlockOpen] = useState<boolean>(false);

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const { data: dataGetAllPayrollsByCompanyId } =
    useGetAllPayrollsByCompanyIdQuery(currentCompanyId);

  const handlePayrollUnlockClick = useCallback((id: GridRowId) => {
    setSeletedPayrollId(id as string);
    setModalUnlockOpen(true);
  }, []);

  const handleCloseSnackbar = () => setSnackbar(null);

  const columns = useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: "pay_date_numeric",
        headerName: "Pay Date",
        type: "date",
        width: 160,
        editable: false,
        hide: true,
      },
      {
        field: "pay_date_actual",
        headerName: "Payroll Run Date",
        type: "date",
        width: 160,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.pay_date_actual
            ? displayColumnDate(params.row.pay_date_actual)
            : "",
      },
      {
        field: "pay_date_scheduled",
        headerName: "Scheduled Run Date",
        type: "date",
        width: 160,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.pay_date_scheduled
            ? displayColumnDate(params.row.pay_date_scheduled)
            : "",
      },
      {
        field: "pay_schedule",
        headerName: "Pay Schedule",
        type: "string",
        width: 120,
        editable: false,
        hide: false,
      },
      {
        field: "pay_period",
        headerName: "Period",
        type: "number",
        width: 60,
        editable: false,
        hide: false,
      },
      {
        field: "tax_year",
        headerName: "Tax Year",
        type: "string",
        width: 100,
        editable: false,
        hide: false,
        align: "right",
      },
      {
        field: "total_cost",
        headerName: "Total",
        type: "number",
        width: 100,
        editable: false,
        hide: false,
        valueFormatter: ({ value }) => displayColumnCurrencyGBP(Number(value)),
      },
      {
        field: "status",
        headerName: "Status",
        type: "string",
        width: 120,
        editable: false,
        hide: false,
      },
      {
        field: "id",
        headerName: "ID",
        type: "string",
        width: 300,
        editable: false,
        hide: true,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 80,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<TableRowsIcon />}
            label="View Details"
            onClick={() =>
              navigate("/payrolls/history/payroll-record", {
                state: {
                  payrollId: params.id,
                  totalCost: params.row.total_cost,
                },
              })
            }
            showInMenu
            disabled={false}
          />,
          <GridActionsCellItem
            icon={<IconLockOpen />}
            label="Unlock to Edit"
            onClick={() => handlePayrollUnlockClick(params.id)}
            showInMenu={true}
            disabled={false}
          />,
          // TODO(DBB): Logic, with advisory ModalPayrollUnlock
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Archive"
          //   // onClick={() => handleArchiveClick(params.id)}
          //   onClick={() => console.log(params.id)}
          //   showInMenu={true}
          //   disabled={false}
          // />,
        ],
      },
    ],
    [navigate, handlePayrollUnlockClick]
  );

  useEffect(() => {
    const payrolls = dataGetAllPayrollsByCompanyId
      ?.filter((item) => item.status === "COMPLETE")
      .map((item: Partial<Payroll>) => {
        const payrollEntries: PayrollEntry[] = item.payroll_entries;

        const totalPayrollCost =
          payrollEntries &&
          payrollEntries
            ?.map((item: PayrollEntry) => item.calculated_total_cost)
            .reduce((partialSum, a) => partialSum + a, 0);

        return {
          id: item.id,
          pay_date_numeric: item.pay_date_scheduled,
          pay_date_scheduled: item.pay_date_scheduled,
          pay_date_actual: item.pay_date_actual,
          pay_schedule: item.payroll_schedule.name,
          pay_period: item.pay_period,
          tax_year: item.tax_year.name,
          total_cost: totalPayrollCost,
          status: item.status,
        };
      });

    setRows(payrolls);
  }, [dataGetAllPayrollsByCompanyId]);

  return (
    <>
      {/* TODO(DBB) : Revisit this... */}
      {/* <ModalPayrollUnlock open={true} onClose={handleModalUnlockClose} /> */}

      <ModalActionPayrollUnlock
        modalUnlockOpen={modalUnlockOpen}
        setModalUnlockOpen={setModalUnlockOpen}
        selectedPayrollId={selectedPayrollId}
      />

      <Box sx={{ marginTop: "2rem", marginBottom: "1rem" }}>
        <Typography component="h1" variant="h4" gutterBottom>
          Payroll History
        </Typography>
      </Box>

      <div style={{ height: 800, width: "100%" }}>
        <DataGridPro
          rows={rows || []}
          columns={columns}
          autoHeight={true}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          pagination
          disableSelectionOnClick={true}
          initialState={{
            pinnedColumns: {
              right: ["actions"],
            },
            sorting: {
              sortModel: [{ field: "pay_date_numeric", sort: "asc" }],
            },
          }}
          sx={{ backgroundColor: "white" }}
        />

        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </div>
    </>
  );
};

export default DataGridPayrollsComplete;
