import { FC } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { RootState } from "../../../../../app/store";
import { Control, Controller, FieldError } from "react-hook-form";
import { EmployeeScheme } from "../ValidationScheme";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import FormDivider from "../../FormDivider";

import HeaderSection from "../../HeaderSection";

import AlertDataMissing from "../../../AlertDataMissing";

import { useGetAllSchemesByCompanyIdQuery } from "../../../../../features/api/apiSchemes";

interface SelectItem {
  id: string | number;
  name: string;
}

interface Props {
  control: Control<EmployeeScheme, any>;
  errors: {
    scheme?: FieldError | undefined;
    effective_date_start?: FieldError | undefined;
  };
}

const SectionScheme: FC<Props> = ({ control, errors }) => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const { data: dataSchemesByCompanyId } =
    useGetAllSchemesByCompanyIdQuery(currentCompanyId);

  return (
    <>
      <Box>
        <HeaderSection title="Employee Schemes" optional={true} />

        {/* Text : Instruction */}
        <Typography>
          Complete all fields and select “Add Scheme” to add to the table below.
        </Typography>

        <FormDivider mt={4} mb={4} />

        {/* Input : Pay Schemes  */}
        {dataSchemesByCompanyId?.length === 0 ? (
          <AlertDataMissing
            entityName="Scheme"
            linkAdd="/payrolls/schemes"
            sx={{ marginBottom: "2rem" }}
          />
        ) : (
          dataSchemesByCompanyId && (
            <>
              {/* Input : Scheme */}
              <Typography sx={{ paddingBottom: "0.5rem" }}>
                Select a scheme:
              </Typography>

              <FormControl sx={{ marginBottom: "2rem" }} fullWidth={true}>
                <Controller
                  control={control}
                  name="scheme"
                  render={({ field }) => (
                    <>
                      <InputLabel id="label-scheme">Scheme</InputLabel>

                      <Select
                        {...field}
                        value={field.value || ""}
                        label="Scheme"
                        labelId="label-scheme"
                        error={!!errors.scheme}
                        disabled={false}
                      >
                        {dataSchemesByCompanyId?.map((item: SelectItem) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </>
                  )}
                />
                <FormHelperText error={true}>
                  {errors.scheme?.message}
                </FormHelperText>
              </FormControl>
            </>
          )
        )}
      </Box>

      {/*  Input : Effective Date Start */}
      <Box>
        <FormControl sx={{ marginBottom: "2rem" }} fullWidth={true}>
          <Typography sx={{ paddingBottom: "0.5rem" }}>
            Effective from date:
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="effective_date_start"
              control={control}
              render={({ field: { name, ref, onBlur, ...rest } }) => (
                <DatePicker
                  {...rest}
                  inputRef={ref}
                  label="Effective Start Date"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={name}
                      error={!!errors.effective_date_start}
                      fullWidth={false}
                      sx={{ maxWidth: "18rem" }}
                    />
                  )}
                  inputFormat="dd-MM-yyyy"
                  minDate={new Date("01-01-1950")}
                  // maxDate={new Date()}
                  disabled={false}
                />
              )}
            />
            <FormHelperText error={true}>
              {errors.effective_date_start?.message}
            </FormHelperText>
          </LocalizationProvider>
        </FormControl>
      </Box>
    </>
  );
};

export default SectionScheme;
