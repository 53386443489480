import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LoginIcon from "@mui/icons-material/Login";

function HeaderSignUp() {
  const navigate = useNavigate();

  return (
    <AppBar
      position="static"
      color="transparent"
      className="py-4 px-6"
      sx={{
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ minHeight: "auto !important", padding: "0 !important" }}>
        <Link
          href="/"
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              "& img": {
                maxWidth: "200px",
              },
            },
            [theme.breakpoints.down("sm")]: {
              "& img": {
                maxWidth: "150px",
              },
            },
          })}
        >
          <img
            src={require("../../../assets/svg/signup-logo.svg").default}
            srcSet={require("../../../assets/svg/signup-logo.svg").default}
            alt="Cloud Payroll"
            loading="lazy"
          />
        </Link>

        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>

        <Box sx={{ flexGrow: 0 }}>
          <Button
            size="medium"
            variant="outlined"
            startIcon={<LoginIcon />}
            onClick={() => navigate("/signin")}
          >
            Sign In
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default HeaderSignUp;
