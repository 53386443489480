import { FC } from "react";

import Container from "@mui/material/Container";

import DataGridEmployees from "./DataGridEmployees";
import Typography from "@mui/material/Typography";

import SelectTaxYear from "./SelectTaxYear";
import SubHeader from "../SubHeader";
import Grid from "@mui/material/Grid";

const DashboardTaxFormT14: FC = () => {
  return (
    <>
      <SubHeader title="Forms > T14" icon="receipt" />

      <Container maxWidth="xl" sx={{ width: "100%" }}>
        <Typography
          component="h2"
          variant="h4"
          sx={{ marginTop: "3rem", marginBottom: "2rem" }}
        >
          T14 – Isle of Man ITIP and National Insurance Deduction Card
        </Typography>

        <Grid container>
          <Grid
            item
            xs={4}
            sx={{
              backgroundColor: "white",
              border: "1px solid rgba(231, 230, 233, 1)",
              borderRadius: "0.25rem",
              padding: "1.25rem 1.5rem 1rem",
              marginBottom: "1rem",
            }}
          >
            <SelectTaxYear />
          </Grid>
        </Grid>

        <DataGridEmployees />
      </Container>
    </>
  );
};

export default DashboardTaxFormT14;
