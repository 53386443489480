import { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import CtaPayrollCreate from "./CtaPayrollCreate";

interface Props {
  selectedPayroll: string;
  canProgress: boolean;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  handleStepperNext: () => void;
}

const Footer: FC<Props> = ({
  selectedPayroll,
  canProgress,
  loading,
  setLoading,
  handleStepperNext,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1rem",
      }}
    >
      <Button
        variant="outlined"
        onClick={() => {
          navigate("/payrolls/pay-schedules");
        }}
      >
        Add a new pay schedule
      </Button>

      <CtaPayrollCreate
        selectedPayroll={selectedPayroll}
        canProgress={canProgress}
        loading={loading}
        setLoading={setLoading}
        handleStepperNext={handleStepperNext}
      />
    </Box>
  );
};

export default Footer;
