import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import type { PayrollEntry } from "../../../types/PayrollEntry";

// TODO(DBB) : Define the full type properly
const initialState: Partial<PayrollEntry> = {
  id: "",
};

export const payrollEntrySlice = createSlice({
  name: "currentPayrollEntry",
  initialState,
  reducers: {
    setCurrentPayrollEntry: (
      state,
      action: PayloadAction<Partial<PayrollEntry>>
    ) => {
      Object.entries(action.payload ?? {}).forEach(([k, v]) => {
        state[k] = v;
      });
    },
    resetCurrentPayrollEntry: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setCurrentPayrollEntry, resetCurrentPayrollEntry } =
  payrollEntrySlice.actions;

export default payrollEntrySlice.reducer;
