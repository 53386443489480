import Typography from "@mui/material/Typography";
import { FC } from "react";

interface Props {
  title: string;
}

const TitleSection: FC<Props> = ({ title }) => {
  return (
    <Typography
      variant="overline"
      display="block"
      gutterBottom
      sx={{
        fontSize: "1rem",
        fontWeight: "700",
        letterSpacing: "0.15px",
        color: "rgba(47, 48, 51, 0.72)"
      }}
    >
      {title}
    </Typography>
  );
};

export default TitleSection;
