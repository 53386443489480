import { toFormat } from "dinero.js";

function intlFormat(dineroObject: any, locale: string, options = {}) {
  function transformer({
    amount,
    currency,
  }: {
    amount: number;
    currency: {
      code: string;
    };
  }) {
    return amount.toLocaleString(locale, {
      ...options,
      style: "currency",
      currency: currency.code,
    });
  }

  return toFormat(dineroObject, transformer);
}

function intlFormatAmount(amount: number, currency: string, options = {}) {
  return amount.toLocaleString("en-GB", {
    ...options,
    style: "currency",
    currency: currency,
  });
}

export { intlFormat, intlFormatAmount };
