import { FC } from "react";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

const FooterDisclaimer: FC = () => {
  return (
    <>
      <Grid item xs={12}>
        By submitting your details and clicking “Create Account”, you agree to
        Cloud Payroll’s <Link href="/terms">Terms of Service</Link> and{" "}
        <Link href="/privacy">Privacy Policy</Link>.
      </Grid>
    </>
  );
};

export default FooterDisclaimer;
