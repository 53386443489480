import { FC } from "react";

import Container from "@mui/material/Container";
import SubHeader from "../SubHeader";

const PayslipTemplates: FC = () => {
  return (
    <>
      <SubHeader title="Payslip Templates" page="payslip-template" icon="summarize"/>

      <Container maxWidth="xl" sx={{ width: "100%" }}>

      </Container>
    </>
  );
};

export default PayslipTemplates;
