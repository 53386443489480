import { FC, useState } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import { EmploymentInformation } from "../ValidationEmploymentInformation";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";

import HeaderSection from "../../HeaderSection";

interface Props {
  control: Control<EmploymentInformation, any>;
  errors: {
    work_permit_required?: FieldError | undefined;
    date_work_permit_issue?: FieldError | undefined;
    date_work_permit_expiry?: FieldError | undefined;
    work_permit_number?: FieldError | undefined;
  };
}

const SectionJobDetails: FC<Props> = ({ control, errors }) => {
  const [checkboxValue, setCheckboxValue] = useState(control._fields?.work_permit_required?._f.value)

  return (
    <>
      <Box>
        <HeaderSection title="Work Permit" optional />

        {/*  Input : Work Permit Required */}
        <Controller
          control={control}
          name="work_permit_required"
          defaultValue={false}
          render={({ field }) => (
            <>
              <FormControl sx={{ marginBottom: "1rem" }} fullWidth={true}>
                <FormControlLabel
                  label="Work permit required"
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.checked);
                        setCheckboxValue(event.target.checked);
                      }}
                      size="medium"
                      disabled={false}
                    />
                  }
                />
              </FormControl>
            </>
          )}
        />

        {/*  Input : Work Permit Issued Date */}
        <FormControl sx={{ marginBottom: "2rem" }} fullWidth={true}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="date_work_permit_issue"
              control={control}
              render={({ field: { name, ref, onBlur, ...rest } }) => (
                <DatePicker
                  {...rest}
                  inputRef={ref}
                  label="Issue Date"
                  renderInput={(inputProps) => (
                    <TextField
                      {...inputProps}
                      name={name}
                      onBlur={onBlur}
                      error={!!errors.date_work_permit_issue}
                      fullWidth={true}
                    />
                  )}
                  inputFormat="dd-MM-yyyy"
                  disabled={!checkboxValue}
                />
              )}
            />
          </LocalizationProvider>
          <FormHelperText error={true}>
            {errors.date_work_permit_issue?.message}
          </FormHelperText>
        </FormControl>

        {/*  Input : Work Permit Expiry Date */}
        <FormControl sx={{ marginBottom: "2rem" }} fullWidth={true}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="date_work_permit_expiry"
              control={control}
              render={({ field: { name, ref, onBlur, ...rest } }) => (
                <DatePicker
                  {...rest}
                  inputRef={ref}
                  label="Expiry Date"
                  renderInput={(inputProps) => (
                    <TextField
                      {...inputProps}
                      name={name}
                      onBlur={onBlur}
                      error={!!errors.date_work_permit_expiry}
                      fullWidth={true}
                    />
                  )}
                  inputFormat="dd-MM-yyyy"
                  disabled={!checkboxValue}
                />
              )}
            />
          </LocalizationProvider>
          <FormHelperText error={true}>
            {errors.date_work_permit_expiry?.message}
          </FormHelperText>
        </FormControl>

        {/*  Input : Work Permit Number */}
        <Controller
          control={control}
          name="work_permit_number"
          render={({ field }) => (
            <TextField
              {...field}
              label="Work Permit Number"
              sx={{ marginBottom: "2rem" }}
              variant="outlined"
              error={!!errors.work_permit_number}
              helperText={errors.work_permit_number?.message}
              fullWidth={true}
              disabled={!checkboxValue}
            />
          )}
        />
      </Box>
    </>
  );
};

export default SectionJobDetails;
