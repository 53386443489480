import { cloudPayrollApi } from "./cloudPayrollApi";

import { PayrollCountUnit } from "../../types/PayrollCountUnit";

const apiPayrollCountUnit = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // CREATE PayrollCountUnit
    createPayrollCountUnit: builder.mutation<void, PayrollCountUnit>({
      query: (payrollCountUnit) => ({
        url: `/payroll-count-units`,
        method: "POST",
        body: payrollCountUnit,
      }),
      invalidatesTags: ["PayrollCountUnit"],
    }),

    // READ All PayrollCountUnits
    getAllPayrollCountUnits: builder.query<PayrollCountUnit[], number | void>({
      query: (limit = 10) => `/payroll-count-units?limit=${limit}`,
      providesTags: ["PayrollCountUnit"],
    }),

    // READ Single PayrollCountUnit
    getPayrollCountUnit: builder.query<PayrollCountUnit, number>({
      query: (id) => `/payroll-count-units/${id}`,
      providesTags: ["PayrollCountUnit"],
    }),

    // UPDATE PayrollCountUnit
    updatePayrollCountUnit: builder.mutation<void, PayrollCountUnit>({
      query: ({ id, ...rest }) => ({
        url: `/payroll-count-units/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["PayrollCountUnit"],
    }),

    // DELETE PayrollCountUnit
    deletePayrollCountUnit: builder.mutation<void, number>({
      query: (id) => ({
        url: `/payroll-count-units/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PayrollCountUnit"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreatePayrollCountUnitMutation,
  useGetAllPayrollCountUnitsQuery,
  useGetPayrollCountUnitQuery,
  useUpdatePayrollCountUnitMutation,
  useDeletePayrollCountUnitMutation,
} = apiPayrollCountUnit;
