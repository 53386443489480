import { FC, useContext, useState, useMemo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { CurrentPayComponentContext } from "./DashboardPayComponents";

import {
  DataGridPro,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridColumns,
  GridRowParams,
  GridRowsProp,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";

import IconEdit from "@mui/icons-material/Edit";
import IconDelete from "@mui/icons-material/Delete";

import NoData from "../NoData";

import { useGetAllPayComponentsByCompanyIdQuery } from "../../../features/api/apiPayComponents";
import type { PayComponent } from "./addEdit/ValidationPayComponent";

interface Props {
  handleModalOpenAdd: () => void;
  handleModalOpenEdit: () => void;
}

const DataGridPayComponents: FC<Props> = ({ handleModalOpenAdd, handleModalOpenEdit }) => {
  const currentCompanyId = useAppSelector((state) => state.currentCompany.id);

  const { data: dataGetAllPayComponentsByCompanyId } =
    useGetAllPayComponentsByCompanyIdQuery(currentCompanyId);

  const { setPayComponentId } = useContext(CurrentPayComponentContext);

  const [pageSize, setPageSize] = useState<number>(10);

  const columns: (GridColDef | GridActionsColDef)[] = useMemo<
    GridColumns<GridValidRowModel>
  >(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 64,
        editable: false,
        hide: true,
      },
      {
        field: "name",
        headerName: "Name",
        type: "string",
        width: 180,
        editable: false,
        hide: false,
      },
      {
        field: "is_gross_pay",
        headerName: "Is Gross Pay",
        type: "boolean",
        width: 80,
        editable: false,
        hide: false,
      },
      {
        field: "is_liable_to_tax",
        headerName: "Is Taxable",
        type: "boolean",
        width: 80,
        editable: false,
        hide: false,
      },
      {
        field: "is_liable_to_ni",
        headerName: "Is N.I.able",
        type: "boolean",
        width: 80,
        editable: false,
        hide: false,
      },
      {
        field: "is_pensionable",
        headerName: "Is Pensionable",
        type: "boolean",
        width: 80,
        editable: false,
        hide: false,
      },
      {
        field: "date_time_created",
        headerName: "Created",
        type: "date",
        width: 100,
        editable: false,
        hide: true,
      },
      {
        field: "status",
        headerName: "Status",
        type: "string",
        width: 100,
        editable: false,
        hide: false,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 80,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<IconEdit />}
            label="Edit"
            onClick={() => {
              setPayComponentId(Number(params.id));
              handleModalOpenEdit();
            }}
            showInMenu={true}
            disabled={false}
          />,
          <GridActionsCellItem
            icon={<IconDelete />}
            label="Archive"
            onClick={() => {
              // TODO(DBB) : Archive action...
              console.log("Archive");
            }}
            showInMenu={true}
            disabled={true}
          />,
        ],
      },
    ],
    [handleModalOpenEdit, setPayComponentId]
  );

  const rows: GridRowsProp = dataGetAllPayComponentsByCompanyId
    ? dataGetAllPayComponentsByCompanyId.map((item: PayComponent) => {
        return {
          id: item.id,
          name: item.name,
          pay_component_type: item.pay_component_type,
          payroll_count_unit: item.payroll_count_unit,
          is_gross_pay: item.is_gross_pay,
          is_liable_to_tax: item.is_liable_to_tax,
          is_liable_to_ni: item.is_liable_to_ni,
          is_pensionable: item.is_pensionable,
          additional_description: item.additional_description,
          status: item.status,
        };
      })
    : [];

  return (
    <>
      {dataGetAllPayComponentsByCompanyId?.length !== 0 ? (
        <DataGridPro
          rows={rows || []}
          columns={columns}
          autoHeight={true}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          pagination
          initialState={{
            pinnedColumns: { right: ["actions"] },
            sorting: {
              sortModel: [{ field: "date_time_created", sort: "desc" }],
            },
          }}
          sx={{ backgroundColor: "white" }}
        />
      ) : (
        <NoData
          title="You have no Pay Components"
          page="payComponent"
          description="Pay Components are the building blocks that make up an Employee's total compensation for a pay period."
          callback={handleModalOpenAdd}
        />
      )}
    </>
  );
};

export default DataGridPayComponents;
