import { z } from "zod";

export type PaymentMethod = z.infer<typeof SchemaPaymentMethod>;

export const SchemaPaymentMethod = z.object({
  payroll_payment_method: z
    .number()
    .min(1, {
      message: "Payment Method should be selected",
    })
    .or(z.string().transform((val) => Number(val))),
});
