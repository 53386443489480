import { FC } from "react";

import OptionCompanySelect from "./OptionCompanySelect";
import { Company } from "../../../types/Company";
import Box from "@mui/material/Box";

interface Props {
  currentUserMemberCompanies: any;
}

const OptionsCompanySelect: FC<Props> = ({ currentUserMemberCompanies }) => {
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, 13rem)",
          justifyContent: "center",
          gap: "1rem",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        {currentUserMemberCompanies &&
          currentUserMemberCompanies
            .sort((a: Company, b: Company) =>
              a.name_trading.localeCompare(b.name_trading)
            )
            .map((item: Company) => {
              return (
                <OptionCompanySelect
                  key={item.id}
                  id={item.id}
                  name_trading={item.name_trading}
                  name_legal={item.name_legal}
                />
              );
            })}
      </Box>
    </>
  );
};

export default OptionsCompanySelect;
