import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";

import SubHeader from "../SubHeader";
import DataGridPayrollsDraft from "./DataGridPayrollsDraft";

const DashboardPayrollDrafts: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <SubHeader
        title="Draft Payrolls"
        page="drafts"
        icon="receipt"
        callback={() => {
          navigate("/payrolls/run");
        }}
      />

      <Container maxWidth="xl" sx={{ width: "100%" }}>
        <DataGridPayrollsDraft />
      </Container>
    </>
  );
};

export default DashboardPayrollDrafts;
