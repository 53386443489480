import { FC } from "react";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import { useGetPayrollEntryQuery } from "../../../../features/api/apiPayrollEntries";

interface RouterState {
  payrollEntryId?: string;
}

const HeaderEmployeeData: FC = () => {
  const location = useLocation();

  const { payrollEntryId: routerPayrollEntryId } =
    (location.state as RouterState) || {};

  const { data: dataPayrollEntry } = useGetPayrollEntryQuery(
    routerPayrollEntryId!
  );

  const {
    employee_forenames,
    employee_surname,
    employee_employee_number,
    employee_job_title,
    employee_tax_code,
    employee_ni_table_code,
  } = dataPayrollEntry || {};

  return (
    <>
      <Grid container component="header">
        <Grid item xs={12} md={8}>
          <Typography
            variant="overline"
            display="block"
            sx={{ fontSize: "1rem", fontWeight: 400, lineHeight: 1.5, color: "#0059C7", letterSpacing: "0.15px", marginBottom: "0.5rem" }}
          >
            {employee_employee_number}
          </Typography>

          <Typography variant="h5" component="h2" display="block" sx={{ marginBottom: "0.222rem" }}>
            {employee_surname}, {employee_forenames}
          </Typography>

          <Typography variant="body1" component="p" display="block" sx={{ fontWeight: 600, marginBottom: "1rem" }}>
            {employee_job_title}
          </Typography>

          <Divider orientation="horizontal" sx={{ marginBlock: "1rem" }} />

          <Stack direction="column" spacing={1}>
            <Typography variant="body1" component="dl" sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", maxWidth: "25rem", gap: "0.5rem", lineHeight: "1.333" }}>
              <dt><strong>Tax Code</strong></dt> <dd>{employee_tax_code}</dd>
              <dt><strong>NI Table</strong></dt> <dd>{employee_ni_table_code}</dd>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default HeaderEmployeeData;
