import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import SelectTaxYear from "./SelectTaxYear";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconArrowForward from "@mui/icons-material/ArrowForward";

interface Props {
  title: string;
  text: string;
  selectTaxYear: boolean;
  ctaText: string;
  linkTo: string;
  sx?: object;
  selectedTaxYearId?: number;
}

const ModuleYearEndForm: FC<Props> = ({
  title,
  text,
  selectTaxYear,
  ctaText,
  linkTo,
  sx,
  selectedTaxYearId,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          padding: "1rem",
          border: "1px solid #E7E6E9",
          borderRadius: "0.5rem",
          ...sx,
        }}
      >
        <Typography
          variant="h6"
          component="h3"
          sx={{ lineHeight: "1.33", paddingRight: "6rem" }}
        >
          {title}
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography sx={{ paddingBottom: "2rem", marginBottom: "auto" }}>
          {text}
        </Typography>

        {selectTaxYear && <SelectTaxYear />}

        <Button
          onClick={() => {
            selectedTaxYearId
              ? navigate(linkTo, {
                  state: { routerSelectedTaxYear: selectedTaxYearId },
                })
              : navigate(linkTo);
          }}
          variant="outlined"
          size="large"
          endIcon={<IconArrowForward />}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          {ctaText}
        </Button>
      </Box>
    </>
  );
};

export default ModuleYearEndForm;
