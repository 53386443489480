import { FC, useMemo, useState, useEffect } from "react";
import { useMatch } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import SubNavigation from "./SubNavigation";
import FormEssentialInformation from "./01-essential-information/FormEssentialInformation";
import FormEmploymentInformation from "./02-employment-information/FormEmploymentInformation";
import DashboardRatesOfPay from "./03-rates-of-pay/DashboardRatesOfPay";
import DashboardSchemes from "./04-schemes/DashboardSchemes";
import FormPaymentMethod from "./05-payment-method/FormPaymentMethod";
import FormAdditionalInformation from "./06-additional-information/FormAdditionalInformation";
import DashboardActivation from "./07-activation/DashboardActivation";

const StepperEmployee: FC = () => {
  const isEdit = useMatch("/employees/edit");

  const steps = useMemo(() => {
    if (isEdit) {
      return [
        "Essential Information",
        "Employment Information",
        "Rates of Pay",
        "Schemes",
        "Payment Method",
        "Additional Information",
      ];
    } else {
      return [
        "Essential Information",
        "Employment Information",
        "Rates of Pay",
        "Schemes",
        "Payment Method",
        "Additional Information",
        "Activation",
      ];
    }
  }, [isEdit]);

  const [activeStep, setActiveStep] = useState<number>(0);

  const [completed] = useState<{
    [k: number]: boolean;
  }>({});

  const handleStepperNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStepperPrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepperArrowLeftClick = () => {
    if (activeStep > 0) {
      handleStepperPrev();
    }
  };

  const handleStepperArrowRightClick = () => {
    if (activeStep < steps.length - 1) {
      handleStepperNext();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ paddingTop: "3rem", paddingBottom: "4rem" }}
      >
        <Grid item xs={4}>
          <SubNavigation
            activeStep={activeStep}
            steps={steps}
            completed={completed}
            handleStepperArrowLeftClick={handleStepperArrowLeftClick}
            handleStepperArrowRightClick={handleStepperArrowRightClick}
          />
        </Grid>

        <Grid item xs={8}>
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid #E7E6E9",
              borderRadius: "1rem",
              padding: "1.5rem",
            }}
          >
            {activeStep === 0 && (
              <FormEssentialInformation
                steps={steps}
                activeStep={activeStep}
                handleStepperNext={handleStepperNext}
              />
            )}

            {activeStep === 1 && (
              <FormEmploymentInformation
                steps={steps}
                activeStep={activeStep}
                handleStepperNext={handleStepperNext}
              />
            )}

            {activeStep === 2 && (
              <DashboardRatesOfPay
                steps={steps}
                activeStep={activeStep}
                handleStepperNext={handleStepperNext}
              />
            )}

            {activeStep === 3 && (
              <DashboardSchemes
                steps={steps}
                activeStep={activeStep}
                handleStepperNext={handleStepperNext}
              />
            )}

            {activeStep === 4 && (
              <FormPaymentMethod
                steps={steps}
                activeStep={activeStep}
                handleStepperNext={handleStepperNext}
              />
            )}

            {activeStep === 5 && (
              <FormAdditionalInformation
                steps={steps}
                activeStep={activeStep}
                handleStepperNext={handleStepperNext}
              />
            )}

            {!isEdit && activeStep === 6 && (
              <DashboardActivation
                steps={steps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default StepperEmployee;
