import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { EmployeeScheme } from "../../../types/EmployeeScheme";

const initialState: EmployeeScheme = {
  employee: 0,
  scheme: "",
  effective_date_start: null,
  effective_date_end: null,
};

const employeeSchemeSlice = createSlice({
  name: "provisionedEmployeeSchemeSlice",
  initialState,
  reducers: {
    updateProvisionedEmployeeScheme: (
      state,
      action: PayloadAction<Partial<EmployeeScheme>>
    ) => {
      Object.entries(action.payload ?? {}).forEach(([k, v]) => {
        state[k] = v;
      });
    },
    resetProvisionedEmployeeScheme: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const {
  updateProvisionedEmployeeScheme,
  resetProvisionedEmployeeScheme,
} = employeeSchemeSlice.actions;

export default employeeSchemeSlice.reducer;
