import { FC, useContext, useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CurrentEmployeeContext } from "../DashboardEmployee";
import { PaymentMethod, SchemaPaymentMethod } from "./ValidationPaymentMethod";

import Grid from "@mui/material/Grid";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import HeaderForm from "../HeaderForm";
import SectionPaymentMethod from "./sections/SectionPaymentMethod";
import { CtaSubmitAndContinue } from "../CtaButtons";

import {
  useLazyGetEmployeeQuery,
  useUpdateEmployeeMutation,
} from "../../../../features/api/apiEmployees";
import FooterForm from "../FooterForm";

interface Props {
  steps: string[];
  activeStep: number;
  handleStepperNext: () => void;
}

const FormPaymentMethod: FC<Props> = ({
  steps,
  activeStep,
  handleStepperNext,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<PaymentMethod>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaPaymentMethod),
    defaultValues: {
      payroll_payment_method: 0,
    },
  });

  const isEdit = useMatch("/employees/edit");
  const { employeeId } = useContext(CurrentEmployeeContext);
  const [triggerLazyGetEmployee] = useLazyGetEmployeeQuery();

  useEffect(() => {
    isEdit &&
      employeeId &&
      triggerLazyGetEmployee(employeeId).then((res) => {
        const { payroll_payment_method } = res.data || {};

        reset({
          payroll_payment_method: payroll_payment_method?.id,
        });
      });
  }, [isEdit, employeeId, triggerLazyGetEmployee, reset]);

  const [updateEmployee] = useUpdateEmployeeMutation();

  const onSubmit = (data: PaymentMethod) => {
    updateEmployee({ id: employeeId, ...data })
      .unwrap()
      .then(() => {
        setSnackbar({
          children: `Employee record updated`,
          severity: "success",
        });
      })
      .then(() => {
        handleStepperNext();
      })
      .catch((error) => {
        setSnackbar({
          children: `${error.data.message}`,
          severity: "error",
        });
        console.error(
          `Could not update Employee record: ${JSON.stringify(error.data)}`
        );
      });
  };

  const onError = (errors: object) => {
    Object.values(errors).forEach((item) =>
      setSnackbar({
        children: `${item.message}`,
        severity: "error",
      })
    );
  };

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <HeaderForm title={steps[activeStep]}>
          <CtaSubmitAndContinue />
        </HeaderForm>

        <Grid item xs={8} sx={{ marginBottom: "2rem" }}>
          <SectionPaymentMethod control={control} errors={errors} />
        </Grid>

        <FooterForm>
          <CtaSubmitAndContinue />
        </FooterForm>
      </form>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

export default FormPaymentMethod;
