import { FC } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
// import { ZodEmployee } from "../../../../../zod/ZodEmployee";
import { EssentialInformation } from "../ValidateEssentialInformation";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";

import HeaderSection from "../../HeaderSection";
import FormDivider from "../../FormDivider";

interface Props {
  control: Control<EssentialInformation, any>;
  errors: {
    ni_table_code?: FieldError | undefined;
    ni_number?: FieldError | undefined;
  };
}

interface SelectItem {
  id: string | number;
  name: string;
}

const SectionNationalInsurance: FC<Props> = ({ control, errors }) => {
  return (
    <>
      <Box>
        <HeaderSection title="National Insurance" />

        {/* Input : NI Table Code */}
        <FormControl sx={{ width: "8rem", marginBottom: "2rem" }}>
          <Controller
            control={control}
            name="ni_table_code"
            render={({ field }) => (
              <>
                <InputLabel id="label-ni-table-code">N.I. Table</InputLabel>
                <Select
                  {...field}
                  label="N.I. Table"
                  labelId="label-ni-table-code"
                  error={!!errors.ni_table_code}
                  disabled={false}
                >
                  {niTables?.map((item: SelectItem) => {
                    return (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            )}
          />
          <FormHelperText error={true}>
            {errors.ni_table_code?.message}
          </FormHelperText>
        </FormControl>

        {/* Input : N.I. Number */}
        <Controller
          control={control}
          name="ni_number"
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              label="N.I. Number"
              sx={{ marginBottom: "2rem" }}
              error={!!errors.ni_number}
              helperText={errors.ni_number?.message}
              fullWidth={true}
              disabled={false}
            />
          )}
        />
      </Box>
    </>
  );
};

const niTables = [
  {
    id: 1,
    name: "A",
  },
];

export default SectionNationalInsurance;
