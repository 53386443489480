const displayColumnDate = (value: Date) => {
  const date = new Date(value);

  const dateFormatted = new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(date);

  return dateFormatted;
};

const displayColumnPercentage = (value: number) => {
  const formattedValuePercentage = `${Number(value / 100).toFixed(2)}%`;

  return formattedValuePercentage;
};

const displayColumnCurrencyGBP = (value: number) => {
  const formatValuesCurrency = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  });

  const valueFormatted = formatValuesCurrency.format(Number(value / 100));

  return valueFormatted;
};

const displayColumnFullName = (forenames?: string, surname?: string) => {
  return `${forenames || ""} ${surname || ""}`;
};

export {
  displayColumnDate,
  displayColumnPercentage,
  displayColumnCurrencyGBP,
  displayColumnFullName,
};
