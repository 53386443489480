import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { getPdfFormT14 } from "../../../../utils/getPdfForm";

import Box from "@mui/material/Box";
import SubHeader from "./SubHeader";
import { useAppSelector } from "../../../../app/hooks";

interface RouterState {
  employeeId?: string;
}

const DashboardFormT14View: FC = () => {
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string>("");

  const location = useLocation();

  const currentTaxYearId = useAppSelector((state) => state.currentTaxYear.id);

  const { employeeId: routerEmployeeId } =
    (location.state as RouterState) || {};

  useEffect(() => {
    getPdfFormT14(routerEmployeeId!, Number(currentTaxYearId)!).then((data) => {
      data && setPdfBlobUrl(data);
    });
  }, [routerEmployeeId, currentTaxYearId]);

  return (
    <>
      <SubHeader title="View Form T14" pdfBlobUrl={pdfBlobUrl} />

      <Box
        sx={{
          width: "calc(600px + 2px)",
          margin: "2rem",
          border: "1px solid #E7E6E9",
          borderRadius: "4px",
          overflow: "hidden",
        }}
      >
        <Document
          file={pdfBlobUrl}
          loading="Loading payslip PDF..."
          error="Error rendering payslip PDF"
          // onLoadSuccess={() => console.log("Success!")}
        >
          <Page
            pageNumber={1}
            width={600}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </Box>
    </>
  );
};

export default DashboardFormT14View;
