import { FC } from "react";
import { dateFormatter } from "../../../../utils/dateFormatter";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider/Divider";

import { Payroll } from "../../../../types/Payroll";

interface Props {
  data?: Payroll;
}

const HeaderSummary: FC<Props> = ({ data }) => {
  // const defaultDate = new Date();
  // const [payDateValue, setPayDate] = useState<Date | null>(defaultDate);

  const displayPayDate = dateFormatter(data?.pay_date_scheduled);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "2rem 0 1rem",
        }}
      >
        <Typography component="h5" variant="h5">
          {data?.payroll_schedule.name}
        </Typography>
      </Box>

      <Box sx={customStyles.defaultBox}>
        <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {/* Column: Pay Frequency */}
          <Grid item xs>
            <Typography
              component="p"
              variant="caption"
              gutterBottom
              sx={customStyles.subTitle}
            >
              Pay Frequency
            </Typography>

            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={customStyles.fontBold}
            >
              {data?.payroll_schedule.payroll_frequency.name}
            </Typography>
          </Grid>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#E7E6E9" }}
          />

          {/* Column: Pay Period */}
          <Grid item xs>
            <Typography
              component="p"
              variant="caption"
              gutterBottom
              sx={customStyles.subTitle}
            >
              Pay Period
            </Typography>

            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={customStyles.fontBold}
            >
              {data &&
                `Pay Period ${data?.pay_period} in Tax Year ${data?.tax_year.name}`}
            </Typography>
          </Grid>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#E7E6E9" }}
          />

          {/* Column: Pay Period */}
          {/* TODO(DBB) : Reinstate  */}
          {/* <Grid item xs sx={{ borderLeft: "1px solid rgba(47, 48, 51, 0.16)" }}>
            <Typography
              component="p"
              variant="body2"
              gutterBottom
              sx={customStyles.subTitle}
            >
              Pay Period
            </Typography>
            {dataSchedule.pay_periods.length ? (
                  <SelectPayPeriod
                    dataPayPeriods={dataSchedule.pay_periods}
                  />
                ) : (
                  <div className="error">
                    Error rendering component: PayPeriodSelectMenu
                  </div>
                )}
            <>{data?.pay_period_index}</>
          </Grid> */}

          {/* Pay Date */}
          <Grid item xs>
            <div className="flex justify-between">
              <Typography
                component="p"
                variant="caption"
                gutterBottom
                sx={customStyles.subTitle}
              >
                Scheduled Pay Date
              </Typography>

              {/* <DatePicker
                label="Pay Date"
                value={payDateValue}
                onChange={(newValue) => {
                  setPayDate(newValue);
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <Box sx={customStyles.payDatePicker}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      style={{ width: 0 }}
                      disabled
                    />
                    Change Date
                    {InputProps?.endAdornment}
                  </Box>
                )}
              /> */}
            </div>

            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={customStyles.fontBold}
            >
              {displayPayDate}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const customStyles = {
  fontBold: {
    fontWeight: "bold",
    fontSize: "1rem",
  },
  subTitle: {
    color: "rgba(47, 48, 51, 0.72)",
    textTransform: "uppercase",
    fontWeight: 500,
  },
  defaultBox: {
    padding: "12px 16px 16px 16px",
    border: "1px solid #E7E6E9",
    borderRadius: "0.5rem",
    margin: "1rem 0",
    backgroundColor: "#ffffff",
  },
  payDatePicker: {
    display: "flex",
    alignItems: "center",
    background: "rgba(0, 89, 199, 0.08)",
    borderRadius: "4px",
    padding: "4px",
    fontSize: "13px",
    lineHeight: "22px",
    color: "#002D6D",
    fontWeight: 500,
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 0,
    },
    "& .MuiSvgIcon-root": {
      color: "#002D6D",
      width: "16px",
    },
  },
};

export default HeaderSummary;
