import { FC } from "react";
import { useLocation } from "react-router-dom";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import SubHeader from "../../SubHeader";
import HeaderSummary from "./HeaderSummary";
import HeaderPdfDocuments from "./HeaderPdfDocuments";
import DataGridPayrollEntries from "./DataGridPayrollEntries";
import ErrorMessage from "./ErrorMessage";

interface RouterState {
  payrollId?: string;
}

const DashboardPayrollRecord: FC = () => {
  const location = useLocation();

  const { payrollId: routerPayrollId } = (location.state as RouterState) || {};

  if (!routerPayrollId) {
    return <ErrorMessage />;
  }

  return (
    <>
      <SubHeader
        title="Payroll History / Payroll Record"
        page="payroll-history-payroll-record"
        icon="table_rows"
      />

      <Container maxWidth="xl" sx={{ width: "100%", padding: "2rem" }}>
        <Stack direction="column" spacing={4}>
          <HeaderSummary />

          <HeaderPdfDocuments />

          <DataGridPayrollEntries />
        </Stack>
      </Container>
    </>
  );
};

export default DashboardPayrollRecord;
