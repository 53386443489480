import { useState } from "react";

import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

import { useDeletePayrollMutation } from "../../../../features/api/apiPayrolls";
import LoadingButton from "@mui/lab/LoadingButton";

export default function DialogCancel() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [deletePayroll] = useDeletePayrollMutation();

  const provisionedPayrollId: string = useAppSelector(
    (state: RootState) => state.provisionedPayroll.id
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setLoading(true);

    console.log("Should not be deleting payrolls");

    console.log("This should instead revert status to GENERATED or DRAFT");

    // deletePayroll(provisionedPayrollId)
    //   // TODO(DBB) : Should be relaying on timing, make this properly async
    //   .then(
    //     async () => await new Promise((resolve) => setTimeout(resolve, 1000))
    //   )
    //   .then(() => handleClose)
    //   .then(() => {
    //     navigate("/payrolls/overview");
    //   });

    // Handle success?
    // Handle error?
  };

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        Cancel
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete new Payroll?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you share you want to remove this provisioned payroll?
            <br /> All unsaved data will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>

          <LoadingButton
            onClick={handleDelete}
            variant="outlined"
            color="error"
            loading={loading}
            disabled
          >
            Delete Payroll
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
