import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { Typography } from "@mui/material";
import LogoCompany from "./LogoCompany";
import { useDispatch } from "react-redux";
import {
  resetCurrentCompany,
  setCurrentCompany,
} from "../../../features/state/company/companySlice";
import { useLazyGetCompanyQuery } from "../../../features/api/apiCompanies";
import { resetCurrentEmployee } from "../../../features/state/employee/currentEmployeeSlice";
import { resetProvisionedEmployee } from "../../../features/state/employee/provisionedEmployeeSlice";
import { resetProvisionedEmployeePayRate } from "../../../features/state/employeePayRate/employeePayRateSlice";
import { resetProvisionedEmployeeScheme } from "../../../features/state/employeeScheme/employeeSchemeSlice";
import { resetProvisionedPayroll } from "../../../features/state/payroll/payrollSlice";
import { resetCurrentPayrollEntry } from "../../../features/state/payrollEntry/payrollEntrySlice";

interface Props {
  id: number;
  name_trading: string;
  name_legal: string;
}

const OptionCompanySelect: FC<Props> = ({ id, name_trading, name_legal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [triggerLazyGetCompany] = useLazyGetCompanyQuery();

  // Handle Company selection
  const handleCompanySelect = (selectedCompanyId: number) => {
    triggerLazyGetCompany(selectedCompanyId)
      .then((response) => {
        // Purge Redux stores
        dispatch(resetCurrentCompany());
        dispatch(resetCurrentEmployee());
        dispatch(resetProvisionedEmployee());
        dispatch(resetProvisionedEmployeePayRate());
        dispatch(resetProvisionedEmployeeScheme());
        dispatch(resetProvisionedPayroll());
        dispatch(resetCurrentPayrollEntry());

        return response.data;
      })
      .then((data) => {
        dispatch(setCurrentCompany(data!));
      })
      .then(() => {
        // NOTE(DBB) : Assures both Redux state and
        // React useContext are purged
        navigate("/home");
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        border: "1px dashed #E7E6E9",
        borderRadius: "0.25rem",
        padding: "2rem 1rem 1rem",
        gap: "1rem",
        backgroundColor: "white",
      }}
    >
      <LogoCompany name_trading={name_trading} />

      <Typography variant="h6" component="h3" sx={{ lineHeight: "1.25" }}>
        {name_trading}
      </Typography>

      <Typography variant="overline" component="h3" sx={{ lineHeight: "1.25" }}>
        {name_legal}
      </Typography>

      <Button
        variant="contained"
        size="small"
        disableElevation={true}
        onClick={() => handleCompanySelect(id)}
      >
        Select
      </Button>
    </Box>
  );
};

export default OptionCompanySelect;
