import { FC } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Settings: FC = () => {
  return (
    <>
      <Container maxWidth="xl" sx={{ width: "100%", mt: "76px" }}>
        {/* DEMO */}
        <Grid item xs={12}>
          <Typography>Settings</Typography>
        </Grid>
      </Container>
    </>
  );
};

export default Settings;
