import { FC } from "react";

import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import SubHeader from "../../SubHeader";
import SectionCompanyProfile from "./01-profile/SectionCompanyProfile";
import SectionContactDetails from "./02-contact-details/SectionContactDetails";

const DashboardCompanyInformation: FC = () => {
  return (
    <>
      <SubHeader title="Company > Company Information" icon="people" />

      <Stack
        direction="column"
        divider={<Divider orientation="horizontal" flexItem />}
        sx={{ maxWidth: "100%" }}
      >
        <SectionCompanyProfile />

        <SectionContactDetails />
      </Stack>
    </>
  );
};

export default DashboardCompanyInformation;
