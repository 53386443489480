import { z } from "zod";

export type EmployeeRatesOfPay = z.infer<typeof SchemaEmployeeRatesOfPay>;

export const SchemaEmployeeRatesOfPay = z.object({
  employee: z.number(),

  pay_component: z.number(),

  //   cost_centre: undefined,

  pay_rate: z
    .number()
    .positive()
    .transform((val) => val * 100),
  // .transform((val) => Number(val.replace(/\./g, ""))),

  pay_count: z
    .number()
    .nonnegative()
    .or(z.string().transform((val) => Number(val))),

  // TODO(DBB) : Remove this functionality, currently calculates
  // in backend subscriber
  pay_amount: z.number().optional(),

  effective_date_start: z
    .date({
      invalid_type_error: "Effective Start Date is required",
    })
    .or(z.string().transform((str) => new Date(str))),

  status: z.string(),
});
