import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CurrentEmployeeContext } from "../DashboardEmployee";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import HeaderForm from "../HeaderForm";
import ValidationChecks from "./ValidationChecks";

import {
  useGetEmployeeQuery,
  useUpdateEmployeeMutation,
} from "../../../../features/api/apiEmployees";
import { useGetAllEmployeePayRatesByEmployeeIdQuery } from "../../../../features/api/apiEmployeePayRates";

interface Props {
  steps: string[];
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

const DashboardActivation: FC<Props> = ({
  steps,
  activeStep,
  setActiveStep,
}) => {
  const navigate = useNavigate();

  const { employeeId } = useContext(CurrentEmployeeContext);

  const [canActivate, setCanActivate] = useState<boolean>(false);

  const { data: dataGetEmployee } = useGetEmployeeQuery(employeeId!);
  const { data: dataGetAllEmployeePayRates } =
    useGetAllEmployeePayRatesByEmployeeIdQuery(employeeId!);

  const [updateEmployee] = useUpdateEmployeeMutation();

  const handleActivateEmployeeClick = () => {
    updateEmployee({ id: employeeId as unknown as number, status: "ACTIVE" })
      .then(() => {
        navigate("/employees/all");
      })
      .catch((error) => console.error("Could not activate Employee: " + error));
  };

  const handleSaveDraftEmployeeClick = () => {
    updateEmployee({ id: employeeId as unknown as number, status: "DRAFT" })
      .unwrap()
      .then(() => {
        navigate("/employees/all");
      })
      .catch((error) => console.error("Could not activate Employee: " + error));
  };

  return (
    <>
      {employeeId && dataGetEmployee && dataGetAllEmployeePayRates && (
        <>
          <HeaderForm title={steps[activeStep]} />

          <Grid item xs={12} sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <>
              <ValidationChecks
                dataGetEmployee={dataGetEmployee}
                dataGetAllEmployeePayRates={dataGetAllEmployeePayRates}
                setActiveStep={setActiveStep}
                setCanActivate={setCanActivate}
              />

              <Box sx={{ mt: 2, mb: 4 }}>
                <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={handleActivateEmployeeClick}
                  disabled={!canActivate}
                >
                  Activate Employee
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleSaveDraftEmployeeClick}
                >
                  Save as Draft
                </Button>
              </Box>
            </>
          </Grid>
        </>
      )}
    </>
  );
};

export default DashboardActivation;
