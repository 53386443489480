import { FC, useEffect, useMemo, useState } from "react";
import { RootState } from "../../../../app/store";
import { useAppSelector } from "../../../../app/hooks";

import {
  DataGridPro,
  GridColumns,
  GridValidRowModel,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

import { useGetAllPayrollSchemesByPayrollEntryIdQuery } from "../../../../features/api/apiPayrollSchemes";
import { PayrollScheme } from "../../../../types/PayrollScheme";
import { SchemeType } from "../../../../types/SchemeType";
import {
  displayColumnCurrencyGBP,
  displayColumnPercentage,
} from "../../../../utils/datagridFormatters";

const formatValuesCurrency = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

const DataGridPayrollSchemes: FC = () => {
  const currentPayrollEntryId: string =
    useAppSelector((state: RootState) => state.currentPayrollEntry.id) || "";

  const apiRef = useGridApiRef();
  const [rows, setRows] = useState<Partial<PayrollScheme>[] | undefined>();

  const [pageSize, setPageSize] = useState<number>(5);

  const { data: dataPayrollSchemesByPayrollEntryId } =
    useGetAllPayrollSchemesByPayrollEntryIdQuery(currentPayrollEntryId);

  useEffect(() => {
    const payrollSchemes =
      dataPayrollSchemesByPayrollEntryId &&
      // TODO(DBB) : Fix typing for employee_contribution_count, employer_contribution_count
      // dataPayrollSchemesByPayrollEntryId.map((item: Partial<PayrollScheme>) => {
      dataPayrollSchemesByPayrollEntryId.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          scheme_type: item.scheme_type?.name as unknown as SchemeType,
          employee_contribution_rate_type: item.employee_contribution_rate_type,
          employee_contribution_amount: item.employee_contribution_count,
          employee_contribution_deducted_before_tax:
            item.employee_contribution_deducted_before_tax,
          employee_contribution_deducted_before_ni:
            item.employee_contribution_deducted_before_ni,
          employer_contribution_rate_type: item.employer_contribution_rate_type,
          employer_contribution_amount: item.employer_contribution_count,
          employee_scheme_contribution: item.employee_scheme_contribution,
          employer_scheme_contribution: item.employer_scheme_contribution,
          status: item.status,
          date_time_created: item.date_time_created,
        };
      });
    setRows(payrollSchemes);
  }, [dataPayrollSchemesByPayrollEntryId]);

  const columns = useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: "id",
        headerName: "ID",
        type: "number",
        width: 80,
        editable: false,
        hide: true,
      },
      {
        field: "name",
        headerName: "Name",
        type: "string",
        width: 120,
        editable: false,
        hide: false,
      },
      {
        field: "scheme_type",
        headerName: "Scheme Type",
        type: "string",
        width: 140,
        editable: false,
        hide: false,
      },
      {
        field: "employee_contribution",
        headerName: "E'ee Ctb",
        description: "Employee Scheme Contribution",
        type: "number",
        width: 80,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) => {
          const {
            employee_contribution_rate_type: type,
            employee_contribution_amount: amount,
          } = params.row;

          if (type === "PERCENTAGE") {
            return displayColumnPercentage(amount);
          }
          if (type === "FIXED") {
            return displayColumnCurrencyGBP(amount);
          }
        },
      },
      {
        field: "employee_contribution_deducted_before_tax",
        headerName: "Pre-Tax",
        description: "Employee Contribution Deducted Before Tax",
        type: "boolean",
        width: 80,
        editable: false,
        hide: false,
      },
      {
        field: "employee_contribution_deducted_before_ni",
        headerName: "Pre-NI",
        description: "Employee Contribution Deducted Before N.I.",
        type: "boolean",
        width: 80,
        editable: false,
        hide: false,
      },
      {
        field: "employer_contribution",
        headerName: "E'er Ctb",
        description: "Employer Scheme Contribution",
        type: "number",
        width: 80,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) => {
          const {
            employer_contribution_rate_type: type,
            employer_contribution_amount: amount,
          } = params.row;

          if (type === "PERCENTAGE") {
            return displayColumnPercentage(amount);
          }
          if (type === "FIXED") {
            return displayColumnCurrencyGBP(amount);
          }
        },
      },
      {
        field: "employee_scheme_contribution",
        headerName: "E'ee Ctb Total",
        type: "number",
        width: 110,
        editable: false,
        hide: false,
        valueFormatter: ({ value }) =>
          formatValuesCurrency.format(Number(value / 100)),
        cellClassName: "datagrid-payroll-scheme-total",
      },
      {
        field: "employer_scheme_contribution",
        headerName: "E'er Ctb Total",
        type: "number",
        width: 110,
        editable: false,
        hide: false,
        valueFormatter: ({ value }) =>
          formatValuesCurrency.format(Number(value / 100)),
        cellClassName: "datagrid-payroll-scheme-total",
      },
    ],
    []
  );

  return (
    <>
      <Box
        sx={{
          height: 300,
          width: "100%",
          "& .datagrid-payroll-scheme-total": {
            fontWeight: "600",
          },
        }}
      >
        <div style={{ height: 400, width: "100%" }}>
          <DataGridPro
            apiRef={apiRef}
            rows={rows || []}
            columns={columns}
            autoHeight={true}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            editMode="row"
            experimentalFeatures={{ newEditingApi: true }}
            disableSelectionOnClick={false}
            initialState={{
              sorting: {
                sortModel: [{ field: "date_time_created", sort: "asc" }],
              },
              pinnedColumns: {
                left: ["name"],
                right: [
                  "employee_scheme_contribution",
                  "employer_scheme_contribution",
                ],
              },
            }}
            sx={{ backgroundColor: "white", marginBottom: "0.5rem" }}
          />
        </div>
      </Box>
    </>
  );
};

export default DataGridPayrollSchemes;
