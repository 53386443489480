import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import IconDownload from "@mui/icons-material/Download";

import { useGetAllPayrollsByCompanyIdQuery } from "../../../../features/api/apiPayrolls";
import { Payroll } from "../../../../types/Payroll";
import Divider from "@mui/material/Divider/Divider";
import { Button, Stack } from "@mui/material";
import { getPdfFormPaymentInstruction } from "../../../../utils/getPdfForm";
import { getPdfFormPayslipsZip } from "../../../../utils/getPdfFormZip";
import { PayrollEntry } from "../../../../types/PayrollEntry";

const SectionNextPayroll: FC = () => {
  const navigate = useNavigate();

  const currentCompanyId = useAppSelector((state) => state.currentCompany.id);

  const [latestCompletedPayroll, setLatestCompletedPayroll] =
    useState<Partial<Payroll>>();

  const [totalPayrollCost, setTotalPayrollCost] = useState<number>();

  const [
    loadingDownloadPaymentInstruction,
    setLoadingDownloadPaymentInstruction,
  ] = useState<boolean>(false);

  const [loadingDownloadAllPayslips, setLoadingDownloadAllPayslips] =
    useState<boolean>(false);

  const { data: dataGetAllPayrollsByCompanyId } =
    useGetAllPayrollsByCompanyIdQuery(currentCompanyId);

  useEffect(() => {
    if (
      dataGetAllPayrollsByCompanyId &&
      dataGetAllPayrollsByCompanyId.length > 0
    ) {
      const completedPayrolls = dataGetAllPayrollsByCompanyId?.filter(
        (item) => item.status === "COMPLETE"
      );
      const latestPayroll = completedPayrolls.sort((a, b) => {
        const date1 = new Date(a.pay_date_scheduled!);
        const date2 = new Date(b.pay_date_scheduled!);

        return date2.getTime() - date1.getTime();
      })[0];

      const payrollEntries: PayrollEntry[] = latestPayroll?.payroll_entries;

      const totalCost = payrollEntries
        ?.map((item: PayrollEntry) => item.calculated_total_cost)
        .reduce((partialSum, a) => partialSum + a, 0);

      setLatestCompletedPayroll(latestPayroll);

      setTotalPayrollCost(totalCost);
    }
  }, [dataGetAllPayrollsByCompanyId]);

  const handleDownloadPaymentInstructionClick = useCallback(() => {
    setLoadingDownloadPaymentInstruction(true);

    latestCompletedPayroll &&
      getPdfFormPaymentInstruction(latestCompletedPayroll!.id!)
        .then((data) => {
          if (data) {
            const filename = `PaymentInstruction-${latestCompletedPayroll!
              .id!}`;
            const safeFilename = filename.replace(/[^a-z0-9]/gi, "-");

            const link = document.createElement("a");
            link.href = data;
            link.setAttribute("download", `${safeFilename}.pdf`);
            document.body.appendChild(link);
            link.click();
          }
        })
        .then(() => setLoadingDownloadPaymentInstruction(false));
  }, [latestCompletedPayroll]);

  const handleDownloadAllPayslipsClick = useCallback(() => {
    setLoadingDownloadAllPayslips(true);

    latestCompletedPayroll &&
      getPdfFormPayslipsZip(latestCompletedPayroll.id!)
        .then((data) => {
          if (data) {
            const filename = `Payslips-${latestCompletedPayroll.id!}`;
            const safeFilename = filename.replace(/[^a-z0-9]/gi, "-");

            const link = document.createElement("a");
            link.href = data;
            link.setAttribute("download", `${safeFilename}.zip`);
            document.body.appendChild(link);
            link.click();
          }
        })
        .then(() => setLoadingDownloadAllPayslips(false));
  }, [latestCompletedPayroll]);

  return (
    <>
      {latestCompletedPayroll ? (
        <Grid
          sx={{
            backgroundColor: "white",
            padding: "1rem",
            border: "1px solid #E7E6E9",
            borderRadius: "0.5rem",
            display: "grid",
            gridTemplateRows: "repeat(3, max-content) 1fr",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            {latestCompletedPayroll?.pay_date_scheduled && (
              <Typography variant="h5" component="h3">
                {new Intl.DateTimeFormat("en-GB", { dateStyle: "full" }).format(
                  new Date(latestCompletedPayroll?.pay_date_scheduled)
                )}
              </Typography>
            )}
          </Box>

          <Grid
            sx={{
              display: "grid",
              gridTemplateColumns: "3fr max-content 2fr",
              gap: "1rem",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Box>
              {/* Column: Pay Period */}
              <Grid
                container
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "1rem",
                }}
              >
                <Typography
                  component="p"
                  variant="body1"
                  gutterBottom
                  sx={{
                    color: "#2F3033",
                    fontWeight: "700",
                    mb: "0.5rem",
                  }}
                >
                  Pay Period
                </Typography>

                <Typography
                  component="p"
                  variant="body1"
                  gutterBottom
                  sx={{ color: "#2F3033" }}
                >
                  Pay period {latestCompletedPayroll?.pay_period} of tax year{" "}
                  {latestCompletedPayroll?.tax_year.name}
                </Typography>
              </Grid>

              {/* Column: Pay Schedule */}
              <Grid
                container
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "1rem",
                }}
              >
                <Typography
                  component="p"
                  variant="body1"
                  gutterBottom
                  sx={{
                    color: "#2F3033",
                    fontWeight: "700",
                    mb: "0.5rem",
                  }}
                >
                  Pay Schedule
                </Typography>

                <Typography
                  component="p"
                  variant="body1"
                  gutterBottom
                  sx={{ color: "#2F3033" }}
                >
                  {latestCompletedPayroll?.payroll_schedule.name}
                </Typography>
              </Grid>

              {/* Column: Number of Employees */}
              <Grid
                container
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "1rem",
                }}
              >
                <Typography
                  component="p"
                  variant="body1"
                  gutterBottom
                  sx={{
                    color: "#2F3033",
                    fontWeight: "700",
                    mb: "0.5rem",
                  }}
                >
                  Number of Employees
                </Typography>

                <Typography
                  component="p"
                  variant="body1"
                  gutterBottom
                  sx={{ color: "#2F3033" }}
                >
                  {latestCompletedPayroll?.payroll_entries.length}
                </Typography>
              </Grid>
            </Box>

            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "#E7E6E9" }}
            />

            <Box>
              <Typography
                variant="overline"
                display="block"
                gutterBottom
                sx={{
                  fontSize: "1rem",
                  fontWeight: "700",
                  letterSpacing: "0.15px",
                  lineHeight: "1",
                  marginBottom: "1rem",
                }}
              >
                PDF Documents
              </Typography>

              <Stack direction="column" spacing={2}>
                <LoadingButton
                  variant="outlined"
                  size="large"
                  endIcon={<IconDownload />}
                  onClick={handleDownloadPaymentInstructionClick}
                  loading={loadingDownloadPaymentInstruction}
                  disabled={false}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  Download Payment Instruction
                </LoadingButton>

                <LoadingButton
                  variant="outlined"
                  size="large"
                  endIcon={<IconDownload />}
                  onClick={handleDownloadAllPayslipsClick}
                  loading={loadingDownloadAllPayslips}
                  disabled={false}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  Download all Payslips
                </LoadingButton>
              </Stack>
            </Box>
          </Grid>

          <Button
            variant="outlined"
            color="secondary"
            sx={{ marginRight: "auto" }}
            onClick={() =>
              navigate("/payrolls/history/payroll-record", {
                state: {
                  payrollId: latestCompletedPayroll?.id,
                  totalCost: totalPayrollCost,
                },
              })
            }
          >
            View details
          </Button>
        </Grid>
      ) : (
        "No payrolls found"
      )}
    </>
  );
};

export default SectionNextPayroll;
