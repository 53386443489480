import { FC } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import { PaymentMethod } from "../ValidationPaymentMethod";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

import HeaderSection from "../../HeaderSection";
import FormDivider from "../../FormDivider";

import { useGetAllPayrollPaymentMethodsQuery } from "../../../../../features/api/apiPayrollPaymentMethods";

interface RadioItem {
  id: string | number;
  label: string;
  value: string | number | boolean;
}

interface Props {
  control: Control<PaymentMethod, any>;
  errors: {
    payroll_payment_method?: FieldError | undefined;
  };
}

const SectionPaymentMethod: FC<Props> = ({ control, errors }) => {
  const { data: dataGetAllPayrollPaymentMethods } =
    useGetAllPayrollPaymentMethodsQuery();

  const formattedPaymentMethods = dataGetAllPayrollPaymentMethods?.map(
    (item) => {
      return { id: item.id, label: item.name, value: item.id };
    }
  );

  return (
    <>
      <Box>
        <HeaderSection title="Payment Method" />

        {/* Input : Payment Method */}
        <Box sx={{ paddingLeft: "1rem", marginBottom: "2rem" }}>
          <Controller
            control={control}
            name="payroll_payment_method"
            render={({ field }) => (
              <FormControl>
                <RadioGroup aria-label="Employee Payment Method" {...field}>
                  {formattedPaymentMethods?.map((item: RadioItem) => {
                    return (
                      <FormControlLabel
                        control={<Radio />}
                        key={item.id}
                        value={item.value}
                        label={item.label}
                        disabled={false}
                      />
                    );
                  })}
                </RadioGroup>
                <FormHelperText error={true}>
                  {errors.payroll_payment_method?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default SectionPaymentMethod;
