import { FC } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
// import { ZodEmployee } from "../../../../../zod/ZodEmployee";
import { EssentialInformation } from "../ValidateEssentialInformation";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";

import HeaderSection from "../../HeaderSection";
import FormDivider from "../../FormDivider";

import { dataCountries } from "../../../../../utils/dataCountries";

interface SelectItem {
  id: string | number;
  name: string;
}

interface Props {
  control: Control<EssentialInformation, any>;
  errors: {
    country?: FieldError | undefined;
    address_1?: FieldError | undefined;
    address_2?: FieldError | undefined;
    city?: FieldError | undefined;
    region?: FieldError | undefined;
    post_code?: FieldError | undefined;
  };
}

const SectionHomeAddress: FC<Props> = ({ control, errors }) => {
  return (
    <>
      <Box>
        <HeaderSection title="Home Address" />

        {/* Input : Country */}
        <FormControl fullWidth={true} sx={{ marginBottom: "2rem" }}>
          <Controller
            control={control}
            name="country"
            render={({ field }) => (
              <>
                <InputLabel id="label-country">Country</InputLabel>
                <Select
                  {...field}
                  label="Country"
                  labelId="label-country"
                  error={!!errors.country}
                  disabled={false}
                >
                  {dataCountries?.map((item: SelectItem) => {
                    return (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            )}
          />
          <FormHelperText error={true}>
            {errors.country?.message}
          </FormHelperText>
        </FormControl>

        {/* Input : Address 1 */}
        <Controller
          control={control}
          name="address_1"
          render={({ field }) => (
            <TextField
              {...field}
              label="Address 1"
              sx={{ marginBottom: "2rem" }}
              variant="outlined"
              error={!!errors.address_1}
              helperText={errors.address_1?.message}
              fullWidth={true}
              disabled={false}
            />
          )}
        />

        {/* Input : Address 2 */}
        <Controller
          control={control}
          name="address_2"
          render={({ field }) => (
            <TextField
              {...field}
              label="Address 2"
              sx={{ marginBottom: "2rem" }}
              variant="outlined"
              error={!!errors.address_2}
              helperText={errors.address_2?.message}
              fullWidth={true}
              disabled={false}
            />
          )}
        />

        {/* Input : City */}
        <Controller
          control={control}
          name="city"
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="City"
              sx={{ marginBottom: "2rem" }}
              variant="outlined"
              error={!!errors.city}
              helperText={errors.city?.message}
              fullWidth={true}
              disabled={false}
            />
          )}
        />

        {/* Input : Region */}
        <Controller
          control={control}
          name="region"
          render={({ field }) => (
            <TextField
              {...field}
              label="Region"
              sx={{ marginBottom: "2rem" }}
              variant="outlined"
              error={!!errors.region}
              helperText={errors.region?.message}
              fullWidth={true}
              disabled={false}
            />
          )}
        />

        {/* Input : Post Code */}
        <Controller
          control={control}
          name="post_code"
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Post Code"
              sx={{ marginBottom: "2rem", width: "12rem" }}
              variant="outlined"
              error={!!errors.post_code}
              helperText={errors.post_code?.message}
              fullWidth={true}
              disabled={false}
            />
          )}
        />
      </Box>

      <FormDivider mt={2} mb={4} />
    </>
  );
};

// const countries = [
//   {
//     id: 1,
//     name: "Isle of Man",
//   },
//   {
//     id: 2,
//     name: "England",
//   },
//   {
//     id: 3,
//     name: "Scotland",
//   },
//   {
//     id: 4,
//     name: "Wales",
//   },
//   {
//     id: 5,
//     name: "Northern Ireland",
//   },
//   {
//     id: 6,
//     name: "Republic of Ireland",
//   },
//   {
//     id: 7,
//     name: "Jersey",
//   },
//   {
//     id: 8,
//     name: "Guernsey",
//   },
//   // {
//   //   id: 99,
//   //   name: "Vietnam",
//   // },
// ];

export default SectionHomeAddress;
