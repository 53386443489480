import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconArrowForward from "@mui/icons-material/ArrowForward";

import SelectTaxYear from "./SelectTaxYear";
import SubHeader from "../SubHeader";

const DashboardYearEndDocuments: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <SubHeader title="Forms > Year End Documents" icon="receipt" />

      <Container maxWidth="xl" sx={{ width: "100%" }}>
        <Grid container>
          <Grid item xs={12} sm={10} md={8}>
            <Typography
              component="h2"
              variant="h4"
              sx={{ marginTop: "2rem", marginBottom: "1.5rem" }}
            >
              Year End Documents
            </Typography>

            <Typography component="p" sx={{ marginBottom: "2rem" }}>
              Year End Tax Documents are annual statements that employers issue
              to employees at the end of each tax year. These documents
              summarize the total earnings and tax deductions for the year, and
              they are used to reconcile the taxes that were withheld from an
              employee's pay throughout the year with the total tax liability
              for the year.
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            sx={{
              backgroundColor: "white",
              border: "1px solid rgba(231, 230, 233, 1)",
              borderRadius: "0.25rem",
              padding: "1rem 1rem 1rem",
              marginBottom: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SelectTaxYear />

            <Button
              variant="contained"
              size="medium"
              onClick={() => navigate("/tax-forms/t37/preview")}
              endIcon={<IconArrowForward />}
              sx={{ marginTop: "1rem", marginLeft: "auto" }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default DashboardYearEndDocuments;
