import { FC, useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";

import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
  GridRowParams,
  GridValidRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import IconEdit from "@mui/icons-material/Edit";
import IconDisable from "@mui/icons-material/DisabledByDefault";

import { useGetAllEmployeesByCompanyIdQuery } from "../../../features/api/apiEmployees";
import IconTableRows from "@mui/icons-material/TableRows";

interface Props {
  handleModalOpen: (employeeId: number, employeeLeaveDate: Date) => void;
}

const DataGridEmployees: FC<Props> = ({ handleModalOpen }) => {
  const navigate = useNavigate();
  const currentCompanyId = useAppSelector((state) => state.currentCompany.id);
  const [rows, setRows] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(10);

  const { data: dataGetAllEmployeesbyCompanyId } =
    useGetAllEmployeesByCompanyIdQuery(currentCompanyId);

  const displayColumnDate = (value: Date) => {
    const date = new Date(value);

    const dateFormatted = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(date);

    return dateFormatted;
  };

  const handleEmployeeLeaveClick = useCallback(
    (employeeId: GridRowId, employeeLeaveDate: Date) => {
      handleModalOpen(Number(employeeId), employeeLeaveDate);
    },
    [handleModalOpen]
  );

  const handleDownloadT21TemplateClick = useCallback(() => {
    const filename = `T21-Template`;
    const safeFilename = filename.replace(/[^a-z0-9]/gi, "-");

    const link = document.createElement("a");
    link.href =
      "https://www.gov.im/media/97552/t21-employee-leaving-230721.pdf";
    link.setAttribute("download", `${safeFilename}.pdf`);
    link.setAttribute("target", `_blank`);
    document.body.appendChild(link);
    link.click();
  }, []);

  const columns = useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: "employee_number",
        headerName: "Emp. No.",
        type: "string",
        width: 120,
        editable: false,
        hide: false,
      },
      {
        field: "surname",
        headerName: "Surname",
        type: "string",
        width: 120,
        editable: false,
        hide: false,
      },
      {
        field: "forenames",
        headerName: "Forenames",
        type: "string",
        width: 140,
        editable: false,
        hide: false,
      },
      {
        field: "job_title",
        headerName: "Job Title",
        type: "string",
        width: 120,
        editable: false,
        hide: false,
      },
      {
        field: "employment_type",
        headerName: "Emp. Type",
        type: "string",
        width: 100,
        editable: false,
        hide: false,
      },
      {
        field: "payroll_schedule",
        headerName: "Pay Schedule",
        type: "string",
        width: 140,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.payroll_schedule && params.row.payroll_schedule?.name,
      },
      {
        field: "job_date_started",
        headerName: "Start Date",
        type: "date",
        width: 100,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.job_date_started &&
          displayColumnDate(params.row.job_date_started),
      },
      {
        field: "job_date_finished",
        headerName: "Leave Date",
        type: "date",
        width: 100,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.job_date_finished &&
          displayColumnDate(params.row.job_date_finished),
      },
      {
        field: "status",
        headerName: "Status",
        type: "string",
        width: 120,
        editable: false,
        hide: false,
      },
      {
        field: "id",
        headerName: "ID",
        type: "string",
        width: 80,
        editable: false,
        hide: true,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 80,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<IconDisable />}
            label="Set Leave Date"
            onClick={() =>
              handleEmployeeLeaveClick(params.id, params.row.job_date_finished)
            }
            showInMenu
            disabled={false}
          />,
          <GridActionsCellItem
            icon={<IconEdit />}
            label="Download T21 Template"
            onClick={() => handleDownloadT21TemplateClick()}
            showInMenu
            disabled={false}
          />,
          <GridActionsCellItem
            icon={<IconTableRows />}
            label="View T21 Data"
            onClick={() =>
              navigate("/tax-forms/t21/view", {
                state: { employeeId: params.id },
              })
            }
            showInMenu
            disabled={params.row.job_date_finished ? false : true}
          />,
        ],
      },
    ],
    [handleEmployeeLeaveClick, handleDownloadT21TemplateClick, navigate]
  );

  useEffect(() => {
    setRows(dataGetAllEmployeesbyCompanyId);
  }, [dataGetAllEmployeesbyCompanyId]);

  return (
    <>
      {rows ? (
        <DataGridPro
          rows={rows || []}
          columns={columns}
          autoHeight={true}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          pagination
          // disableColumnMenu={true}
          initialState={{
            pinnedColumns: {
              right: ["actions"],
            },
            sorting: {
              sortModel: [{ field: "job_date_started", sort: "desc" }],
            },
          }}
          sx={{
            backgroundColor: "white",
          }}
        />
      ) : (
        <>No data to show...</>
      )}
    </>
  );
};

export default DataGridEmployees;
