import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";

import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
  GridRowModel,
  GridRowParams,
  GridValidRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconEdit from "@mui/icons-material/Edit";
import IconDelete from "@mui/icons-material/Delete";

import {
  useDiscardPayrollMutation,
  useGetAllPayrollsByCompanyIdQuery,
  useUpdatePayrollMutation,
} from "../../../features/api/apiPayrolls";
import { Payroll } from "../../../types/Payroll";
import { displayColumnCurrencyGBP } from "../../../utils/datagridFormatters";
import { PayrollEntry } from "../../../types/PayrollEntry";

const usePayrollMutation = () => {
  return useCallback(
    (payroll: Partial<Payroll>) =>
      new Promise<Partial<Payroll>>((resolve, reject) =>
        setTimeout(() => {
          resolve({
            ...payroll,
          });
        }, 0)
      ),
    []
  );
};

const DataGridPayrolls: FC = () => {
  const navigate = useNavigate();

  const currentCompanyId = useAppSelector((state) => state.currentCompany.id);

  const mutateRow = usePayrollMutation();

  const [rows, setRows] = useState<Partial<Payroll>[] | undefined>();

  const [pageSize, setPageSize] = useState<number>(10);

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const {
    data: dataGetAllPayrollsByCompanyId,
    isLoading: isLoadingGetAllPayrollsByCompanyId,
  } = useGetAllPayrollsByCompanyIdQuery(currentCompanyId);

  const [updatePayroll] = useUpdatePayrollMutation();
  const [discardPayroll] = useDiscardPayrollMutation();

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel) => {
      try {
        const response = await mutateRow(newRow);

        updatePayroll(newRow as Partial<Payroll>);

        setSnackbar({
          children: "Record successfully archived",
          severity: "success",
        });

        return response;
      } catch (error) {
        setSnackbar({
          children: `Error writing to store: ${error}`,
          severity: "error",
        });
      }
    },
    [mutateRow, updatePayroll]
  );

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  const handleEditClick = useCallback(
    (id: GridRowId) => {
      navigate("/payrolls/run", {
        state: { runPayrollActiveStep: 1, provisionedPayrollId: id },
      });
    },
    [navigate]
  );

  const handleDiscardClick = useCallback(
    (id: GridRowId) => {
      discardPayroll(String(id));
    },
    [discardPayroll]
  );

  const handleCloseSnackbar = () => setSnackbar(null);

  const displayColumnDate = (value: Date) => {
    const date = new Date(value);

    const dateFormatted = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(date);

    return dateFormatted;
  };

  const columns = useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: "pay_date_numeric",
        headerName: "Pay Date",
        type: "date",
        width: 160,
        editable: false,
        hide: true,
      },
      {
        field: "pay_date_actual",
        headerName: "Payroll Run Date",
        type: "date",
        width: 160,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.pay_date_actual
            ? displayColumnDate(params.row.pay_date_actual)
            : "",
      },
      {
        field: "pay_date_scheduled",
        headerName: "Scheduled Pay Date",
        type: "date",
        width: 160,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.pay_date_scheduled
            ? displayColumnDate(params.row.pay_date_scheduled)
            : "",
      },
      {
        field: "pay_schedule",
        headerName: "Pay Schedule",
        type: "string",
        width: 160,
        editable: false,
        hide: false,
      },
      {
        field: "pay_period",
        headerName: "Period",
        type: "number",
        width: 60,
        editable: false,
        hide: false,
      },
      {
        field: "tax_year",
        headerName: "Tax Year",
        type: "string",
        width: 100,
        editable: false,
        hide: false,
        align: "right",
      },
      {
        field: "total_cost",
        headerName: "Total",
        type: "number",
        width: 80,
        editable: false,
        hide: false,
        valueFormatter: ({ value }) => displayColumnCurrencyGBP(Number(value)),
      },
      {
        field: "status",
        headerName: "Status",
        type: "string",
        width: 120,
        editable: false,
        hide: false,
      },
      {
        field: "id",
        headerName: "ID",
        type: "string",
        width: 300,
        editable: false,
        hide: true,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 80,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<IconEdit />}
            label="Edit"
            onClick={() => handleEditClick(params.id)}
            showInMenu={true}
            disabled={false}
          />,
          <GridActionsCellItem
            icon={<IconDelete />}
            label="Discard"
            onClick={() => handleDiscardClick(params.id)}
            showInMenu={true}
            disabled={false}
          />,
        ],
      },
    ],
    [handleEditClick, handleDiscardClick]
  );

  useEffect(() => {
    const payrolls = dataGetAllPayrollsByCompanyId
      ?.filter((item) => item.status === "DRAFT")
      .map((item: Partial<Payroll>) => {
        const payrollEntries: PayrollEntry[] = item.payroll_entries;

        const totalPayrollCost =
          payrollEntries &&
          payrollEntries
            ?.map((item: PayrollEntry) => item.calculated_total_cost)
            .reduce((partialSum, a) => partialSum + a, 0);

        return {
          id: item.id,
          pay_date_numeric: item.pay_date_scheduled,
          pay_date_scheduled: item.pay_date_scheduled,
          pay_date_actual: item.pay_date_actual,
          pay_schedule: item.payroll_schedule.name,
          pay_period: item.pay_period,
          tax_year: item.tax_year.name,
          total_cost: totalPayrollCost,
          status: item.status,
        };
      });
    setRows(payrolls);
  }, [dataGetAllPayrollsByCompanyId]);

  if (isLoadingGetAllPayrollsByCompanyId) return <div>Loading...</div>;
  if (!dataGetAllPayrollsByCompanyId)
    return <div>No payrolls data to display...</div>;

  return (
    <>
      <Box sx={{ marginTop: "2rem", marginBottom: "1rem" }}>
        <Typography component="h1" variant="h4" gutterBottom>
          Draft Payrolls
        </Typography>
      </Box>

      <div style={{ height: 800, width: "100%" }}>
        <DataGridPro
          rows={rows || []}
          columns={columns}
          autoHeight={true}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          pagination
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          disableSelectionOnClick={true}
          initialState={{
            pinnedColumns: {
              left: ["name"],
              right: ["actions"],
            },
            sorting: {
              sortModel: [{ field: "pay_date_numeric", sort: "asc" }],
            },
          }}
          sx={{ backgroundColor: "white" }}
        />

        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </div>
    </>
  );
};

export default DataGridPayrolls;
