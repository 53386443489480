import { Dispatch, FC, SetStateAction, useEffect } from "react";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { Employee } from "../../../../types/Employee";
import { EmployeePayRate } from "../../../../types/EmployeePayRate";

interface Props {
  dataGetEmployee: Employee;
  dataGetAllEmployeePayRates: EmployeePayRate[];
  setActiveStep: Dispatch<SetStateAction<number>>;
  setCanActivate: Dispatch<SetStateAction<boolean>>;
}

const ValidationChecks: FC<Props> = ({
  dataGetEmployee,
  dataGetAllEmployeePayRates,
  setActiveStep,
  setCanActivate,
}) => {
  useEffect(() => {
    setCanActivate(true);
  }, [setCanActivate]);

  // Check 01 : Ensure Employment Type is set
  if (dataGetEmployee && !dataGetEmployee.employment_type) {
    setCanActivate(false);
    return (
      <>
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          <Typography>
            <strong>Cannot activate Employee:</strong> An employee must be
            assigned an Employment Type before activation
          </Typography>
          <Button
            sx={{ marginTop: "0.5rem" }}
            variant="outlined"
            onClick={() => setActiveStep(1)}
          >
            Resolve issue
          </Button>
        </Alert>
      </>
    );
  }

  // Check 02 : Ensure Job Date Started is set
  if (dataGetEmployee && !dataGetEmployee.job_date_started) {
    setCanActivate(false);
    return (
      <>
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          <Typography>
            <strong>Cannot activate Employee:</strong> An employee must be
            assigned a Start Date.
          </Typography>
          <Button
            sx={{ marginTop: "0.5rem" }}
            variant="outlined"
            onClick={() => setActiveStep(1)}
          >
            Resolve issue
          </Button>
        </Alert>
      </>
    );
  }

  // Check 03 : Ensure Payroll Schedule is set
  if (dataGetEmployee && !dataGetEmployee.payroll_schedule) {
    setCanActivate(false);
    return (
      <>
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          <Typography>
            <strong>Cannot activate Employee:</strong> An employee must be
            assigned to a Payroll Schedule before activation
          </Typography>
          <Button
            sx={{ marginTop: "0.5rem" }}
            variant="outlined"
            onClick={() => setActiveStep(1)}
          >
            Resolve issue
          </Button>
        </Alert>
      </>
    );
  }

  // Check 04 : Ensure Payment Method is set
  if (dataGetEmployee && !dataGetEmployee.payroll_payment_method) {
    setCanActivate(false);
    return (
      <>
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          <Typography>
            <strong>Cannot activate Employee:</strong> An employee must have a
            Payment Method selected
          </Typography>
          <Button
            sx={{ marginTop: "0.5rem" }}
            variant="outlined"
            onClick={() => setActiveStep(3)}
          >
            Resolve issue
          </Button>
        </Alert>
      </>
    );
  }

  // Check 05: Ensure at least one Employee Pay rate assigned
  if (dataGetAllEmployeePayRates?.length === 0) {
    setCanActivate(false);
    return (
      <>
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>{" "}
          <Typography>
            <strong>Cannot activate Employee:</strong> An employee must be
            assigned at least one Employee Rate of Pay before activation
          </Typography>
          <Button
            sx={{ marginTop: "0.5rem" }}
            variant="outlined"
            onClick={() => setActiveStep(2)}
          >
            Resolve issue
          </Button>
        </Alert>
      </>
    );
  }

  return (
    <>
      <Typography>
        All required data for Employee is completed. Activate employee?
      </Typography>
    </>
  );
};

export default ValidationChecks;
