import { FC, useState, Dispatch, SetStateAction } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import TabsPayrolls from "./TabsPayrolls";
import Footer from "./Footer";

interface Props {
  canProgress: boolean;
  setCanProgress: Dispatch<SetStateAction<boolean>>;
  handleStepperNext: () => void;
}

const DashboardPayrollSelect: FC<Props> = ({
  canProgress,
  setCanProgress,
  handleStepperNext,
}) => {
  const [loading, setLoading] = useState(false);

  const [selectedPayroll, setSelectedPayroll] = useState<string>("");

  return (
    <>
      <Box sx={{ marginTop: "3rem" }}>
        <Typography variant="h4" component="h3" gutterBottom>
          Select a Payroll Schedule
        </Typography>
      </Box>

      <TabsPayrolls
        selectedPayroll={selectedPayroll}
        setSelectedPayroll={setSelectedPayroll}
        setCanProgress={setCanProgress}
      />

      <Footer
        selectedPayroll={selectedPayroll}
        canProgress={canProgress}
        loading={loading}
        setLoading={setLoading}
        handleStepperNext={handleStepperNext}
      />
    </>
  );
};

export default DashboardPayrollSelect;
