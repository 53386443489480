import { FC, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import SubHeader from "../SubHeader";
import DashboardPayrollSelect from "./01-select-payroll/DashboardPayrollSelect";
import DashboardEnterPayroll from "./02-enter-payroll/DashboardEnterPayroll";
import PreviewPayroll from "./03-preview-payroll/PreviewPayroll";
import PayrollSummary from "./04-payroll-summary/PayrollSummary";

const steps = [
  "Select Pay Schedule",
  "Enter Payroll",
  "Preview Payroll",
  "Payroll Summary",
];

const StepperRunPayroll: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [canProgress, setCanProgress] = useState(false);

  const { state }: any = useLocation();
  const { runPayrollActiveStep } = state || {};

  useEffect(() => {
    runPayrollActiveStep && setActiveStep(runPayrollActiveStep);
  }, [runPayrollActiveStep]);

  const isStepOptional = (step: number) => {
    // return step === 2;
    return step === null;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleStepperNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleStepperPrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <SubHeader title="Run Payroll" page="run-payroll" icon="receipt_long" />

      <Container maxWidth="xl" sx={{ width: "100%" }}>
        <Box sx={{ width: "100%", marginTop: "1rem" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </>
          ) : (
            <>
              {activeStep === 0 && (
                <DashboardPayrollSelect
                  canProgress={canProgress}
                  setCanProgress={setCanProgress}
                  handleStepperNext={handleStepperNext}
                />
              )}
              {activeStep === 1 && (
                <DashboardEnterPayroll handleStepperNext={handleStepperNext} />
              )}
              {activeStep === 2 && (
                <PreviewPayroll
                  handleStepperPrev={handleStepperPrev}
                  handleStepperNext={handleStepperNext}
                />
              )}
              {activeStep === 3 && <PayrollSummary />}
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default StepperRunPayroll;
