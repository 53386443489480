import { FC } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { RootState } from "../../../../../app/store";

import BlockText from "../../BlockText";

import { useGetCompanyQuery } from "../../../../../features/api/apiCompanies";

const DetailsCompanyProfile: FC = () => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const { data: dataGetCompany } = useGetCompanyQuery(currentCompanyId);

  return (
    <>
      <BlockText
        title="Company Legal Name"
        data={dataGetCompany?.name_legal || ""}
      />

      <BlockText
        title="Company Trading Name"
        data={dataGetCompany?.name_trading || ""}
      />

      <BlockText
        title="Company Incorporation Number"
        data={dataGetCompany?.company_incorporation_number || ""}
      />

      <BlockText
        title="Company Tax Reference Number"
        data={dataGetCompany?.company_tax_reference_number || ""}
      />
    </>
  );
};

export default DetailsCompanyProfile;
