import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "./components/common/routing/ProtectedRoute";

// Layout
import LayoutSignIn from "./layouts/LayoutSignIn";
import LayoutApp from "./layouts/LayoutApp";
import LayoutCompanyAdd from "./layouts/LayoutCompanyAdd";

// Sign In
import DashboardSignIn from "./pages/signin/DashboardSignIn";
import DashboardPasswordReset from "./pages/reset/DashboardPasswordReset";

// Home
import DashboardHome from "./pages/home/DashboardHome";

// Company
import DashboardCompanyAdd from "./pages/company/add/DashboardCompanyAdd";
import DashboardCompanySelect from "./pages/company/select/DashboardCompanySelect";
import DashboardCompany from "./pages/company/DashboardCompany";
import DashboardCompanyInformation from "./pages/company/edit/01-information/DashboardCompanyInformation";
import DashboardMembers from "./pages/company/edit/02-members/DashboardMembers";
import DashboardBilling from "./pages/company/edit/03-billing/DashboardCompanyBilling";

// Payrolls
import {
  DashboardPayrolls,
  DashboardPayrollsOverview,
  RunPayroll,
  DashboardPayrollHistory,
  DashboardPayrollRecord,
  DashboardPayrollDrafts,
  DashboardPayComponents,
  DashboardPaySummary,
  DashboardPayslip,
  DashboardSchemes,
  DashboardPaySchedules,
  PaymentMethods,
  PayslipTemplates,
  PayrollMakeup,
} from "./pages/payrolls";

// Employees
import Employees from "./pages/employees/Employees";
import DashboardEmployeesOverview from "./pages/employees/overview/DashboardOverview";
import DashboardEmployees from "./pages/employees/dashboard/DashboardEmployees";
import DashboardEmployee from "./pages/employees/addEdit/DashboardEmployee";

// Tax Forms
import {
  TaxForms,
  DashboardTaxFormsOverview,
  DashboardTaxFormT14,
  DashboardTaxFormT14View,
  DashboardTaxFormT21,
  DashboardTaxFormT21View,
  DashboardTaxFormT35,
  DashboardYearEndDocuments,
  DashboardYearEndDocumentsPreview,
  DashboardTaxRates,
  DashboardNicRates,
} from "./pages/tax-forms";

// Ancillary
import Settings from "./pages/settings";
import Support from "./pages/support";
import DashboardAccount from "./pages/account/DashboardAccount";
import DashboardAccountOverview from "./pages/account/overview/DashboardAccountOverview";
import DashboardAccountCompany from "./pages/account/company/DashboardAccountCompany";

// Notifications

import DashboardWelcome from "./pages/signup/welcome/DashboardWelcome";

// Signup
import LayoutSignUp from "./layouts/LayoutSignup";
import DashboardSignUp from "./pages/signup/DashboardSignup";
import DashboardCreateAccount from "./pages/signup/create-account/DashboardCreateAccount";
import DashboardCodeResend from "./pages/signup/resend-code/DashboardCodeResend";

export default function App() {
  return (
    <>
      <Routes>
        {/* LANDING */}
        <Route element={<LayoutSignIn />}>
          <Route path="/" element={<DashboardSignIn />}></Route>
        </Route>

        {/* SIGN IN : Public Route */}
        <Route element={<LayoutSignIn />}>
          <Route path="signin" element={<DashboardSignIn />}></Route>
          <Route
            path="password-reset"
            element={<DashboardPasswordReset />}
          ></Route>
        </Route>

        {/* ONBOARDING : Public Routes */}
        <Route element={<LayoutSignUp />}>
          <Route path="signup" element={<DashboardSignUp />}>
            <Route index element={<DashboardCreateAccount />} />
            <Route path="resend" element={<DashboardCodeResend />} />
          </Route>
        </Route>

        {/* ONBOARDING : Private Setup */}
        <Route path="/" element={<ProtectedRoute />}>
          <Route element={<LayoutCompanyAdd />}>
            <Route path="welcome" element={<DashboardWelcome />}></Route>
            <Route path="company/add" element={<DashboardCompanyAdd />} />
          </Route>
        </Route>

        {/* APP : Protected Routes */}
        <Route path="/" element={<ProtectedRoute />}>
          <Route element={<LayoutApp />}>
            {/* Home */}
            <Route path="home" element={<DashboardHome />} />

            {/* Company */}
            <Route path="company" element={<DashboardCompany />}>
              <Route path="select" element={<DashboardCompanySelect />} />
              <Route
                path="information"
                element={<DashboardCompanyInformation />}
              />
              <Route path="members" element={<DashboardMembers />} />
              <Route path="billing" element={<DashboardBilling />} />
            </Route>

            {/* Payrolls */}
            <Route path="payrolls" element={<DashboardPayrolls />}>
              <Route path="overview" element={<DashboardPayrollsOverview />} />
              <Route path="run" element={<RunPayroll />} />
              <Route path="run/makeup" element={<PayrollMakeup />} />
              <Route path="payslip" element={<DashboardPayslip />} />
              <Route path="drafts" element={<DashboardPayrollDrafts />} />
              <Route path="history" element={<DashboardPayrollHistory />} />
              <Route
                path="history/payroll-record"
                element={<DashboardPayrollRecord />}
              />
              <Route
                path="history/pay-summary"
                element={<DashboardPaySummary />}
              />
              <Route path="pay-schedules" element={<DashboardPaySchedules />} />
              <Route
                path="pay-components"
                element={<DashboardPayComponents />}
              />
              <Route path="schemes" element={<DashboardSchemes />} />
              <Route path="payment-methods" element={<PaymentMethods />} />
              <Route path="payslip-templates" element={<PayslipTemplates />} />
            </Route>

            {/* Employees */}
            <Route path="employees" element={<Employees />}>
              <Route path="overview" element={<DashboardEmployeesOverview />} />
              <Route path="all" element={<DashboardEmployees />} />
              <Route path="add" element={<DashboardEmployee />} />
              <Route path="edit" element={<DashboardEmployee />} />
            </Route>

            {/* Tax Forms */}
            <Route path="tax-forms" element={<TaxForms />}>
              <Route path="overview" element={<DashboardTaxFormsOverview />} />
              <Route path="t14" element={<DashboardTaxFormT14 />} />
              <Route path="t14/view" element={<DashboardTaxFormT14View />} />
              <Route path="t21" element={<DashboardTaxFormT21 />} />
              <Route path="t21/view" element={<DashboardTaxFormT21View />} />
              <Route path="t35" element={<DashboardTaxFormT35 />} />
              <Route path="t37" element={<DashboardYearEndDocuments />} />
              <Route
                path="t37/preview"
                element={<DashboardYearEndDocumentsPreview />}
              />
            </Route>

            {/* Rates */}
            <Route path="rates">
              <Route path="tax" element={<DashboardTaxRates />} />
              <Route path="nic" element={<DashboardNicRates />} />
            </Route>

            {/* Account */}
            <Route path="account" element={<DashboardAccount />}>
              <Route path="overview" element={<DashboardAccountOverview />} />
              <Route path="companies" element={<DashboardAccountCompany />} />
            </Route>
          </Route>

          {/* 404 */}
          {/* TODO(DBB) : Present 404 properly, return Home link */}
          <Route path="*" element={<p>Error: 404!</p>} />

          {/* Support */}
          <Route path="support" element={<Support />}></Route>

          {/* Settings */}
          <Route path="settings" element={<Settings />}></Route>
        </Route>
      </Routes>
    </>
  );
}
