import { FC, ReactNode } from "react";

import Box from "@mui/material/Box";

interface Props {
  children?: ReactNode;
}

const FooterForm: FC<Props> = ({ children }) => {
  return (
    <Box
      sx={{
        borderTop: "1px solid #E7E6E9",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};

export default FooterForm;
