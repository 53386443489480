import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../app/store";
import { useAppSelector } from "../../../app/hooks";

import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
  GridRowParams,
  GridValidRowModel,
  GridValueGetterParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconDisable from "@mui/icons-material/DisabledByDefault";
import IconActivate from "@mui/icons-material/PowerSettingsNew";

import CtaEmployeeAdd from "./CtaEmployeeAdd";
import AlertDataMissing from "../AlertDataMissing";
import RenderCellConditionalStatus from "../../../utils/RenderCellConditionalStatus";

import {
  useGetAllEmployeesByCompanyIdQuery,
  useUpdateEmployeeMutation,
} from "../../../features/api/apiEmployees";
import { useGetAllPayrollSchedulesByCompanyIdQuery } from "../../../features/api/apiPayrollSchedules";
import { useGetAllPayComponentsByCompanyIdQuery } from "../../../features/api/apiPayComponents";
import { Employee } from "../../../types/Employee";
import { PayrollSchedule } from "../../../types/PayrollSchedule";

interface Props {
  handleModalOpen: (employeeId: number, employeeLeaveDate: Date) => void;
}

const DataGridEmployees: FC<Props> = ({ handleModalOpen }) => {
  const navigate = useNavigate();

  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const { data: dataPayrollSchedulesByCompanyId } =
    useGetAllPayrollSchedulesByCompanyIdQuery(currentCompanyId);

  const { data: dataPayComponentsByCompanyId } =
    useGetAllPayComponentsByCompanyIdQuery(currentCompanyId);

  const [rows, setRows] = useState<Partial<Employee>[] | undefined>();

  const [pageSize, setPageSize] = useState<number>(20);

  const { data: dataGetAllEmployeesByCompanyId } =
    useGetAllEmployeesByCompanyIdQuery(currentCompanyId);

  const [updateEmployee] = useUpdateEmployeeMutation();

  const displayColumnDate = (value: Date) => {
    const date = new Date(value);

    const dateFormatted = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(date);

    return dateFormatted;
  };

  const handleEditClick = useCallback(
    (id: GridRowId) => {
      navigate("/employees/edit", {
        state: { employeeId: id },
      });
    },
    [navigate]
  );

  const handleEmployeeLeaveClick = useCallback(
    (employeeId: GridRowId, employeeLeaveDate: Date) => {
      handleModalOpen(Number(employeeId), employeeLeaveDate);
    },
    [handleModalOpen]
  );

  const handleActivateClick = useCallback(
    (params: GridRowParams) => {
      const { id, status } = params.row;

      // TODO(DBB) : Consider a check for that all required data
      // exists on record, otherwise block Activation with
      // advisory to user
      if (status === "DRAFT") {
        const submission = {
          id,
          status: "ACTIVE",
        };
        updateEmployee(submission);
      }

      if (status === "ACTIVE") {
        const submission = {
          id,
          status: "DEACTIVATED",
        };
        updateEmployee(submission);
      }

      if (status === "DEACTIVATED") {
        const submission = {
          id,
          status: "ACTIVE",
        };
        updateEmployee(submission);
      }
    },
    [updateEmployee]
  );

  const handleArchiveClick = useCallback(
    (params: GridRowParams) => {
      const { id } = params.row;
      const dateNow = new Date();

      const submission = {
        id,
        date_time_archived: dateNow,
        status: "ARCHIVED",
      };

      // TODO(DBB) : IMPORTANT : Does archiving Employee
      // correctly archive children entities (employee_pay_rate,
      // employee_scheme, etc...?)

      updateEmployee(submission);
    },
    [updateEmployee]
  );

  const columns = useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: "id",
        headerName: "ID",
        type: "string",
        width: 80,
        editable: false,
        hide: true,
      },
      {
        field: "employee_number",
        headerName: "No.",
        type: "string",
        width: 80,
        editable: false,
        hide: false,
      },
      {
        field: "forenames",
        headerName: "Forenames",
        type: "string",
        width: 130,
        editable: false,
        hide: false,
      },
      {
        field: "surname",
        headerName: "Surname",
        type: "string",
        width: 130,
        editable: false,
        hide: false,
      },
      {
        field: "job_title",
        headerName: "Job Title",
        type: "string",
        width: 130,
        editable: false,
        hide: false,
      },
      {
        field: "employment_type",
        headerName: "Employment",
        type: "string",
        width: 130,
        editable: false,
        hide: false,
      },
      {
        field: "job_date_started",
        headerName: "Start Date",
        type: "date",
        width: 100,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.job_date_started
            ? displayColumnDate(params.row.job_date_started)
            : null,
      },
      {
        field: "job_date_finished",
        headerName: "Finish Date",
        type: "date",
        width: 100,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.job_date_finished
            ? displayColumnDate(params.row.job_date_finished)
            : null,
      },
      {
        field: "payroll_schedule",
        headerName: "Pay Schedule",
        type: "string",
        width: 130,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.payroll_schedule && params.row.payroll_schedule?.name,
      },
      {
        field: "ni_number",
        headerName: "NI No.",
        type: "string",
        width: 130,
        editable: false,
        hide: false,
      },
      {
        field: "tax_reference_number",
        headerName: "Tax Ref. No.",
        type: "string",
        width: 130,
        editable: false,
        hide: false,
      },
      {
        field: "tax_code",
        headerName: "Tax Code",
        type: "string",
        width: 80,
        editable: false,
        hide: false,
      },
      {
        field: "status",
        headerName: "Status",
        type: "string",
        width: 90,
        editable: false,
        hide: false,
        renderCell: (params: GridRenderCellParams<string>) => (
          <RenderCellConditionalStatus status={params.row.status} />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 80,
        getActions: (params: GridRowParams) => [
          ...(params.row.status === "DRAFT"
            ? [
                <GridActionsCellItem
                  icon={<IconActivate />}
                  label="Activate"
                  onClick={() => handleActivateClick(params)}
                  showInMenu={true}
                  disabled={false}
                />,
              ]
            : []),
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={() => handleEditClick(params.id)}
            showInMenu={true}
            disabled={false}
          />,
          ...(params.row.status === "ACTIVE" && !params.row.job_date_finished
            ? [
                <GridActionsCellItem
                  icon={<IconDisable />}
                  label="Set Leave Date"
                  onClick={() =>
                    handleEmployeeLeaveClick(
                      params.id,
                      params.row.job_date_finished
                    )
                  }
                  showInMenu={true}
                  disabled={false}
                />,
              ]
            : []),
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Archive"
            onClick={() => handleArchiveClick(params)}
            showInMenu={true}
            disabled={false}
          />,
        ],
      },
    ],
    [
      handleActivateClick,
      handleEditClick,
      handleEmployeeLeaveClick,
      handleArchiveClick,
    ]
  );

  useEffect(() => {
    const employees =
      dataGetAllEmployeesByCompanyId &&
      dataGetAllEmployeesByCompanyId
        ?.filter(
          (item) =>
            item.status === "ACTIVE" ||
            item.status === "DRAFT" ||
            item.status === "DEACTIVATED"
          // || item.status === "ARCHIVED"
        )
        .map((item: Partial<Employee>) => {
          return {
            id: item.id || "",
            employee_number: item.employee_number || "",
            forenames: item.forenames || "",
            surname: item.surname || "",
            ni_number: item.ni_number || "",
            tax_reference_number: item.tax_reference_number || "",
            tax_code: item.tax_code || "",
            employment_type: item.employment_type,
            job_date_started: item.job_date_started,
            job_date_finished: item.job_date_finished,
            payroll_schedule:
              item.payroll_schedule || ("" as unknown as PayrollSchedule),
            job_title: item.job_title || "",
            status: item.status || "",
          };
        });

    setRows(employees);
  }, [dataGetAllEmployeesByCompanyId]);

  return (
    <>
      {dataGetAllEmployeesByCompanyId?.length === 0 ? (
        <>
          {dataPayrollSchedulesByCompanyId?.length === 0 && (
            <AlertDataMissing
              entityName="Payroll Schedule"
              linkAdd="/payrolls/pay-schedules"
              sx={{ marginBottom: "2rem" }}
            />
          )}

          {dataPayComponentsByCompanyId?.length === 0 && (
            <AlertDataMissing
              entityName="Pay Component"
              linkAdd="/payrolls/pay-components"
              sx={{ marginBottom: "2rem" }}
            />
          )}

          <Typography component="h3" variant="h4" gutterBottom>
            You have no Employees.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
            Create your first employee in order to manage payroll for them.
          </Typography>
          <CtaEmployeeAdd
            disabled={
              dataPayrollSchedulesByCompanyId?.length === 0 ||
              dataPayComponentsByCompanyId?.length === 0
            }
          />
        </>
      ) : (
        <div className="w-100">
          <DataGridPro
            rows={rows || []}
            columns={columns}
            autoHeight={true}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50]}
            pagination
            initialState={{
              pinnedColumns: {
                right: ["status", "actions"],
              },
              sorting: {
                sortModel: [{ field: "status", sort: "asc" }],
              },
            }}
            sx={{ backgroundColor: "white" }}
          />
        </div>
      )}
    </>
  );
};

export default DataGridEmployees;
