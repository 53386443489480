import { FC } from "react";
import { RootState } from "../../../../app/store";
import { useAppSelector } from "../../../../app/hooks";
import { intlFormatAmount } from "../../../../utils/currencyFormatter";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useGetPayrollQuery } from "../../../../features/api/apiPayrolls";
import { useGetAllPayrollEntriesByPayrollIdQuery } from "../../../../features/api/apiPayrollEntries";
import { PayrollEntry } from "../../../../types/PayrollEntry";
import { dateFormatter } from "../../../../utils/dateFormatter";
import Divider from "@mui/material/Divider/Divider";

const HeaderSummary: FC = () => {
  const provisionedPayrollId: string = useAppSelector(
    (state: RootState) => state.provisionedPayroll.id
  );

  const { data: dataPayroll } = useGetPayrollQuery(provisionedPayrollId);

  const { data: dataPayrollEntriesByPayrollId } =
    useGetAllPayrollEntriesByPayrollIdQuery(provisionedPayrollId);

  const displaySummaryCurrency = (value: number, currency: string) => {
    return intlFormatAmount(Number(value / 100), currency);
  };

  const displayPayDate = dateFormatter(dataPayroll?.pay_date_scheduled);

  const calculatedPayrollTotalAmount = dataPayrollEntriesByPayrollId
    ?.map((item: PayrollEntry) => item.calculated_employee_gross_normal_pay)
    .reduce((partialSum, a) => partialSum + a, 0);

  return (
    <>
      <Box sx={customStyles.defaultBox}>
        <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2 }}>
          {/* Pay Schedule */}
          <Grid item xs>
            <Typography
              component="p"
              variant="caption"
              gutterBottom
              sx={customStyles.subTitle}
            >
              Pay Schedule
            </Typography>

            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={customStyles.fontBold}
            >
              {dataPayroll?.payroll_schedule.name}
            </Typography>
          </Grid>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#E7E6E9" }}
          />

          {/* Pay Period */}
          <Grid item xs>
            <Typography
              component="p"
              variant="caption"
              gutterBottom
              sx={customStyles.subTitle}
            >
              Pay Period
            </Typography>

            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={customStyles.fontBold}
            >
              {dataPayroll?.pay_period} of {dataPayroll?.total_pay_periods}
            </Typography>
          </Grid>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#E7E6E9" }}
          />

          {/* Pay Date */}
          <Grid item xs>
            <Typography
              component="p"
              variant="caption"
              gutterBottom
              sx={customStyles.subTitle}
            >
              Scheduled Pay Date
            </Typography>

            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={customStyles.fontBold}
            >
              {displayPayDate}
            </Typography>
          </Grid>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#E7E6E9" }}
          />

          {/* Total Employees */}
          <Grid item xs>
            <Typography
              component="p"
              variant="caption"
              gutterBottom
              sx={customStyles.subTitle}
            >
              Total Employees
            </Typography>

            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={customStyles.fontBold}
            >
              {dataPayrollEntriesByPayrollId?.length}
            </Typography>
          </Grid>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#E7E6E9" }}
          />

          {/* Total Payroll */}
          <Grid item xs>
            <Typography
              component="p"
              variant="caption"
              gutterBottom
              sx={customStyles.subTitle}
            >
              Total Payroll
            </Typography>

            <Typography
              component="p"
              variant="body1"
              gutterBottom
              sx={customStyles.fontBold}
            >
              {displaySummaryCurrency(calculatedPayrollTotalAmount!, "GBP")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const customStyles = {
  defaultBox: {
    padding: "12px 16px 16px 16px",
    border: "1px solid #E7E6E9",
    borderRadius: "0.5rem",
    margin: "1rem 0",
    backgroundColor: "#ffffff",
  },

  fontBold: {
    fontWeight: "bold",
    fontSize: "1rem",
  },

  subTitle: {
    color: "rgba(47, 48, 51, 0.72)",
    textTransform: "uppercase",
    fontWeight: 500,
  },
};

export default HeaderSummary;
