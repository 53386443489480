import React from "react";
import ReactDOM from "react-dom/client";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./app/store";
import { BrowserRouter as Router } from "react-router-dom";
import { LicenseInfo } from "@mui/x-license-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import reportWebVitals from "./reportWebVitals";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { theme } from "./createTheme";

import "./index.css";

import App from "./App";

LicenseInfo.setLicenseKey(
  "81b84dbc6111a03e4edf99e5e17e3ce5Tz04Njk3NSxFPTE3NDI3MDgwNDIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);
// "906931bf1e384c8cfe12b9aab43550caTz01NDU3NCxFPTE3MDAzMjU4OTYxNDYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
// "dfbf4926978d706443dfc23fe4df1be3T1JERVI6MzE0NDAsRVhQSVJZPTE2NjY3NzU4NDIwMDAsS0VZVkVSU0lPTj0x"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Router>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              <App />
            </LocalizationProvider>
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// Ref: https://bit.ly/CRA-vitals
reportWebVitals();
