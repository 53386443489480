import { z } from "zod";

export type PasswordResetPassword = z.infer<typeof SchemaPasswordResetPassword>;

export const SchemaPasswordResetPassword = z
  .object({
    reset_token: z.string().uuid(),
    password_new: z.string(),
    password_confirm: z.string(),
  })
  .refine((data) => data.password_new === data.password_confirm, {
    message: "Passwords don't match",
    path: ["password_confirm"],
  });
