import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../app/store";
import { useAppSelector } from "../../../app/hooks";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button/Button";
import Stack from "@mui/material/Stack";
import IconGroupAdd from "@mui/icons-material/GroupAdd";
import IconBusiness from "@mui/icons-material/Business";

const DashboardWelcome: FC = () => {
  const navigate = useNavigate();

  const currentUserMemberCompanies = useAppSelector(
    (state: RootState) => state.currentUser?.members
  );

  useEffect(() => {
    currentUserMemberCompanies?.length > 0 && navigate("/home");
  }, [currentUserMemberCompanies, navigate]);

  return (
    <>
      <Container maxWidth="xl" sx={{ width: "100%", marginTop: "4rem" }}>
        {currentUserMemberCompanies?.length === 0 && (
          <Box>
            <Typography component="h2" variant="h4">
              Welcome to Cloud Payroll
            </Typography>

            <Grid container sx={{ marginTop: "1.5rem", marginBottom: "3rem" }}>
              <Grid item xs={12} md={10} lg={8}>
                <Typography variant="body1">
                  Welcome to <strong>Cloud Payroll</strong>, your trusted online
                  payroll platform designed to streamline employee payroll and
                  schemes for your Isle of Man business. We're proud to serve
                  our growing community of businesses, by offering the
                  convenience of effortless payroll management and delivery.
                </Typography>
              </Grid>
            </Grid>

            <Stack direction="row" gap={8}>
              <Stack
                direction="column"
                gap={2}
                sx={{
                  width: "100%",
                  border: "1px solid #E7E6E9",
                  padding: "1.5rem",
                  borderRadius: "0.5rem",
                }}
              >
                <IconGroupAdd color="primary" sx={{ fontSize: "4rem" }} />

                <Typography component="h3" variant="h6">
                  Join Existing Team
                </Typography>

                <Typography component="p">
                  If you are joining an existing company, please advise your
                  system admistration that your account is setup.
                </Typography>
                <Typography component="p">
                  They will be able to grant your account access through the{" "}
                  <strong>Company &gt; Members</strong> dashboard.
                </Typography>
              </Stack>

              <Stack
                direction="column"
                gap={2}
                sx={{
                  width: "100%",
                  border: " 1px solid #E7E6E9",
                  padding: "1.5rem",
                  borderRadius: "0.5rem",
                }}
              >
                <IconBusiness color="primary" sx={{ fontSize: "4rem" }} />

                <Typography component="h3" variant="h6">
                  New Company
                </Typography>

                <Typography component="p">
                  If you are creating your own company, please complete the
                  following forms with your company information.
                </Typography>

                <Button
                  type="button"
                  onClick={() => navigate("/company/add")}
                  variant="contained"
                  sx={{ maxWidth: "14rem" }}
                >
                  Create new company
                </Button>
              </Stack>
            </Stack>
          </Box>
        )}
      </Container>
    </>
  );
};

export default DashboardWelcome;
