import { Dispatch, FC, SetStateAction } from "react";

import Button from "@mui/material/Button";

import IconAdd from "@mui/icons-material/Add";

interface Props {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CtaMemberAdd: FC<Props> = ({ setModalOpen }) => {
  return (
    <>
      <Button
        onClick={() => setModalOpen(true)}
        variant="contained"
        color="primary"
        size="medium"
        endIcon={<IconAdd />}
        disabled={false}
        disableElevation={true}
      >
        Add Members
      </Button>
    </>
  );
};

export default CtaMemberAdd;
