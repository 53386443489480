import { cloudPayrollApi } from "./cloudPayrollApi";

import { SchemeType } from "../../types/SchemeType";

const apiSchemes = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // CREATE Scheme Type
    createSchemeType: builder.mutation<void, SchemeType>({
      query: (SchemeType) => ({
        url: `/schemes-types`,
        method: "POST",
        body: SchemeType,
      }),
      invalidatesTags: ["SchemeType"],
    }),

    // READ All Scheme Types
    getAllSchemeTypes: builder.query<SchemeType[], number | void>({
      query: (limit = 10) => `/scheme-types?limit=${limit}`,
      providesTags: ["SchemeType"],
    }),

    // READ Single Scheme Type
    getSchemeType: builder.query<SchemeType, number>({
      query: (id) => `/scheme-types/${id}`,
      providesTags: ["SchemeType"],
    }),

    // UPDATE Scheme Type
    updateSchemeType: builder.mutation<void, SchemeType>({
      query: ({ id, ...rest }) => ({
        url: `/scheme-types/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["SchemeType"],
    }),

    // DELETE Scheme Type
    deleteSchemeType: builder.mutation<void, number>({
      query: (id) => ({
        url: `/scheme-types/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SchemeType"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateSchemeTypeMutation,
  useGetAllSchemeTypesQuery,
  useGetSchemeTypeQuery,
  useUpdateSchemeTypeMutation,
  useDeleteSchemeTypeMutation,
} = apiSchemes;
