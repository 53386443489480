import { FC, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import { useUpdatePayrollMutation } from "../../../features/api/apiPayrolls";

interface Props {
  modalUnlockOpen: boolean;
  setModalUnlockOpen: Dispatch<SetStateAction<boolean>>;
  selectedPayrollId: string;
}

const ModalActionPayrollUnlock: FC<Props> = ({
  modalUnlockOpen,
  setModalUnlockOpen,
  selectedPayrollId,
}) => {
  const navigate = useNavigate();

  const [updatePayroll] = useUpdatePayrollMutation();

  const handlePayrollUnlockClick = () => {
    const submission = {
      id: selectedPayrollId,
      status: "DRAFT",
    };

    selectedPayrollId &&
      updatePayroll(submission)
        .then(() => {
          setModalUnlockOpen(false);
        })
        .then(() => {
          navigate("/payrolls/drafts");
        })
        .catch((error) => console.error(error));
  };

  return (
    <Modal open={modalUnlockOpen} onClose={() => setModalUnlockOpen(false)}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: 720,
          width: "100%",
          backgroundColor: "white",
          padding: "1.5rem",
          borderRadius: "1rem",
          boxShadow: 24,
        }}
      >
        <Stack direction="column" gap={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Unlock Completed Payroll
          </Typography>
          <Typography id="modal-modal-description">
            Are you sure that you want to unlock previous payroll?
          </Typography>
          <Typography id="modal-modal-description">
            Making changes to the payroll history will invalidate cumulative
            totals of all following payrolls and should be avoided.
          </Typography>
          <Typography id="modal-modal-description">
            It is recommended to contact{" "}
            <Link href="mailto:support@cloudpayroll.io">
              support@cloudpayroll.io
            </Link>{" "}
            for any advanced edits.
          </Typography>
          <Typography id="modal-modal-description">
            Proceeding will send this Payroll to the{" "}
            <strong>Payroll Drafts</strong> dashboard.
          </Typography>

          <Divider />

          <Stack direction="row" gap={2}>
            <Button
              type="button"
              onClick={() => handlePayrollUnlockClick()}
              variant="outlined"
              size="large"
              color="error"
              disableElevation={true}
            >
              I understand, unlock Payroll
            </Button>
            <Button
              type="button"
              onClick={() => setModalUnlockOpen(false)}
              variant="contained"
              size="large"
              disableElevation={true}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ModalActionPayrollUnlock;
