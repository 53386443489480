import { FC, useCallback, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import IconDownload from "@mui/icons-material/Download";

import { getPdfFormFormT14sZip } from "../../../utils/getPdfFormZip";

const Footer: FC = () => {
  const currentCompany = useAppSelector(
    (state: RootState) => state.currentCompany
  );
  const currentTaxYear = useAppSelector(
    (state: RootState) => state.currentTaxYear
  );

  const [loadingDownloadAllT14s, setLoadingDownloadAllT14s] =
    useState<boolean>(false);

  const handleDownloadAllT14sClick = useCallback(() => {
    setLoadingDownloadAllT14s(true);

    getPdfFormFormT14sZip(currentCompany.id!, Number(currentTaxYear.id)!)
      .then((data) => {
        if (data) {
          const filename = `FormT14s`;
          const safeFilename = filename.replace(/[^a-z0-9]/gi, "-");

          const link = document.createElement("a");
          link.href = data;
          link.setAttribute("download", `${safeFilename}.zip`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .then(() => setLoadingDownloadAllT14s(false));
  }, [currentCompany, currentTaxYear]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Box>
          <LoadingButton
            variant="contained"
            size="medium"
            endIcon={<IconDownload />}
            onClick={handleDownloadAllT14sClick}
            loading={loadingDownloadAllT14s}
          >
            Download all Form T14s
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
