import { cloudPayrollApi } from "./cloudPayrollApi";

import { TaxRateRow } from "../../types/TaxRateRow";

const apiTaxRates = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // READ All Tax Rates
    getAllTaxRates: builder.query<TaxRateRow[], void>({
      query: () => `/im/tax-rates`,
      providesTags: ["TaxRate"],
    }),

    // READ Single Tax Rate by Year Index
    getTaxRateByYear: builder.query<TaxRateRow[], number | void>({
      query: (yearIndex) => `/im/tax-rates/year/${yearIndex}`,
      providesTags: ["TaxRate"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllTaxRatesQuery, useLazyGetTaxRateByYearQuery } =
  apiTaxRates;
