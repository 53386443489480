import { FC, useMemo, useState, useContext } from "react";
import { useAppSelector } from "../../../app/hooks";
import { CurrentPayrollScheduleContext } from "./DashboardPaySchedules";

import {
  DataGridPro,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridColumns,
  GridRowParams,
  GridRowsProp,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";

import IconEdit from "@mui/icons-material/Edit";
import IconDelete from "@mui/icons-material/Delete";

import NoData from "../NoData";

import { useGetAllPayrollSchedulesByCompanyIdQuery } from "../../../features/api/apiPayrollSchedules";
import { PayrollSchedule } from "../../../types/PayrollSchedule";

interface Props {
  handleModalOpenEdit: () => void;
  handleModalOpenAdd: () => void;
}

const DataGridPaySchedules: FC<Props> = ({ handleModalOpenEdit, handleModalOpenAdd }) => {
  const currentCompanyId = useAppSelector((state) => state.currentCompany.id);

  const { data: dataGetAllPayrollSchedulesByCompanyId } =
    useGetAllPayrollSchedulesByCompanyIdQuery(currentCompanyId);

  const { setPayrollScheduleId } = useContext(CurrentPayrollScheduleContext);

  const [pageSize, setPageSize] = useState<number>(10);

  const columns: (GridColDef | GridActionsColDef)[] = useMemo<
    GridColumns<GridValidRowModel>
  >(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 64,
        editable: false,
        hide: true,
      },
      {
        field: "name",
        headerName: "Name",
        type: "string",
        width: 180,
        editable: false,
        hide: false,
      },
      {
        field: "payroll_frequency",
        headerName: "Pay Frequency",
        type: "string",
        width: 140,
        editable: false,
        hide: false,
      },
      {
        field: "total_pay_periods",
        headerName: "Pay Periods",
        type: "string",
        width: 140,
        editable: false,
        hide: false,
      },
      {
        field: "default_pay_day",
        headerName: "Default Pay Day",
        type: "string",
        width: 140,
        editable: false,
        hide: false,
      },
      {
        field: "date_time_created",
        headerName: "Created",
        type: "date",
        width: 100,
        editable: false,
        hide: true,
      },
      {
        field: "status",
        headerName: "Status",
        type: "string",
        width: 100,
        editable: false,
        hide: false,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 80,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<IconEdit />}
            label="Edit"
            onClick={() => {
              setPayrollScheduleId(Number(params.id));
              handleModalOpenEdit();
            }}
            showInMenu={true}
            disabled={false}
          />,
          <GridActionsCellItem
            icon={<IconDelete />}
            label="Archive"
            onClick={() => {
              // TODO(DBB) : Archive action...
              console.log("Archive");
            }}
            showInMenu={true}
            disabled={true}
          />,
        ],
      },
    ],
    [handleModalOpenEdit, setPayrollScheduleId]
  );

  const formatWeeklyPayDay = (index: number) => {
    const date = new Date(2023, 0, index);
    return date.toLocaleString("default", { weekday: "long" });
  };

  const formatMonthlyPayDay = (n: number) => {
    const suffix =
      ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
    return `${n}${suffix}`;
  };

  const rows: GridRowsProp = dataGetAllPayrollSchedulesByCompanyId
    ? dataGetAllPayrollSchedulesByCompanyId.map((item: PayrollSchedule) => {
        // NOTE(DBB) : Needs to be properly dynamic when other pay counts are reintroduced
        const calculatedTotalPayPeriods = item.monthly_pay_day
          ? "12 per year"
          : "52 per year";

        const defaultPayDay = item.monthly_pay_day
          ? formatMonthlyPayDay(item.monthly_pay_day!)
          : formatWeeklyPayDay(item.weekly_pay_day!);

        return {
          id: item.id,
          name: item.name,
          payroll_frequency: item.payroll_frequency?.name,
          total_pay_periods: calculatedTotalPayPeriods,
          date_time_created: item.date_time_created,
          default_pay_day: defaultPayDay,
          status: item.status,
        };
      })
    : [];

  return (
    <>
      {dataGetAllPayrollSchedulesByCompanyId?.length !== 0 ? (
        <DataGridPro
          rows={rows || []}
          columns={columns}
          autoHeight={true}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          pagination
          initialState={{
            pinnedColumns: { right: ["actions"] },
            sorting: {
              sortModel: [{ field: "date_time_created", sort: "desc" }],
            },
          }}
          sx={{ backgroundColor: "white" }}
        />
      ) : (
        <NoData
          title="You have no Pay Schedules"
          page="paySchedules"
          description="Pay Schedules are the frequency at which your company compensate groups of employees. Commonly this is as a monthly salary, but can also be configured in weekly payments."
          callback={handleModalOpenAdd}
        />
      )}
    </>
  );
};

export default DataGridPaySchedules;
