import { FC, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { useGetAllPayComponentsByCompanyIdQuery } from "../../../../features/api/apiPayComponents";
import { useCreatePayrollPayRateMutation } from "../../../../features/api/apiPayrollPayRates";
import type { PayComponent } from "../../../../types/PayComponent";
import type { PayrollEntry } from "../../../../types/PayrollEntry";
import { PayrollCountUnit } from "../../../../types/PayrollCountUnit";

const AddPayComponent: FC = () => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );
  const currentPayrollEntryId = useAppSelector(
    (state: RootState) => state.currentPayrollEntry.id
  );
  const [payComponentId, setPayComponentId] = useState("");

  // TODO(DBB) : By Company ID...
  const [createPayrollPayRate] = useCreatePayrollPayRateMutation();

  const {
    data: dataPayComponentsByCompanyId,
    isLoading: isLoadingPayComponentsByCompanyId,
    isFetching: isFetchingPayComponentsByCompanyId,
    isError: isErrorPayComponentsByCompanyId,
  } = useGetAllPayComponentsByCompanyIdQuery(currentCompanyId);

  if (isLoadingPayComponentsByCompanyId) return <div>Loading...</div>;
  if (isFetchingPayComponentsByCompanyId) return <div>Fetching...</div>;
  if (isErrorPayComponentsByCompanyId) return <div>Error!</div>;
  if (!dataPayComponentsByCompanyId) return <div>No data to display...</div>;

  const handleSelectChange = (event: SelectChangeEvent) => {
    setPayComponentId(event.target.value as string);
  };

  const handleSubmit = (payComponentId: string) => {
    const selectedPayComponent =
      dataPayComponentsByCompanyId.filter(
        (item: PayComponent) => Number(item.id) === Number(payComponentId)
      )[0] || null;

    // const payrollCountUnitId = selectedPayComponent.payroll_count_unit.id;
    const isGrossPay = selectedPayComponent.is_gross_pay;
    const isLiableToTax = selectedPayComponent.is_liable_to_tax;
    const isLiableToNi = selectedPayComponent.is_liable_to_ni;
    const isPensionable = selectedPayComponent.is_pensionable;

    // TODO(DBB) : Needs to inherit from Pay Component
    createPayrollPayRate({
      // payroll_entry: currentPayrollEntryId as unknown as PayrollEntry,
      payroll_entry: { id: currentPayrollEntryId! } as unknown as PayrollEntry,
      pay_component: Number(payComponentId) as unknown as PayComponent,
      // payroll_count_unit: Number(
      //   payrollCountUnitId
      // ) as unknown as PayrollCountUnit,
      pay_count: 0,
      pay_rate: 0,
      pay_amount: 0,
      is_gross_pay: isGrossPay,
      is_liable_to_tax: isLiableToTax,
      is_liable_to_ni: isLiableToNi,
      is_pensionable: isPensionable,
      status: "ACTIVE",
    })
      // .then((res) => console.log(res))
      .catch((error) => console.error("PPR add error: " + error));
  };

  return (
    <FormControl
      size="small"
      sx={{
        padding: "0.75rem 0.",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ padding: "0.5rem" }}
      >
        <Typography
          variant="body2"
          sx={{ padding: "0 1rem 0 0.25rem", color: "rgba(47, 48, 51, 0.72)" }}
        >
          Add component:
        </Typography>

        <Select
          id="new-pay-component-select"
          value={payComponentId}
          onChange={handleSelectChange}
          sx={{ minWidth: "12rem", paddingRight: "1rem" }}
        >
          {dataPayComponentsByCompanyId?.map((item) => {
            return (
              <MenuItem key={item.id} value={String(item.id)}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>

        <Button
          variant="contained"
          sx={{ width: "120px" }}
          onClick={() => handleSubmit(payComponentId)}
        >
          Add
        </Button>
      </Stack>
    </FormControl>
  );
};

export default AddPayComponent;
