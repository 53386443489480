import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import type { Company } from "../../../types/Company";

const initialState: Partial<Company> = {
  id: 0,
  name_trading: "",
  logo_url: "",
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCurrentCompany(state, action: PayloadAction<Partial<Company>>) {
      Object.entries(action.payload).forEach(([k, v]) => {
        state[k] = v;
      });
    },
    resetCurrentCompany: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentCompany, resetCurrentCompany } = companySlice.actions;

export default companySlice.reducer;
