import axios from "axios";

// PDF Form : Payslip

export const getPdfFormPayslip = async (payrollEntryId?: string) => {
  const apiUrl = process.env.REACT_APP_CLOUDPAYROLL_API_URL;
  const dynamicEndpoint = `${apiUrl}/pdf/createpayslip/${payrollEntryId}`;

  try {
    const instance = axios.create({
      baseURL: dynamicEndpoint,
      withCredentials: true,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    });

    const blobObjectUrl = instance.post("/").then((res) => {
      const blob = new Blob([res.data], { type: "application/pdf" });
      const blobObject = URL.createObjectURL(blob);

      return blobObject;
    });

    return blobObjectUrl;
  } catch (error) {
    console.error("Error downloading Payslip PDF object: " + error);
  }
};

// PDF Form : Payment Instruction

export const getPdfFormPaymentInstruction = async (payrollId: string) => {
  const apiUrl = process.env.REACT_APP_CLOUDPAYROLL_API_URL;
  const dynamicEndpoint = `${apiUrl}/pdf/generatepaymentinstruction/${payrollId}`;

  try {
    const instance = axios.create({
      baseURL: dynamicEndpoint,
      withCredentials: true,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    });

    const blobObjectUrl = instance.post("/").then((res) => {
      const blob = new Blob([res.data], { type: "application/pdf" });
      const blobObject = URL.createObjectURL(blob);

      return blobObject;
    });

    return blobObjectUrl;
  } catch (error) {
    console.error("Error downloading Payment Instruction PDF object: " + error);
  }
};

// PDF Form : T14

export const getPdfFormT14 = async (employeeId: string, taxYearId: number) => {
  const apiUrl = process.env.REACT_APP_CLOUDPAYROLL_API_URL;
  const dynamicEndpoint = `${apiUrl}/pdf/generateT14/${employeeId}/${taxYearId}`;

  try {
    const instance = axios.create({
      baseURL: dynamicEndpoint,
      withCredentials: true,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    });

    const blobObjectUrl = instance.post("/").then((res) => {
      const blob = new Blob([res.data], { type: "application/pdf" });
      const blobObject = URL.createObjectURL(blob);

      return blobObject;
    });

    return blobObjectUrl;
  } catch (error) {
    console.error("Error downloading Form T14 PDF object: " + error);
  }
};

// PDF Form : T37 Report

export const getPdfFormT37Report = async (
  companyId: number,
  taxYearId: number
) => {
  const apiUrl = process.env.REACT_APP_CLOUDPAYROLL_API_URL;
  const dynamicEndpoint = `${apiUrl}/pdf/generateT37/${companyId}/${taxYearId}`;

  try {
    const instance = axios.create({
      baseURL: dynamicEndpoint,
      withCredentials: true,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    });

    const blobObjectUrl = instance.post("/").then((res) => {
      const blob = new Blob([res.data], { type: "application/pdf" });
      const blobObject = URL.createObjectURL(blob);

      return blobObject;
    });

    return blobObjectUrl;
  } catch (error) {
    console.error("Error downloading Form T37 Report PDF object: " + error);
  }
};
