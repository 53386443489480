import { cloudPayrollApi } from "./cloudPayrollApi";
import { PayrollSchedule } from "../../types/PayrollSchedule";

const apiPayrollSchedules = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // CREATE Payroll Schedule
    createPayrollSchedule: builder.mutation<void, PayrollSchedule>({
      query: (payrollSchedule) => ({
        url: `/payroll-schedules`,
        method: "POST",
        body: payrollSchedule,
      }),
      invalidatesTags: ["PayrollSchedule"],
    }),

    // CREATE Payroll Schedule by Company ID
    createPayrollScheduleByCompanyId: builder.mutation<
      PayrollSchedule,
      PayrollSchedule
    >({
      query: (payrollSchedule) => ({
        url: `/payroll-schedules`,
        method: "POST",
        body: payrollSchedule,
      }),
      invalidatesTags: ["PayrollSchedule"],
    }),

    // READ All Payroll Schedules
    getAllPayrollSchedules: builder.query<PayrollSchedule[], number | void>({
      query: (limit = 10) => `/payroll-schedules?limit=${limit}`,
      providesTags: ["PayrollSchedule"],
    }),

    // READ All Payroll Schedules by Company ID
    getAllPayrollSchedulesByCompanyId: builder.query<
      PayrollSchedule[],
      number | void | undefined
    >({
      query: (companyId) => `/payroll-schedules/company/${companyId}`,
      providesTags: ["PayrollSchedule"],
    }),

    // READ Single Payroll Schedule
    getPayrollSchedule: builder.query<PayrollSchedule, number>({
      query: (id) => `/payroll-schedules/${id}`,
      providesTags: ["PayrollSchedule"],
    }),

    // READ All Employees of single Payroll Schedule
    getPayrollScheduleEmployees: builder.query<any, number>({
      query: (id) => `/payroll-schedules/${id}/employees`,
      providesTags: ["PayrollSchedule"],
    }),

    // UPDATE Payroll Schedule
    updatePayrollSchedule: builder.mutation<void, PayrollSchedule>({
      query: ({ id, ...rest }) => ({
        url: `/payroll-schedules/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["PayrollSchedule"],
    }),

    // DELETE Payroll Schedule
    deletePayrollSchedule: builder.mutation<void, number>({
      query: (id) => ({
        url: `/payroll-schedules/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PayrollSchedule"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreatePayrollScheduleMutation,
  useCreatePayrollScheduleByCompanyIdMutation,
  useGetAllPayrollSchedulesQuery,
  useGetAllPayrollSchedulesByCompanyIdQuery,
  useGetPayrollScheduleQuery,
  useLazyGetPayrollScheduleQuery,
  useGetPayrollScheduleEmployeesQuery,
  useLazyGetPayrollScheduleEmployeesQuery,
  useUpdatePayrollScheduleMutation,
  useDeletePayrollScheduleMutation,
} = apiPayrollSchedules;
