import { FC } from "react";
import { dinero } from "dinero.js";
import { GBP } from "@dinero.js/currencies";
import { intlFormat } from "../../../../../utils/currencyFormatter";
import Typography from "@mui/material/Typography";

interface Props {
  title: string;
  value: number | undefined;
}

const PaySubtotal: FC<Props> = ({ title, value }) => {
  const displayPaySubtotalAmount =
    value && intlFormat(dinero({ amount: value, currency: GBP }), "en-GB");

  return (
    <>
      <Typography variant="caption" sx={customStyles.title}>
        <strong>{title}</strong>
      </Typography>

      <Typography component="h6" variant="h6">
        {displayPaySubtotalAmount}
      </Typography>
    </>
  );
};

const customStyles = {
  title: {
    textTransform: "uppercase",
    color: "rgba(47, 48, 51, 0.72)",
    marginBottom: "12px",
    display: "block",
  },
};

export default PaySubtotal;
