import { FC } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
// import { ZodEmployee } from "../../../../../zod/ZodEmployee";
import { EssentialInformation } from "../ValidateEssentialInformation";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";

import HeaderSection from "../../HeaderSection";
import FormDivider from "../../FormDivider";

interface Props {
  control: Control<EssentialInformation, any>;
  errors: {
    birth_date?: FieldError | undefined;
  };
}

const SectionDateOfBirth: FC<Props> = ({ control, errors }) => {
  return (
    <>
      <Box>
        <HeaderSection title="Date of Birth" />

        {/*  Input : Date of Birth */}
        <FormControl sx={{ marginBottom: "2rem" }} fullWidth={true}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="birth_date"
              control={control}
              render={({ field: { name, ref, onBlur, ...rest } }) => (
                <DatePicker
                  {...rest}
                  inputRef={ref}
                  label="Date of Birth"
                  renderInput={(inputProps) => (
                    <TextField
                      {...inputProps}
                      name={name}
                      onBlur={onBlur}
                      error={!!errors.birth_date}
                      fullWidth={true}
                    />
                  )}
                  inputFormat="dd-MM-yyyy"
                  minDate={new Date("01-01-1900")}
                  maxDate={new Date()}
                  disabled={false}
                />
              )}
            />
          </LocalizationProvider>
          <FormHelperText error={true}>
            {errors.birth_date?.message}
          </FormHelperText>
        </FormControl>
      </Box>

      <FormDivider mt={2} mb={4} />
    </>
  );
};

export default SectionDateOfBirth;
