import { FC, useCallback, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { getPdfFormT37Report } from "../../../../utils/getPdfForm";

import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "@mui/material/Icon";

const CTAGenerateFormT37s: FC = () => {
  const [loadingGenerateFormT37, setLoadingGenerateFormT37] =
    useState<boolean>(false);

  const currentCompany = useAppSelector(
    (state: RootState) => state.currentCompany
  );

  const currentTaxYear = useAppSelector(
    (state: RootState) => state.currentTaxYear
  );

  const { id: currentCompanyId, name_legal: currentCompanyName } =
    currentCompany;

  const { id: currentTaxYearId, name: currentTaxYearName } = currentTaxYear;

  const handleDownloadT37ReportClick = useCallback(() => {
    setLoadingGenerateFormT37(true);

    getPdfFormT37Report(currentCompanyId!, Number(currentTaxYearId)!)
      .then((data) => {
        if (data) {
          const filename = `T37Report-${currentTaxYearName}-${currentCompanyName}`;
          const safeFilename = filename.replace(/[^a-z0-9]/gi, "-");

          const link = document.createElement("a");
          link.href = data;
          link.setAttribute("download", `${safeFilename}.pdf`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .then(() => {
        setLoadingGenerateFormT37(false);
      })
      .catch((error) => {
        //  TODO(DBB) : Snackbar error
        console.error(error);
        setLoadingGenerateFormT37(false);
      });
  }, [
    setLoadingGenerateFormT37,
    currentCompanyId,
    currentCompanyName,
    currentTaxYearId,
    currentTaxYearName,
  ]);

  return (
    <>
      <LoadingButton
        onClick={() => handleDownloadT37ReportClick()}
        loading={loadingGenerateFormT37}
        variant="contained"
        color="primary"
        size="medium"
        startIcon={<Icon>insert_drive_file</Icon>}
      >
        Generate Form T37
      </LoadingButton>
    </>
  );
};

export default CTAGenerateFormT37s;
