import { FC } from "react";

import Container from "@mui/material/Container";
// import Typography from "@mui/material/Typography";

import SubHeader from "../SubHeader";
import TableTaxRates from "./TableTaxRates";

const DashboardTaxRates: FC = () => {
  return (
    <>
      <SubHeader title="Tax Rates" icon="description" />

      <Container
        maxWidth="xl"
        sx={{
          width: "100%",
          marginBottom: "8rem",
        }}
      >
        <TableTaxRates />
      </Container>
    </>
  );
};

export default DashboardTaxRates;
