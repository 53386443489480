import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import type { Payroll } from "../../../types/Payroll";

const initialState: Payroll = {
  key: 0,
  id: "",
  payroll_schedule: 0,
  machine_name: "",
  pay_period_index: 0,
};

export const payrollSlice = createSlice({
  name: "provisionedPayroll",
  initialState,
  reducers: {
    updateProvisionedPayroll: (
      state,
      action: PayloadAction<Partial<Payroll>>
    ) => {
      Object.entries(action.payload ?? {}).forEach(([k, v]) => {
        state[k] = v;
      });
    },
    resetProvisionedPayroll: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { updateProvisionedPayroll, resetProvisionedPayroll } =
  payrollSlice.actions;

export default payrollSlice.reducer;
