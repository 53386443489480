import { cloudPayrollApi } from "./cloudPayrollApi";
import { Employee } from "../../types/Employee";
import { EssentialInformation } from "../../pages/employees/addEdit/01-essential-information/ValidateEssentialInformation";
import { EmploymentInformation } from "../../pages/employees/addEdit/02-employment-information/ValidationEmploymentInformation";
import { PaymentMethod } from "../../pages/employees/addEdit/05-payment-method/ValidationPaymentMethod";
import { AdditionalInformation } from "../../pages/employees/addEdit/06-additional-information/ValidationAdditionalInformation";

// TODO(DBB) : Extract this out to standalone type file?
interface ZodEmployee {
  id: number;
  essentialInformation: EssentialInformation;
  employmentInformation: EmploymentInformation;
  paymentMethod: PaymentMethod;
  additionalInformation: AdditionalInformation;
  status: string;
}

const apiEmployees = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // CREATE Employee
    createEmployee: builder.mutation<Employee, Partial<ZodEmployee>>({
      query: (employee) => ({
        url: `/employees`,
        method: "POST",
        body: employee,
      }),
      invalidatesTags: ["Employee"],
    }),

    // READ All Employees
    getAllEmployees: builder.query<Employee[], number | void>({
      query: (limit = 10) => `/employees?limit=${limit}`,
      providesTags: ["Employee"],
    }),

    // READ Single Employee
    getEmployee: builder.query<Employee, number>({
      query: (id) => `/employees/${id}`,
      providesTags: ["Employee"],
    }),

    // READ All Employees by Company ID
    getAllEmployeesByCompanyId: builder.query<Employee[], number | undefined>({
      query: (companyId, limit = 10) =>
        `/employees/company/${companyId}?limit=${limit}`,
      providesTags: ["Employee"],
    }),

    // READ Employee ID with Payroll Count for Tax Year
    getAllEmployeeIdsByCompanyIdByTaxYearWithPayrollCount: builder.query<
      Employee[],
      { companyId: number; taxYearId: number }
    >({
      query: ({ companyId, taxYearId }) =>
        `/employees/company/${companyId}/tax_year/${taxYearId}`,
      // providesTags: ["Employee"],
    }),

    // UPDATE Employee
    updateEmployee: builder.mutation<Employee, Partial<ZodEmployee>>({
      query: ({ id, ...rest }) => ({
        url: `/employees/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Employee"],
    }),

    // DELETE Employee
    deleteEmployee: builder.mutation<void, number>({
      query: (id) => ({
        url: `/employees/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Employee"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateEmployeeMutation,
  useGetAllEmployeesQuery,
  useGetAllEmployeesByCompanyIdQuery,
  useGetAllEmployeeIdsByCompanyIdByTaxYearWithPayrollCountQuery,
  useLazyGetAllEmployeesByCompanyIdQuery,
  useGetEmployeeQuery,
  useLazyGetEmployeeQuery,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
} = apiEmployees;
