import { Dispatch, FC, SetStateAction } from "react";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";

interface Props {
  modalConfirmOpen: boolean;
  setModalConfirmOpen: Dispatch<SetStateAction<boolean>>;
  confirmActionType: "REINVITE" | "DEACTIVATE" | "REACTIVATE" | "ARCHIVE";
  confirmActionCallback: Function | any;
  memberEmailAddress: string;
}

const ModalConfirmAction: FC<Props> = ({
  modalConfirmOpen,
  setModalConfirmOpen,
  confirmActionType,
  confirmActionCallback,
  memberEmailAddress,
}) => {
  return (
    <>
      <Modal open={modalConfirmOpen} onClose={() => setModalConfirmOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 480,
            width: "100%",
            backgroundColor: "white",
            padding: "1.5rem",
            borderRadius: "1rem",
            boxShadow: 24,
          }}
        >
          <Stack direction="column" gap={2}>
            <Typography variant="h6" component="h2">
              {`Confirm ${confirmActionType?.toLocaleLowerCase()} action`}
            </Typography>

            {confirmActionType === "REINVITE" && memberEmailAddress && (
              <>
                <Typography>
                  The user will be resent an invitation email to{" "}
                  <strong>{memberEmailAddress}</strong> from Cloud Payroll.
                </Typography>
                <Typography>
                  Once they validate their email address, they can setup an
                  account to gain access.
                </Typography>
              </>
            )}

            {confirmActionType === "DEACTIVATE" && memberEmailAddress && (
              <>
                <Typography>
                  The user account for <strong>{memberEmailAddress}</strong>{" "}
                  will be revoked the ability to access, view or modify this
                  company on Cloud Payroll.
                </Typography>
                <Typography>
                  Deactivated members can be viewed and controlled in the
                  Deactivated tab in the Members dashboard.
                </Typography>
              </>
            )}

            {confirmActionType === "REACTIVATE" && memberEmailAddress && (
              <>
                <Typography>
                  The user account for <strong>{memberEmailAddress}</strong>{" "}
                  will be regranted the ability to access, view or modify this
                  company on Cloud Payroll.
                </Typography>
                <Typography>
                  The user will receive an email from Cloud Payroll to confirm
                  company access.
                </Typography>
              </>
            )}

            {confirmActionType === "ARCHIVE" && memberEmailAddress && (
              <>
                <Typography>
                  The user account for <strong>{memberEmailAddress}</strong>{" "}
                  will be permanently archived.
                </Typography>
                <Typography>Are you sure you wish to continue?</Typography>
              </>
            )}

            <Divider />

            <Stack direction="row" gap={2}>
              <Button
                onClick={() => {
                  confirmActionCallback();
                  setModalConfirmOpen(false);
                }}
                variant="contained"
                color={
                  confirmActionType === "DEACTIVATE" ||
                  confirmActionType === "ARCHIVE"
                    ? "error"
                    : "primary"
                }
                size="medium"
                disabled={false}
                disableElevation={true}
              >
                Confirm
              </Button>
              <Button
                onClick={() => setModalConfirmOpen(false)}
                variant="outlined"
                color="secondary"
                size="medium"
                disabled={false}
                disableElevation={true}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ModalConfirmAction;
