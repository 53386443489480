import { z } from "zod";

export type PayComponent = z.infer<typeof SchemaPayComponent>;

export const SchemaPayComponent = z.object({
  id: z.number().optional(),

  name: z
    .string()
    .min(1, {
      message: "Pay Component Name is required.",
    })
    .max(64, {
      message: "Pay Component Name should not exceed 64 characters",
    }),

  pay_component_type: z.number().or(z.literal("")),

  payroll_count_unit: z.number().or(z.literal("")),

  is_gross_pay: z
    .boolean()
    .or(z.enum(["true", "false"]).transform((val) => val === "true")),

  is_liable_to_tax: z
    .boolean()
    .or(z.enum(["true", "false"]).transform((val) => val === "true")),

  is_liable_to_ni: z
    .boolean()
    .or(z.enum(["true", "false"]).transform((val) => val === "true")),

  is_pensionable: z
    .boolean()
    .or(z.enum(["true", "false"]).transform((val) => val === "true")),

  additional_description: z
    .string()
    .max(128, { message: "Maximum character length of 128 characters" })
    .optional(),

  status: z.string().optional(),
});
