import { FC, useCallback, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Toolbar from "@mui/material/Toolbar";
import LoadingButton from "@mui/lab/LoadingButton";
import IconReceipt from "@mui/icons-material/ReceiptLong";

import { getPdfFormPayslip } from "../../../../utils/getPdfForm";
import { useGetPayrollEntryQuery } from "../../../../features/api/apiPayrollEntries";
import { PayrollEntry } from "../../../../types/PayrollEntry";

interface RouterState {
  payrollEntryId?: string;
}

const SubHeader: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loadingDownloadPayslip, setLoadingDownloadPayslip] =
    useState<boolean>(false);

  const { payrollEntryId: routerPayrollEntryId } =
    (location.state as RouterState) || {};

  const { data: dataPayrollEntry } = useGetPayrollEntryQuery(
    routerPayrollEntryId!
  );

  const handleDownloadPayslipClick = useCallback(
    (payrollEntry: PayrollEntry) => {
      setLoadingDownloadPayslip(true);

      const { id, forenames, surname } = payrollEntry;

      getPdfFormPayslip(id)
        .then((data) => {
          if (data) {
            const filename = `Payslip-${forenames}-${surname}`;
            const safeFilename = filename.replace(/[^a-z0-9]/gi, "-");

            const link = document.createElement("a");
            link.href = data;
            link.setAttribute("download", `${safeFilename}.pdf`);
            document.body.appendChild(link);
            link.click();
          }
        })
        .then(() => setLoadingDownloadPayslip(false));
    },
    []
  );

  return (
    <AppBar position="relative" color="transparent" sx={customStyles.appBar}>
      <Toolbar sx={customStyles.toolBar}>
        <Button
          variant="outlined"
          size="medium"
          onClick={() => navigate(-1)}
          startIcon={<Icon>chevron_left</Icon>}
          color="inherit"
        >
          Back to Payroll Record
        </Button>

        <LoadingButton
          variant="outlined"
          color="primary"
          size="large"
          startIcon={<IconReceipt />}
          onClick={() =>
            dataPayrollEntry && handleDownloadPayslipClick(dataPayrollEntry)
          }
          loading={loadingDownloadPayslip}
          disabled={false}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          Download Payslip
        </LoadingButton>
      </Toolbar>
    </AppBar>
  );
};

const customStyles = {
  appBar: {
    boxShadow: "inset 0px -1px 0px rgba(47, 48, 51, 0.12)",
  },
  toolBar: {
    padding: "18px 24px !important",
    minHeight: "68px !important",
    display: "flex",
    justifyContent: "space-between",
  },
  icon: {
    color: "rgba(47, 48, 51, 0.54)",
    fontSize: "20px",
    marginRight: "10px",
  },
  title: {
    flexGrow: 1,
    fontWeight: 700,
    alignItems: "center",
  },
};

export default SubHeader;
