import { cloudPayrollApi } from "./cloudPayrollApi";

// TODO(DBB) : IMPORTANT : API and frontend need refactoring

interface TaxFormT35Response {
  totals: any;
  monthly: TaxFormT35PayrollSummaryMonthly[];
  weekly?: any;
}

interface TaxFormT35PayrollSummaryMonthly {
  month: string;
  payPeriod: { from: Date; to: Date };
  employeeCountForMonth: number;
  incomeTaxForMonthFormatted: string;
  niForMonthFormatted: string;
  remittanceForMonthFormatted: string;
  employeeITIPForMonthFormatted: string;
  employeeNiForMonthFormatted: string;
}

interface GetDataQueryParams {
  companyId: number;
  taxYearId: number;
}

const apiTaxFormT35 = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // READ All Tax Years
    getT35Response: builder.query<TaxFormT35Response, GetDataQueryParams>({
      query: ({ companyId, taxYearId }) =>
        `/pdf/generatet35data/${companyId}/${taxYearId}`,
      // TODO(DBB) : Can't cache this data for reuse...
      // providesTags: ["TaxYear"],
    }),

    // READ Single Template
    // getTemplate: builder.query<Template, number>({
    //   query: (id) => `/templates/${id}`,
    //   providesTags: ["TaxYear"],
    // }),
  }),
  overrideExisting: false,
});

export const { useGetT35ResponseQuery } = apiTaxFormT35;
