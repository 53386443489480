import { FC } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { RootState } from "../../../../../app/store";

import BlockAddress from "../../BlockAddress";
import BlockText from "../../BlockText";

import { useGetCompanyQuery } from "../../../../../features/api/apiCompanies";

const DetailsContactDetails: FC = () => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const { data: dataGetCompany } = useGetCompanyQuery(currentCompanyId);

  return (
    <>
      <BlockAddress
        title="Registered Address"
        country={dataGetCompany?.country || ""}
        address_1={dataGetCompany?.address_1 || ""}
        address_2={dataGetCompany?.address_2 || ""}
        city={dataGetCompany?.city || ""}
        region={dataGetCompany?.region || ""}
        post_code={dataGetCompany?.post_code || ""}
      />

      <BlockText
        title="Phone Number"
        data={dataGetCompany?.phone_number || ""}
      />
      <BlockText
        title="Email Address"
        data={dataGetCompany?.email_address || ""}
      />

      {/* <BlockText
        title="Website Address"
        data={dataGetCompany?.website_url || ""}
      /> */}
    </>
  );
};

export default DetailsContactDetails;
