import { FC, useState } from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import SubHeader from "../SubHeader";
import DataGridEmployees from "./DataGridEmployees";
import ModalActionEmployeeLeave from "./ModalActionEmployeeLeave";

const DashboardTaxFormT21: FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentEmployeeId, setCurrentEmployeeId] = useState<number>();
  const [currentEmployeeLeaveDate, setCurrentEmployeeLeaveDate] =
    useState<Date>();

  const handleModalOpen = (employeeId: number, employeeLeaveDate: Date) => {
    setCurrentEmployeeId(employeeId);
    setCurrentEmployeeLeaveDate(employeeLeaveDate);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <SubHeader title="Forms > T21" icon="receipt" />

      <Container maxWidth="xl" sx={{ width: "100%" }}>
        {currentEmployeeId && (
          <ModalActionEmployeeLeave
            modalOpen={modalOpen}
            handleModalClose={handleModalClose}
            currentEmployeeId={currentEmployeeId}
            currentEmployeeLeaveDate={currentEmployeeLeaveDate}
          />
        )}

        <Typography
          component="h2"
          variant="h4"
          sx={{ marginTop: "3rem", marginBottom: "2rem" }}
        >
          T21 – Employers Leaving Certificate
        </Typography>

        <DataGridEmployees handleModalOpen={handleModalOpen} />
      </Container>
    </>
  );
};

export default DashboardTaxFormT21;
