import { cloudPayrollApi } from "./cloudPayrollApi";

import { Scheme } from "../../types/Scheme";

export const apiSchemes = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // CREATE Scheme
    createScheme: builder.mutation<void, Scheme>({
      query: (Scheme) => ({
        url: `/schemes`,
        method: "POST",
        body: Scheme,
      }),
      invalidatesTags: ["Scheme"],
    }),

    // CREATE Scheme by Company ID
    createSchemeByCompanyId: builder.mutation<void, Scheme>({
      query: (Scheme) => ({
        url: `/schemes`,
        method: "POST",
        body: Scheme,
      }),
      invalidatesTags: ["Scheme"],
    }),

    // READ All Schemes
    getAllSchemes: builder.query<Scheme[], number | null>({
      query: (limit = 10) => `/schemes?limit=${limit}`,
      providesTags: ["Scheme"],
    }),

    // READ All Scheme by Company ID
    getAllSchemesByCompanyId: builder.query<Scheme[], number | undefined>({
      query: (companyId) => `/schemes/company/${companyId}`,
      providesTags: ["Scheme"],
    }),

    // READ Single Scheme
    getScheme: builder.query<Scheme, number>({
      query: (id) => `/schemes/${id}`,
      providesTags: ["Scheme"],
    }),

    // UPDATE Scheme
    updateScheme: builder.mutation<void, Partial<Scheme>>({
      query: ({ id, ...rest }) => ({
        url: `/schemes/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Scheme"],
    }),

    // DELETE Scheme
    deleteScheme: builder.mutation<void, number>({
      query: (id) => ({
        url: `/schemes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Scheme"],
    }),
  }),
  overrideExisting: false,
});

export const {
  endpoints,
  useCreateSchemeMutation,
  useCreateSchemeByCompanyIdMutation,
  useGetAllSchemesQuery,
  useGetAllSchemesByCompanyIdQuery,
  useGetSchemeQuery,
  useLazyGetSchemeQuery,
  useUpdateSchemeMutation,
  useDeleteSchemeMutation,
} = apiSchemes;
