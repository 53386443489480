import { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  label: string;
  value?: number | string;
}

const formatValuesCurrency = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

const BlockPaySummaryValue: FC<Props> = ({ label, value }) => {
  return (
    <>
      <Box sx={{ display: "grid", gap: "0.5rem" }}>
        <Typography
          variant="overline"
          display="block"
          sx={{
            fontSize: "0.667rem",
            fontWeight: 500,
            lineHeight: "1.66",
            color: "rgba(47, 48, 51, 0.72)",
            letterSpacing: "0.022rem",
          }}
        >
          {label}
        </Typography>

        <Typography
          variant="h6"
          display="block"
          sx={{ fontSize: "1rem", fontWeight: 600, color: "#2F3033" }}
        >
          {typeof value === "number"
            ? formatValuesCurrency.format(Number(value / 100))
            : value}
        </Typography>
      </Box>
    </>
  );
};

export default BlockPaySummaryValue;
