import { FC } from "react";

import Container from "@mui/material/Container";
import SubHeader from "../SubHeader";
import DataGridPayrollsComplete from "./DataGridPayrollsComplete";

const DashboardPayrollHistory: FC = () => {
  return (
    <>
      <SubHeader
        title="Payroll History"
        page="payroll-history"
        icon="all_inbox"
      />

      <Container maxWidth="xl" sx={{ width: "100%" }}>
        <DataGridPayrollsComplete />
      </Container>
    </>
  );
};

export default DashboardPayrollHistory;
