import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import FormPasswordResetEmail from "./FormPasswordResetEmail";
import FormPasswordResetPassword from "./FormPasswordResetPassword";

const DashboardPasswordReset: FC = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [resetToken, setResetToken] = useState<string>("");

  useEffect(() => {
    const resetTokenReceived = searchParams.get("token");
    resetTokenReceived && setResetToken(resetTokenReceived);
    searchParams.delete("token");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "2rem",
          paddingBottom: "4rem",
        }}
      >
        <Card
          sx={{
            maxWidth: 640,
            boxShadow: "16px 16px 0px rgba(0, 0, 0, 0.12)",
            borderRadius: "1rem",
            padding: "1rem 1.5rem 1.5rem",
            marginTop: "6rem",
          }}
        >
          <CardContent
            sx={{ padding: "1rem 0 1.5rem", paddingBottom: "0 !important" }}
          >
            {!resetToken ? (
              <FormPasswordResetEmail />
            ) : (
              <FormPasswordResetPassword resetToken={resetToken} />
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default DashboardPasswordReset;
