import { cloudPayrollApi } from "./cloudPayrollApi";

import { PayComponentType } from "../../types/PayComponentType";

const apiPayComponentTypes = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // CREATE Pay Component Type
    createPayComponentType: builder.mutation<void, PayComponentType>({
      query: (payComponentType) => ({
        url: `/pay-component-types`,
        method: "POST",
        body: payComponentType,
      }),
      invalidatesTags: ["PayComponentType"],
    }),

    // READ All Pay Component Types
    getAllPayComponentTypes: builder.query<PayComponentType[], number | void>({
      query: (limit = 10) => `/pay-component-types?limit=${limit}`,
      providesTags: ["PayComponentType"],
    }),

    // READ Single Pay Component Type
    getPayComponentType: builder.query<PayComponentType, number>({
      query: (id) => `/pay-component-types/${id}`,
      providesTags: ["PayComponentType"],
    }),

    // UPDATE Pay Component Type
    updatePayComponentType: builder.mutation<void, PayComponentType>({
      query: ({ id, ...rest }) => ({
        url: `/pay-component-types/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["PayComponentType"],
    }),

    // DELETE Pay Component Type
    deletePayComponentType: builder.mutation<void, number>({
      query: (id) => ({
        url: `/pay-component-types/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PayComponentType"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreatePayComponentTypeMutation,
  useGetAllPayComponentTypesQuery,
  useGetPayComponentTypeQuery,
  useUpdatePayComponentTypeMutation,
  useDeletePayComponentTypeMutation,
} = apiPayComponentTypes;
