import { FC } from "react";
import { useLocation } from "react-router-dom";
import { dateFormatter } from "../../../../utils/dateFormatter";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useGetPayrollQuery } from "../../../../features/api/apiPayrolls";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { useGetAllPayrollEntriesByPayrollIdQuery } from "../../../../features/api/apiPayrollEntries";

interface RouterState {
  payrollId?: string;
  totalCost?: number;
}

const formatValuesCurrency = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

const HeaderSummary: FC = () => {
  const location = useLocation();

  const { payrollId: routerPayrollId, totalCost } =
    (location.state as RouterState) || {
      payrollId: 0,
    };

  const { data: dataPayroll } = useGetPayrollQuery(routerPayrollId!);

  const displayPayDate = dateFormatter(dataPayroll?.pay_date_scheduled);

  const { data: dataPayrollEntries } = useGetAllPayrollEntriesByPayrollIdQuery(
    routerPayrollId!
  );

  return (
    <>
      <Grid
        container
        component="header"
        sx={{
          border: "1px solid #E7E6E9",
          borderRadius: "0.5rem",
          backgroundColor: "white",
          padding: "0.75rem 1.5rem 1.5rem",
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="overline"
            display="block"
            sx={{ fontSize: "1rem", fontWeight: 600 }}
          >
            Payroll Summary
          </Typography>
        </Grid>

        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Box sx={{ flexGrow: 1, paddingBottom: "0.5rem" }}>
            <Typography variant="overline" display="block">
              Pay Schedule
            </Typography>
            <Typography variant="h6" component="h3">
              {dataPayroll?.payroll_schedule.name}
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, paddingBottom: "0.5rem" }}>
            <Typography variant="overline" display="block">
              Pay Period
            </Typography>
            <Typography variant="h6" component="h3">
              {dataPayroll?.pay_period} of {dataPayroll?.total_pay_periods}
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, paddingBottom: "0.5rem" }}>
            <Typography variant="overline" display="block">
              Scheduled Pay Date
            </Typography>
            <Typography variant="h6" component="h3">
              {displayPayDate}
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, paddingBottom: "0.5rem" }}>
            <Typography variant="overline" display="block">
              Total Employees
            </Typography>
            <Typography variant="h6" component="h3">
              {dataPayrollEntries?.length}
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, paddingBottom: "0.5rem" }}>
            <Typography variant="overline" display="block">
              Total Cost
            </Typography>
            <Typography variant="h6" component="h3">
              {totalCost &&
                formatValuesCurrency.format(Number(totalCost / 100))}
            </Typography>
          </Box>
        </Stack>
      </Grid>
    </>
  );
};

export default HeaderSummary;
