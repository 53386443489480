import { FC } from "react";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

interface Props {
  entityName: string;
  linkAdd: string;
  sx: any;
}

const NoticeDataMissing: FC<Props> = ({ entityName, linkAdd, sx }) => {
  return (
    <>
      <Alert severity="warning" sx={{ ...sx }}>
        <AlertTitle>Notice</AlertTitle>
        <Typography gutterBottom>
          You need to set up at least one{" "}
          <Link
            to={linkAdd}
            style={{ borderBottom: "2px solid rgb(102, 48, 25)" }}
          >
            <strong>{entityName}</strong>
          </Link>{" "}
          before continuing.
        </Typography>
        <Typography gutterBottom>
          Return when complete to continue adding your first Employee.
        </Typography>

        <Button href={linkAdd} color="warning" variant="outlined">
          Add {entityName}
        </Button>
      </Alert>
    </>
  );
};

export default NoticeDataMissing;
