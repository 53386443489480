import {
  FC,
  useMemo,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { RootState } from "../../../../app/store";
import { useAppSelector } from "../../../../app/hooks";

import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValidRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";

import IconBlock from "@mui/icons-material/Block";
import IconActivate from "@mui/icons-material/PowerSettingsNew";
import IconCopyContent from "@mui/icons-material/ContentCopy";

import RenderCellConditionalStatus from "../../../../utils/RenderCellConditionalStatus";
import {
  displayColumnDate,
  displayColumnFullName,
} from "../../../../utils/datagridFormatters";
import useCopyToClipboard from "../../../../utils/copyToClipboard";

import {
  useGetAllMembersByCompanyIdQuery,
  useInviteMemberMutation,
  useUpdateMemberMutation,
} from "../../../../features/api/apiMembers";
import IconEmailForward from "@mui/icons-material/ForwardToInbox";
import IconDelete from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import ModalConfirmAction from "./ModalConfirmAction";
import { AlertMessage } from "../../../../types/AlertMessage";
import { MemberAlertContext } from "./DashboardMembers";

interface Props {
  presentation: "ACTIVE" | "DEACTIVATED";
}

const DataGridMembers: FC<Props> = ({ presentation }) => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  // TODO(DBB) : Tyoe for frontend Member
  const [rows, setRows] = useState<Partial<any>[] | undefined>();

  const [pageSize, setPageSize] = useState<number>(20);

  const { data: dataGetAllMembersByCompanyId } =
    useGetAllMembersByCompanyIdQuery(currentCompanyId);

  const [inviteMember] = useInviteMemberMutation();
  const [updateMember] = useUpdateMemberMutation();

  const [confirmActionType, setConfirmActionType] = useState<
    "REINVITE" | "DEACTIVATE" | "REACTIVATE" | "ARCHIVE"
  >("REINVITE");
  const [modalConfirmOpen, setModalConfirmOpen] = useState<boolean>(false);
  const [confirmActionCallback, setConfirmActionCallback] =
    useState<() => void>();
  const [memberEmailAddress, setMemberEmailAddress] = useState<string>("");

  const { setAlertMessage } = useContext(MemberAlertContext);

  // TODO(DBB) : Test this
  const handleReinviteClick = useCallback(
    (params: GridRowParams) => {
      const { email_address } = params.row;

      const reinviteCallback = () => {
        const submission = {
          company_id: currentCompanyId!,
          email_address: String(email_address),
        };

        inviteMember(submission)
          .unwrap()
          .then((res: AlertMessage) => {
            setAlertMessage(res);
          });
        // .then(() => {
        //   // TODO(DBB) : Set Fail Alert on completion in Dashboard
        // });
      };
      setConfirmActionType("REINVITE");
      setMemberEmailAddress(email_address);
      setConfirmActionCallback(() => reinviteCallback);
      setModalConfirmOpen(true);
    },
    [currentCompanyId, inviteMember, setAlertMessage]
  );

  const handleDeactivateClick = useCallback(
    (params: GridRowParams) => {
      const { id, email_address } = params.row;

      const deactivateCallback = () => {
        const dateNow = new Date();
        const submission = {
          id,
          date_time_updated: dateNow,
          date_time_invitation_expiry: dateNow,
          status: "DEACTIVATED",
        };

        updateMember(submission)
          .unwrap()
          .then((res) => {
            // TODO(DBB) : Fix this
            console.log(res);
            // setAlertMessage(res);
          });
      };
      setConfirmActionType("DEACTIVATE");
      setMemberEmailAddress(email_address);
      setConfirmActionCallback(() => deactivateCallback);
      setModalConfirmOpen(true);
    },
    [updateMember]
  );

  const handleReactivateClick = useCallback(
    (params: GridRowParams) => {
      const { id, email_address } = params.row;

      const reactivateCallback = () => {
        const dateNow = new Date();
        const submission = {
          id,
          date_time_updated: dateNow,
          status: "INVITED",
        };

        updateMember(submission)
          .unwrap()
          .then(() => {
            // TODO(DBB) : Need to call emailService to redistribute invitation email
          })
          .then(() => {
            // TODO(DBB) : Set Alert on completion in Dashboard
          });
      };
      setConfirmActionType("REACTIVATE");
      setMemberEmailAddress(email_address);
      setConfirmActionCallback(() => reactivateCallback);
      setModalConfirmOpen(true);
    },
    [updateMember]
  );

  const handleArchiveClick = useCallback(
    (params: GridRowParams) => {
      const { id, email_address } = params.row;

      const archiveCallback = () => {
        const dateNow = new Date();
        const submission = {
          id,
          date_time_archived: dateNow,
          date_time_updated: dateNow,
          date_time_invitation_expiry: dateNow,
          status: "ARCHIVED",
        };

        updateMember(submission)
          .unwrap()
          .then(() => {
            // TODO(DBB) : Set Alert on completion in Dashboard
          });
      };
      setConfirmActionType("ARCHIVE");
      setMemberEmailAddress(email_address);
      setConfirmActionCallback(() => archiveCallback);
      setModalConfirmOpen(true);
    },
    [updateMember]
  );

  const [copy] = useCopyToClipboard();

  const columns = useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: "id",
        headerName: "ID",
        type: "string",
        width: 160,
        editable: false,
        hide: true,
      },
      {
        field: "email_address",
        headerName: "Email Address",
        type: "string",
        width: 240,
        editable: false,
        hide: false,
        cellClassName: "datagrid-email-address",
      },
      {
        field: "employee_full_name",
        headerName: "Full Name",
        type: "string",
        width: 160,
        editable: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.forenames && params.row.surname
            ? displayColumnFullName(params.row.forenames, params.row.surname)
            : null,
      },
      {
        field: "date_time_invitation_created",
        headerName: "Invited",
        type: "dateTime",
        width: 130,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.date_time_invitation_created
            ? displayColumnDate(params.row.date_time_invitation_created)
            : null,
      },
      {
        field: "date_time_invitation_accepted",
        headerName: "Joined",
        type: "dateTime",
        width: 130,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.date_time_invitation_accepted
            ? displayColumnDate(params.row.date_time_invitation_accepted)
            : "Pending...",
      },
      // TODO(DBB) : Last login
      // {
      //   field: "last_login",
      //   headerName: "Last Active",
      //   type: "dateTime",
      //   width: 130,
      //   editable: false,
      //   hide: false,
      //  displayColumnDate()
      // },
      {
        field: "status",
        headerName: "Status",
        type: "string",
        width: 120,
        editable: false,
        hide: false,
        renderCell: (params: GridRenderCellParams<string>) => (
          <RenderCellConditionalStatus status={params.row.status} />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 90,
        // TODO(DBB) : Conditional action – resend invitation
        getActions: (params: GridRowParams) => [
          // TODO(DBB) : IMPORTANT : Can't revoke self
          // TODO(DBB) : IMPORTANT : Can't revoke last solo user
          ...(params.row.status === "INVITED"
            ? [
                <GridActionsCellItem
                  label="Resend Invitation"
                  onClick={() => handleReinviteClick(params)}
                  icon={<IconEmailForward />}
                  showInMenu={true}
                  hidden={false}
                />,
              ]
            : []),
          ...(params.row.status === "INVITED" || params.row.status === "ACTIVE"
            ? [
                <GridActionsCellItem
                  label="Revoke access"
                  onClick={() => handleDeactivateClick(params)}
                  icon={<IconBlock />}
                  showInMenu={true}
                  hidden={false}
                />,
              ]
            : []),
          ...(params.row.status === "DEACTIVATED"
            ? [
                <GridActionsCellItem
                  label="Reactivate"
                  onClick={() => handleReactivateClick(params)}
                  icon={<IconActivate />}
                  showInMenu={true}
                  hidden={false}
                />,
                <GridActionsCellItem
                  label="Archive"
                  onClick={() => handleArchiveClick(params)}
                  icon={<IconDelete />}
                  showInMenu={true}
                  hidden={false}
                />,
              ]
            : []),
          <GridActionsCellItem
            label="Copy email address"
            onClick={() => {
              const email_address = params.row.email_address;
              copy(email_address);
            }}
            icon={<IconCopyContent />}
            showInMenu={true}
            hidden={false}
          />,
        ],
      },
    ],
    [
      handleReinviteClick,
      handleReactivateClick,
      handleDeactivateClick,
      handleArchiveClick,
      copy,
    ]
  );

  useEffect(() => {
    const members =
      dataGetAllMembersByCompanyId &&
      dataGetAllMembersByCompanyId.map((item) => {
        return {
          id: item.id,
          forenames: item.user.forenames,
          surname: item.user.surname,
          user_id: item.user_id,
          date_time_invitation_created: item.date_time_invitation_created,
          date_time_invitation_accepted: item.date_time_invitation_accepted,
          email_address: item.user.email_address,
          status: item.status,
        };
      });

    const membersActive = members?.filter(
      (item) => item.status === "INVITED" || item.status === "ACTIVE"
    );
    const membersDeactivated = members?.filter(
      (item) => item.status === "DEACTIVATED"
    );

    presentation === "ACTIVE" && setRows(membersActive);
    presentation === "DEACTIVATED" && setRows(membersDeactivated);
  }, [presentation, dataGetAllMembersByCompanyId]);

  return (
    <>
      <ModalConfirmAction
        modalConfirmOpen={modalConfirmOpen}
        setModalConfirmOpen={setModalConfirmOpen}
        confirmActionType={confirmActionType}
        confirmActionCallback={confirmActionCallback}
        memberEmailAddress={memberEmailAddress}
      />

      <Box sx={{ width: "100%" }}>
        <DataGridPro
          rows={rows || []}
          columns={columns}
          autoHeight={true}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          pagination
          initialState={{
            pinnedColumns: {
              right: ["status", "actions"],
            },
            sorting: {
              sortModel: [
                { field: "date_time_invitation_accepted", sort: "desc" },
              ],
            },
          }}
          sx={{
            backgroundColor: "white",
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
          }}
        />
      </Box>
    </>
  );
};

export default DataGridMembers;
