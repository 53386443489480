import { FC } from "react";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormActionEmployeeLeave from "../../tax-forms/run-t21/FormActionEmployeeLeave";

interface Props {
  modalOpen: boolean;
  handleModalClose: () => void;
  currentEmployeeId: number;
  currentEmployeeLeaveDate?: Date;
}

const ModalActionEmployeeLeave: FC<Props> = ({
  modalOpen,
  handleModalClose,
  currentEmployeeId,
  currentEmployeeLeaveDate,
}) => {
  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 460,
            width: "100%",
            backgroundColor: "white",
            padding: "2rem",
            borderRadius: "1rem",
            boxShadow: 24,
          }}
        >
          <FormActionEmployeeLeave
            handleModalClose={handleModalClose}
            currentEmployeeId={currentEmployeeId}
            currentEmployeeLeaveDate={currentEmployeeLeaveDate}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ModalActionEmployeeLeave;
