import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import TitleSection from "../TitleSection";
import ModulePayrollConfiguration from "./ModulePayrollConfiguration";
import { useAppSelector } from "../../../../app/hooks";
import { useGetAllPayComponentsByCompanyIdQuery } from "../../../../features/api/apiPayComponents";
import { useGetAllSchemesByCompanyIdQuery } from "../../../../features/api/apiSchemes";
import { useGetAllPayrollSchedulesByCompanyIdQuery } from "../../../../features/api/apiPayrollSchedules";

const SectionPayrollConfigurations: FC = () => {
  const displayCount = (count: number | undefined) =>
    `${count || 0} active item${count !== 1 ? 's' : ''}`;
  
  const currentCompanyId = useAppSelector((state) => state.currentCompany.id);

  // TODO: streamline process
  //       possibly move filter and blurb logic inside ModulePayrollConfiguration
  
  // Dynamic fetch of entities
  const { data: dataGetAllPayComponentsByCompanyId } =
    useGetAllPayComponentsByCompanyIdQuery(currentCompanyId);  
  const { data: dataGetAllPayrollSchedulesByCompanyId } =
    useGetAllPayrollSchedulesByCompanyIdQuery(currentCompanyId);
  const { data: dataGetAllSchemesByCompanyId } =
    useGetAllSchemesByCompanyIdQuery(currentCompanyId);

  // Filter entities by status === 'Active'
  const countPayComponents = dataGetAllPayComponentsByCompanyId?.filter((item) => item.status === 'Active').length;
  const countSchemes = dataGetAllSchemesByCompanyId?.filter((item) => item.status === 'Active').length;
  const countPayrollSchedules = dataGetAllPayrollSchedulesByCompanyId?.filter((item) => item.status === 'Active').length;

  const navigate = useNavigate();

  return (
    <>
      <Box component="section">
        <TitleSection title="Payroll Configurations" />

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "1rem",
          }}
        >
          <ModulePayrollConfiguration
            title="Pay Schedules"
            blurb={displayCount(countPayrollSchedules)}
            ctaPrimaryText="View items"
            ctaPrimaryAction={() => {
              navigate("/payrolls/pay-schedules");
            }}
            ctaSecondaryText={"Add"}
            ctaSecondaryIcon="add"
            ctaSecondaryAction={() => {
              navigate("/payrolls/pay-schedules", {
                state: { routerSetModalOpen: true },
              });
            }}
            disabled={false}
          />

          <ModulePayrollConfiguration
            title="Pay Components"
            blurb={displayCount(countPayComponents)}
            ctaPrimaryText="View items"
            ctaPrimaryAction={() => {
              navigate("/payrolls/pay-components");
            }}
            ctaSecondaryText={"Add"}
            ctaSecondaryIcon="add"
            ctaSecondaryAction={() => {
              navigate("/payrolls/pay-components", {
                state: { routerSetModalOpen: true },
              });
            }}
            disabled={false}
          />

          <ModulePayrollConfiguration
            title="Schemes"
            blurb={displayCount(countSchemes)}
            ctaPrimaryText="View items"
            ctaPrimaryAction={() => {
              navigate("/payrolls/schemes");
            }}
            ctaSecondaryText={"Add"}
            ctaSecondaryIcon="add"
            ctaSecondaryAction={() => {
              navigate("/payrolls/schemes", {
                state: { routerSetModalOpen: true },
              });
            }}
            disabled={false}
          />

          <ModulePayrollConfiguration
            title="Cost Centers"
            blurb="0 active items"
            ctaPrimaryText="View Items"
            ctaPrimaryAction={() => {}}
            disabled={true}
          />

          <ModulePayrollConfiguration
            title="Payment Methods"
            blurb="0 active items"
            ctaPrimaryText="View Items"
            ctaPrimaryAction={() => {}}
            ctaSecondaryText="View Items"
            ctaSecondaryAction={() => {}}
            disabled={true}
          />

          <ModulePayrollConfiguration
            title="Payslip Templates"
            blurb="0 active items"
            ctaPrimaryText="View Items"
            ctaPrimaryAction={() => {}}
            disabled={true}
          />
        </Box>
      </Box>
    </>
  );
};

export default SectionPayrollConfigurations;
