import { FC } from "react";
import { Outlet, useMatch } from "react-router-dom";

import Box from "@mui/material/Box";

import SubNavigation from "./SubNavigation";

const DashboardCompany: FC = () => {
  const isSelector = useMatch("/company/select");

  return (
    <>
      {isSelector ? (
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <SubNavigation />
          </Box>

          <Box component="main" sx={{ flexGrow: 1 }}>
            <Outlet />
          </Box>
        </Box>
      )}
    </>
  );
};

export default DashboardCompany;
