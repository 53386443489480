import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";

import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
  GridValidRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import IconEdit from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { useGetAllPayrollEmployeesByCompanyIdByTaxYearQuery } from "../../../features/api/apiPayrollEmployees";
import { getPdfFormT14 } from "../../../utils/getPdfForm";
import { Employee } from "../../../types/Employee";
import Footer from "./Footer";

interface EmployeeExtended extends Employee {
  completed: boolean;
}

const DataGridEmployees: FC = () => {
  const navigate = useNavigate();

  const currentCompanyId = useAppSelector((state) => state.currentCompany.id);

  const currentTaxYearId = useAppSelector((state) => state.currentTaxYear.id);
  const currentTaxYearName = useAppSelector(
    (state) => state.currentTaxYear.name
  );

  const [hasCompletedAllPayrolls, setHasCompletedAllPayrolls] =
    useState<boolean>(false);
  const [rows, setRows] = useState<Partial<EmployeeExtended>[] | undefined>();
  const [pageSize, setPageSize] = useState<number>(10);

  const { data: dataGetAllPayrollEmployeesByCompanyIdByTaxYear } =
    useGetAllPayrollEmployeesByCompanyIdByTaxYearQuery({
      companyId: Number(currentCompanyId)!,
      taxYearId: Number(currentTaxYearId)!,
    });

  const displayColumnDate = (value: Date) => {
    const date = new Date(value);

    const dateFormatted = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(date);

    return dateFormatted;
  };

  const handleDownloadT14Click = useCallback(
    (params: GridRowParams<any>) => {
      getPdfFormT14(String(params.id), Number(currentTaxYearId)).then(
        (data) => {
          if (data) {
            const { forenames, surname } = params.row;
            const filename = `T14-${currentTaxYearName}-${surname}-${forenames}`;
            const safeFilename = filename.replace(/[^a-z0-9]/gi, "-");

            const link = document.createElement("a");
            link.href = data;
            link.setAttribute("download", `${safeFilename}.pdf`);
            document.body.appendChild(link);
            link.click();
          }
        }
      );
    },
    [currentTaxYearId, currentTaxYearName]
  );

  // TODO(DBB) : Handle Preview
  // const handlePreviewT14Click = useCallback((params: GridRowParams<any>) => {
  //   console.log("Preview" + params.id);
  // }, []);

  const columns = useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: "employee_number",
        headerName: "Emp. No.",
        type: "string",
        width: 120,
        editable: false,
        hide: false,
      },
      {
        field: "surname",
        headerName: "Surname",
        type: "string",
        width: 120,
        editable: false,
        hide: false,
      },
      {
        field: "forenames",
        headerName: "Forenames",
        type: "string",
        width: 140,
        editable: false,
        hide: false,
      },
      {
        field: "job_title",
        headerName: "Job Title",
        type: "string",
        width: 120,
        editable: false,
        hide: false,
      },
      {
        field: "employment_type",
        headerName: "Emp. Type",
        type: "string",
        width: 100,
        editable: false,
        hide: false,
      },
      {
        field: "payroll_schedule",
        headerName: "Pay Schedule",
        type: "string",
        width: 140,
        editable: false,
        // TODO(DBB) : Hide column until response object is updated
        // hide: true,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.payroll_schedule && params.row.payroll_schedule?.name,
      },
      {
        field: "job_date_started",
        headerName: "Start Date",
        type: "date",
        width: 100,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.job_date_started &&
          displayColumnDate(params.row.job_date_started),
      },
      {
        field: "status",
        headerName: "Status",
        type: "string",
        width: 120,
        editable: false,
        hide: false,
      },
      {
        field: "id",
        headerName: "ID",
        type: "string",
        width: 80,
        editable: false,
        hide: false,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 80,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<IconEdit />}
            label="Download T14"
            onClick={() => handleDownloadT14Click(params)}
            showInMenu
            disabled={!params.row.completed}
          />,
          <GridActionsCellItem
            icon={<IconEdit />}
            label="View T14"
            onClick={() =>
              navigate("/tax-forms/t14/view", {
                state: { employeeId: params.id },
              })
            }
            showInMenu
            disabled={false}
          />,
        ],
      },
    ],
    [handleDownloadT14Click, navigate]
  );

  useEffect(() => {
    const completedEmployees =
      dataGetAllPayrollEmployeesByCompanyIdByTaxYear?.completedEmployees.map(
        (item: Employee) => ({
          ...item,
          completed: true,
        })
      );

    const incompletedEmployees =
      dataGetAllPayrollEmployeesByCompanyIdByTaxYear?.incompleteEmployees.map(
        (item: Employee) => ({
          ...item,
          completed: false,
        })
      );

    const combinedEmployees = completedEmployees &&
      incompletedEmployees && [...completedEmployees, ...incompletedEmployees];

    setRows(combinedEmployees);

    dataGetAllPayrollEmployeesByCompanyIdByTaxYear &&
      setHasCompletedAllPayrolls(
        !dataGetAllPayrollEmployeesByCompanyIdByTaxYear?.incompletePayrolls
      );
  }, [dataGetAllPayrollEmployeesByCompanyIdByTaxYear]);

  // TODO(DBB) : Need to handle incomplete Payrolls in interface
  // console.log(incompletePayrollIds);

  return (
    <>
      {hasCompletedAllPayrolls ? (
        <>
          <Typography component="h3" variant="h5" gutterBottom>
            Employees
          </Typography>

          {rows ? (
            <DataGridPro
              rows={rows || []}
              columns={columns}
              autoHeight={true}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50]}
              pagination
              initialState={{
                pinnedColumns: {
                  right: ["actions"],
                },
                sorting: {
                  sortModel: [{ field: "job_date_started", sort: "desc" }],
                },
              }}
              sx={{ backgroundColor: "white" }}
            />
          ) : (
            <>
              <Typography component="h3" variant="h4" gutterBottom>
                You have no Employees...
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
                Create your first employee in order to manage payroll for them.
              </Typography>
              {/* TODO(DBB) : Redirect */}
              {/* <CtaEmployeeAdd /> */}
            </>
          )}

          <Footer />
        </>
      ) : (
        <Alert
          severity="warning"
          sx={{ marginTop: "1rem", marginBottom: "2rem" }}
        >
          <Box
            sx={{
              marginBottom: "0.5rem",
            }}
          >
            <AlertTitle>Warning: Incomplete Payrolls</AlertTitle>
            <p>
              There are incomplete payrolls preventing the completion of the
              disabled T14s below.
            </p>
            <p>
              Please check all Payrolls for an Employee are completed to run
              their T14 report.
            </p>
          </Box>
          <Button
            startIcon={<Icon>receipt_long</Icon>}
            href={"/payrolls/overview"}
            color="warning"
            variant="outlined"
            size="small"
          >
            View Payrolls
          </Button>
        </Alert>
      )}
    </>
  );
};

export default DataGridEmployees;
