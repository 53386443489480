import { FC } from "react";

import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import Toolbar from "@mui/material/Toolbar";

interface SubHeaderProps {
  title: string;
  icon?: string;
}

const SubHeader: FC<SubHeaderProps> = ({ title, icon }) => {
  return (
    <AppBar
      position="relative"
      color="transparent"
      sx={{ boxShadow: "inset 0px -1px 0px rgba(47, 48, 51, 0.12)" }}
    >
      <Toolbar
        sx={{ padding: "16px 32px !important", minHeight: "68px !important" }}
      >
        <Typography
          variant="body1"
          component="div"
          sx={{ flexGrow: 1, fontWeight: 700, alignItems: "center" }}
          className="flex"
        >
          {icon && (
            <Icon
              sx={{
                color: "rgba(47, 48, 51, 0.54)",
                fontSize: "20px",
                marginRight: "10px",
              }}
            >
              {icon}
            </Icon>
          )}
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default SubHeader;
