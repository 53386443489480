import { FC } from "react";
import { useMatch } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";

interface Props {
  activeStep: number;
  steps: any;
  completed: { [k: number]: boolean };
}

const SubNavigation: FC<Props> = ({ activeStep, steps, completed }) => {
  const isFirst = useMatch("/setup/company");

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="subtitle2" sx={{ textTransform: "uppercase" }}>
            {!isFirst && "Setup a new company"}
            {isFirst && "Setup your Cloud Payroll"}
          </Typography>
          <Typography variant="h6">
            {!isFirst && "Add a new company"}
            {isFirst && "Add your company"}
          </Typography>
        </Box>

        <Stepper nonLinear orientation="vertical" activeStep={activeStep}>
          {steps.map((label: string, index: number) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" disabled>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </>
  );
};

export default SubNavigation;
