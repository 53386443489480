import { FC } from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import SubHeader from "../SubHeader";
import ModuleYearEndForm from "./ModuleYearEndForm";

const DashboardOverview: FC = () => {
  return (
    <>
      <SubHeader title="Forms" icon="description" />

      <Container
        maxWidth="xl"
        sx={{
          width: "100%",
          marginBottom: "8rem",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            columnGap: "1rem",
          }}
        >
          <Typography
            component="h2"
            variant="h5"
            sx={{ gridColumn: "1/-1", marginTop: "3rem", marginBottom: "2rem" }}
          >
            Ready to submit forms:
          </Typography>

          <ModuleYearEndForm
            title="T14 — Isle of Man ITIP and National Insurance Deduction Card"
            text="Updated annually. To be completed annually by the employer for each employee; one copy to be given to the employee and one submitted with the T37 employer’s annual return."
            selectTaxYear={true}
            ctaText="Generate Forms"
            linkTo="/tax-forms/t14"
          />

          <ModuleYearEndForm
            title="T21 — Employers Leaving Certificate"
            text="To be completed by the employer when an employee ceases employment."
            selectTaxYear={false}
            ctaText="Generate Forms"
            linkTo="/tax-forms/t21"
          />
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            columnGap: "1rem",
          }}
        >
          <Typography
            component="h2"
            variant="h5"
            sx={{ gridColumn: "1/-1", marginTop: "3rem", marginBottom: "2rem" }}
          >
            Reference reports:
          </Typography>

          <ModuleYearEndForm
            title="Report for Tax & NI Remittance Card (T35)"
            text="Employers are required to submit T35 Remittance Card for Deductions of Income Tax Instalment Payments (ITIP) and National Insurance Contributions (NIC) with payment on a monthly basis."
            selectTaxYear={true}
            ctaText="Generate Forms"
            linkTo="/tax-forms/t35"
          />
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            columnGap: "1rem",
          }}
        >
          <Typography
            component="h2"
            variant="h5"
            sx={{ gridColumn: "1/-1", marginTop: "3rem", marginBottom: "2rem" }}
          >
            Year end submissions:
          </Typography>

          <ModuleYearEndForm
            title="Year End Documents"
            text="Include all employees’ T14s, electronic submission file for IOM online service, report document for T37."
            selectTaxYear={true}
            ctaText="Generate Forms"
            linkTo="/tax-forms/year-end"
          />
        </Box>
      </Container>
    </>
  );
};

export default DashboardOverview;
