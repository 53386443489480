import { cloudPayrollApi } from "./cloudPayrollApi";

import { TaxYear } from "../../types/TaxYear";

const apiTaxYears = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // READ All Tax Years
    getAllTaxYears: builder.query<TaxYear[], void>({
      query: () => `/im/tax-years`,
      providesTags: ["TaxYear"],
    }),

    // READ Current Tax Year
    getCurrentTaxYear: builder.query<TaxYear, void>({
      query: () => `/im/tax-years/current`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllTaxYearsQuery, useGetCurrentTaxYearQuery } =
  apiTaxYears;
