import { FC, MouseEvent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import IconSettings from "@mui/icons-material/Settings";

import CompanySelectorOptions from "./CompanySelectorOptions";

import { setCurrentCompany } from "../../../features/state/company/companySlice";
import {
  useGetCompanyQuery,
  useLazyGetCompanyQuery,
} from "../../../features/api/apiCompanies";
import { User } from "../../../types/User";

const CompanySelector: FC = () => {
  const dispatch = useAppDispatch();

  const currentUserDefaultCompanyId = useAppSelector(
    (state) => state.currentUser.default_company
  );

  const currentUserMemberCompanies = useAppSelector((state: RootState) =>
    state.currentUser?.members?.map((item: User) => item.company)
  );

  const currentCompany = useAppSelector((state) => state.currentCompany);

  const { data: dataGetCompanyDefault } = useGetCompanyQuery(
    currentUserDefaultCompanyId
  );

  const [triggerLazyGetCompany] = useLazyGetCompanyQuery();

  useEffect(() => {
    if (currentCompany.id === 0) {
      dataGetCompanyDefault &&
        dispatch(setCurrentCompany(dataGetCompanyDefault));
    }
  }, [currentCompany, dataGetCompanyDefault, dispatch]);

  // Handle menu open/close
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpen}
        sx={(theme) => ({
          display: "flex",
          color: "#FFFFFF",
          maxWidth: "272px",
          width: "100%",
          justifyContent: "flex-start",
          margin: "8px",
          borderRadius: 0,
          position: "relative",
          "&:after": {
            content: '""',
            position: "absolute",
            top: 0,
            right: "-7px",
            width: "1px",
            height: "100%",
            boxShadow: "1px 0px 0px rgba(255, 255, 255, 0.25)",
          },
          [theme.breakpoints.down(992)]: {
            maxWidth: "180px",
            display: "none",
          },
          [theme.breakpoints.down(1040)]: {
            maxWidth: "230px",
          },
        })}
        endIcon={<Icon>keyboard_arrow_down</Icon>}
      >
        <Typography
          variant="subtitle1"
          sx={{
            flex: "1 1 auto",
            textAlign: "left",
          }}
        >
          {currentCompany && currentCompany.name_trading}
        </Typography>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            boxShadow:
              "0px 8px 10px 1px rgba(47, 48, 51, 0.14), 0px 3px 14px 2px rgba(47, 48, 51, 0.12)",
            mt: -0.5,
            width: "272px",
            left: "8px !important",
            "& .MuiSvgIcon-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              display: "none",
            },
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        aria-labelledby="demo-positioned-button"
      >
        <MenuItem
          sx={{
            paddingTop: "0.66rem",
            paddingBottom: "0.8rem",
          }}
        >
          <ListItemText>
            {currentCompany && currentCompany?.name_trading}
          </ListItemText>
          <Icon>keyboard_arrow_down</Icon>
        </MenuItem>

        <MenuItem
          sx={{
            display: "flex",
            alignItems: "center",
            paddingBottom: "0.5rem",
            marginBottom: "0.75rem",
          }}
        >
          <NavLink
            to="/company/information"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconSettings
              sx={{
                opacity: "0.66",
                transform: "scale(0.88)",
              }}
            />
            Company Settings
          </NavLink>
        </MenuItem>

        <Divider />

        {currentUserDefaultCompanyId && (
          <CompanySelectorOptions
            currentUserMemberCompanies={currentUserMemberCompanies}
            triggerLazyGetCompany={triggerLazyGetCompany}
            handleClose={handleClose}
          />
        )}
      </Menu>
    </>
  );
};

export default CompanySelector;
