import { combineReducers } from "@reduxjs/toolkit";

import { cloudPayrollApi } from "../features/api/cloudPayrollApi";

import userReducer from "../features/state/user/userSlice";
import currentTaxYearReducer from "../features/state/taxYear/taxYearSlice";
import companyReducer from "../features/state/company/companySlice";
import currentEmployeeReducer from "../features/state/employee/currentEmployeeSlice";
import provisionedEmployeeReducer from "../features/state/employee/provisionedEmployeeSlice";
import provisionedEmployeePayRateReducer from "../features/state/employeePayRate/employeePayRateSlice";
import provisionedEmployeeSchemeReducer from "../features/state/employeeScheme/employeeSchemeSlice";
import provisionedPayrollReducer from "../features/state/payroll/payrollSlice";
import payrollEntryReducer from "../features/state/payrollEntry/payrollEntrySlice";
import userSignUpReducer from "../features/state/signup/userSignUpSlice";
import userInterfaceReducer from "../features/state/interface/InterfaceSlice";
import modeAddEditReducer from "../features/state/modeAddEdit/modeAddEditSlice";

export const rootReducer = combineReducers({
  [cloudPayrollApi.reducerPath]: cloudPayrollApi.reducer,
  currentUser: userReducer,
  currentTaxYear: currentTaxYearReducer,
  currentCompany: companyReducer,
  currentEmployee: currentEmployeeReducer,
  provisionedEmployee: provisionedEmployeeReducer,
  provisionedEmployeePayRate: provisionedEmployeePayRateReducer,
  provisionedEmployeeScheme: provisionedEmployeeSchemeReducer,
  provisionedPayroll: provisionedPayrollReducer,
  currentPayrollEntry: payrollEntryReducer,
  currentUserSignUp: userSignUpReducer,
  userInterface: userInterfaceReducer,
  modeAddEdit: modeAddEditReducer,
});
