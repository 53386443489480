import { FC, useState, useContext, useMemo } from "react";
import { useAppSelector } from "../../../app/hooks";

import {
  DataGridPro,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridRowsProp,
  GridValidRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";

import IconEdit from "@mui/icons-material/Edit";
import IconDelete from "@mui/icons-material/Delete";
import {
  displayColumnCurrencyGBP,
  displayColumnPercentage,
} from "../../../utils/datagridFormatters";
import RenderCellConditionalStatus from "../../../utils/RenderCellConditionalStatus";

import { useGetAllSchemesByCompanyIdQuery } from "../../../features/api/apiSchemes";
import { CurrentSchemeContext } from "./DashboardSchemes";
import { Scheme } from "../../../types/Scheme";
import NoData from "../NoData";

interface Props {
  handleModalOpenAdd: () => void;
  handleModalOpenEdit: () => void;
  handleModalOpenArchive: () => void;
}

const DataGridSchemes: FC<Props> = ({
  handleModalOpenAdd,
  handleModalOpenEdit,
  handleModalOpenArchive,
}) => {
  const currentCompanyId = useAppSelector((state) => state.currentCompany.id);

  const { setSchemeId } = useContext(CurrentSchemeContext);

  const { data: dataGetAllSchemesByCompanyId } =
    useGetAllSchemesByCompanyIdQuery(currentCompanyId);

  const [pageSize, setPageSize] = useState<number>(10);

  const columns: (GridColDef | GridActionsColDef)[] = useMemo<
    GridColumns<GridValidRowModel>
  >(
    () => [
      {
        field: "id",
        headerName: "ID",
        type: "string",
        width: 64,
        editable: false,
        hide: true,
      },
      {
        field: "name",
        headerName: "Name",
        type: "string",
        width: 150,
        editable: false,
        hide: false,
      },
      {
        field: "scheme_type",
        headerName: "Type",
        type: "string",
        width: 150,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.scheme_type.name;
        },
      },
      {
        field: "employee_contribution",
        headerName: "E'ee Ctb.",
        description: "Employee Contribution",
        type: "number",
        width: 90,
        valueGetter: (params: GridValueGetterParams) => {
          const {
            employee_contribution_rate_type: type,
            employee_contribution_count: amount,
          } = params.row;

          if (type === "PERCENTAGE") {
            return displayColumnPercentage(amount);
          }
          if (type === "FIXED") {
            return displayColumnCurrencyGBP(amount);
          }
        },
      },
      {
        field: "employee_contribution_deducted_before_tax",
        headerName: "Pre-Tax",
        description: "Employee Contribution Deducted Before Tax",
        type: "boolean",
        width: 60,
      },
      {
        field: "employee_contribution_deducted_before_ni",
        headerName: "Pre-NI",
        description: "Employee Contribution Deducted Before N.I.",
        type: "boolean",
        width: 60,
      },
      {
        field: "employer_contribution",
        headerName: "E'er Ctb.",
        description: "Employer Contribution",
        type: "number",
        width: 90,
        valueGetter: (params: GridValueGetterParams) => {
          const {
            employer_contribution_rate_type: type,
            employer_contribution_count: amount,
          } = params.row;

          if (type === "PERCENTAGE") {
            return displayColumnPercentage(amount);
          }
          if (type === "FIXED") {
            return displayColumnCurrencyGBP(amount);
          }
        },
      },
      {
        field: "status",
        headerName: "Status",
        type: "string",
        width: 90,
        editable: false,
        hide: false,
        renderCell: (params: GridRenderCellParams<string>) => (
          <RenderCellConditionalStatus status={params.row.status} />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 90,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<IconEdit />}
            label="Edit"
            onClick={() => {
              setSchemeId(Number(params.id));
              handleModalOpenEdit();
            }}
            showInMenu={true}
            disabled={false}
          />,
          <GridActionsCellItem
            icon={<IconDelete />}
            label="Archive"
            onClick={() => {
              setSchemeId(Number(params.id));
              handleModalOpenArchive();
            }}
            showInMenu={true}
            disabled={false}
          />,
        ],
      },
    ],
    [handleModalOpenEdit, handleModalOpenArchive, setSchemeId]
  );

  const rows: GridRowsProp = dataGetAllSchemesByCompanyId
    ? dataGetAllSchemesByCompanyId
        ?.filter((item) => item.status === "ACTIVE")
        .map((item: Scheme) => {
          return {
            id: item.id,
            name: item.name,
            scheme_type: item.scheme_type,
            employee_contribution_rate_type:
              item.employee_contribution_rate_type,
            employee_contribution_count: item.employee_contribution_count,
            employee_contribution_deducted_before_tax:
              item.employee_contribution_deducted_before_tax,
            employee_contribution_deducted_before_ni:
              item.employee_contribution_deducted_before_ni,
            employer_contribution_rate_type:
              item.employer_contribution_rate_type,
            employer_contribution_count: item.employer_contribution_count,
            status: item.status,
          };
        })
    : [];

  return (
    <>
      {dataGetAllSchemesByCompanyId?.length !== 0 ? (
        <DataGridPro
          rows={rows || []}
          columns={columns}
          autoHeight={true}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          pagination
          initialState={{
            pinnedColumns: { right: ["status", "actions"] },
            sorting: {
              sortModel: [{ field: "date_time_created", sort: "desc" }],
            },
          }}
          sx={{ backgroundColor: "white" }}
        />
      ) : (
        <NoData
          title="You have no Schemes"
          page="schemes"
          description="Schemes are fixed or percentage-based additions or subtractions for employees, defined per Employee."
          callback={handleModalOpenAdd}
        />
      )}
    </>
  );
};

export default DataGridSchemes;
