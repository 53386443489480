import { FC, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../app/hooks";

import Typography from "@mui/material/Typography";

import { useGetT35ResponseQuery } from "../../../features/api/apiTaxFormT35";
import {
  DataGridPro,
  GridColumns,
  GridValidRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";

const DataGridTaxFormT35: FC = () => {
  const currentCompanyId = useAppSelector((state) => state.currentCompany.id);
  const currentTaxYearId = useAppSelector((state) => state.currentTaxYear.id);

  const [rows, setRows] = useState<any[]>();

  const { data: dataT35ResponseByCompanyIdByTaxYear } = useGetT35ResponseQuery({
    companyId: Number(currentCompanyId)!,
    taxYearId: Number(currentTaxYearId)!,
  });

  function formatColumnMonthsRange(params: GridValueGetterParams<any, any>) {
    const formattedDate = (date: Date) =>
      new Intl.DateTimeFormat("gb", {
        day: "2-digit",
        month: "short",
      }).format(date);

    const dateFrom = formattedDate(new Date(params.row.payPeriod.from));
    const dateTo = formattedDate(new Date(params.row.payPeriod.to));

    return `${dateFrom || ""} – ${dateTo || ""}`;
  }

  const columns = useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: "id",
        headerName: "ID",
        type: "string",
        width: 120,
        editable: false,
        sortable: false,
        hide: true,
      },
      {
        field: "month",
        headerName: "Month No.",
        type: "string",
        width: 80,
        editable: false,
        sortable: false,
        hide: false,
      },
      {
        field: "month_range",
        headerName: "Month",
        type: "string",
        width: 140,
        editable: false,
        sortable: false,
        hide: false,
        valueGetter: formatColumnMonthsRange,
      },
      {
        field: "employeeCountForMonth",
        headerName: "No. of Employees in Month (A)",
        type: "string",
        align: "right",
        width: 120,
        editable: false,
        sortable: false,
        hide: false,
      },
      {
        field: "incomeTaxForMonthFormatted",
        headerName: "Income Tax Deducted in Month (B)",
        type: "string",
        align: "right",
        width: 120,
        editable: false,
        sortable: false,
        hide: false,
      },
      {
        field: "niForMonthFormatted",
        headerName: "N.I. Contributions Deduced in Month Employer/Employee (C)",
        type: "string",
        width: 120,
        align: "right",
        editable: false,
        sortable: false,
        hide: false,
      },
      {
        field: "remittanceForMonthFormatted",
        headerName: "Remittance: Total I.T & N.I. Cols (D)",
        type: "string",
        align: "right",
        width: 120,
        editable: false,
        sortable: false,
        hide: false,
      },
      {
        field: "employeeITIPForMonthFormatted",
        headerName: "Pay ITIP Amount",
        type: "string",
        align: "right",
        width: 120,
        editable: false,
        sortable: false,
        hide: false,
      },
      {
        field: "employeeNiForMonthFormatted",
        headerName: "Pay N.I. Amount",
        type: "string",
        align: "right",
        width: 120,
        editable: false,
        sortable: false,
        hide: false,
      },
    ],
    []
  );

  // TODO(DBB): Refactor this with UI naming conventions
  useEffect(() => {
    const payrollSubtotalsMonthly =
      dataT35ResponseByCompanyIdByTaxYear?.monthly.map((item: any, index) => ({
        id: index,
        month: item.month,
        month_from: item.payPeriod.from,
        month_to: item.payPeriod.to,
        // employeeCountForMonth: item.employeeCountForMonth,
        // incomeTaxForMonthFormatted: item.incomeTaxForMonthFormatted,
        // niForMonthFormatted: item.niForMonthFormatted,
        // remittanceForMonthFormatted: item.remittanceForMonthFormatted,
        // employeeITIPForMonthFormatted: item.employeeITIPForMonthFormatted,
        ...item,
        // TODO(DBB) : This is a frontend HACK, where the backend is returning the wrong value
        //             Refactor getT35Data() in pdf.service.ts
        employeeNiForMonthFormatted: item.niForMonthFormatted,
      }));

    const combinedPayrollSubtotals = payrollSubtotalsMonthly;

    setRows(combinedPayrollSubtotals);
  }, [dataT35ResponseByCompanyIdByTaxYear]);

  return (
    <>
      <Typography component="h3" variant="h5" gutterBottom>
        Reports
      </Typography>

      {rows ? (
        <DataGridPro
          rows={rows || []}
          columns={columns}
          autoHeight={true}
          initialState={{
            pinnedColumns: {
              right: [
                "employeeITIPForMonthFormatted",
                "employeeNiForMonthFormatted",
              ],
            },
          }}
          disableColumnMenu={true}
          sx={{
            backgroundColor: "white",
            marginBottom: "2rem",

            // Headers
            // Forced to use !important since overriding inline styles
            // Must manually set header row height and offset body margin top
            // Ref: https://github.com/mui/mui-x/issues/898
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            "& .MuiDataGrid-columnHeaders": {
              maxHeight: "168px !important",
              padding: "0.5rem inherit",
            },
            "& .MuiDataGrid-columnHeader": {
              height: "unset !important",
              textAlign: "right",
              textTransform: "uppercase",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
            },
            "& .MuiDataGrid-columnHeadersInner": {
              alignItems: "flex-start",
            },
            "& .MuiDataGrid-virtualScroller": {
              marginTop: "132px !important",
            },
            // Cells
            "& .MuiDataGrid-cell": {
              textAlign: "right",
            },
            "& .MuiDataGrid-cell:nth-of-type(1)": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-cell:nth-of-type(2)": {
              fontWeight: "bold",
            },
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DataGridTaxFormT35;
