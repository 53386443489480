import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconKeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { resetProvisionedEmployee } from "../../../features/state/employee/provisionedEmployeeSlice";

interface Props {
  disabled?: boolean;
}

const CtaEmployeeAdd: FC<Props> = ({ disabled }) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<IconKeyboardArrowDown />}
        disabled={disabled}
      >
        Add Employees
      </Button>

      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Link
          to="/employees/add"
          onClick={() => dispatch(resetProvisionedEmployee())}
        >
          <MenuItem onClick={handleClose} disableRipple={true}>
            Add an employee
          </MenuItem>
        </Link>

        <Divider sx={{ my: 0.5 }} />

        <MenuItem onClick={handleClose} disabled={true} disableRipple={true}>
          Import multiple employees
        </MenuItem>
      </Menu>
    </>
  );
};

export default CtaEmployeeAdd;
