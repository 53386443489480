import { FC } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import Toolbar from "@mui/material/Toolbar";

import CtaEmployeeAdd from "./dashboard/CtaEmployeeAdd";
import CtaEmployeeExport from "./dashboard/CtaEmployeeExport";

interface SubHeaderProps {
  title: string;
  icon?: string;
  disabled?: boolean;
}

const SubHeader: FC<SubHeaderProps> = ({ title, icon, disabled }) => {
  return (
    <AppBar position="relative" color="transparent" sx={customStyles.appBar}>
      <Toolbar sx={customStyles.toolBar}>
        <Typography
          variant="body1"
          component="div"
          sx={customStyles.title}
          className="flex"
        >
          {icon && <Icon sx={customStyles.icon}>{icon}</Icon>}
          {title}
        </Typography>

        <>
          <Box>
            <CtaEmployeeExport />
          </Box>

          <Box sx={{ ml: "1rem" }}>
            <CtaEmployeeAdd disabled={disabled} />
          </Box>
        </>
      </Toolbar>
    </AppBar>
  );
};

const customStyles = {
  appBar: {
    boxShadow: "inset 0px -1px 0px rgba(47, 48, 51, 0.12)",
  },
  toolBar: {
    padding: "16px 32px !important",
    minHeight: "68px !important",
  },
  icon: {
    color: "rgba(47, 48, 51, 0.54)",
    fontSize: "20px",
    marginRight: "10px",
  },
  title: {
    flexGrow: 1,
    fontWeight: 700,
    alignItems: "center",
  },
};

export default SubHeader;
