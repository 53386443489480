import { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";

import {
  DataGridPro,
  GridColumns,
  GridValidRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";

import { intlFormatAmount } from "../../../../utils/currencyFormatter";

import { useGetAllPayrollEntriesByPayrollIdQuery } from "../../../../features/api/apiPayrollEntries";

import { PayrollEntry } from "../../../../types/PayrollEntry";
import { Typography } from "@mui/material";

const DataGridPreviewPayroll: FC = () => {
  const dispatch = useAppDispatch();

  const provisionedPayrollId: string = useAppSelector(
    (state: RootState) => state.provisionedPayroll.id
  );

  const [rows, setRows] = useState<Partial<PayrollEntry>[] | undefined>();

  const {
    data: dataPayrollEntriesByPayrollId,
    isLoading: isLoadingPayrollEntriesByPayrollId,
    isFetching: isFetchingPayrollEntriesByPayrollId,
    isError: isErrorPayrollEntriesByPayrollId,
  } = useGetAllPayrollEntriesByPayrollIdQuery(provisionedPayrollId);

  const displayColumnFullName = (params: GridValueGetterParams) => {
    return `${params.row.employee_forenames || ""} ${
      params.row.employee_surname || ""
    }`;
  };

  const displayColumnTaxCode = (params: GridValueGetterParams) => {
    // TODO(DBB) : Refactor employee tax_code tax_code_suffix schema
    // return `${params.row.employee_tax_code || ""}${
    //   params.row.employee_tax_code_suffix || ""
    // }`;
    return `${params.row.employee_tax_code || ""}`;
  };

  const displayColumnCurrency = (value: number, currency: string) => {
    return intlFormatAmount(Number(value / 100), currency);
  };

  const columns = useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: "id",
        headerName: "ID",
        type: "string",
        width: 200,
        editable: false,
        hide: true,
      },
      {
        field: "employee_number",
        headerName: "No.",
        type: "string",
        width: 80,
        editable: false,
        // valueGetter: displayColumnFullName,
      },
      {
        field: "employee_full_name",
        headerName: "Employee",
        type: "string",
        width: 160,
        editable: false,
        valueGetter: displayColumnFullName,
      },
      // TODO(DBB) : Payment Method
      {
        field: "employee_ni_table_code",
        headerName: "N.I.",
        type: "string",
        width: 60,
        editable: false,
      },
      {
        field: "employee_tax_code",
        headerName: "Tax C.",
        type: "string",
        width: 80,
        editable: false,
        valueGetter: displayColumnTaxCode,
      },
      {
        field: "calculated_employee_gross_normal_pay",
        headerName: "Gross",
        type: "string",
        width: 120,
        editable: false,
        valueGetter: (params) =>
          displayColumnCurrency(
            params.row.calculated_employee_gross_normal_pay,
            "GBP"
          ),
      },
      {
        field: "calculated_employee_tax_free_pay",
        headerName: "Free Pay",
        type: "string",
        width: 100,
        editable: false,
        valueGetter: (params) =>
          displayColumnCurrency(
            params.row.calculated_employee_tax_free_pay,
            "GBP"
          ),
      },
      {
        field: "calculated_employee_tax_payable",
        headerName: "Tax",
        type: "string",
        width: 100,
        editable: false,
        valueGetter: (params) =>
          displayColumnCurrency(
            params.row.calculated_employee_tax_payable,
            "GBP"
          ),
      },
      {
        field: "calculated_employee_ni_subtotal",
        headerName: "N.I.",
        type: "string",
        width: 100,
        editable: false,
        valueGetter: (params) =>
          displayColumnCurrency(
            params.row.calculated_employee_ni_subtotal,
            "GBP"
          ),
      },
      {
        field: "calculated_employer_ni_contribution",
        headerName: "E'er N.I.",
        type: "string",
        width: 100,
        editable: false,
        valueGetter: (params) =>
          displayColumnCurrency(
            params.row.calculated_employer_ni_contribution,
            "GBP"
          ),
      },
      {
        field: "calculated_employee_gross_other",
        headerName: "Other",
        type: "string",
        width: 100,
        editable: false,
        valueGetter: (params) =>
          displayColumnCurrency(
            params.row.calculated_employee_gross_other,
            "GBP"
          ),
      },
      // {
      //   field: "calculated_employee_gross_schemes_contribution",
      //   headerName: "E'ee Scheme",
      //   type: "string",
      //   width: 100,
      //   editable: false,
      //   valueGetter: (params) =>
      //     displayColumnCurrency(
      //       params.row.calculated_employee_gross_schemes_contribution,
      //       "GBP"
      //     ),
      // },
      {
        field: "calculated_employee_net_pay",
        headerName: "E'ee Net Pay",
        type: "string",
        width: 100,
        editable: false,
        valueGetter: (params) =>
          displayColumnCurrency(params.row.calculated_employee_net_pay, "GBP"),
      },
      {
        field: "calculated_total_cost",
        headerName: "Total Cost",
        type: "string",
        width: 128,
        editable: false,
        valueGetter: (params) =>
          displayColumnCurrency(params.row.calculated_total_cost, "GBP"),
        cellClassName: "datagrid-payroll-preview-total-cost",
        resizable: false,
      },
    ],
    []
  );

  useEffect(() => {
    const payrollEntries = dataPayrollEntriesByPayrollId
      ?.filter((item: PayrollEntry) => item.status === "ACTIVE" || item.status === "UPDATED")
      .map((item: PayrollEntry) => {
        return {
          id: item.id,
          employee_number: item.employee_employee_number,
          employee_forenames: item.employee_forenames,
          employee_surname: item.employee_surname,
          // employee_payment_method: TODO(DBB) : ???
          employee_ni_table_code: item.employee_ni_table_code,
          employee_tax_code: item.employee_tax_code,
          calculated_employee_gross_normal_pay:
            item.calculated_employee_gross_normal_pay,
          calculated_employee_tax_free_pay:
            item.calculated_employee_tax_free_pay,
          calculated_employee_tax_payable: item.calculated_employee_tax_payable,
          calculated_employee_ni_subtotal: item.calculated_employee_ni_subtotal,
          calculated_employer_ni_contribution:
            item.calculated_employer_ni_contribution,
          calculated_employee_gross_other: item.calculated_employee_gross_other,
          // calculated_employee_gross_schemes_contribution:
          //   item.calculated_employee_gross_schemes_contribution,
          calculated_employee_net_pay: item.calculated_employee_net_pay,
          calculated_total_cost: item.calculated_total_cost,
        };
      });

    setRows(payrollEntries);
  }, [dataPayrollEntriesByPayrollId, dispatch]);

  if (isLoadingPayrollEntriesByPayrollId)
    return <div>Loading Payroll Entries...</div>;
  if (isFetchingPayrollEntriesByPayrollId)
    return <div>Fetching Payroll Entries...</div>;
  if (isErrorPayrollEntriesByPayrollId)
    return <div>Error Payroll Entries!</div>;
  if (!dataPayrollEntriesByPayrollId)
    return <div>No Payroll Entries data to display...</div>;

  return (
    <>
      {/* <Box sx={{ marginTop: "2rem" }}>
        <Typography variant="h5" component="h3" gutterBottom>
          Payroll Entries
        </Typography>
      </Box> */}

      <Box
        sx={{
          "& .datagrid-payroll-preview-total-cost": {
            fontWeight: "600",
          },
          marginTop: "2rem"
        }}
      >
        <div className="w-100">
          <DataGridPro
            rows={rows || []}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[10]}
            autoHeight={true}
            sx={{backgroundColor: "#ffffff"}}
            initialState={{
              pinnedColumns: {
                left: ["employee_number", "employee_full_name"],
                right: ["calculated_total_cost"],
              },
            }}
          />
        </div>
      </Box>
    </>
  );
};

export default DataGridPreviewPayroll;
