import { Dispatch, FC, SetStateAction } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { Controller, useForm } from "react-hook-form";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { useSubmitUserSignUpMutation } from "../../../../features/api/apiUserSignUp";
import { setCurrentUserSignUp } from "../../../../features/state/signup/userSignUpSlice";

import { UserSignUp } from "../../../../types/UserSignUp";
import { useNavigate } from "react-router-dom";
import FooterDisclaimer from "../FooterDisclaimer";

interface Props {
  handleStepperNext: () => void;
  setActiveStep: Dispatch<SetStateAction<number>>;
  activeStep: number;
}

const FormEmailAddress: FC<Props> = ({ handleStepperNext }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { handleSubmit, control } = useForm<UserSignUp>();

  const [submitUserSignUp] = useSubmitUserSignUpMutation();

  const onSubmit = async (data: UserSignUp) => {
    submitUserSignUp(data)
      .unwrap()
      .then((payload) => {
        const { email_verified, account_type } = payload;

        // TODO(DBB) : Refactor capitalisation of ENUm

        // Signup Scenario 1
        //   User email_address exists, is verified, password has been set
        //   User should signin, or reset password
        if (email_verified === true && account_type === "OTC_ISSUED") {
          return navigate("/signin");
        }

        // Signup Scenario 2
        //   User email_address exists, is not yet verified, no password set
        //   User should continue signup, validating email, setting password
        if (email_verified === false && account_type === "PROVISIONED") {
          dispatch(setCurrentUserSignUp(payload));

          return handleStepperNext();
        }

        // Signup Scenario 3
        //   User email_address exists, is verified, no password set
        //   User should set password
        if (email_verified === true && account_type === "PROVISIONED") {
          dispatch(setCurrentUserSignUp(payload));

          return handleStepperNext();
        }

        // Exception
        if (email_verified === false && account_type === "OTC_ISSUED") {
          return navigate("/signin");
        }
      })
      .catch((error: any) =>
        console.error(
          `[${error.data.statusCode}] ${error.data.error}: "${error.data.message}"`
        )
      );
  };

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        className="mx-auto"
        style={{ maxWidth: "696px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          className="p-12"
          sx={(theme) => ({
            [theme.breakpoints.down(992)]: {
              padding: "0 0 50px 0",
            },
          })}
        >
          {/* Page Title */}
          <Grid item xs={12}>
            <Typography
              component="h4"
              variant="h4"
              gutterBottom
              className="m-4"
            >
              Create an account
            </Typography>
            <div className="mb-3">
              Already have an account?{" "}
              <Link href="/signin" className="font-bold">
                Sign in
              </Link>
            </div>
          </Grid>

          {/* Email */}
          <Grid item xs={12}>
            <Controller
              name="email_address"
              control={control}
              defaultValue=""
              rules={{
                required: "Work email is required",
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "Invalid email address",
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email_address"
                  label="Work Email"
                  name="email_address"
                  autoComplete="email"
                  autoFocus
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          {/* CTA: Create Account */}
          <Grid item xs={12}>
            <Button
              className="mb-9"
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ height: "60px" }}
            >
              Create Account
            </Button>
          </Grid>

          <FooterDisclaimer />
        </Grid>
      </form>
    </>
  );
};

export default FormEmailAddress;
