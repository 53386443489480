import { FC } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { persistor } from "../../../app/store";
import { useAppDispatch } from "../../../app/hooks";

import {
  cloudPayrollApi,
  useLazyUserSignOutQuery,
} from "../../../features/api/cloudPayrollApi";

import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import IconSettings from "@mui/icons-material/Settings";
import IconAccount from "@mui/icons-material/AccountCircle";
import IconLogout from "@mui/icons-material/Logout";

interface Props {
  anchorElUser: Element | ((element: Element) => Element) | null | undefined;
  handleCloseUserMenu: () => void;
}

const MenuAccount: FC<Props> = ({ anchorElUser, handleCloseUserMenu }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [triggerSignOut] = useLazyUserSignOutQuery();

  const handleSignOut = () => {
    // Clear all RTK Query entity caches
    dispatch(cloudPayrollApi.util.resetApiState());

    // Reset all data Redux Persist
    // await persistor.purge();
    const purge = () => {
      persistor.pause();
      persistor.flush().then(() => {
        return persistor.purge();
      });
    };
    purge();

    // Destroy Nest session cookie
    // and redirect to /signin route
    triggerSignOut().then((result) => result.isSuccess && navigate("/signin"));
  };

  return (
    <>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted={true}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          marginTop: "2.75rem",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuList sx={{ minWidth: "12rem", padding: 0 }}>
          <Link to="/account/overview">
            <MenuItem>
              <ListItemIcon>
                <IconAccount />
              </ListItemIcon>
              <ListItemText>Account</ListItemText>
            </MenuItem>
          </Link>

          <Link to="/settings">
            <MenuItem disabled={true}>
              <ListItemIcon>
                <IconSettings />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </MenuItem>
          </Link>

          <Box sx={{ marginTop: "0.33rem", marginBottom: "0.33rem" }}>
            <Divider />
          </Box>

          <MenuItem onClick={handleSignOut}>
            <ListItemIcon>
              <IconLogout />
            </ListItemIcon>
            <ListItemText>Sign Out</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default MenuAccount;
