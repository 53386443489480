import { cloudPayrollApi } from "./cloudPayrollApi";

import { PayrollFrequency } from "../../types/PayrollFrequency";

const apiPayrollFrequencies = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // READ All Payroll Frequencies
    getAllPayrollFrequencies: builder.query<PayrollFrequency[], number | void>({
      query: (limit = 10) => `/payroll-frequencies?limit=${limit}`,
      providesTags: ["PayrollFrequency"],
    }),

    // READ Single Payroll Frequency
    getPayrollFrequency: builder.query<PayrollFrequency, number>({
      query: (id) => `/payroll-frequencies/${id}`,
      providesTags: ["PayrollFrequency"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllPayrollFrequenciesQuery, useGetPayrollFrequencyQuery } =
  apiPayrollFrequencies;
