import { cloudPayrollApi } from "./cloudPayrollApi";
import { Company } from "../../types/Company";

// import { ZodCompany } from "../../zod/company/ZodCompany";

import { CompanyProfile } from "../../pages/company/add/01-company-details/ValidationCompanyProfile";
import { ContactDetails } from "../../pages/company/add/02-contact-details/ValidationContactDetails";

// TODO(DBB) : Extract this out to standalone type file?

interface ZodCompany {
  id: number;
  companyProfile: CompanyProfile;
  contactDetails: ContactDetails;
  status: string;
}

const apiCompanies = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // CREATE Company
    // createCompany: builder.mutation<void, Company>({
    //   query: (company) => ({
    //     url: `/companies`,
    //     method: "POST",
    //     body: company,
    //   }),
    //   invalidatesTags: ["Company"],
    // }),
    // TODO(DBB) : ZodCompany type needs proper inference
    createCompany: builder.mutation<ZodCompany, Partial<Company>>({
      query: (company) => ({
        url: `/companies`,
        method: "POST",
        body: company,
      }),
      invalidatesTags: ["Company"],
    }),

    // READ All Companies
    getAllCompanies: builder.query<Company[], number | null>({
      query: (limit = 10) => `/companies?limit=${limit}`,
      providesTags: ["Company"],
    }),

    // READ Single Company
    getCompany: builder.query<Company, number | undefined>({
      query: (id) => `/companies/${id}`,
      providesTags: ["Company"],
    }),

    // UPDATE Company
    // updateCompany: builder.mutation<void, Company>({
    //   query: ({ id, ...rest }) => ({
    //     url: `/companies/${id}`,
    //     method: "PATCH",
    //     body: rest,
    //   }),
    //   invalidatesTags: ["Company"],
    // }),
    updateCompany: builder.mutation<ZodCompany, Partial<ZodCompany>>({
      query: ({ id, ...rest }) => ({
        url: `/companies/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Company"],
    }),

    // DELETE Company
    deleteCompany: builder.mutation<void, number>({
      query: (id) => ({
        url: `/companies/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Company"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateCompanyMutation,
  useGetCompanyQuery,
  useLazyGetCompanyQuery,
  useGetAllCompaniesQuery,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
} = apiCompanies;
