import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  EmploymentInformation,
  SchemaEmploymentInformation,
} from "./ValidationEmploymentInformation";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertProps } from "@mui/material/Alert";

import { useUpdateEmployeeMutation } from "../../../features/api/apiEmployees";

interface Props {
  handleModalClose: () => void;
  currentEmployeeId: number;
  currentEmployeeLeaveDate?: Date;
}

const FormActionEmployeeLeave: FC<Props> = ({
  handleModalClose,
  currentEmployeeId,
  currentEmployeeLeaveDate,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<EmploymentInformation>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaEmploymentInformation),
    defaultValues: {
      // job_date_finished: currentEmployeeLeaveDate || (null as unknown as Date),
      job_date_finished: currentEmployeeLeaveDate || null,
    },
  });

  const [updateEmployee] = useUpdateEmployeeMutation();

  const onSubmit = (data: EmploymentInformation) => {
    updateEmployee({ id: currentEmployeeId, status: "DEACTIVATED", ...data })
      .unwrap()
      .then(() => handleModalClose())
      .catch((error) => {
        console.error(
          `Could not update Employee record: ${JSON.stringify(error.data)}`
        );
      });
  };

  const onError = (errors: object) => {
    Object.values(errors).forEach((item) =>
      setSnackbar({
        children: `${item.message}`,
        severity: "error",
      })
    );
  };

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Container sx={{ marginBottom: "1rem" }}>
          <Grid item xs={8}>
            <Typography component="h3" variant="h5" sx={{ mt: 2, mb: 2 }}>
              Employee Leave Date
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {/*  Input : Starting Date */}
            <FormControl sx={{ marginBottom: "1rem" }} fullWidth={true}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  name="job_date_finished"
                  control={control}
                  render={({ field: { name, ref, onBlur, ...rest } }) => (
                    <DatePicker
                      {...rest}
                      inputRef={ref}
                      label="Employee Leave Date"
                      renderInput={(inputProps) => (
                        <TextField
                          {...inputProps}
                          name={name}
                          onBlur={onBlur}
                          error={!!errors.job_date_finished}
                          fullWidth={true}
                        />
                      )}
                      inputFormat="dd-MM-yyyy"
                      minDate={new Date("01-01-2000")}
                      maxDate={new Date()}
                      disabled={false}
                    />
                  )}
                />
              </LocalizationProvider>

              <FormHelperText error={true}>
                {errors.job_date_finished?.message}
              </FormHelperText>

              <Button
                variant="text"
                onClick={() =>
                  reset({
                    job_date_finished: null as unknown as Date,
                  })
                }
                sx={{
                  maxWidth: "4rem",
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                Reset
              </Button>
            </FormControl>
          </Grid>

          <Stack direction="row" spacing={2}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disableElevation={true}
            >
              Set Employee Leave Date
            </Button>

            <Button
              type="button"
              onClick={handleModalClose}
              variant="outlined"
              size="large"
              disableElevation={true}
            >
              Cancel
            </Button>
          </Stack>
        </Container>
      </form>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

export default FormActionEmployeeLeave;
