import { FC } from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import SubHeader from "../../SubHeader";
// import HeaderSelectorTaxYear from "./HeaderSelectorTaxYear";
import DataGridEmployees from "./DataGridEmployees";
import Footer from "./Footer";

const DashboardYearEndDocumentsPreview: FC = () => {
  return (
    <>
      <SubHeader title="Forms > Year End Documents > Preview" icon="receipt" />

      <Container maxWidth="xl" sx={{ width: "100%" }}>
        <Typography
          component="h2"
          variant="h4"
          sx={{ marginTop: "2rem", marginBottom: "1.5rem" }}
        >
          Year End Documents
        </Typography>

        {/* <HeaderSelectorTaxYear /> */}

        <Typography
          component="h2"
          variant="h5"
          sx={{ marginTop: "2rem", marginBottom: "1rem" }}
        >
          Entries
        </Typography>

        <DataGridEmployees />

        <Footer />
      </Container>
    </>
  );
};

export default DashboardYearEndDocumentsPreview;
