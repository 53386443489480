import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SettingsIcon from "@mui/icons-material/Settings";

import LogoCompany from "./LogoCompany";
import PlatformStatus from "./StatusPlatform";

const SectionHeader: FC = () => {
  const currentCompany = useAppSelector((state) => state.currentCompany);

  return (
    <>
      <Box
        component="header"
        sx={{ backgroundColor: "#FFFFFF", borderBottom: "1px solid #E7E6E9" }}
      >
        <Container
          maxWidth="xl"
          sx={{
            width: "100%",
            paddingTop: "3rem",
            paddingBottom: "4rem",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ marginRight: "2rem" }}>
            <LogoCompany />
          </Box>

          <Box>
            <Typography variant="h4" display="block">
              {currentCompany.name_trading}
            </Typography>
            <Typography variant="overline" display="block">
              {currentCompany.name_legal}
            </Typography>
            <Typography variant="subtitle1" display="block">
              Incorporation Number:{" "}
              {currentCompany.company_incorporation_number}
            </Typography>
            <Typography variant="subtitle1" display="block">
              Tax Reference Number:{" "}
              {currentCompany.company_tax_reference_number}
            </Typography>
          </Box>

          <Box sx={{ marginLeft: "auto" }}>
            <NavLink
              to="/company/information"
              style={{
                display: "flex",
                alignItems: "center",
                color: "#555F71",
                padding: "1rem 0.5rem",
              }}
            >
              <SettingsIcon />
              <Typography sx={{ marginLeft: "0.5rem" }}>
                Edit company settings
              </Typography>
            </NavLink>

            <PlatformStatus />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SectionHeader;
