import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { RootState } from "../../../../../app/store";
import { useAppSelector } from "../../../../../app/hooks";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import HeaderDialog from "../../../../../components/common/dialog/HeaderDialog";
import FormMemberAdd from "./FormMemberAdd";
import ListMembersProspective from "./ListMembersProspective";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import Button from "@mui/material/Button";
import FooterDialog from "../../../../../components/common/dialog/FooterDialog";

import { useInviteMemberMutation } from "../../../../../features/api/apiMembers";
import { MemberAlertContext } from "../DashboardMembers";

interface Props {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  currentCompanyId: number;
}

const DialogMembersAdd: FC<Props> = ({
  modalOpen,
  setModalOpen,
  currentCompanyId,
}) => {
  const [membersProspective, setMembersProspective] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const addMemberProspective = (email_address: string) => {
    setMembersProspective((membersProspective) => [
      ...membersProspective,
      email_address,
    ]);
  };

  const removeMemberProspective = (email_address: string) => {
    const updatedMembersProspective = membersProspective.filter(
      (item) => item !== email_address
    );

    setMembersProspective(() => [...updatedMembersProspective]);
  };

  const [inviteMember] = useInviteMemberMutation();

  const { setAlertMessage } = useContext(MemberAlertContext);

  const currentUserEmailAddress = useAppSelector(
    (state: RootState) => state.currentUser?.email_address
  );

  const handleSubmit = (membersProspective: string[]) => {
    setLoading(true);

    for (const member of membersProspective) {
      inviteMember({
        email_address_recipient: member,
        company_id: currentCompanyId,
        email_address_sender: currentUserEmailAddress!,
      })
        .unwrap()
        .then((res) => {
          setAlertMessage(res);
        })
        .then(() => {
          setLoading(false);
        })
        .then(() => {
          setModalOpen(false);
        })
        .then(() => {
          setMembersProspective([]);
        })
        .catch((error) => {
          console.error(
            `Could not create Member record: ${JSON.stringify(error.data)}`
          );
        });
    }
  };

  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="sm"
        fullWidth={true}
      >
        <HeaderDialog
          handleModalClose={() => setModalOpen(false)}
          title="Invite New Members"
        />

        <DialogContent>
          <FormMemberAdd addMemberProspective={addMemberProspective} />

          {membersProspective && (
            <ListMembersProspective
              membersProspective={membersProspective}
              removeMemberProspective={removeMemberProspective}
            />
          )}
        </DialogContent>

        <FooterDialog>
          <Stack direction="row" gap={2}>
            <LoadingButton
              onClick={() => handleSubmit(membersProspective)}
              variant="contained"
              color="primary"
              size="large"
              disabled={false}
              disableElevation={true}
              loading={loading}
            >
              Continue
            </LoadingButton>

            <Button
              type="button"
              onClick={() => setModalOpen(false)}
              variant="outlined"
              color="secondary"
              size="large"
              disabled={false}
              disableElevation={true}
            >
              Cancel
            </Button>
          </Stack>
        </FooterDialog>
      </Dialog>
    </>
  );
};

export default DialogMembersAdd;
