import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMatch } from "react-router-dom";
import { CurrentEmployeeContext } from "../DashboardEmployee";
import { EmployeeScheme } from "./ValidationScheme";

import {
  DataGridPro,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridColumns,
  GridRowId,
  GridRowParams,
  GridValidRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";

import Button from "@mui/material/Button";
import IconDisable from "@mui/icons-material/DisabledByDefault";

import FooterForm from "../FooterForm";

import {
  useGetAllEmployeeSchemesByEmployeeIdQuery,
  useUpdateEmployeeSchemeMutation,
} from "../../../../features/api/apiEmployeeSchemes";

const formatValuesCurrency = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

interface Props {
  handleStepperNext: () => void;
  canProgress: boolean;
}

const DataGridSchemes: FC<Props> = ({ handleStepperNext, canProgress }) => {
  const isEdit = useMatch("/employees/edit");
  const { employeeId } = useContext(CurrentEmployeeContext);

  const [rows, setRows] = useState<Partial<EmployeeScheme>[] | undefined>();

  const { data: dataGetAllEmployeeSchemesByEmployeeId } =
    useGetAllEmployeeSchemesByEmployeeIdQuery(Number(employeeId));

  const [updateEmployeeScheme] = useUpdateEmployeeSchemeMutation();

  const displayColumnDate = (value: any | Date) => {
    const date = new Date(value);

    const dateFormatted = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(date);

    return dateFormatted;
  };

  const handleSchemeEndClick = useCallback(
    (employeeSchemeId: GridRowId) => {
      const dateNow = new Date();

      const submission = {
        id: Number(employeeSchemeId),
        effective_date_end: dateNow,
      };

      updateEmployeeScheme(submission);
    },
    [updateEmployeeScheme]
  );

  const columns: GridColDef[] = useMemo<GridColumns<GridValidRowModel>>(
    () => [
      {
        field: "scheme",
        headerName: "Item",
        type: "string",
        width: 160,
        editable: false,
        hide: false,
        valueGetter: (params: GridCellParams) => {
          return `${params.row.scheme.name}`;
        },
      },
      {
        field: "contribution_employee",
        headerName: "E'ee Ctrb",
        type: "number",
        align: "right",
        width: 80,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) => {
          const valueType = params.row.scheme.employee_contribution_rate_type;
          const value = params.row.scheme.employee_contribution_count / 100;

          const formattedValue =
            (valueType === "PERCENTAGE" && `${value}%`) ||
            (valueType === "FIXED" && formatValuesCurrency.format(value));

          return formattedValue || "";
        },
      },
      {
        field: "effective_date_start",
        headerName: "Start Date",
        type: "date",
        align: "right",
        width: 120,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.effective_date_start
            ? displayColumnDate(params.row.effective_date_start)
            : null,
      },
      {
        field: "effective_date_end",
        headerName: "End Date",
        type: "date",
        align: "right",
        width: 120,
        editable: false,
        hide: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.effective_date_end
            ? displayColumnDate(params.row.effective_date_end)
            : null,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 80,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<IconDisable />}
            label="Set End Date"
            onClick={() => handleSchemeEndClick(params.id)}
            showInMenu={true}
            disabled={false}
          />,
        ],
      },
    ],
    [handleSchemeEndClick]
  );

  useEffect(() => {
    const employeeSchemes =
      dataGetAllEmployeeSchemesByEmployeeId &&
      dataGetAllEmployeeSchemesByEmployeeId.map((item: any) => {
        return {
          id: item.id,
          scheme: item.scheme,
          contribution_employee: item.scheme,
          contribution_employer: item.scheme,
          effective_date_start: item.effective_date_start,
          effective_date_end: item.effective_date_end,
        };
      });

    setRows(employeeSchemes);
  }, [isEdit, dataGetAllEmployeeSchemesByEmployeeId]);

  return (
    <>
      <div style={{ height: 300, width: "100%", marginBottom: "2rem" }}>
        <DataGridPro
          rows={rows || []}
          columns={columns}
          initialState={{
            pinnedColumns: {
              right: ["actions"],
            },
          }}
        />
      </div>

      <FooterForm>
        <Button
          id="employee-add-edit-submit"
          variant="contained"
          disableElevation
          onClick={() => handleStepperNext()}
          type="button"
          size="large"
          disabled={!canProgress}
        >
          Continue
        </Button>
      </FooterForm>
    </>
  );
};

export default DataGridSchemes;
