import { FC, ReactNode, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useAppDispatch } from "../../../app/hooks";
import { persistor } from "../../../app/store";

import {
  useLazyUserSignOutQuery,
  cloudPayrollApi,
} from "../../../features/api/cloudPayrollApi";

import DialogIdleTimer from "./DialogIdleTimer";

interface Props {
  children: ReactNode;
}

const IdleTimer: FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const idleTimeout = Number(process.env.REACT_APP_IDLE_TIMEOUT);
  const promptBeforeIdle = Number(process.env.REACT_APP_IDLE_PROMPTBEFOREIDLE);

  // Function : Idle TImer
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [idleState, setIdleState] = useState<string>("active");
  const [idleRemaining, setIdleRemaining] = useState<number>(9999);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [triggerSignOut] = useLazyUserSignOutQuery();

  const handleSignOut = useCallback(() => {
    dispatch(cloudPayrollApi.util.resetApiState());

    const purge = () => {
      persistor.pause();
      persistor.flush().then(() => {
        return persistor.purge();
      });
    };
    purge();

    triggerSignOut().then((result) => result.isSuccess && navigate("/signin"));
  }, [dispatch, navigate, triggerSignOut]);

  const onIdle = () => {
    setIdleState("idle");
    setDialogOpen(false);
  };

  const onActive = () => {
    setIdleState("active");
    setDialogOpen(false);
  };

  const onPrompt = () => {
    setIdleState("prompted");
    setDialogOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: idleTimeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingSeconds = Math.ceil(getRemainingTime() / 1000);
      setIdleRemaining(remainingSeconds);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, idleRemaining]);

  useEffect(() => {
    idleRemaining === 0 && handleSignOut();
  }, [idleRemaining, handleSignOut]);

  const handleStillHere = () => {
    activate();
  };

  return (
    <>
      <DialogIdleTimer
        dialogOpen={dialogOpen}
        idleRemaining={idleRemaining}
        promptBeforeIdle={promptBeforeIdle}
        handleStillHere={handleStillHere}
      />

      {children}
    </>
  );
};

export default IdleTimer;
