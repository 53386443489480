import { FC } from "react";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

import Container from "@mui/material/Container";

import SubHeader from "../SubHeader";
import ModuleViewEmployees from "./ModuleViewEmployee";
import AlertDataMissing from "../AlertDataMissing";

import { useGetAllPayrollSchedulesByCompanyIdQuery } from "../../../features/api/apiPayrollSchedules";
import { useGetAllPayComponentsByCompanyIdQuery } from "../../../features/api/apiPayComponents";

const DashboardOverview: FC = () => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const { data: dataPayrollSchedulesByCompanyId } =
    useGetAllPayrollSchedulesByCompanyIdQuery(currentCompanyId);

  const { data: dataPayComponentsByCompanyId } =
    useGetAllPayComponentsByCompanyIdQuery(currentCompanyId);

  return (
    <>
      <SubHeader
        title="Overview"
        icon="home"
        disabled={
          dataPayrollSchedulesByCompanyId?.length === 0 ||
          dataPayComponentsByCompanyId?.length === 0
        }
      />

      <Container maxWidth="xl" sx={{ width: "100%", marginTop: "2rem" }}>
        {dataPayrollSchedulesByCompanyId?.length === 0 && (
          <AlertDataMissing
            entityName="Payroll Schedule"
            linkAdd="/payrolls/pay-schedules"
            sx={{ marginBottom: "2rem" }}
          />
        )}

        {dataPayComponentsByCompanyId?.length === 0 && (
          <AlertDataMissing
            entityName="Pay Component"
            linkAdd="/payrolls/pay-components"
            sx={{ marginBottom: "2rem" }}
          />
        )}

        <ModuleViewEmployees />
      </Container>
    </>
  );
};

export default DashboardOverview;
