// TODO(DBB) : Unique entity for single use case...
// Should this be solved with a union?

import { cloudPayrollApi } from "./cloudPayrollApi";
import { PayrollEmployees } from "../../types/PayrollEmployees";

interface GetDataQueryParams {
  companyId: number;
  taxYearId: number;
}

const apiPayrollEmployees = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // READ All Payroll Employees by Company ID and by Tax Year
    getAllPayrollEmployeesByCompanyIdByTaxYear: builder.query<
      PayrollEmployees,
      GetDataQueryParams
    >({
      query: ({ companyId, taxYearId }) =>
        `/pdf/payroll-employees/${companyId}/${taxYearId}`,
      providesTags: ["Payroll"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllPayrollEmployeesByCompanyIdByTaxYearQuery } =
  apiPayrollEmployees;
