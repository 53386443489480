import { cloudPayrollApi } from "./cloudPayrollApi";

// import { PayComponent } from "../../pages/payrolls/pay-components/addEdit/ValidationPayComponent";

import { PayComponent } from "../../types/PayComponent";

const apiPayComponents = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // CREATE Pay Component

    createPayComponent: builder.mutation<void, PayComponent>({
      query: (payComponent) => ({
        url: `/pay-components`,
        method: "POST",
        body: payComponent,
      }),
      invalidatesTags: ["PayComponent"],
    }),

    // CREATE Pay Component by Company ID
    createPayComponentByCompanyId: builder.mutation<void, PayComponent>({
      query: (payComponent) => ({
        url: `/pay-components`,
        method: "POST",
        body: payComponent,
      }),
      invalidatesTags: ["PayComponent"],
    }),

    // READ All Pay Components
    getAllPayComponents: builder.query<PayComponent[], number | void>({
      query: (limit = 10) => `/pay-components?limit=${limit}`,
      providesTags: ["PayComponent"],
    }),

    // READ All Pay Components by Company ID
    getAllPayComponentsByCompanyId: builder.query<
      PayComponent[],
      number | void | undefined
    >({
      query: (companyId) => `/pay-components/company/${companyId}`,
      providesTags: ["PayComponent"],
    }),

    // READ Single Pay Component
    getPayComponent: builder.query<PayComponent, number>({
      query: (id) => `/pay-components/${id}`,
      providesTags: ["PayComponent"],
    }),

    // UPDATE Pay Component
    updatePayComponent: builder.mutation<void, PayComponent>({
      query: ({ id, ...rest }) => ({
        url: `/pay-components/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["PayComponent"],
    }),

    // DELETE Pay Component
    deletePayComponent: builder.mutation<void, number>({
      query: (id) => ({
        url: `/pay-components/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PayComponent"],
    }),
  }),
  overrideExisting: false,
});

export const {
  endpoints,
  useCreatePayComponentMutation,
  useCreatePayComponentByCompanyIdMutation,
  useGetAllPayComponentsQuery,
  useGetAllPayComponentsByCompanyIdQuery,
  useGetPayComponentQuery,
  useLazyGetPayComponentQuery,
  useUpdatePayComponentMutation,
  useDeletePayComponentMutation,
} = apiPayComponents;
