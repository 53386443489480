import axios from "axios";

// PDF Form Zip : Payslips

export const getPdfFormPayslipsZip = async (payrollId: string) => {
  const apiUrl = process.env.REACT_APP_CLOUDPAYROLL_API_URL;
  const dynamicEndpoint = `${apiUrl}/pdf/createpayslipsbypayrollid/${payrollId}`;

  try {
    const instance = axios.create({
      baseURL: dynamicEndpoint,
      withCredentials: true,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/zip",
        Accept: "application/zip",
      },
    });

    const blobObjectUrl = instance.post("/").then((res) => {
      const blob = new Blob([res.data], { type: "application/zip" });
      const blobObject = URL.createObjectURL(blob);

      return blobObject;
    });

    return blobObjectUrl;
  } catch (error) {
    console.error("Error downloading Payslips ZIP object: " + error);
  }
};

// PDF Form Zip : Form T14s

export const getPdfFormFormT14sZip = async (
  companyId: number,
  taxYearId: number
) => {
  const apiUrl = process.env.REACT_APP_CLOUDPAYROLL_API_URL;
  const dynamicEndpoint = `${apiUrl}/pdf/createcompanyt14bytaxyear/${companyId}/${taxYearId}`;

  try {
    const instance = axios.create({
      baseURL: dynamicEndpoint,
      withCredentials: true,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/zip",
        Accept: "application/zip",
      },
    });

    const blobObjectUrl = instance.post("/").then((res) => {
      const blob = new Blob([res.data], { type: "application/zip" });
      const blobObject = URL.createObjectURL(blob);

      return blobObject;
    });

    return blobObjectUrl;
  } catch (error) {
    console.error("Error downloading Form T14s ZIP object: " + error);
  }
};
