import { FC, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { NewCompanyContext } from "../DashboardCompanyAdd";
import {
  ContactDetails,
  SchemaContactDetails,
} from "./ValidationContactDetails";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { useLazyGetCurrentUserSelfQuery } from "../../../../features/api/apiUsers";
import {
  useLazyGetCompanyQuery,
  useUpdateCompanyMutation,
} from "../../../../features/api/apiCompanies";
import { setCurrentUser } from "../../../../features/state/user/userSlice";
import { setCurrentCompany } from "../../../../features/state/company/companySlice";
import { dataCountries } from "../../../../utils/dataCountries";

interface SelectItem {
  id: string | number;
  name: string;
}

interface Props {
  handleStepperNext: () => void;
}

const FormContactDetails: FC<Props> = ({ handleStepperNext }) => {
  const dispatch = useDispatch();

  const { newCompanyId } = useContext(NewCompanyContext);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ContactDetails>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaContactDetails),
    defaultValues: {
      country: "",
      address_1: "",
      address_2: "",
      city: "",
      region: "",
      post_code: "",
      email_address: "",
      phone_number: "",
    },
  });

  const [updateCompany] = useUpdateCompanyMutation();

  const [triggerLazyGetCompany] = useLazyGetCompanyQuery();
  const [triggerLazyGetCurrentUserSelf] = useLazyGetCurrentUserSelfQuery();

  const onSubmit = (data: ContactDetails) => {
    newCompanyId &&
      updateCompany({ id: newCompanyId, status: "Active", ...data })
        .unwrap()
        .then(async () => {
          const refetchedUser = await triggerLazyGetCurrentUserSelf().unwrap();

          refetchedUser && dispatch(setCurrentUser(refetchedUser));
        })
        .then(async () => {
          const refetchedCompany = await triggerLazyGetCompany(
            newCompanyId
          ).unwrap();

          refetchedCompany && dispatch(setCurrentCompany(refetchedCompany));
        })
        .then(() => {
          handleStepperNext();
        })
        .catch((error) => {
          setSnackbar({
            children: `${error}`,
            severity: "error",
          });
          console.error(
            `Could not create Company record: ${JSON.stringify(error.data)}`
          );
        });
  };

  const onError = (errors: object) => {
    Object.values(errors).forEach((item) =>
      setSnackbar({
        children: `${item.message}`,
        severity: "error",
      })
    );
  };

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Stack direction="column" spacing={2}>
          <Typography component="h2" variant="h4">
            Update Contact Details
          </Typography>

          <Typography component="h6" variant="h6">
            Registered Address
          </Typography>

          {/* Input : Country */}
          <FormControl fullWidth={true} sx={{ marginBottom: "2rem" }}>
            <Controller
              control={control}
              name="country"
              render={({ field }) => (
                <>
                  <InputLabel id="label-country">Country</InputLabel>
                  <Select
                    {...field}
                    label="Country"
                    labelId="label-country"
                    error={!!errors.country}
                    disabled={false}
                  >
                    {dataCountries?.map((item: SelectItem) => {
                      return (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </>
              )}
            />
            <FormHelperText error={true}>
              {errors.country?.message}
            </FormHelperText>
          </FormControl>

          <Controller
            name="address_1"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Address Line 1"
                variant="outlined"
                error={!!errors.address_1}
                helperText={errors.address_1 ? errors.address_1?.message : null}
              />
            )}
          />

          <Controller
            name="address_2"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Address Line 2"
                variant="outlined"
                error={!!errors.address_2}
                helperText={errors.address_2 ? errors.address_2?.message : null}
              />
            )}
          />

          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="City"
                variant="outlined"
                error={!!errors.city}
                helperText={errors.city ? errors.city?.message : null}
              />
            )}
          />

          <Controller
            name="region"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="State/Region"
                variant="outlined"
                error={!!errors.region}
                helperText={errors.region ? errors.region?.message : null}
              />
            )}
          />

          <Controller
            name="post_code"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Post Code"
                variant="outlined"
                error={!!errors.post_code}
                helperText={errors.post_code ? errors.post_code?.message : null}
              />
            )}
          />

          <Divider />

          <Typography component="h6" variant="h6">
            Contact Information
          </Typography>

          <Controller
            name="phone_number"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Phone Number"
                variant="outlined"
                error={!!errors.phone_number}
                helperText={
                  errors.phone_number ? errors.phone_number?.message : null
                }
              />
            )}
          />

          <Controller
            name="email_address"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Email Address"
                variant="outlined"
                error={!!errors.email_address}
                helperText={
                  errors.email_address ? errors.email_address?.message : null
                }
              />
            )}
          />

          <Divider />

          <Stack direction="row" spacing={2}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disableElevation
            >
              Save & Continue
            </Button>

            {/* TODO(DBB) : Cancel in case of !isFirst */}
            {/* <Button
              type="button"
              onClick={handleModalClose}
              variant="outlined"
              size="large"
              disableElevation
            >
              Cancel
            </Button> */}
          </Stack>
        </Stack>
      </form>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

export default FormContactDetails;
