import { FC, useState } from "react";
import { RootState } from "../../../../../app/store";
import { useAppSelector } from "../../../../../app/hooks";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ContactDetails,
  SchemaContactDetails,
} from "./ValidationContactDetails";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import {
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from "../../../../../features/api/apiCompanies";

interface Props {
  handleModalClose: () => void;
}

const FormContactDetails: FC<Props> = ({ handleModalClose }) => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const { data: dataGetCompany } = useGetCompanyQuery(currentCompanyId);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ContactDetails>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(SchemaContactDetails),
    defaultValues: {
      country: dataGetCompany?.country || "",
      address_1: dataGetCompany?.address_1 || "",
      address_2: dataGetCompany?.address_2 || "",
      city: dataGetCompany?.city || "",
      region: dataGetCompany?.region || "",
      post_code: dataGetCompany?.post_code || "",
      email_address: dataGetCompany?.email_address || "",
      phone_number: dataGetCompany?.phone_number || "",
    },
  });

  const [updateCompany] = useUpdateCompanyMutation();

  const onSubmit = (data: ContactDetails) => {
    currentCompanyId &&
      updateCompany({ id: currentCompanyId, ...data })
        .unwrap()
        .then(() => {
          handleModalClose();
        });
  };

  const onError = (errors: object) => {
    Object.values(errors).forEach((item) =>
      setSnackbar({
        children: `${item.message}`,
        severity: "error",
      })
    );
  };

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Stack direction="column" spacing={3}>
          <Typography component="h6" variant="h6">
            Registered Address
          </Typography>

          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Country"
                variant="outlined"
                fullWidth={true}
                error={!!errors.country}
                helperText={errors.country ? errors.country?.message : null}
              />
            )}
          />

          <Controller
            name="address_1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Address Line 1"
                variant="outlined"
                fullWidth={true}
                error={!!errors.address_1}
                helperText={errors.address_1 ? errors.address_1?.message : null}
              />
            )}
          />

          <Controller
            name="address_2"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Address Line 2"
                variant="outlined"
                fullWidth={true}
                error={!!errors.address_2}
                helperText={errors.address_2 ? errors.address_2?.message : null}
              />
            )}
          />

          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="City"
                variant="outlined"
                error={!!errors.city}
                helperText={errors.city ? errors.city?.message : null}
              />
            )}
          />

          <Controller
            name="region"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="State/Region"
                variant="outlined"
                fullWidth={true}
                error={!!errors.region}
                helperText={errors.region ? errors.region?.message : null}
              />
            )}
          />

          <Controller
            name="post_code"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Post Code"
                variant="outlined"
                fullWidth={true}
                error={!!errors.post_code}
                helperText={errors.post_code ? errors.post_code?.message : null}
              />
            )}
          />

          <Divider orientation="horizontal" />

          <Typography component="h6" variant="h6">
            Contact Information
          </Typography>

          <Controller
            name="phone_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Phone Number"
                variant="outlined"
                fullWidth={true}
                error={!!errors.phone_number}
                helperText={
                  errors.phone_number ? errors.phone_number?.message : null
                }
              />
            )}
          />

          <Controller
            name="email_address"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email Address"
                variant="outlined"
                fullWidth={true}
                error={!!errors.email_address}
                helperText={
                  errors.email_address ? errors.email_address?.message : null
                }
              />
            )}
          />

          <Divider orientation="horizontal" />

          <Stack direction="row" spacing={2}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disableElevation={true}
            >
              Update & Close
            </Button>

            <Button
              type="button"
              onClick={handleModalClose}
              variant="outlined"
              size="large"
              color="secondary"
              disableElevation={true}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

export default FormContactDetails;
