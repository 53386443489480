import { FC } from "react";
import Box from "@mui/material/Box";

interface Props {
  children?: React.ReactNode;
}

const FooterDialog: FC<Props> = ({ children }) => {
  return (
    <Box
      component="footer"
      sx={{
        padding: "1.5rem 1.5rem",
        borderTop: "2px solid #E7E6E9",
      }}
    >
      {children}
    </Box>
  );
};

export default FooterDialog;
