import { createContext, FC, useMemo, useState } from "react";

import Container from "@mui/material/Container";

import SubHeader from "../SubHeader";
import DialogPayComponent from "./addEdit/DialogPayComponent";
import DataGridPayComponents from "./DataGridPayComponents";

interface PayComponentContextType {
  payComponentId: number | undefined;
  setPayComponentId: React.Dispatch<React.SetStateAction<number | undefined>>;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CurrentPayComponentContext =
  createContext<PayComponentContextType>({
    payComponentId: undefined,
    setPayComponentId: () => {},
    isEdit: false,
    setIsEdit: () => {},
  });

const DashboardPayComponents: FC = () => {
  const [payComponentId, setPayComponentId] = useState<number | undefined>(
    undefined
  );

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const providerPayComponent = useMemo(
    () => ({ payComponentId, setPayComponentId, isEdit, setIsEdit }),
    [payComponentId, setPayComponentId, isEdit, setIsEdit]
  );

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = ({ isEdit }: { isEdit: boolean }) => {
    setIsEdit(isEdit);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <CurrentPayComponentContext.Provider value={providerPayComponent}>
        <DialogPayComponent
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
        />

        <SubHeader
          title="Pay Components"
          page="pay-components"
          icon="settings_suggest"
          callback={() => handleModalOpen({ isEdit: false })}
        />

        <Container maxWidth="xl" sx={{ width: "100%", marginTop: "2rem" }}>
          <DataGridPayComponents
            handleModalOpenEdit={() => handleModalOpen({ isEdit: true })}
            handleModalOpenAdd={() => handleModalOpen({ isEdit: false })}
          />
        </Container>
      </CurrentPayComponentContext.Provider>
    </>
  );
};

export default DashboardPayComponents;
