export const dataCountries = [
  {
    id: 1,
    name: "Isle of Man",
  },
  {
    id: 2,
    name: "England",
  },
  {
    id: 3,
    name: "Scotland",
  },
  {
    id: 4,
    name: "Wales",
  },
  {
    id: 5,
    name: "Northern Ireland",
  },
  {
    id: 6,
    name: "Republic of Ireland",
  },
  {
    id: 7,
    name: "Jersey",
  },
  {
    id: 8,
    name: "Guernsey",
  },
  // {
  //   id: 99,
  //   name: "Vietnam",
  // },
];
