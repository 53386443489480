import { FC, useState, useEffect } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  idleRemaining: number;
  promptBeforeIdle: number;
}

const ProgressIdleTimer: FC<Props> = ({ idleRemaining, promptBeforeIdle }) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const offestIdleRemaining = idleRemaining - 1;
    const remainingPercentage = Math.ceil(
      (offestIdleRemaining / (promptBeforeIdle / 1000)) * 100
    );
    setProgress(remainingPercentage);
  }, [idleRemaining, promptBeforeIdle]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        width: "2.5rem",
        height: "2.5rem",
      }}
    >
      <CircularProgress variant="determinate" value={progress} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${idleRemaining}s`}</Typography>
      </Box>
    </Box>
  );
};

export default ProgressIdleTimer;
