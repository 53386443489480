import { FC } from "react";
import ListItemMemberProspective from "./ListItemMemberProspective";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

interface Props {
  membersProspective: string[];
  removeMemberProspective: (email_address: string) => void;
}

const ListMembersProspective: FC<Props> = ({
  membersProspective,
  removeMemberProspective,
}) => {
  return (
    <>
      <Stack direction="column" gap={2}>
        <Box component="header" sx={{ display: "flex" }}>
          <Typography
            variant="overline"
            display="block"
            sx={{
              fontWeight: "bold",
              // marginBottom: "1rem",
              marginRight: "0.5rem",
            }}
          >
            Members to be invited
          </Typography>

          <Chip
            label={membersProspective.length}
            size="small"
            color="primary"
          />
        </Box>

        <Typography>
          Press submit below to invite the following members:
        </Typography>

        <Stack
          direction="column"
          gap={0}
          sx={{
            padding: "0.125rem 0 0.25rem",
            border: "1px solid #E7E6E9",
            borderRadius: "0.5rem",
          }}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          {membersProspective.length > 0 ? (
            membersProspective?.map((email_address) => (
              <ListItemMemberProspective
                key={email_address}
                emailAddress={email_address}
                removeMemberProspective={removeMemberProspective}
              />
            ))
          ) : (
            <ListItemMemberProspective />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default ListMembersProspective;
