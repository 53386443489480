import { FC } from "react";

import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import ProgressIdleTimer from "./ProgressIdleTimer";

interface Props {
  dialogOpen: boolean;
  idleRemaining: number;
  promptBeforeIdle: number;
  handleStillHere: () => void;
}

const DialogIdleTimer: FC<Props> = ({
  dialogOpen,
  idleRemaining,
  promptBeforeIdle,
  handleStillHere,
}) => {
  return (
    <>
      <Dialog open={dialogOpen}>
        <DialogTitle>Session Expiring</DialogTitle>
        <Stack
          direction="column"
          gap={4}
          sx={{ maxWidth: "24rem", padding: "1.5rem" }}
        >
          <Stack direction="row" gap={4}>
            <ProgressIdleTimer
              idleRemaining={idleRemaining}
              promptBeforeIdle={promptBeforeIdle}
            />

            <Typography>
              For security reasons, inactive sessions will be logged out.
            </Typography>
          </Stack>

          <Button
            type="button"
            onClick={handleStillHere}
            variant="contained"
            size="large"
            color="primary"
            disableElevation={true}
            sx={{ maxWidth: "10rem" }}
          >
            Continue
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};

export default DialogIdleTimer;
