import { FC } from "react";

import Container from "@mui/material/Container";
// import Typography from "@mui/material/Typography";

import SubHeader from "../SubHeader";
import TableNicRates from "./TableNicRates";

const DashboardNicRates: FC = () => {
  return (
    <>
      <SubHeader
        title="National Insurance Contributions Rates"
        icon="description"
      />

      <Container
        maxWidth="xl"
        sx={{
          width: "100%",
          marginBottom: "8rem",
        }}
      >
        <TableNicRates />
      </Container>
    </>
  );
};

export default DashboardNicRates;
