import { FC } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import { EmploymentInformation } from "../ValidationEmploymentInformation";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import HeaderSection from "../../HeaderSection";
import FormDivider from "../../FormDivider";

interface Props {
  control: Control<EmploymentInformation, any>;
  errors: {
    job_title?: FieldError | undefined;
  };
}

const SectionJobDetails: FC<Props> = ({ control, errors }) => {
  return (
    <>
      <Box>
        <HeaderSection title="Job Details" optional />

        {/* Input : Job Title */}
        <Controller
          control={control}
          name="job_title"
          render={({ field }) => (
            <TextField
              {...field}
              label="Job Title"
              sx={{ marginBottom: "2rem" }}
              variant="outlined"
              disabled={false}
              error={!!errors.job_title}
              helperText={errors.job_title?.message}
              fullWidth={true}
            />
          )}
        />
      </Box>

      <FormDivider mt={2} mb={4} />
    </>
  );
};

export default SectionJobDetails;
