import { cloudPayrollApi } from "./cloudPayrollApi";

import { PayrollPayRate } from "../../types/PayrollPayRate";

const apiPayrollPayRates = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // CREATE Payroll Pay Rate
    createPayrollPayRate: builder.mutation<void, Partial<PayrollPayRate>>({
      query: (payrollPayRate) => ({
        url: `/payroll-pay-rates`,
        method: "POST",
        body: payrollPayRate,
      }),
      invalidatesTags: ["PayrollPayRate", "PayrollEntry"],
    }),

    // READ All Payroll Pay Rates
    getAllPayrollPayRates: builder.query<PayrollPayRate[], number | void>({
      query: (limit = 10) => `/payroll-pay-rates?limit=${limit}`,
      providesTags: ["PayrollPayRate", "PayrollEntry"],
    }),

    // READ All Payroll Pay Rates by Payroll Entry ID
    getAllPayrollPayRatesByPayrollEntryId: builder.query<
      PayrollPayRate[],
      string | undefined
    >({
      query: (payrollEntryId) =>
        `/payroll-pay-rates/payroll-entry/${payrollEntryId}`,
      providesTags: ["PayrollPayRate", "PayrollEntry"],
    }),

    // READ Single Payroll Pay Rate
    getPayrollPayRate: builder.query<PayrollPayRate, string>({
      query: (id) => `/payroll-pay-rates/${id}`,
      providesTags: ["PayrollPayRate", "PayrollEntry"],
    }),

    // UPDATE Payroll Pay Rate
    updatePayrollPayRate: builder.mutation<void, Partial<PayrollPayRate>>({
      query: ({ id, ...rest }) => ({
        url: `/payroll-pay-rates/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["PayrollPayRate", "PayrollEntry"],
    }),

    // DELETE Payroll Pay Rate
    deletePayrollPayRate: builder.mutation<void, string>({
      query: (id) => ({
        url: `/payroll-pay-rates/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PayrollPayRate", "PayrollEntry"],
    }),
  }),
  overrideExisting: false,
});

export const {
  endpoints,
  useCreatePayrollPayRateMutation,
  useGetAllPayrollPayRatesQuery,
  useGetAllPayrollPayRatesByPayrollEntryIdQuery,
  useGetPayrollPayRateQuery,
  useUpdatePayrollPayRateMutation,
  useDeletePayrollPayRateMutation,
} = apiPayrollPayRates;
