import { Dispatch, FC, SetStateAction } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  useLazyGetPayrollQuery,
  useProvisionPayrollMutation,
} from "../../../../features/api/apiPayrolls";
import { useDispatch } from "react-redux";
import { updateProvisionedPayroll } from "../../../../features/state/payroll/payrollSlice";

interface Props {
  selectedPayroll: string;
  canProgress: boolean;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  handleStepperNext: () => void;
}

const CtaPayrollCreate: FC<Props> = ({
  selectedPayroll,
  canProgress,
  loading,
  setLoading,
  handleStepperNext,
}) => {
  const dispatch = useDispatch();

  const [provisionPayroll] = useProvisionPayrollMutation();

  const [triggerGetPayroll] = useLazyGetPayrollQuery();

  const handlePayrollSubmit = async () => {
    setLoading(true);

    provisionPayroll(selectedPayroll)
      .unwrap()
      .then(() => {
        const provisionedPayroll = triggerGetPayroll(selectedPayroll);

        return provisionedPayroll;
      })
      .then((response) => {
        const dataProvisionedPayroll = response.data;

        dataProvisionedPayroll &&
          dispatch(updateProvisionedPayroll(dataProvisionedPayroll));
      })
      .then(() => {
        handleStepperNext();
      })
      .catch((error) => console.error("rejected", error));
  };

  return (
    <>
      <LoadingButton
        sx={{ minWidth: "200px" }}
        variant="contained"
        disabled={!canProgress}
        loading={loading}
        onClick={() => {
          handlePayrollSubmit();
        }}
      >
        Create Payroll
      </LoadingButton>
    </>
  );
};

export default CtaPayrollCreate;
