import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { UserSignUp } from "../../../types/UserSignUp";

const initialState: UserSignUp = {
  email_address: "",
};

const userSignUpSlice = createSlice({
  name: "userSignUp",
  initialState,
  reducers: {
    setCurrentUserSignUp: (
      state,
      action: PayloadAction<Partial<UserSignUp>>
    ) => {
      Object.entries(action.payload ?? {}).forEach(([k, v]) => {
        state[k] = v;
      });
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setCurrentUserSignUp, resetState } = userSignUpSlice.actions;

export default userSignUpSlice.reducer;
