import { cloudPayrollApi } from "./cloudPayrollApi";

// import { EmployeeScheme } from "../../types/EmployeeScheme";

// TODO(DBB) : Tidy Zod types
import { EmployeeScheme } from "../../pages/employees/addEdit/04-schemes/ValidationScheme";

const apiEmployeeSchemes = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // CREATE Employee Scheme
    // createEmployeeScheme: builder.mutation<void, Partial<EmployeeScheme>>({
    //   query: (employeeScheme) => ({
    //     url: `/employee-schemes`,
    //     method: "POST",
    //     body: employeeScheme,
    //   }),
    //   invalidatesTags: ["EmployeeScheme"],
    // }),
    createEmployeeScheme: builder.mutation<void, Partial<EmployeeScheme>>({
      query: (employeeScheme) => ({
        url: `/employee-schemes`,
        method: "POST",
        body: employeeScheme,
      }),
      invalidatesTags: ["EmployeeScheme"],
    }),

    // READ All Employee Schemes
    getAllEmployeeSchemes: builder.query<EmployeeScheme[], number | void>({
      query: (limit = 10) => `/employee-schemes?limit=${limit}`,
      providesTags: ["EmployeeScheme"],
    }),

    // READ All Employee Schemes by Employee ID
    getAllEmployeeSchemesByEmployeeId: builder.query<
      EmployeeScheme[],
      number | undefined
    >({
      query: (employeeId) => `/employee-schemes/employee/${employeeId}`,
      providesTags: ["EmployeeScheme"],
    }),

    // READ Single Employee Scheme
    getEmployeeScheme: builder.query<EmployeeScheme, number>({
      query: (id) => `/employee-schemes/${id}`,
      providesTags: ["EmployeeScheme"],
    }),

    // UPDATE Employee Scheme
    updateEmployeeScheme: builder.mutation<void, Partial<EmployeeScheme>>({
      query: ({ id, ...rest }) => ({
        url: `/employee-schemes/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["EmployeeScheme"],
    }),

    // DELETE Employee Scheme
    deleteEmployeeScheme: builder.mutation<void, number>({
      query: (id) => ({
        url: `/employee-schemes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EmployeeScheme"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateEmployeeSchemeMutation,
  useGetAllEmployeeSchemesQuery,
  useGetAllEmployeeSchemesByEmployeeIdQuery,
  useGetEmployeeSchemeQuery,
  useLazyGetEmployeeSchemeQuery,
  useUpdateEmployeeSchemeMutation,
  useDeleteEmployeeSchemeMutation,
} = apiEmployeeSchemes;
