import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertProps } from "@mui/material/Alert";

import DialogCancel from "./DialogCancel";

import { useUpdatePayrollMutation } from "../../../../features/api/apiPayrolls";
import { Payroll } from "../../../../types/Payroll";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";

interface Props {
  handleStepperPrev: () => void;
  handleStepperNext: () => void;
}

const Footer: FC<Props> = ({ handleStepperPrev, handleStepperNext }) => {
  const provisionedPayrollId: string = useAppSelector(
    (state: RootState) => state.provisionedPayroll.id
  );

  const [loadingSave, setLoadingSave] = useState(false);

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const [updatePayroll] = useUpdatePayrollMutation();

  const navigate = useNavigate();

  const handleSave = async () => {
    setLoadingSave(true);

    const timeout = (ms: number) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    const snackbarSaveProgress = setSnackbar({
      children: "Saving draft payroll...",
      severity: "info",
    });

    let falseLoad = await Promise.all([snackbarSaveProgress, timeout(2500)]);

    const snackbarSaveComplete = setSnackbar({
      children: "Payroll saved!",
      severity: "success",
    });

    let falseLoadComplete = await Promise.all([
      falseLoad,
      snackbarSaveComplete,
      timeout(1250),
    ]);

    falseLoadComplete && navigate("/payrolls/overview");
  };

  const handleSubmit = () => {
    const dateNow = new Date();

    const submission = {
      id: provisionedPayrollId,
      pay_date_actual: dateNow,
      status: "COMPLETE",
    };

    updatePayroll(submission as Partial<Payroll>)
      .unwrap()
      .then(() => handleStepperNext())
      .catch((error) => console.error(error));
  };

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Box>
          <DialogCancel />

          <Button
            variant="outlined"
            sx={{ ml: "1rem" }}
            onClick={handleStepperPrev}
          >
            Back
          </Button>
        </Box>

        <Box>
          <LoadingButton
            loading={loadingSave}
            variant="outlined"
            color="secondary"
            onClick={handleSave}
          >
            Save as draft
          </LoadingButton>

          <Button
            sx={{ minWidth: "200px", marginLeft: "1rem" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Approve & Run Payroll
          </Button>
        </Box>
      </Box>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

export default Footer;
