import { cloudPayrollApi } from "./cloudPayrollApi";
import { PayrollEntry } from "../../types/PayrollEntry";

const apiPayrollEntries = cloudPayrollApi.injectEndpoints({
  endpoints: (builder) => ({
    // CREATE Payroll Entry
    createPayrollEntry: builder.mutation<void, PayrollEntry>({
      query: (payrollEntry) => ({
        url: `/payroll-entries`,
        method: "POST",
        body: payrollEntry,
      }),
      invalidatesTags: ["PayrollEntry"],
    }),

    // READ All Payroll Entries
    getAllPayrollEntries: builder.query<PayrollEntry[], number | void>({
      query: (limit = 10) => `/payroll-entries?limit=${limit}`,
      providesTags: ["PayrollEntry"],
    }),

    // READ All Payroll Entries by Payroll ID
    getAllPayrollEntriesByPayrollId: builder.query<PayrollEntry[], string>({
      query: (payrollId) => `/payroll-entries/payroll/${payrollId}`,
      providesTags: ["PayrollEntry"],
    }),

    // READ Single Payroll Entry
    getPayrollEntry: builder.query<PayrollEntry, string>({
      query: (id) => `/payroll-entries/${id}`,
      providesTags: ["PayrollEntry"],
    }),

    // UPDATE Payroll Entry
    updatePayrollEntry: builder.mutation<void, PayrollEntry>({
      query: ({ id, ...rest }) => ({
        url: `/payroll-entries/${id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["PayrollEntry"],
    }),

    // RESET Payroll Entry
    // TODO(DBB) : Should this be a GET, as no data is mutated?
    resetPayrollEntry: builder.mutation<void, string>({
      query: (payrollEntryId) => ({
        url: `/payroll-entries/regenerate/${payrollEntryId}`,
        method: "POST",
      }),
      invalidatesTags: ["PayrollEntry"],
    }),

    // DELETE Payroll Entry
    deletePayrollEntry: builder.mutation<void, string>({
      query: (id) => ({
        url: `/payroll-entries/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PayrollEntry"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreatePayrollEntryMutation,
  useGetAllPayrollEntriesQuery,
  useGetAllPayrollEntriesByPayrollIdQuery,
  useLazyGetAllPayrollEntriesByPayrollIdQuery,
  useGetPayrollEntryQuery,
  useUpdatePayrollEntryMutation,
  useResetPayrollEntryMutation,
  useDeletePayrollEntryMutation,
} = apiPayrollEntries;
