import { FC, createContext, useMemo, useState, useEffect } from "react";
import { RootState } from "../../../../app/store";
import { useAppSelector } from "../../../../app/hooks";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import SubHeader from "./SubHeader";
import DialogMembersAdd from "./add/DialogMembersAdd";
import TabsMembers from "./TabsMembers";

import { AlertMessage } from "../../../../types/AlertMessage";
// import CircularProgress from "@mui/material/CircularProgress";
// import LinearProgress from "@mui/material/LinearProgress";

interface MemberAlertType {
  alertMessage: AlertMessage | undefined;
  setAlertMessage: React.Dispatch<
    React.SetStateAction<AlertMessage | undefined>
  >;
}

export const MemberAlertContext = createContext<MemberAlertType>({
  alertMessage: undefined,
  setAlertMessage: () => {},
});

const DashboardMembers: FC = () => {
  const currentCompanyId = useAppSelector(
    (state: RootState) => state.currentCompany.id
  );

  const [modalOpen, setModalOpen] = useState(false);

  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>(
    undefined
  );

  const providerMessageAlert = useMemo(
    () => ({ alertMessage, setAlertMessage }),
    [alertMessage, setAlertMessage]
  );

  return (
    <>
      <MemberAlertContext.Provider value={providerMessageAlert}>
        <SubHeader title="Members" icon="people" setModalOpen={setModalOpen} />

        {currentCompanyId && (
          <DialogMembersAdd
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            currentCompanyId={currentCompanyId}
          />
        )}

        {alertMessage && (
          <Alert severity={alertMessage.severity}>
            <AlertTitle>{alertMessage.title}</AlertTitle>
            {alertMessage.message}
          </Alert>
        )}

        <Container maxWidth="xl" sx={{ width: "100%", marginTop: "2rem" }}>
          <Typography component="h2" variant="h4" sx={{ marginBottom: "1rem" }}>
            Members
          </Typography>

          <TabsMembers />
        </Container>
      </MemberAlertContext.Provider>
    </>
  );
};

export default DashboardMembers;
