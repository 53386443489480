import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { AdditionalInformation } from "../ValidationAdditionalInformation";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import HeaderSection from "../../HeaderSection";

interface Props {
  control: Control<AdditionalInformation, any>;
  // TODO(DBB) : Type me
  errors: any;
}

const SectionContactInformation: FC<Props> = ({ control, errors }) => {
  return (
    <>
      <Box>
        <HeaderSection title="Contact Information" />

        {/* Input : Email Address */}
        <Controller
          control={control}
          name="email_address"
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Email Address"
              sx={{ marginBottom: "2rem" }}
              variant="outlined"
              error={!!errors.email_address}
              helperText={errors.email_address?.message}
              fullWidth={true}
              disabled={false}
            />
          )}
        />

        {/* Input : Phone Number */}
        <Controller
          control={control}
          name="phone_number"
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Phone Number"
              sx={{ marginBottom: "2rem" }}
              variant="outlined"
              error={!!errors.phone_number}
              helperText={errors.phone_number?.message}
              fullWidth={true}
              disabled={false}
            />
          )}
        />
      </Box>
    </>
  );
};

export default SectionContactInformation;
