import { FC } from "react";
import { useNavigate } from "react-router-dom";

import MuiLink from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

interface SubHeaderProps {
  title: string;
  icon?: string;
  routerPayrollEntryId: string;
  pdfBlobUrl?: string;
}

const SubHeader: FC<SubHeaderProps> = ({
  title,
  icon,
  routerPayrollEntryId,
  pdfBlobUrl,
}) => {
  const navigate = useNavigate();

  return (
    <AppBar position="relative" color="transparent" sx={customStyles.appBar}>
      <Toolbar sx={customStyles.toolBar}>
        <Typography
          variant="body1"
          component="div"
          sx={customStyles.title}
          className="flex"
        >
          {icon && <Icon sx={customStyles.icon}>{icon}</Icon>}
          {title}
        </Typography>

        <Button
          variant="outlined"
          size="medium"
          // TODO(DBB) : Needs to set activeStep to 4 (Payroll Summary)
          onClick={() => navigate(-1)}
          startIcon={<Icon>chevron_left</Icon>}
        >
          Back
        </Button>

        {pdfBlobUrl && (
          <>
            <Button
              component={MuiLink}
              variant="contained"
              size="medium"
              startIcon={<Icon>receipt_long</Icon>}
              sx={{ marginLeft: "1rem" }}
              href={pdfBlobUrl}
              // TODO(DBB) : Name file properly?
              download={`${routerPayrollEntryId}.pdf`}
            >
              Download PDF
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

const customStyles = {
  appBar: {
    boxShadow: "inset 0px -1px 0px rgba(47, 48, 51, 0.12)",
  },
  toolBar: {
    padding: "16px 32px !important",
    minHeight: "68px !important",
  },
  icon: {
    color: "rgba(47, 48, 51, 0.54)",
    fontSize: "20px",
    marginRight: "10px",
  },
  title: {
    flexGrow: 1,
    fontWeight: 700,
    alignItems: "center",
  },
};

export default SubHeader;
