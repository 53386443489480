import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconRecentActors from "@mui/icons-material/RecentActors";

const ModuleViewEmployees: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Typography
        component="h2"
        variant="subtitle1"
        sx={{
          textTransform: "uppercase",
          fontWeight: "bold",
          marginBottom: "1rem",
        }}
      >
        View Employees
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          maxWidth: "13rem",
          border: "1px dashed #E7E6E9",
          borderRadius: "0.25rem",
          padding: "2rem 1rem 1rem",
          gap: "4rem",
          backgroundColor: "white",
        }}
      >
        <IconRecentActors
          sx={{
            transform: "scale(3)",
            opacity: "0.66",
            marginTop: "2rem",
            alignSelf: "center",
          }}
        />
        <Button
          variant="contained"
          onClick={() => {
            navigate("/employees/all");
          }}
        >
          View all employees
        </Button>
      </Box>
    </>
  );
};

export default ModuleViewEmployees;
